import React from "react";
import {
    Button,
    Drawer, FlexboxGrid,
    Form, IconButton, Input,
    Notification,
    Schema
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import CloseIcon from '@rsuite/icons/Close';
import {FormInstance} from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";
import {pushInforming} from "../../@Utils/Messager";
import TikTokDomainTable from "../Domains/TikTokDomainTable";
import {initialTikTokDomainData} from "../Domains/TikTokDomainModel";

const {StringType, NumberType, ArrayType, ObjectType} = Schema.Types;

const model = Schema.Model({
    data: ArrayType()
        .of(
            ObjectType().shape({
                id: NumberType(),
                adgroup_id: NumberType('Adgroup ID must be a number.').isRequired('Required.'),
                subid: StringType().isRequired('Required.'),
                domain: StringType().isRequired('Required.')
            }),
        ),
});

type TikTokDomainProps = {
    formData: any;
    isOpen: any;
    setOpen: any;
    closeDrawer: () => void;
    adId: number;
    action: any;
    setAdId: any;
    onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};
const ErrorMessage = ({ children }:any) => <span style={{ color: 'red' }}>{children}</span>;
const Cell = ({ children, style, ...rest }:any) => (
    <td style={{ padding: '2px 4px 2px 0', verticalAlign: 'top', ...style }} {...rest}>
        {children}
    </td>
);
const ProductItem = ({ rowValue = {}, action, count, onChange, handleMinus, rowIndex, rowError}:any) => {
    const handleChangeDomain = (value: any) => {
        onChange(rowIndex, { ...rowValue, domain: value });
    };
    const handleChangeAdId = (value: any) => {
        onChange(rowIndex, { ...rowValue, adgroup_id: value });
    };
    const handleChangeSubId =(value:any) => {
        onChange(rowIndex, { ...rowValue, subid: value });
    };

    const handleRemove = () => {
        handleMinus(rowValue.id)
    }

    return (
        <tr>
            <Cell>
                <Input value={rowValue.domain} onChange={handleChangeDomain} style={{ width: 196 }} />
                {rowError  ? <ErrorMessage>{rowError.domain.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <Input value={rowValue.adgroup_id} onChange={handleChangeAdId} style={{ width: 196 }} />
                {rowError  ? <ErrorMessage>{rowError.adgroup_id.errorMessage}</ErrorMessage> : null}
            </Cell>
            <Cell>
                <Input value={rowValue.subid} onChange={handleChangeSubId} style={{ width: 196 }} />
                {rowError  ? <ErrorMessage>{rowError.subid.errorMessage}</ErrorMessage> : null}
            </Cell>
            {action ==='create' && count > 1 ?
                <Cell colSpan={2} >
                    <IconButton onClick={handleRemove} icon={<CloseIcon />} />
                </Cell> : null
            }
        </tr>
    );
};

const ProductInputControl = ({ value = [], action, onChange, fieldError, saveButtonLoading }:any) => {

    const errors = fieldError ? fieldError.array : [];
    const [products, setProducts] = React.useState(value);
    React.useEffect(() => {
        setProducts(value);
    }, [value]);
    const handleChangeProducts = (nextProducts: any) => {
        setProducts(nextProducts);
        onChange(nextProducts);
    };
    const handleInputChange = (rowIndex:any, value:any) => {
        const nextProducts = [...products];
        nextProducts[rowIndex] = value;
        handleChangeProducts(nextProducts);
    };
    const handleAdd = () => {
        const maxId = getMaxId(value);
        handleChangeProducts(products.concat([{
            id: maxId + 1,
            domain: "",
            adgroup_id: "",
            subid: ""
        }]));
    };

    const handleMinus = (id: any) => {
        handleChangeProducts(products.filter((item: any) => item.id != id));
    };
    return (
        <table>
            <thead>
            {action === 'create' ?
            <tr>
                <Cell colSpan={2} style={{ paddingTop: 10 }}>
                    <IconButton
                        onClick={handleAdd}
                        icon={<PlusIcon />}
                        placement="left"
                        size="sm"
                        appearance="ghost"
                        style={{marginRight: 15}}
                    >
                    Add Row
                </IconButton>
                </Cell>
            </tr> : null }
            <tr>
            </tr>
            <tr>
                <Cell>Domain</Cell>
                <Cell>Adgroup ID</Cell>
                <Cell>SUB ID</Cell>
            </tr>
            </thead>
            <tbody>
            {products.map((rowValue: any, index:any) => (
                <ProductItem
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    rowError={errors[index] ? errors[index].object : null}
                    handleMinus = {handleMinus}
                    onChange={handleInputChange}
                    action = {action}
                    count = {products.length}
                    saveButtonLoading = {saveButtonLoading}
                />
            ))}
            </tbody>
            <tfoot>

            </tfoot>
        </table>
    );
};

const TikTokDomain: React.FC<TikTokDomainProps> = ({
  formData,
  isOpen,
  setOpen,
  closeDrawer,
  adId,
  action,
  setAdId,
  onAfterCreateOrUpdateGridRow
}) => {
  const [formError, setFormError] = React.useState<any>({});
  const [formValue, setFormValue] = React.useState(formData);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const mainFormRef = React.createRef<FormInstance>();
  const data = addIds(formValue?.data ? formValue.data : []);

  React.useEffect(() => {
    setFormValue(formData);
  }, [formData]);
    const handleChange = (data: any) => changeFormValue(data);

    const changeFormValue = (data: any) => {
        setFormValue(data);
    };
    const onChangeData = (values: any) => {
        setFormValue({
            data: values,
        });
    };
    const handleSave = () => {
        const node = mainFormRef && mainFormRef.current;

        if (node?.check && !node.check()) {
            return;
        }

        setSaveButtonLoading(true);

        if (action === 'create') {

            // Create a new one
            HttpClient
                .post<object, any>('tiktok_subid_mapping', formValue)

                .then(res => {
                    const adId = parseInt(res.data.adgroup_id);
                    const type = res.status === 201 ? "success" : "error";
                    const text = res.status === 201 ? "Sub ID mapping has been created" : res.statusText;
                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
                    // Update form data
                    const updatedFormValue = {
                        ...formValue,
                        adgroup_id: res.data.adgroup_id,
                    };
                    setAdId(adId);
                    setFormValue(formValue);
                    setSaveButtonLoading(false);
                    setOpen(false)
                    // Add a new row into a grid data

                    onAfterCreateOrUpdateGridRow(res.data.adgroup_id, formValue.data);
                    setFormValue({...initialTikTokDomainData})

                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        } else {
            // Update an existing one
            HttpClient
                .put<any>(`tiktok_subid_mapping/${formData.data[0].adgroup_id}`, {
                    action: action,
                    data: formValue.data[0],
                    oldData: formData
                })
                .then(res => {
                    const type = res.status === 200 ? "success" : "error";
                    const text = res.status === 200 ? "Sub ID mapping has been updated" : res.statusText;
                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
                    setSaveButtonLoading(false);
                    setOpen(false)
                    const updateData = formValue.data;
                    // Update a grid data
                    onAfterCreateOrUpdateGridRow(parseInt(formData.adgroup_id), updateData[0]);
                    setFormValue({...initialTikTokDomainData})
                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        }
        setFormError({});
    };

    return (
        <>
            <Drawer open={isOpen} backdrop={false} onClose={() => closeDrawer()} size="lg">
                <Drawer.Header>
                    <Drawer.Title>
                        {adId === 0 ? 'Add TikTok Domain' : 'Edit TikTok Domain'}
                    </Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setFormError({});
                            }}
                        >
                            Cancel
                        </Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={12}>
                            <Form
                                ref={mainFormRef}
                                onChange={handleChange}
                                onCheck={setFormError}
                                checkTrigger="blur"
                                model={model}
                                layout="horizontal"
                                formValue={formValue}
                                formError={formError}
                            >
                            <>
                                <FlexboxGrid justify="space-between" style={{marginBottom: 15}}>
                                    <FlexboxGrid.Item colspan={24}>

                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                                <TikTokDomainTable
                                    domains={ProductInputControl}
                                    fieldError={formError.data}
                                    data = {data}
                                    action ={action}
                                />
                            </>
                            </Form>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Drawer.Body>
            </Drawer>
        </>
    );
};

const defaultIdKey = 'id';
const addIds = (data: any, idKey: string = defaultIdKey) =>
    data.map((item: any, index: number) => ({
        ...item,
        [idKey]: index + 1
    }));
const getMaxId = (data: object[], idKey: string = defaultIdKey) => Math.max(...data.map((item: any) => item[idKey]));

export default TikTokDomain;
