import React from "react";
import Dashboard from "../@Components/Dashboard";
import MediaBuyerPerformanceReportTree from "../@Components/Media/MediaBuyerPerformanceReportTree";

const MediaBuyingPerformanceReport: React.FC = () => {
    return (
        <Dashboard>
            <MediaBuyerPerformanceReportTree />
        </Dashboard>
    );
};

export default MediaBuyingPerformanceReport;
