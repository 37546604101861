import React from "react";
import {
  Button,
  Checkbox,
  FlexboxGrid,
  IconButton,
  Loader,
  Pagination,
  Table,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { getSummaryByProperties, paginateData } from "../../@Utils/DataProcessing";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import { TableInstance } from "rsuite/esm/Table/Table";
import { format } from "date-fns";
import { currency, thousands } from "../../@Utils/Format";

import EditModal from "../OfferSources/EditModal";
import { FormInstance } from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";
import AdGroupBudgetForm from "./AdGroupBudgetForm";
import { pushErrorNotification } from "../../@Utils/Messager";
import { RowDataType } from "rsuite-table/src/@types/common";
import RelatedMapIcon from '@rsuite/icons/RelatedMap';
import { AD_GROUP_STATUS_ENABLE } from "../Campaigns/AdGroupModel";

const {Column, HeaderCell, Cell} = Table;

const rowKey: string = "node_id";

type DomainDirectSummaryListProps = {
  data?: RowDataType[];
  dataFetchParams?: Record<string, any>;
  page: number;
  setPage: (page: number) => void;
  loading?: boolean;
  onChangeGridConfiguration?: (columns: string[], order: string[]) => void;
  onChangeCampaignStatus?: (nodeId: string, checked: boolean) => void;
  onChangeAdGroupStatus?: (nodeId: string, checked: boolean) => void;
  isGropedByDate?: boolean;
  setIsGropedByDate?: (value: boolean) => void;
};

const DomainDirectSummaryList: React.FC<DomainDirectSummaryListProps> = ({
  data = [],
  dataFetchParams = {},
  page = 1,
  setPage,
  loading = false,
  onChangeGridConfiguration,
  onChangeCampaignStatus,
  onChangeAdGroupStatus,
  isGropedByDate = false,
  setIsGropedByDate,
}) => {
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState("campaign_id");
  const [limit, setLimit] = React.useState(10);
  const tableRef = React.useRef<TableInstance>(null);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  // Sort and paginate data, and add aggregation values as the last row
  const builtData = React.useMemo(() => {
    const totalCount = data.length;

    if (totalCount === 0) {
      return [];
    }

    // Calculate summary values
    const summary = getSummaryByProperties(data, [
      'spend', 'conversion', 'impressions', 'clicks', 'revenue',
      'cpa', 'ctr', 'cpc', 'cpm', 'rpc', 'conversion_rate', 'profit',
      's1clicks', 's1impressions', 'roi'
    ]);


    // Calculate average values
    const aggregations = {
      ...summary,
      cpa: summary.cpa / totalCount || 0,
      ctr: summary.ctr / totalCount || 0,
      cpc: summary.cpc / totalCount || 0,
      cpm: summary.cpm / totalCount || 0,
      rpc: summary.rpc / totalCount || 0,
      roi: summary.spend !== 0 ? (summary.profit / summary.spend) * 100 : 0 || 0,
      conversion_rate: summary.conversion_rate / totalCount || 0,
    };

    // Add aggregation data as the last row
    return [
      ...paginateData(sort(data, sortColumn, sortType), page, limit),
      {
        ...aggregations,
        // Added tree node values
        [rowKey]: -1,
        parent_title: 'Summary',
      },
    ];
  }, [data, limit, page, sortColumn, sortType]);

  const columns = React.useMemo(() => [
    {
      dataKey: 'spend',
      label: 'Spend',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'revenue',
      label: 'Revenue',
      width: 100,
      align: 'right',
      renderFn: (rowData: any) => {
        return rowData.stat_date || rowData[rowKey] === -1 ? currency(rowData.revenue) : '';
      }
    },
    {
      dataKey: 'profit',
      label: 'Profit',
      width: 100,
      align: 'right',
      renderFn: (rowData: any) => {
        return rowData.stat_date || rowData[rowKey] === -1 ? currency(rowData.profit) : '';
      }
    },
    {
      dataKey: 'conversion',
      label: 'Conversion',
      width: 110,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 'cpa',
      label: 'CPA',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'impressions',
      label: 'Impressions',
      width: 110,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 's1impressions',
      label: 'G Impressions',
      width: 110,
      align: 'right',
      renderFn: (rowData: any) =>
        rowData.stat_date || rowData[rowKey] === -1 ? thousands(rowData.s1impressions) : '',
    },
    {
      dataKey: 'clicks',
      label: 'Clicks',
      width: 100,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 's1clicks',
      label: 'G Clicks',
      width: 110,
      align: 'right',
      renderFn: (rowData: any) =>
        rowData.stat_date || rowData[rowKey] === -1 ? thousands(rowData.s1clicks) : '',
    },
    {
      dataKey: 'ctr',
      label: 'CTR',
      width: 100,
      align: 'right',
      type: 'percent',
    },
    {
      dataKey: 'cpc',
      label: 'CPC',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'cpm',
      label: 'CPM',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'rpc',
      label: 'RPC',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'conversion_rate',
      label: 'Conversion Rate',
      width: 140,
      align: 'right',
      type: 'percent',
    },
    {
      dataKey: 'roi',
      label: 'ROI',
      width: 140,
      align: 'right',
      type: 'percent',
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  React.useEffect(() => {

    if (onChangeGridConfiguration instanceof Function) {
      onChangeGridConfiguration(visibleColumnKeys, orderedColumnKeys);
    }

  }, [visibleColumnKeys, orderedColumnKeys]);

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  const handleChangeCampaignStatus = (rowData: any, checked: boolean) => {
    if (typeof onChangeCampaignStatus === "function") {
      onChangeCampaignStatus(rowData[rowKey], checked);
    }
  };

  const handleChangeAdGroupStatus = (rowData: any, checked: boolean) => {
    if (typeof onChangeAdGroupStatus === "function") {
      onChangeAdGroupStatus(rowData[rowKey], checked);
    }
  };

  const handleSetBudgetButtonClick = (rowData: any) => {
    setAdGroupBudgetFormValue({
      adgroup_id: rowData.adgroup_id,
      adgroup_name: rowData.adgroup_name,
      budget_mode: 'BUDGET_MODE_DAY',
      budget: null,
    });
    loadAdGroupBudget(rowData.adgroup_id);
    setIsAdGroupBudgetModalOpen(true);
  };

  const [isAdGroupBudgetDataLoading, setIsAdGroupBudgetDataLoading] = React.useState(false);
  const [isAdGroupBudgetModalOpen, setIsAdGroupBudgetModalOpen] = React.useState(false);
  const [isAdGroupBudgetSaveButtonLoading, setIsAdGroupBudgetSaveButtonLoading] = React.useState(false);
  const adGroupBudgetFormRef = React.createRef<FormInstance>();
  const [adGroupBudgetFormValue, setAdGroupBudgetFormValue] = React.useState({});

  const handleEditModalClose = () => {
    setIsAdGroupBudgetModalOpen(false);
  };

  const handleAdGroupBudgetSave = () => {
    const node = adGroupBudgetFormRef && adGroupBudgetFormRef.current;

    if (node?.check && node.check()) {
      saveAdGroupBudget(adGroupBudgetFormValue);
    }
  };

  const saveAdGroupBudget = (data: any) => {
    setIsAdGroupBudgetSaveButtonLoading(true);

    data.api_adgroup_id = data.adgroup_id;

    HttpClient.put<any>('tiktok_ad_group_budget', data)
      .then((res) => {
        setIsAdGroupBudgetModalOpen(false);
      })
      .catch((error) => {
        pushErrorNotification(error.response.data?.error || error.toString());
      })
      .finally(() => {
        setIsAdGroupBudgetSaveButtonLoading(false);
      });
  };

  // Load tiktok ad group data
  const loadAdGroupBudget = (adgroup_id: string) => {
    setIsAdGroupBudgetDataLoading(true);

    HttpClient
      .get<any>(`tiktok_ad_group_budget/${adgroup_id}`)
      .then(res => {
        setAdGroupBudgetFormValue({
          ...adGroupBudgetFormValue,
          ...res.data,
        });
      })
      .catch((error) => {
        pushErrorNotification(error.response.data?.error || error.toString());
      })
      .finally(() => {
        setIsAdGroupBudgetDataLoading(false);
      });
  };

  const handleAdGroupBudgetFormDataChange = (formValue: any) => {
    setAdGroupBudgetFormValue(formValue);
  };


  // Show all rows by default. The "defaultExpandAllRows" parameter is not working
  const expandedRowKeys = builtData
    .filter((item: any) => !!item[rowKey])
    .map((item: any) => item[rowKey]);

  return (
    <>
      <FlexboxGrid justify="end" style={{marginTop: 8}}>
        <FlexboxGrid.Item colspan={11}>
          <Whisper
            trigger="hover"
            placement="leftStart"
            speaker={<Tooltip>Refresh data by "Go" button<br/>to change grouping</Tooltip>}
          >
            <span>{/*Wrap with span for proper work of tooltip*/}
              <Checkbox
                checked={isGropedByDate}
                onChange={(value, checked) => {
                  if (setIsGropedByDate instanceof Function) setIsGropedByDate(checked);
                }}
              >
                Group by Date
              </Checkbox>
            </span>
          </Whisper>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
            columns={columns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          ref={tableRef}
          isTree
          defaultExpandAllRows={true}
          shouldUpdateScroll={false}
          autoHeight
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          // Hide collapse|expand icon
          renderTreeToggle={() => <></>}
          data={builtData}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={handleSortColumn}
          rowHeight={43}
          className="adm-aggregated-table adm-expanded-tree"
        >

          {dataFetchParams.is_grouped_by_date
            ?
            <Column width={110} align="left" fixed sortable resizable>
              <HeaderCell>Date</HeaderCell>
              <Cell dataKey="stat_date">
                {(rowData: any) =>
                  rowData[rowKey] === -1
                    ? rowData.parent_title
                    : (rowData.stat_date ? <span>{format(new Date(rowData.stat_date), "Y-MM-dd")}</span> : null)
                }
              </Cell>
            </Column>
            : null
          }

          <Column width={250} align="left" fixed sortable resizable>
            <HeaderCell>Domain</HeaderCell>
            <Cell>
              {(rowData: any) => {
                // Summary row output
                if (rowData[rowKey] === -1 && !isGropedByDate)
                  return rowData.parent_title;

                // Don't output this for children rows
                if (rowData.isParentRow) {
                  return rowData.domain;
                }

                return <></>;
              }}
            </Cell>
          </Column>
          <Column width={150} align="left" fixed sortable resizable>
            <HeaderCell>SubID</HeaderCell>
            <Cell dataKey="subid">
              {(rowData: any) => {
                if (rowData[rowKey] === -1)
                  return <></>;

                if (rowData.subid == 0 || rowData.subid == null || !rowData.subid ) {
                  return (<>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Redirect to DomainMappingTool</Tooltip>}
                        style={{marginBottom: 5, lineHeight: "16px"}}
                    >
                      <IconButton
                          appearance="subtle"
                          icon={<RelatedMapIcon/>}
                          circle
                          onClick={() => {
                            const url = new URL(window.location.origin + '/tiktok-subid-mapping?domain=' + rowData.domain + '&adgroup_id=' + rowData.adgroup_id);
                            // @ts-ignore
                            window.location.href = url;
                          }}
                      >
                        {rowData.subid }
                      </IconButton>
                    </Whisper>
                  </>);
                }

                return <div style={{padding: 10, marginLeft: 40}}>{rowData.subid}</div>;
              }
              }
            </Cell>
          </Column>
          <Column width={240} align="left" fixed sortable resizable>
            <HeaderCell>AdGroup Name</HeaderCell>

            <Cell dataKey="adgroup_name" className="link-group" >
              {(rowData: any) => {
                // Summary row output
                if (rowData[rowKey] === -1)
                  return <></>;

                const parentRowValue = '---';
                const rowValue = rowData.adgroup_name ? rowData.adgroup_name : (rowData.stat_date ? parentRowValue : '');

                if (rowValue !== parentRowValue) {
                  const status = rowData.adgroup_operation_status === AD_GROUP_STATUS_ENABLE;

                  const StatusChanger =
                    <Whisper
                      trigger="hover"
                      placement="top"
                      speaker={<Tooltip>{status ? 'Deactivate' : 'Activate'}</Tooltip>}
                    >
                      <span style={{marginRight: 5}}> {/*Wrap with span to proper work of tooltip*/}
                        <Toggle
                          loading={rowData.isAdGroupUpdating}
                          checked={status}
                          onChange={() => handleChangeAdGroupStatus(rowData, !status)}
                          size="md"
                          style={{marginBottom: 'auto', marginTop: 'auto'}}
                        />
                      </span>
                    </Whisper>;

                  return (<>
                    {StatusChanger}
                    <Whisper
                      trigger="hover"
                      placement="top"
                      speaker={<Tooltip>Set Ad Group Budget</Tooltip>}
                    >
                      <Button
                        color="blue"
                        appearance="ghost"
                        style={{marginRight: 2, lineHeight: "16px"}}
                        onClick={() => {
                          handleSetBudgetButtonClick(rowData);
                        }}
                      >
                        <b>$</b>
                      </Button>
                    </Whisper>
                    <span style={{lineHeight: "20px"}}>{rowValue}</span>
                  </>);
                }

                return <div style={{padding: 10}}>{rowValue}</div>;
              }}
            </Cell>
          </Column>
          <Column width={200} align="left" fixed resizable>
            <HeaderCell>Campaign Name</HeaderCell>
            <CampaignWithStatusToggleCell changeStatusHandler={handleChangeCampaignStatus}/>
          </Column>

          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>

      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="lg"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={data.length}
        limitOptions={[10, 20, 50, 100]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />


      <EditModal
        size="sm"
        title="Set Ad Group Budget"
        loading={isAdGroupBudgetSaveButtonLoading}
        open={isAdGroupBudgetModalOpen}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleAdGroupBudgetSave}
      >
        <AdGroupBudgetForm
          adGroupBudgetFormValue={adGroupBudgetFormValue}
          formRef={adGroupBudgetFormRef}
          onFormDataChange={handleAdGroupBudgetFormDataChange}
        />
        {isAdGroupBudgetDataLoading ? <Loader size="lg" content="Data loading..." center backdrop /> : null}
      </EditModal>

    </>
  );
};


export const CampaignWithStatusToggleCell = ({
  rowData,
  changeStatusHandler,
  ...props
}: any) => {
  // Root row that has children
  if (rowData.stat_date && rowData.children.length) {
    return <Cell {...props}><span>---</span></Cell>;
  }

  // Summary row output
  if (rowData[rowKey] === -1) {
    return <Cell {...props}><span></span></Cell>;
  }

  if (!rowData.campaign_name) {
    return <Cell {...props}><span>&mdash;</span></Cell>;
  }

  const status = rowData.campaign_active === 1;

  const StatusChanger =
    <Whisper
      trigger="hover"
      placement="top"
      speaker={<Tooltip>{status ? 'Deactivate' : 'Activate'}</Tooltip>}
    >
      <span style={{marginRight: 5}}> {/*Wrap with span to proper work of tooltip*/}
        <Toggle
          loading={rowData.isCampaignUpdating}
          checked={status}
          onChange={() => changeStatusHandler(rowData, !status)}
          size="md"
          style={{marginBottom: 'auto', marginTop: 'auto'}}
        />
      </span>
    </Whisper>;

  return (
    <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
      {StatusChanger}
      <span>{rowData.campaign_name}</span>
    </Cell>
  );
};

export default DomainDirectSummaryList;