import React, { ReactNode } from "react";
import { FlexboxGrid, IconButton, Notification, Table, toaster, Tooltip, Whisper } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import CopyIcon from '@rsuite/icons/Copy';
import FolderFillIcon from '@rsuite/icons/FolderFill';
import RunningRoundIcon from '@rsuite/icons/RunningRound';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import HttpClient from "../../@Utils/HttpClient";
import { Loader } from 'rsuite';
import ConfirmModal from "../ConfirmModal";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const { Column, HeaderCell, Cell } = Table;

type FunnelsTreeProps = {
  data?: any;
  loading?: any;
  sortType?: any;
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  setIsOpenDrawer: any;
  setFormData: any;
  setFunnelId: any;
  refreshCallback?: () => void;
  setData: any;
  statusFilter: number;
};

const FunnelsTree: React.FC<FunnelsTreeProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn,
  onSortColumn,
  setIsOpenDrawer,
  setFormData,
  setFunnelId,
  refreshCallback,
  setData,
  statusFilter
}) => {
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({ funnel_id: 0 });
  const rowKey: string = "node_id";
  const handleOpen = () => setRemoveConfirmModalOpen(true);
  const handleClose = () => setRemoveConfirmModalOpen(false);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [loadingFunnelStatus, setLoadingFunnelStatus] = React.useState(0);
  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleRemove = () => {
    removeItem(selectedItem ? selectedItem.funnel_id : 0);

    handleClose();
  };

  /**
   * Rewrite list data with formatted data
   * @param data
   */
  const formatData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        ...item,
        funnel_id: !isNaN(parseInt(item.funnel_id)) ? parseInt(item.funnel_id) : 0,
        campaign_id: !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0,
        bid_amount: !isNaN(parseFloat(item.bid_amount)) ? parseFloat(item.bid_amount) : 0,
        spending_limit: !isNaN(parseFloat(item.spending_limit)) ? parseFloat(item.spending_limit) : 0,
        daily_cap: !isNaN(parseFloat(item.daily_cap)) ? parseFloat(item.daily_cap) : 0,
      }
    });
  };

  const mapData = (list:any) => {
  const mappedData = list.map((item:any) => {
    // Modify and return the properties of each item here
    // For example, let's assume you want to extract specific properties and rename them:
    const { campaign_id, funnel_id, funnel_name, campaign_name, visitors, conv, revenue, cost } = item;
    return {
      campaignId: campaign_id,
      funnelId: funnel_id,
      funnelName: funnel_name,
      campaignName: campaign_name || 'UNKNOWN_OR_REMOVED_CAMPAIGN',
      visitors: visitors || 0,
      conversions: conv || 0,
      revenue: revenue || 0,
      cost: cost || 0,
    };
  });

  return mappedData;
};

  /**
   * Transform flat list into a tree structure
   * @param list
   */
  const transformListToTree = (list: any) => {
    list = Array.isArray(list) ? list : [];

    const nodeIdDelimiter = '_';

    if (!list.length) {
      return [];
    }

    // Add node title and node ID values
    const listData = list.map((item: any) => ({
      ...item,
      [rowKey]: nodeIdDelimiter + item.campaign_id + nodeIdDelimiter + item.funnel_id,
      node_level: 2,
      node_title: item.funnel_name,
    }));

    

    // Build groups by campaign ID
    const groupsByCampaign: any = {};
    const campaignsOrder: number[] = [];
    listData.forEach((item: any) => {
      if (!campaignsOrder.find(value => value === item.campaign_id)) {
        campaignsOrder.push(item.campaign_id);
      }

      groupsByCampaign[item.campaign_id] = {
        ...item,
        [rowKey]: nodeIdDelimiter + item.campaign_id,
        node_level: 1,
        node_title: item.campaign_name || 'UNKNOWN_OR_REMOVED_CAMPAIGN',
        visitors: 0,
        conv: 0,
        revenue: 0,
        cost: 0,
        roi: 0,
        pnl: 0,
        cvr: 0,
        cpv: 0,
        rpcv: 0,
        cpcv: 0,
        children: [],
      };
    });

    // Add items into groups by campaign ID as children
    listData.forEach((item: any) => {
      groupsByCampaign[item.campaign_id].children.push(item);
    });

    // Convert object into array in the right order
    const tree = campaignsOrder.map((campaignId) => groupsByCampaign[campaignId]);

    return aggregateDataByColumns(tree, [
      "visitors",
      "conv",
      "revenue",
      "cost",
      "pnl",
      "cpv",
      "rpcv",
      "cpcv",
    ], ["roi", "cvr"]);
  };

  const aggregateDataByColumns = (data: any, columns: string[], avgColumns: string[]) => {
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    data.forEach((node: any) => {
      columns.forEach((field: string) => {
        const values = node.children.map((item: any): number => {
          return parseFloat(item[field]);
        });

        node[field] = values.reduce(summarizer, 0) || 0;
      });

      avgColumns.forEach((field: string) => {
        const values = node.children.map((item: any): number => {
          return parseFloat(item[field]);
        });

        node[field] = (values.reduce(summarizer, 0) / values.length) || 0;
      });
    });

    return data;
  };

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    HttpClient.delete<any>(`funnel/${itemId}`)
      .then(res => {
        const type = res.status === 200 ? "success" : "error";
        const text = res.status === 200 ? "Funnel has been removed" : res.statusText;

        pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);

        if (refreshCallback instanceof Function) {
          refreshCallback();
        }
      })
      .catch(error => {
        pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          {error.toString()}
        </Notification>);
      });
  };

  const handleEditActionClick = (rowData: any) => {
    setFunnelId(rowData.funnel_id)
    setFormData(rowData);
    setIsOpenDrawer(true);
  };

  const handleCopyActionClick = (rowData: any) => {
    const matches = rowData.funnel_name.match(/^(.*)copy\(([0-9]*)\)$/);
    const index = matches && matches.length ? parseInt(matches[2]) + 1 : 1;
    const newName = (matches && matches.length) ? matches[1] : rowData.funnel_name;
    const funnel_id = 0;
    setFunnelId(funnel_id);
    setFormData({
      ...rowData,
      funnel_id,
      funnel_name: `${newName} copy(${index})`
    });
    setIsOpenDrawer(true);
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };


  //Toggle Status between active and archived
  const handleArchiveActionClick = (rowData: any) => {
    setLoadingFunnelStatus(rowData.funnel_id);
    
    let newStatus = rowData.status == '1' ? 0 : 1;
    let itemId = rowData.funnel_id;

    // Change funnel status
    HttpClient.put<any>(`funnel/${itemId}`, {'newStatus' : newStatus})
      .then(res => {
        const type = res.status === 200 ? "success" : "error";
        if(type == 'success') {
          let newDataSet = data.map((row: any) => {
            if(row.funnel_id == itemId) {
              return {...rowData, 'status': newStatus};
            }
            return row;
          });
          
          newDataSet = newDataSet.filter((row: any) => (row.status == statusFilter || statusFilter == 2));
          setData(newDataSet);
        }
        setLoadingFunnelStatus(0);
      })
      .catch(error => {
        pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
        setLoadingFunnelStatus(0);
      });
  };

  const columns = React.useMemo(() => [
    {
      dataKey: 'funnel_id',
      label: 'Funnel ID',
      width: 100,
      align: 'right',
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.FUNNELS_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.FUNNELS_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);



  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }


  return (
    <>
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
            columns={columns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          isTree
          defaultExpandAllRows={true}
          shouldUpdateScroll={false}
          height={600}
          // data={Array.isArray(data) ? transformListToTree(formatData(data)) : []}
          data={Array.isArray(data) ? formatData(data) : []}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={onSortColumn}
          // onRowClick={handleExpanded}
        >
          <Column width={250} align="left" fixed sortable treeCol={true}>
            <HeaderCell>{"Funnel"}</HeaderCell>
            {/* <Cell dataKey="node_title" /> */}
             <Cell dataKey="funnel_name" />
          </Column>
          <Column width={250} align="left" fixed sortable treeCol={true}>
            <HeaderCell>{"Campaign ID"}</HeaderCell>
            {/* <Cell dataKey="node_title" /> */}
             <Cell dataKey="campaign_id" />
          </Column>
          <Column width={300} align="center" fixed>
            <HeaderCell>{"Action"} </HeaderCell>
            <EditActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
              copyHandler={handleCopyActionClick}
              archiveHandler={handleArchiveActionClick}
              loadingFunnelStatus={loadingFunnelStatus}
            />
          </Column>
          {renderColumns(builtColumns, handleDragColumn)}
          
        </Table>
      </DndProvider>

      <ConfirmModal
        title="Removing"
        open={removeConfirmModalOpen}
        onClose={handleClose}
        onYes={handleRemove}
      >
        Are you sure you want to remove this Funnel?
      </ConfirmModal>
    </>
  );
};

export const EditActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  copyHandler,
  archiveHandler,
  loadingFunnelStatus,
  ...props
}: any) =>
  <Cell {...props} className="link-group">
    {/* {rowData.node_level === 1 && */}
      <div>
        <ButtonToolbar>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <IconButton
              icon={<EditIcon />}
              onClick={(e) => editHandler(rowData, e)}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Clone</Tooltip>}
          >
            <IconButton
              icon={<CopyIcon />}
              onClick={(e) => copyHandler(rowData, e)}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>{rowData.status == '1' ? 'Archive':'Activate'}</Tooltip>}
          >
            <IconButton
              icon={loadingFunnelStatus==rowData.funnel_id ? (<Loader/>) : 
                (rowData.status == '1' ? (
                  <FolderFillIcon />
                ) : (
                  <RunningRoundIcon />
                ))}
              onClick={(e) => archiveHandler(rowData, e)}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Delete</Tooltip>}
          >
            <IconButton
              icon={<TrashIcon />}
              onClick={(e) => removeHandler(rowData, e)}
            />
          </Whisper>
        </ButtonToolbar>
      </div>
    {/*} */} 
  </Cell>;

export default FunnelsTree;