import React from "react";
import {Form, Schema, SelectPicker} from "rsuite";
import Field from "../Field";
import HttpClient from "../../@Utils/HttpClient";

const {NumberType, StringType} = Schema.Types;

const model = Schema.Model({
  campaign_id: NumberType(),
  campaign_name: StringType()
    .isRequired("Campaign Name is required"),
  traffic_source_id: NumberType()
    .isRequiredOrEmpty("Traffic Source is required")
    .addRule((value, data) => value > 0, 'Traffic Source is required'),
});

type CampaignType = {
  campaign_id?: number;
  campaign_name?: string;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const Campaign: React.FC<CampaignType> = ({
  campaign_id = 0,
  campaign_name = "",
  formRef = null,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  const [formError, setFormError] = React.useState({});
  const [formValue, setFormValue] = React.useState({
    campaign_id,
    campaign_name,
  });

  // Load traffic source list
  const [trafficSources, setTrafficSources]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('traffic_source', {
        page: 1,
        limit: 5000,
      })
      .then(res => {
        setTrafficSources(res.data.data.map((item: any) => ({
          ...item,
          traffic_source_id: !isNaN(parseInt(item.traffic_source_id)) ? parseInt(item.traffic_source_id) : 0
        })));
      });
  }, []);

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Campaign" name="campaign_name"/>
      <Field
        label="Traffic Source"
        name="traffic_source_id"
        placeholder="Select Traffic Source"
        placement="autoVerticalStart"
        accepter={SelectPicker}
        data={trafficSources}
        valueKey="traffic_source_id"
        labelKey="traffic_source_name"
      />
    </Form>
  );
};

export default Campaign;
