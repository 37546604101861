import React from "react";
import { Panel, Popover, Table, Whisper } from "rsuite";
import { JSONTree } from 'react-json-tree';
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";
import { addHours, format, parse, set } from "date-fns";
import { sort } from "../../@Utils/Sorting";
import SearchIcon from '@rsuite/icons/Search';

const { Column, HeaderCell, Cell } = Table;

type RuleHistoryTableProps = {
  // data?: any;
}

const RuleHistoryTable: React.FC<RuleHistoryTableProps> = ({
  // data = [],
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState("execution_date");

  const fetchData = () => {
    setIsLoading(true);

    // Get data
    HttpClient
      .get<any>('rule_history', {})
      .then(res => {
        const getNextExecutionDate = (dateStr: string, hours: number) => {
          const date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", new Date());
          const nextDate = set(addHours(date, hours), {minutes: 0, seconds: 0, milliseconds: 0});

          return format(nextDate, "yyyy-MM-dd HH:mm:ss");
        }

        setData(res.data.data.map((item: any) => ({
          ...item,
          rule_id: !isNaN(parseInt(item.rule_id)) ? parseInt(item.rule_id, 10) : 0,
          next_execution: getNextExecutionDate(item.execution_date, parseInt(item.run_interval, 10) || 1),
        })));
        setIsLoading(false);
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        setIsLoading(false);
      });
  };

  React.useEffect(fetchData, []);

  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  return <Table
    height={400}
    fillHeight={true}
    data={sort(data, sortColumn, sortType)}
    loading={isLoading}
    sortType={sortType}
    sortColumn={sortColumn}
    onSortColumn={handleSortColumn}
    affixHeader
  >
    <Column width={150} align="right" sortable resizable>
      <HeaderCell>Execution Date</HeaderCell>
      <Cell dataKey="execution_date"/>
    </Column>
    <Column width={150} align="right" sortable resizable>
      <HeaderCell>Next Execution</HeaderCell>
      <Cell dataKey="next_execution"/>
    </Column>
    <Column width={90} align="right" sortable resizable>
      <HeaderCell>Rule ID</HeaderCell>
      <Cell dataKey="rule_id"/>
    </Column>
    <Column width={250} align="left" sortable resizable>
      <HeaderCell>Rule Name</HeaderCell>
      <Cell dataKey="rule_name"/>
    </Column>
    <Column width={70} align="center" resizable>
      <HeaderCell>Results</HeaderCell>
      <Cell dataKey="results">
        {(rowData: any) => {
          const tooltip = (
            <Popover>
              <Panel className="json-tree-wrapper" header={<p>Results</p>}>
                <JSONTree data={isJson(rowData.results) ? JSON.parse(rowData.results) : {}} />
              </Panel>
            </Popover>
          );

          return (
            <Whisper placement="autoHorizontal" trigger="hover" enterable={true} speaker={tooltip}>
              <SearchIcon style={{cursor: "pointer"}} />
            </Whisper>
          );
        }}
      </Cell>
    </Column>
  </Table>;
};

const isJson = (str: string) => {
  try { JSON.parse(str);} catch (e) {return false;}
  return true;
};

export default RuleHistoryTable;