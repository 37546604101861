import React from "react";
import Dashboard from "../@Components/Dashboard";
import FunnelsGrid from "../@Components/Funnels/FunnelsGrid";


const FunnelsReport: React.FC = (props: any) => {
  return (
    <Dashboard>
      <FunnelsGrid campaignId={props.match.params.campaignId}/>
    </Dashboard>
  );
};

export default FunnelsReport;
