import * as React from 'react'
import HttpClient from "../@Utils/HttpClient";
import { useState } from "react";
import { FirebaseAuthContext } from "./FirebaseAuthContext";

type Error = string | null;
type Permissions = any[];
type UserPermissionsProviderProps = {children: React.ReactNode};
type Fn = () => void;

const UserPermissionsContext = React.createContext<
  {
    isLoading: boolean;
    permissions: Permissions;
    error: Error;
    reload: Fn;
  } | undefined
>(undefined)

function UserPermissionsProvider({children}: UserPermissionsProviderProps) {
  const [isLoading, setIsLoading] = useState(true); //Default value is true because of to prevent pre-rendering of child components
  const [permissions, setPermissions] = useState<any[]>([]);
  const [error, setError] = useState<string|null>(null);
  const {isUserInitialized} = React.useContext(FirebaseAuthContext);

  const loadPermissions = (waitAfterLoadingMs: number = 0) => {
    setPermissions([]);
    setIsLoading(true);

    HttpClient.get<any>('user_permissions')
      .then(res => {
        if (!waitAfterLoadingMs) {
          setPermissions(res.data.data);
        } else {
          // Wait to take time to change token value in the localstorage
          setTimeout(() => {
            setPermissions(res.data.data);
          }, waitAfterLoadingMs);
        }
        if (res.data?.data.length === 0) {
          setError('UserPermissionsProvider -> Permissions are empty!');
        }
      })
      .catch((error) => {
        setError(error.response.data?.error || error.toString());
      })
      .finally(() => {
        if (!waitAfterLoadingMs) {
          setIsLoading(false);
        } else {
          // Wait to take time to change token value in the localstorage
          setTimeout(() => {
            setIsLoading(false);
          }, waitAfterLoadingMs);
        }
      });
  };

  // clear, memoized
  const clear = React.useCallback(() => {
    setIsLoading(true);
    setPermissions([]);
    setError(null);
  }, []);

  // Clear data if user sign out
  React.useEffect(() => {
    if (isUserInitialized) {
      loadPermissions();
    } else {
      clear();
    }
  }, [isUserInitialized, setPermissions, clear]);

  // Reload user permissions, memoized
  const reload = React.useCallback(() =>
      loadPermissions(1000)
  , []);

  // Memoize the full context value
  const contextValue = React.useMemo(() => ({
    permissions,
    isLoading,
    error,
    reload,
  }), [permissions, isLoading, error, reload]);

  return (
    <UserPermissionsContext.Provider value={contextValue}>
      {children}
    </UserPermissionsContext.Provider>
  )
}

function useUserPermissions() {
  const context = React.useContext(UserPermissionsContext);
  if (context === undefined) {
    throw new Error('useUserPermissions must be used within a UserPermissionsProvider');
  }
  return context;
}

export {UserPermissionsProvider, useUserPermissions};