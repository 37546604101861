import React from "react";
import {
  Col,
  Grid,
  IconButton,
  InputNumber,
  Notification,
  Pagination,
  Row,
  Table,
  Toggle,
  Tooltip,
  Whisper
} from "rsuite";
import { DEFAULT_LIMIT, paginateData } from "../../@Utils/DataProcessing";
import { sort } from "../../@Utils/Sorting";
import { float } from "../../@Utils/Format";
import { FloatCell, NumberCell } from "../Table";
import ButtonToolbar from "rsuite/ButtonToolbar";
import CheckIcon from "@rsuite/icons/Check";
import ReloadIcon from "@rsuite/icons/Reload";
import TrashIcon from "@rsuite/icons/Trash";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";

const {Column, HeaderCell, Cell} = Table;

type CampaignWidgetListProps = {
  data?: any;
  loading?: any;
  page?: number;
  setPage: any;
  campaignData: any;
  changeWidgetStatus: (rowData: any, checked: boolean) => void;
  changeWidgetBidModifier: (rowData: any, bidModifier: number|null) => void;
};

const CampaignWidgetList: React.FC<CampaignWidgetListProps> = ({
  data = [],
  loading = false,
  page = 1,
  setPage,
  campaignData ,
  changeWidgetStatus,
  changeWidgetBidModifier,
}) => {
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
  const [sortType, setSortType] = React.useState<"desc" | "asc">("asc");
  const [sortColumn, setSortColumn] = React.useState("site");

  // Move to the first page if current page is empty
  React.useEffect(() => {
    if (data.length < (page -1) * limit) {
      setPage(1);
    }
  }, [data, limit, page]);

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleToggleBlocking = (rowData: any, checked: boolean) => {
    changeWidgetStatus(rowData, checked);
  };

  const handleBidSaveActionClick = (rowData: any, value: number, callback: any) => {
    HttpClient
      .put<any>(`campaign_widgets/${rowData.campaign_id}`, {
        account: rowData.account,
        api_campaign_id: rowData.api_campaign_id,
        publisher_id: rowData.site_id,
        publisher: rowData.site,
        bid: value,
      })
      .then((res) => {
        const isStatusSuccess = res.status === 200;
        const type = isStatusSuccess ? "success" : "error";
        const text = res.data.message
          ? res.data.message
          : (isStatusSuccess ? "Publisher Bid Modifier has been changed" : res.statusText);

        if (isStatusSuccess) {
          changeWidgetBidModifier(rowData, value);

          if (typeof callback === "function") {
            callback(value);
          }
        }

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
      })
      .catch((error) => {
        pushErrorNotification(error.response.data?.error || error.toString());
      })
      .finally(() => {
        // setSaveButtonLoading(false);
      });
  };

  const handleBidRemoveActionClick = (rowData: any, callback: any) => {
    HttpClient
      .delete<any>(`campaign_widgets/${rowData.campaign_id}`, {
        data: {
          account: rowData.account,
          api_campaign_id: rowData.api_campaign_id,
          publisher_id: rowData.site_id,
          publisher: rowData.site,
        }
      })
      .then((res) => {
        const isStatusSuccess = res.status === 200;
        const type = isStatusSuccess ? "success" : "error";
        const text = res.data.message
          ? res.data.message
          : (isStatusSuccess ? "Publisher Bid Modifier has been removed" : res.statusText);

        if (isStatusSuccess) {
          changeWidgetBidModifier(rowData, null);

          if (typeof callback === "function") {
            callback(null);
          }
        }

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
      })
      .catch((error) => {
        pushErrorNotification(error.response.data?.error || error.toString());
      });
  };

  return (
    <>
      <Table
        data={paginateData(sort(data, sortColumn, sortType), page, limit)}
        loading={loading}
        height={575}
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        autoHeight
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
      >
        <Column width={55} align="center" fixed verticalAlign="top">
          <HeaderCell>Blocked</HeaderCell>
          <ActionCell toggleBlocking={handleToggleBlocking} />
        </Column>
        <Column width={110} align="right" resizable fixed>
          <HeaderCell>Bid</HeaderCell>
          <Cell>
            {(rowData: any) => {
              return float(campaignData.bid_amount * (rowData.bid_modifier > 0 ? rowData.bid_modifier : 1), 4);
            }}
          </Cell>
        </Column>
        <Column width={236} align="center" fixed>
          <HeaderCell>Bid Modifier</HeaderCell>
          <BidActionCell
            style={{ paddingRight: 0, paddingLeft: 0 }}
            changeHandler={changeWidgetBidModifier}
            saveHandler={handleBidSaveActionClick}
            removeHandler={handleBidRemoveActionClick}
          />
        </Column>
        <Column width={110} align="right" resizable sortable fixed>
          <HeaderCell>Publisher ID</HeaderCell>
          <Cell dataKey="site_id" />
        </Column>
        <Column width={300} align="left" resizable sortable fixed>
          <HeaderCell>Publisher</HeaderCell>
          <Cell dataKey="site" />
        </Column>
        <Column width={250} align="left" resizable sortable>
          <HeaderCell>Publisher Name</HeaderCell>
          <Cell dataKey="site_name" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Taboola Clicks</HeaderCell>
          <NumberCell dataKey="clicks" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Impressions</HeaderCell>
          <NumberCell dataKey="impressions" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Visible Impressions</HeaderCell>
          <NumberCell dataKey="visible_impressions" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Spent</HeaderCell>
          <Cell dataKey="spent" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Revenue</HeaderCell>
          <Cell dataKey="arb_revenue" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Conversions</HeaderCell>
          <Cell dataKey="cpa_actions_num" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>ROAS</HeaderCell>
          <Cell dataKey="roas" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CTR</HeaderCell>
          <FloatCell dataKey="ctr" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>VCTR</HeaderCell>
          <FloatCell dataKey="vctr" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPM</HeaderCell>
          <Cell dataKey="cpm" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>VCPM</HeaderCell>
          <Cell dataKey="vcpm" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPC</HeaderCell>
          <Cell dataKey="cpc" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPA</HeaderCell>
          <Cell dataKey="cpa" />
        </Column>
        <Column width={170} align="right" resizable sortable>
          <HeaderCell>CPA Conversion Rate</HeaderCell>
          <Cell dataKey="cpa_conversion_rate" />
        </Column>
        <Column width={150} align="left" resizable sortable>
          <HeaderCell>Blocking Lvl</HeaderCell>
          <Cell dataKey="blocking_level" />
        </Column>
        <Column width={150} align="left" resizable sortable>
          <HeaderCell>Currency</HeaderCell>
          <Cell dataKey="currency" />
        </Column>
        <Column width={100} align="right" resizable sortable>
          <HeaderCell>Arb Clicks</HeaderCell>
          <NumberCell dataKey="arb_clicks" />
        </Column>
        <Column width={130} align="right" resizable sortable>
          <HeaderCell>S1/Tonic Clicks</HeaderCell>
          <NumberCell dataKey="tonic_clicks" />
        </Column>
        <Column width={100} align="right" resizable sortable>
          <HeaderCell>Arb Cost</HeaderCell>
          <FloatCell dataKey="arb_cost" digits={4} />
        </Column>
        <Column width={120} align="right" resizable sortable>
          <HeaderCell>Arb Revenue</HeaderCell>
          <FloatCell dataKey="arb_revenue" digits={4} />
        </Column>
      </Table>

      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={data.length}
          limitOptions={[10, 20]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  );
};

const ActionCell = ({
  rowData,
  toggleBlocking,
  ...props
}: any) => {
  const status = !!parseInt(rowData.blocked_publisher_id, 10);
  return (
    <Cell {...props} className="link-group" style={{ lineHeight: "10px !important" }}>
      <div>
        <ButtonToolbar>
          <Whisper trigger="hover" placement="top" speaker={<Tooltip>Enable/Disable Toggle</Tooltip>}>
            <Toggle
              loading={rowData.isUpdating}
              checked={status}
              onChange={() => toggleBlocking(rowData, !status)}
              size="md"
              style={{ marginBottom: "auto", marginTop: "auto" }}
            />
          </Whisper>
        </ButtonToolbar>
      </div>
    </Cell>
  );
};

const minBidModifierPercent = 1.1;
const maxBidModifierPercent = 200;

const BidActionCell = ({ rowData, saveHandler, changeHandler, removeHandler, ...props }: any) => {
  const [originalValue, setOriginalValue] = React.useState(rowData.bid_modifier);
  const bidModifierPercent = Math.round(rowData.bid_modifier * 1000)/10;
  // The empty '' string is not a mistake. It's only found way to clean an InputNumber
  const defaultValue = '';

  const changeOriginalCallback = (value: number) => setOriginalValue(value);

  return (
    <Cell {...props} className="link-group">
      <Grid fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <InputNumber
              key={rowData.entry_id}
              value={bidModifierPercent || defaultValue}
              defaultValue={defaultValue}
              step={1}
              min={minBidModifierPercent}
              max={maxBidModifierPercent}
              postfix="%"
              style={{ width: 110 }}
              onChange={(value) => {
                if (!value) {
                  changeHandler(rowData, null);
                }

                value = typeof value === "string" ? parseFloat(value) : value;

                if (isNaN(value)) {
                  changeHandler(rowData, null);
                }

                if (value > maxBidModifierPercent) {
                  value = maxBidModifierPercent;
                }

                changeHandler(rowData, parseFloat((value / 100).toFixed(3)));
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Whisper trigger="hover" placement="top" speaker={<Tooltip>Save</Tooltip>}>
              <IconButton
                appearance="subtle"
                icon={<CheckIcon />}
                key="save"
                disabled={rowData.bid_modifier === originalValue || !rowData.bid_modifier}
                onClick={(e) =>
                  saveHandler(rowData, rowData.bid_modifier, changeOriginalCallback, e)}
              />
            </Whisper>
            <Whisper trigger="hover" placement="top" speaker={<Tooltip>Refresh</Tooltip>}>
              <IconButton
                appearance="subtle"
                icon={<ReloadIcon />}
                key="refresh"
                disabled={rowData.bid_modifier === originalValue}
                onClick={(e) => changeHandler(rowData, originalValue)}
              />
            </Whisper>
            <Whisper trigger="hover" placement="top" speaker={<Tooltip>Remove</Tooltip>}>
              <IconButton
                appearance="subtle"
                icon={<TrashIcon />}
                key="remove"
                disabled={!originalValue}
                onClick={(e) => removeHandler(rowData, changeOriginalCallback, e)}
              />
            </Whisper>
          </Col>
        </Row>
      </Grid>
    </Cell>
  );
};

export default CampaignWidgetList;