import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const config = {
  apiKey: "AIzaSyDjxvmnOFt_FvNRfZHd9IJm2elUcluOyFk",
  authDomain: "auth.dashboard.admediary.com", //"authentication-8c8d4.firebaseapp.com",
  databaseURL: "https://authentication-8c8d4.firebaseio.com",
  projectId: "authentication-8c8d4",
  storageBucket: "authentication-8c8d4.appspot.com",
  messagingSenderId: "23970361646",
  appId: "1:23970361646:web:c5fbb39f7f6101625d0abf",
  measurementId: "G-2T0W0PBXGZ",
};

// Initialize Firebase
export const app = initializeApp(config);

// The recommended way to get the current user is by setting an observer on the Auth object:
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
