import React from "react";
import { Col, Grid, IconButton, Input, InputNumber, Row, SelectPicker, Tooltip, Whisper } from "rsuite";
import Field from "../Field";
import MinusIcon from "@rsuite/icons/Minus";
import PlusRound from "@rsuite/icons/PlusRound";
import { WeekHourTable } from "./WeekHourTable";
import { isTikTokAdgroupOnlyRule } from "./RuleForm";
import useShowAllowedValues from "../../@Utils/useShowAllowedValues";

export const generalConditionTypeList = [
  {value: 'tracker_roi', label: 'Tracker ROI'},
  {value: 'publisher_clicks', label: 'Publisher Clicks'},
  {value: 'hour_of_day', label: 'Hour of Day'},
  {value: 'traffic_source_clicks', label: 'Traffic Source Clicks'},
  {value: 'traffic_source_spent', label: 'Traffic Source Spent'},
  {value: 'tracker_cvr', label: 'Tracker CVR'},
  {value: 'tracker_conversions', label: 'Tracker Conversions'},
  {value: 'traffic_source_conversions', label: 'Traffic Source Conversions'},
  {value: 'rpc', label: 'RPC'},
  {value: 'name', label: 'Name'},
];

export const tiktokOnlyConditionTypeList = [
  {value: 'spend', label: 'Spend'},
  {value: 'roi', label: 'ROI'},
  {value: 'rpc', label: 'RPC'},
  {value: 'impressions', label: 'Impressions'},
  {value: 's1_clicks', label: 'S1 Clicks'},
  {value: 'clicks', label: 'TikTok Clicks'},
  {value: 'conversion', label: 'TikTok Conversions'},
  {value: 'name', label: 'Name'},
];

export const stringComparisonList = [
  {value: 'contains', label: 'Contains'},
  {value: 'starts_with', label: 'Starts with'},
  {value: 'ends', label: 'Ends with'},
  {value: 'does_not_contain', label: 'Does not contain'},
  {value: 'does_not_start_with', label: 'Does not start with'},
  {value: 'does_not_end_with', label: 'Does not end with'},
];

const ConditionInputRowControl = ({
  errors,
  value = [],
  onChange,
  onCheck,
  ruleType,
  showActionButtons = false,
  // ...props
}: any) => {
  errors = errors ? errors.array : [];
  const conditions = value ? value : [];

  const handleConditionListChange = (nextConditions: any[]) => {
    onChange(nextConditions);
  };

  const handleConditionItemChange = (rowIndex: number, value: any) => {
    const nextConditions = [...conditions];
    nextConditions[rowIndex] = value;
    handleConditionListChange(nextConditions);
  };

  const removeConditionClickHandler = (index: number) => {
    const newConditions = [...conditions];
    const condition = newConditions[index];

    if (newConditions.filter((condition: any) => condition.action !== 'remove').length === 1) {
      return;
    }

    if (condition.condition_id > 0) {
      newConditions[index] = {
        ...condition,
        action: 'remove',
      }
    } else {
      newConditions.splice(index, 1);
    }

    handleConditionListChange(newConditions);
  };

  const addConditionClickHandler = () => {
    if (!onCheck()) {
      return;
    }

    handleConditionListChange([...conditions, {condition_id: 0}]);
  };

  const isTikTokOnly = isTikTokAdgroupOnlyRule(ruleType);

  return (
    <>
      <Grid fluid>
        {conditions
          .map((condition: any, index: number) =>
            condition.action !== 'remove'
              ?
              <React.Fragment key={`${index}-${condition.condition_id || 'new'}`}>
                <Row>
                  <Col md={24}>
                    <ConditionItem
                      key={index}
                      rowIndex={index}
                      rowValue={condition}
                      rowError={errors[index] && errors[index].hasError ? errors[index].object : null}
                      showActionButtons={showActionButtons}
                      onChange={handleConditionItemChange}
                      removeHandler={removeConditionClickHandler}
                      onlyHourCondition={!showActionButtons}
                      isTikTokOnly={isTikTokOnly}
                    />
                  </Col>
                </Row>
              </React.Fragment>
              : null
          )
        }
        {showActionButtons &&
          <Row style={{marginTop: 8}}>
            <Col xs={24} sm={24} md={24}>
              <IconButton
                size="md"
                color="blue"
                appearance="default"
                icon={<PlusRound/>}
                onClick={addConditionClickHandler}
              >
                Add
              </IconButton>
            </Col>
          </Row>
        }
      </Grid>
    </>
  );
};

export const REQUIRED_CONDITION_VALUE = 'required';

const ConditionItem = ({
  rowValue,
  onChange,
  rowIndex,
  removeHandler,
  showActionButtons,
  onlyHourCondition,
  isTikTokOnly,
  rowError
}: any) => {
  rowValue = rowValue || {};

  const handleChangeIfCriteria = (value: any) => {
    const isHourOfDay = value === 'hour_of_day';
    const isName = value === 'name';
    const compare_criteria = ['tracker_roi', 'tracker_cvr', 'roi'].includes(value)
      ? 'percent'
      : (value === 'traffic_source_spent'
          ? 'dollar'
          : 'dots'
      );
    const currentRow = {
      if_criteria: value,
      are_criteria: isHourOfDay ? REQUIRED_CONDITION_VALUE : '',
      than_criteria: isHourOfDay ? REQUIRED_CONDITION_VALUE : '',
      compare_criteria: isHourOfDay || isName ? '' : compare_criteria,
      field_criteria: REQUIRED_CONDITION_VALUE,
      hours_than_criteria: {},
    };

    onChange(rowIndex, {...rowValue, ...currentRow});
  };

  const handleChangeAreCriteria = (value: any) => {
    onChange(rowIndex, {...rowValue, are_criteria: value});
  };

  const handleChangeThanCriteria = (value: any) => {
    onChange(rowIndex, {...rowValue, than_criteria: value});
  };

  const handleChangeCompareCriteria = (value: any) => {
    const fieldCriteria = ['percent_of_campaign', 'percent_of_adgroup'].includes(value) ? '' : REQUIRED_CONDITION_VALUE;

    onChange(rowIndex, {...rowValue, compare_criteria: value, field_criteria: fieldCriteria});
  };

  const handleChangeFieldCriteria = (value: any) => {
    onChange(rowIndex, {...rowValue, field_criteria: value});
  };

  const setHourOfDayConditionValues = (value: any) => onChange(rowIndex, {...rowValue, hours_than_criteria: value});

  const filterConditionTypeList = useShowAllowedValues();
  const conditionTypeList =
    filterConditionTypeList(
      'rules.form.conditions.if_criteria',
      'show',
      (isTikTokOnly ? tiktokOnlyConditionTypeList : generalConditionTypeList),
      'value'
    );

  const compareCriteriaList =
    isTikTokOnly
      ? [{value: 'percent_of_adgroup', label: '% of ad group'}]
      : [{value: 'percent_of_campaign', label: '% of campaign'}]
  ;

  if (['tracker_roi', 'tracker_cvr', 'roi'].includes(rowValue.if_criteria)) {
    compareCriteriaList.unshift({value: 'percent', label: '%'});
  } else if (rowValue.if_criteria === 'traffic_source_spent') {
    compareCriteriaList.unshift({value: 'dollar', label: '$'});
  } else {
    compareCriteriaList.unshift({value: 'dots', label: '...'});
  }

  const filterCompareCriteriaList = useShowAllowedValues();
  const filteredCompareCriteriaListByPermissions =
    ['tracker_roi', 'roi', 'rpc'].includes(rowValue.if_criteria)
      ? filterCompareCriteriaList('rules.form.conditions.compare_criteria', 'show', compareCriteriaList, 'value')
      : compareCriteriaList;

  if (rowValue.if_criteria === 'hour_of_day') {
    return (
      <>
        <b style={{padding: "0 5px 0 5px", width: 35, display: 'inline-block', textAlign: 'center'}}>
          {rowIndex > 0 ? 'and' : 'If'}
        </b>
        <Field
          label=""
          name="if_criteria"
          placeholder="Select Option"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={onlyHourCondition
            ? [{value: 'hour_of_day', label: 'Hour of Day'}]
            : conditionTypeList}
          onChange={handleChangeIfCriteria}
          cleanable={false}
          readOnly={onlyHourCondition}
          style={{width: 815}}
          value={rowValue.if_criteria}
        />
        <b style={{padding: "0 5px 0 5px", width: 41, display: 'inline-block',}}>is in</b>
        {showActionButtons &&
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Remove Condition</Tooltip>}
          >
            <IconButton
              circle
              size="md"
              icon={<MinusIcon/>}
              onClick={() => removeHandler(rowIndex)}
            />
          </Whisper>
        }
        <br/>
        <WeekHourTable data={rowValue.hours_than_criteria} setData={setHourOfDayConditionValues} isError={!!rowError}/>
        <br/>
        {rowError ? <ErrorMessage>{rowError.hours_than_criteria.errorMessage}</ErrorMessage> : null}
      </>
    );
  }

  if (rowValue.if_criteria === 'name') {
    return (
      <Grid fluid>
        <Row gutter={6}>
          <Col lg={6}>
            <b style={{padding: "0 5px 0 0", width: 30, display: 'inline-block', textAlign: 'center'}}>
              {rowIndex > 0 ? 'and' : 'If'}
            </b>
            <Field
              label=""
              name="if_criteria"
              placeholder="Select Option"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={conditionTypeList}
              value={rowValue.if_criteria}
              onChange={handleChangeIfCriteria}
              cleanable={false}
              style={{width: 150}}
            />
          </Col>
          <Col lg={6}>
            <b style={{padding: "0 5px 0 0", width: 40, display: 'inline-block', textAlign: 'center'}}>match</b>
            <Field
              label=""
              name="are_criteria"
              placeholder="Select Option"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={stringComparisonList}
              value={rowValue.are_criteria}
              onChange={handleChangeAreCriteria}
              cleanable={false}
              style={{width: 150}}
            />
          </Col>
          <Col lg={11}>
            <Field
              label=""
              name="than_criteria"
              placeholder=""
              placement="autoVerticalStart"
              accepter={Input}
              value={rowValue.than_criteria}
              onChange={handleChangeThanCriteria}
              style={{width: 404}}
            />
          </Col>
          <Col lg={1}>
            {showActionButtons &&
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Remove Condition</Tooltip>}
              >
                <IconButton
                  circle
                  size="md"
                  icon={<MinusIcon/>}
                  onClick={() => removeHandler(rowIndex)}
                  style={{marginTop: 8}}
                />
              </Whisper>
            }
          </Col>
        </Row>
        <Row gutter={6}>
          <Col lg={6}>
            {rowError ? <ErrorMessage>{rowError.if_criteria.errorMessage}</ErrorMessage> : null}
          </Col>
          <Col lg={6}>
            {rowError ? <ErrorMessage>{rowError.are_criteria.errorMessage}</ErrorMessage> : null}
          </Col>
          <Col lg={12}>
            {rowError ? <ErrorMessage>{rowError.than_criteria.errorMessage}</ErrorMessage> : null}
          </Col>
        </Row>
      </Grid>
    );
  }

  return (
    <Grid fluid>
      <Row gutter={6}>
        <Col lg={6}>
          <b style={{padding: "0 5px 0 0", width: 30, display: 'inline-block', textAlign: 'center'}}>
            {rowIndex > 0 ? 'and' : 'If'}
          </b>
          <Field
            label=""
            name="if_criteria"
            placeholder="Select Option"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={conditionTypeList}
            value={rowValue.if_criteria}
            onChange={handleChangeIfCriteria}
            cleanable={false}
            style={{width: 150}}
          />
        </Col>
        <Col lg={6}>
          <b style={{padding: "0 5px 0 0", width: 40, display: 'inline-block', textAlign: 'center'}}>
            {['publisher_clicks', 'traffic_source_clicks', 'tracker_conversions', 'traffic_source_conversions']
              .includes(rowValue.if_criteria) ? 'are' : 'is'
            }
          </b>
          <Field
            label=""
            name="are_criteria"
            placeholder="Select Option"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={[
              {value: 'greater', label: 'Greater'},
              {value: 'less', label: 'Less'},
              {value: 'greater_or_equal', label: 'Greater or Equal'},
              {value: 'less_or_equal', label: 'Less or Equal'},
            ]}
            value={rowValue.are_criteria}
            onChange={handleChangeAreCriteria}
            cleanable={false}
            style={{width: 150}}
          />
        </Col>
        <Col lg={11}>
          <b style={{padding: "0 5px 0 5px"}}>than</b>
          <Field
            label=""
            name="than_criteria"
            placeholder=""
            placement="autoVerticalStart"
            accepter={InputNumber}
            value={rowValue.than_criteria}
            onChange={handleChangeThanCriteria}
            style={{width: ['percent_of_campaign', 'percent_of_adgroup'].includes(rowValue.compare_criteria) ? 65 : 195}}
          />
          <Field
            label=""
            name="compare_criteria"
            placeholder="Select Option"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={filteredCompareCriteriaListByPermissions}
            value={rowValue.compare_criteria}
            onChange={handleChangeCompareCriteria}
            cleanable={false}
            style={{
              width: ['percent_of_campaign', 'percent_of_adgroup'].includes(rowValue.compare_criteria) ? 150 : 170,
              paddingLeft: 4,
            }}
          />
          {['percent_of_campaign', 'percent_of_adgroup'].includes(rowValue.compare_criteria) &&
            <Field
              label=""
              name="field_criteria"
              placeholder="Select Option"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={conditionTypeList
                // Remove both "name" and "hour_of_day" from the right side of comparison
                .filter((type: Record<string, string>) => !['name', 'hour_of_day'].includes(type.value))
                // Don't compare with the same field
                .filter((type: Record<string, string>) => type.value !== rowValue.if_criteria)
              }
              value={rowValue.field_criteria}
              onChange={handleChangeFieldCriteria}
              cleanable={false}
              searchable={false}
              style={{width: 150}}
            />
          }
        </Col>
        <Col lg={1}>
          {showActionButtons &&
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Remove Condition</Tooltip>}
            >
              <IconButton
                circle
                size="md"
                icon={<MinusIcon/>}
                onClick={() => removeHandler(rowIndex)}
                style={{marginTop: 8}}
              />
            </Whisper>
          }
        </Col>
      </Row>
      <Row gutter={6}>
        <Col lg={6}>
          {rowError ? <ErrorMessage>{rowError.if_criteria.errorMessage}</ErrorMessage> : null}
        </Col>
        <Col lg={6}>
          {rowError ? <ErrorMessage>{rowError.are_criteria.errorMessage}</ErrorMessage> : null}
        </Col>
        <Col lg={12}>
          {rowError ? <ErrorMessage>{rowError.than_criteria.errorMessage}</ErrorMessage> : null}
          {rowError ? <ErrorMessage>
            <div style={{float: 'right', marginRight: 90}}>{rowError.field_criteria.errorMessage}</div>
          </ErrorMessage> : null}
        </Col>
      </Row>
    </Grid>
  );
};

const ErrorMessage = ({...props}) => <span style={{color: 'red'}}>{props.children}</span>;

export default ConditionInputRowControl;