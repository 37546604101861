import React from "react";
import {
  FlexboxGrid,
  Pagination,
  Table,
} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { getSummaryByProperties, paginateData } from "../../@Utils/DataProcessing";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import { TableInstance } from "rsuite/esm/Table/Table";
const { Column, HeaderCell, Cell } = Table;


type CampaignListProps = {
  data?: any;
  page: number;
  setPage: any;
  loading?: any;
  parentCallback?:any;
};

const CampaignList: React.FC<CampaignListProps> = ({
  data = [],
  page = 1,
  setPage,
  loading = false,
  parentCallback = (data: any) => {},
}) => {
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState("campaign_id");
  const [rules, setRules] = React.useState<any[]>([]);
  const [load, setLoad] = React.useState(true);
  const [limit, setLimit] = React.useState(10);
  const tableRef = React.useRef<TableInstance>(null);
  var scrollXIndex = 0;
  
  const rowKey: string = "node_id";
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  React.useEffect(() => {
    setRules(data);
    setLoad(loading);
  }, [data, load, loading]);

  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    //Reset horizontal scroll on table after expanded rows don't get css transforms
    setTimeout(() => {
      if(tableRef.current) tableRef.current.scrollLeft(scrollXIndex);
    }, 1);

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  // Sort and paginate data, and add aggregation values as the last row
  const builtData = React.useMemo(() => {
    const totalCount = data.length;

    if (totalCount === 0) {
      return [];
    }

    // Calculate summary values
    const summary = getSummaryByProperties(data, [
      'spend', 'conversion', 'impressions', 'clicks', 'revenue',
      'cpa', 'ctr', 'cpc', 'cpm', 'conversion_rate', 'profit'
    ]);


    // Calculate average values
    const aggregations = {
      ...summary,
      // cpa: summary.cpa / totalCount || 0,
      cpa: summary.spend / summary.conversion || 0,
      ctr: summary.ctr / totalCount || 0,
      cpc: summary.cpc / totalCount || 0,
      cpm: summary.cpm / totalCount || 0,
      conversion_rate: summary.conversion_rate / totalCount || 0,
      // profit: summary.revenue - summary.spend || 0
    };

    // Add aggregation data as the last row
    return [
      ...paginateData(sort(data, sortColumn, sortType), page, limit),
      {
        ...aggregations,
        // Added tree node values
        [rowKey]: -1,
        parent_title: 'Summary',
      },
    ];
  }, [data, limit, page, sortColumn, sortType]);

  const columns = React.useMemo(() => [
    {
      dataKey: 'advertiser_name',
      label: 'Account Name',
      width: 200,
    },
    {
      dataKey: 'placement_type',
      label: 'Placement Type',
      width: 130,
    },
    {
      dataKey: 'spend',
      label: 'Spend',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'revenue',
      label: 'Revenue',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'profit',
      label: 'Profit',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'conversion',
      label: 'Conversion',
      width: 110,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 'cpa',
      label: 'CPA',
      width: 110,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'impressions',
      label: 'Impressions',
      width: 110,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 'ctr',
      label: 'CTR',
      width: 100,
      align: 'right',
      type: 'percent',
    },
    {
      dataKey: 'ad_type',
      label: 'Ad Type',
      width: 100,
      align: 'right',
    },
    {
      dataKey: 'cpc',
      label: 'CPC',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'cpm',
      label: 'CPM',
      width: 100,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'clicks',
      label: 'Clicks',
      width: 100,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 'conversion_rate',
      label: 'Conversion Rate',
      width: 140,
      align: 'right',
      type: 'percent',
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );
  React.useEffect(()=>{ parentCallback(visibleColumnKeys)}, [visibleColumnKeys]);
  const builtColumns = React.useMemo(() => {

    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  return (
    <>
      <FlexboxGrid justify="end" style={{marginTop: 8}}>
        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
              columns={columns}
              visibleColumnKeys={visibleColumnKeys}
              setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <DndProvider backend={HTML5Backend}>
        <Table
          ref={tableRef}
          onScroll={(scrollX: number, scrollY: number) => {scrollXIndex = scrollX}}
          isTree
          defaultExpandAllRows={true}
          shouldUpdateScroll={false}
          autoHeight
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          onRowClick={handleExpanded}
          data={builtData}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={handleSortColumn}
          rowHeight={43}
          className="adm-aggregated-table"
        >
          <Column width={400} align="left" fixed sortable resizable={true} treeCol={true}>
            <HeaderCell>{"Campaign Name/AdGroup Name"}</HeaderCell>
            <Cell dataKey="parent_title"/>
          </Column>
          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>
      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="lg"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={rules.length}
        limitOptions={[10, 20, 50, 100]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </>
  );
};

export default CampaignList;