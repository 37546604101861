import React, {ReactNode} from "react";
import {Table, Pagination, Checkbox, Loader,} from "rsuite";
import {CurrencyCell, NumberCell, PercentCell} from "../Table";
import {sort} from "../../@Utils/Sorting";
import {thousands, percent, currency, float} from "../../@Utils/Format";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
    ColDef,
    ColGroupDef,
    GridApi,
    GridOptions,
    IDateFilterParams,
    IMultiFilterParams,
    ISetFilterParams,
    ITextFilterParams,
    SideBarDef,
    createGrid,
} from "@ag-grid-community/core";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { ModuleRegistry } from "@ag-grid-community/core";

ModuleRegistry.registerModules([
    // ClientSideRowModelModule,
    MenuModule,
    MultiFilterModule,
    SetFilterModule,
]);
const {Column, HeaderCell, Cell} = Table;

type GoogleKeywordListProps = {
    data?: any;
    loading?: any;
    checkedKeywords: any[];
    setCheckedKeywords: any;
    selectedKeywordData: any[];
    setSelectedKeywordData: any;
}

const GoogleKeywordList: React.FC<GoogleKeywordListProps> = ({
                                                                 data = [],
                                                                 loading = false,
                                                                 checkedKeywords,
                                                                 setCheckedKeywords,
                                                                 selectedKeywordData,
                                                                 setSelectedKeywordData
                                                             }) => {
    const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
    const [sortColumn, setSortColumn] = React.useState("");
    const [limit, setLimit] = React.useState(25);
    const [page, setPage] = React.useState(1);

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
    };

    const paginatedData = data.filter((v: any, i: any) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    })

    const updateRowData = (checked: boolean, rowData: any) => {
        if (checked) {
            // If checked is true, add the rowData to the selectedKeywordData array.
            setSelectedKeywordData((prevState:any) => [...prevState, rowData]);
        } else {
            // If checked is false, remove the rowData from the selectedKeywordData array.
            setSelectedKeywordData((prevState:any) => prevState.filter((item:any) => item.keyword !== rowData.keyword));
        }
    };

    const handleCheck = (value: any, checked: boolean, rowData: any) => {
            // Update the checkedKeywords state.
            setCheckedKeywords((prevState:any) => checked ? [...prevState, value] : prevState.filter((item:any) => item !== value));

            // Update the rowData.
            updateRowData(checked, rowData);
        };

    const columnDefs = [
        {
            headerName: 'Select Keywords',
            field: 'checked',
            filter: false,
            cellRenderer: (params: any) => CheckboxRenderer(params)
        },
        {
            headerName: 'Keyword',
            field: 'keyword',
            filter: "agMultiColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
        },
        {
            headerName: 'Monthly Searches',
            field: 'monthly_searches',
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            valueFormatter: (params: any) => thousands(params.value),
        },
        {
            field: 'competition',
            filter: "agSetColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
        },
        {
            headerName: 'Low Bid',
            field: 'low_bid',
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            valueFormatter: (params: any) => currency(params.value),
        },
        {
            headerName: 'High Bid',
            field: 'high_bid',
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            valueFormatter: (params: any) => currency(params.value),
        }
    ]

    function CheckboxRenderer(params: any) {
        const [checked, setChecked] = React.useState(checkedKeywords.includes(params.data.keyword));

        const handleCheckChange = () => {
            const newCheckedState = !checked;
            setChecked(newCheckedState);
            handleCheck(params.data.keyword, newCheckedState, params.data);
        };

        return (
            <input type="checkbox" checked={checked} onChange={handleCheckChange} />
        );
    }
    const defaultColDef = React.useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            filter: "agTextColumnFilter",
            menuTabs: ["filterMenuTab" as const],
        };
    }, []);

    const rowHeight = 50;
    return (
        <>
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "60%",
                        height: "500px",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader />
                    <p style={{ marginLeft: "10px" }}>Loading...</p>
                </div>
            )}

            {!loading && (
                <div className={"ag-theme-quartz"} style={{width: '100%', height: "80vh"}}>
                    {/*<AgGridReact rowData={data} columnDefs={columnDefs}*/}
                    <AgGridReact
                        rowData={data}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={rowHeight}
                        // domLayout={"autoHeight"}
                        // autoSizeStrategy={autoSizeStrategy}
                    />
                </div>
                )}


            <div style={{padding: 20}}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={['total']}
                    total={data.length}
                    limitOptions={[10, 30, 50]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleChangeLimit}
                />
            </div>
        </>
    )
}

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
    <Cell {...props} style={{padding: 0}}>
        <div style={{lineHeight: "35px"}}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={(value, checked) => onChange(value, checked, rowData)}
                checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
            />
        </div>
    </Cell>;



export default GoogleKeywordList;