import React from "react";
import { FirebaseAuthContext } from "../@Context/FirebaseAuthContext";
import { Redirect, useLocation } from "react-router-dom";
import { Button, Container, FlexboxGrid, Panel } from "rsuite";
import { FaGoogle } from "react-icons/fa";
import { SEARCH_ARB_HOME_URL } from "../App";

const Signin = () => {
  const authContext = React.useContext(FirebaseAuthContext);
  const location = useLocation();
  const { from } = location.state ? (location.state as any) : { from: { pathname: SEARCH_ARB_HOME_URL } };

  return (
    <Container>
      <FlexboxGrid justify="center" style={{ marginTop: 100 }}>
        <FlexboxGrid.Item colspan={12}>
          <Panel header={<h3>Sign In</h3>} bordered>
            {authContext.isAuth ? (
              from.pathname === "/signin" || from.pathname === SEARCH_ARB_HOME_URL ? (
                <Redirect to={SEARCH_ARB_HOME_URL} />
              ) : (
                <Redirect to={from.pathname} />
              )
            ) : (
              <Button color="cyan" appearance="primary" onClick={authContext.signInPopup}>
                <FaGoogle /> Sign in with Google
              </Button>
            )}
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>
  );
};

export default Signin;
