import React from "react";
import {Button, Checkbox, Popover, Table, Whisper,} from "rsuite";

const {Column, HeaderCell, Cell} = Table;

type MediaListProps = {
  data?: any;
  loading?: any;
  sortType?: 'desc' | 'asc';
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  checkedKeys?: number[];
  setCheckedKeys?: any;
};

const MediaList: React.FC<MediaListProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn = "content_id",
  onSortColumn,
  checkedKeys = [],
  setCheckedKeys,
}) => {
  const handleCheck = (value: any, checked: boolean) => {
    const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
    setCheckedKeys(keys);
  };

  return (
    <>
      <Table
        height={350}
        data={data}
        loading={loading}
        virtualized
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={onSortColumn}
      >
        <Column width={60} align="right" fixed>
          <HeaderCell> </HeaderCell>
          <CheckCell dataKey="media_id" checkedKeys={checkedKeys} onChange={handleCheck}/>
        </Column>
        <Column width={100} align="right" fixed sortable>
          <HeaderCell>Headline</HeaderCell>
          <Cell dataKey="headline"/>
        </Column>
        <Column width={700} align="left" sortable>
          <HeaderCell>Image</HeaderCell>
          <ImageCell dataKey="image"/>
        </Column>
      </Table>
    </>
  );
};

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
  <Cell {...props} style={{padding: 0}}>
    <div style={{lineHeight: '46px'}}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Cell>;

const ImageCell = ({rowData, dataKey, ...props}: any) => {
  const speaker = (
    <Popover>
      <img src={rowData[dataKey]} alt={rowData['image']} height={200}/><br/>
      <a target="_blank" rel="noreferrer noopener" href={rowData[dataKey]}>View in a new tab</a>
    </Popover>
  );

  return <Cell {...props}>
    <Whisper trigger="hover" placement="right" enterable speaker={speaker}>
      <Button style={{padding: 0}}>{rowData[dataKey]}</Button>
    </Whisper>
  </Cell>;
}

export default MediaList;