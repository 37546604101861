import React from "react";
import {
  Button,
  FlexboxGrid,
  IconButton,
  Loader,
  Modal,
  Panel,
  Radio,
  RadioGroup,
} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";
import CheckIcon from "@rsuite/icons/Check";
import RemindFillIcon from '@rsuite/icons/RemindFill';

type ManageAccessProps = {
  open: boolean;
  handleClose: any;
  handleOpen: any;
  trafficSourceKeys: any;
  refreshCallback?: () => void;
};

const ManageAccess: React.FC<ManageAccessProps> = ({
  open,
  handleClose,
  trafficSourceKeys,
  refreshCallback,
}) => {
  const [users, setUsers] = React.useState<Record<string, any>[]>([]);
  const [groups, setGroups] = React.useState<Record<string, any>[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<number | null>(null);
  const [selectedGroup, setSelectedGroup] = React.useState<number | null>(null);
  const [isUsersLoading, setIsUsersLoading] = React.useState<boolean>(false);
  const [isGroupsLoading, setIsGroupsLoading] = React.useState<boolean>(false);
  const [isMappingLoading, setIsMappingLoading] = React.useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState<boolean>(false);
  const [isDifferentAccessMappingsConfirmationOpen, setIsDifferentAccessMappingsConfirmationOpen] = React.useState<boolean>(false);

  const handleGroupOnChange = (value: number) => {
    setSelectedGroup(value);
    setSelectedUser(null);
  };

  const handleUserOnChange = (value: number) => {
    setSelectedGroup(null);
    setSelectedUser(value);
  };

  const handleSave = () => {
    setIsSaveButtonLoading(true);

    HttpClient
      .put<any>('access_traffic_source_mapping', {
        traffic_source_id: trafficSourceKeys,
        mapping: [
          ...selectedGroup !== null ? [{group_id: selectedGroup}] : [],
          ...selectedUser !== null ? [{user_id: selectedUser}] : [],
        ]
      })
      .then((res) => {
        handleClose();

        if (refreshCallback instanceof Function) {
          refreshCallback();
        }
      }).catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
      })
      .finally(() => {
        setIsSaveButtonLoading(false);
      });
  };

  const handleCloseModal = () => {
    // setRemoveIds([]);
    handleClose();
  };

  React.useEffect(() => {
    if (open) {
      setIsUsersLoading(true);
      setIsGroupsLoading(true);

      // Get users
      HttpClient
        .get<any>('access_user')
        .then(res => {
          const data = res.data.data;

          setUsers(
            data.map((item: any) => ({...item, user_id: convertToInt(item.user_id)}))
          );
          setIsUsersLoading(false);
        }).catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
          setIsUsersLoading(false);
        });

      // Get groups
      HttpClient
        .get<any>('access_group')
        .then(res => {
          const data = res.data.data;

          setGroups(
            data.map((item: any) => ({...item, group_id: convertToInt(item.group_id)}))
          );
          setIsGroupsLoading(false);
        }).catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
          setIsGroupsLoading(false);
        });
    }
  }, [open]);

  React.useEffect(() => {
    if (open) {
      setIsMappingLoading(true);

      // Get users
      HttpClient
        .get<any>('access_traffic_source_mapping', {
          traffic_source_id: trafficSourceKeys,
        })
        .then(res => {
          const data = res.data.data;

          setIsMappingLoading(false);

          const areMappingIdentical = (Array.isArray(data) && data.length === 1)
            ? true : checkIsMappingIdentical(data);

          // Show message if mappings are not the same
          if (!areMappingIdentical) {
            setIsDifferentAccessMappingsConfirmationOpen(true);
          }

          // If mappings are the same we can get the first one
          const mappings = areMappingIdentical ? data[0] : [];

          const groups = mappings.filter((item: any) => item.group_id !== null && item.group_id !== '');
          const users = mappings.filter((item: any) => item.user_id !== null && item.user_id !== '');

          setSelectedGroup(groups.length && groups[0].group_id ? convertToInt(groups[0].group_id) : null);
          setSelectedUser(users.length && users[0].user_id ? convertToInt(users[0].user_id) : null);
        }).catch(error => {
          pushErrorNotification(error.response?.data?.error || error.toString());
          setIsMappingLoading(false);
        });
    }
  }, [open]);

  const handleDifferentAccessMappingsConfirmationClose = () => setIsDifferentAccessMappingsConfirmationOpen(false);
  const handleDifferentAccessMappingsConfirmationCancel = () => {
    handleClose();

    setIsDifferentAccessMappingsConfirmationOpen(false);
  }

  const isFormLoading = isUsersLoading || isGroupsLoading || isMappingLoading;

  return (
    <>
      <Modal
        size="lg"
        overflow
        open={open}
        onClose={handleCloseModal}
        dialogClassName={'modal-group'}
      >
        <Modal.Header>
          <Modal.Title as={'h4'} classPrefix={'modal-title'}>Manage Access</Modal.Title>
        </Modal.Header>
        <hr/>
        <Modal.Body>
          <Panel bodyFill>
            <div><i>You can select only one group or user</i></div>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12} style={{minHeight: 190}}>
                <h5>Groups</h5>
                {(isGroupsLoading || isMappingLoading) ?
                  <Loader size="lg" center /> :
                  <RadioGroup name="radio-group-controlled" value={selectedGroup || ''} onChange={(value) => handleGroupOnChange(convertToInt(value))}>
                    {groups.map((item: any) => <Radio value={item.group_id} key={item.group_id}>{item.group_name}</Radio>)}
                  </RadioGroup>
                }
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12} style={{minHeight: 190}}>
                <h5>Users</h5>
                {(isUsersLoading || isMappingLoading) ?
                  <Loader size="lg" center /> :
                  <RadioGroup name="radio-group-controlled" value={selectedUser || ''} onChange={(value) => handleUserOnChange(convertToInt(value))}>
                    {users.map((item: any) => <Radio value={item.user_id} key={item.user_id}>{item.email}</Radio>)}
                  </RadioGroup>
                }
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Panel>
        </Modal.Body>
        <Modal.Footer className="show-container">
          <IconButton
            size="lg"
            color="blue"
            appearance="primary"
            icon={<CheckIcon/>}
            onClick={handleSave}
            disabled={isFormLoading}
            loading={isSaveButtonLoading}
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        role="alertdialog"
        open={isDifferentAccessMappingsConfirmationOpen}
        onClose={handleDifferentAccessMappingsConfirmationClose}
        size="xs"
      >
        <Modal.Body>
          <RemindFillIcon style={{ color: '#ffb300', fontSize: 24 }} />
          These traffic sources have different access mappings and won’t be displayed.<br/>
          Any changes made will overwrite the current configuration. Proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDifferentAccessMappingsConfirmationClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleDifferentAccessMappingsConfirmationCancel} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const convertToInt = (value: string | number) => typeof value === 'number' ? value : parseInt(value, 10);

// Check for identity of mappings for multiple traffic sources
const checkIsMappingIdentical = ((allMappings: any[]) => {
  // Check that each traffic source has an array of mappings
  if (allMappings.some((item: any) => !Array.isArray(item))) {
    return false;
  }

  const mappingCount = allMappings[0].length;

  // Check that each traffic source has the same number of mappings
  if (allMappings.some((item: any) => item.length !== mappingCount)) {
    return false;
  }

  // Check that each traffic source has the same mappings
  const firstMappingList = allMappings[0];

  return allMappings.every((mappingList: any[]) =>
    firstMappingList.every((firstMapping: any) =>
      mappingList.some((mapping: any) =>
        mapping.email === firstMapping.email
        && mapping.active === firstMapping.active
        && mapping.group_id === firstMapping.group_id
        && mapping.user_id === firstMapping.user_id
      )
    )
  );
});

export default ManageAccess;
