export type Filter = {
  column: string;
  template: string;
  fn: (value: any) => boolean;
};

export const filterData = (data: any[], filters: Filter[]) => {
  if (!filters.length) {
    return data;
  }

  let filteredData = data;

  filters.forEach((filter: Filter) => {
    filteredData = filteredData.filter((item) => filter.fn(item[filter.column]));
  });

  return filteredData;
};


export const DEFAULT_PAGE = 1;
export const MIN_LIMIT = 10;
export const DEFAULT_LIMIT = 10;

/**
 * Get page rows when we use the local pagination
 */
export const paginateData = (data: any[], page: number = DEFAULT_PAGE, limit: number = MIN_LIMIT) =>
  data.slice((page - 1) * limit, page * limit) || [];

/**
 * Filter array of objects by properties
 */
export const filterByProperties = (data: any[], query: string, properties: string[]) => {
  const regex = new RegExp(query, 'i');

  return data.filter((item: any) =>  {
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];
      const value = item[property];

      if (regex.test(value)) {
        return true;
      }
    }

    return false;
  });
};

/**
 * Summarize data list of objects by properties.
 * Returned object contains property => summaryByProperty pairs
 */
export const getSummaryByProperties = (data: any[], properties: string[]) => {
  const summary: { [p: string]: number } = {};
  const summarizer = (accumulator: number, currentValue: number) => accumulator + currentValue;

  properties.forEach((field) => {
    summary[field] = 0;
  });

  properties.forEach((property: string) => {
    let values = data.map((item: { [index: string]: string }): number => {
      return parseFloat(item[property]);
    });
    summary[property] = values.reduce(summarizer, 0) || 0;
  });

  return summary;
};