import React from "react";
import Dashboard from "../@Components/Dashboard";
import GoogleKeywordTree from "../@Components/GoogleKeywords/GoogleKeywordTree";

const GoogleKeywordsPage: React.FC = () => {
    return (
        <Dashboard>
            <GoogleKeywordTree />
        </Dashboard>
    );
};

export default GoogleKeywordsPage;