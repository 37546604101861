import { Schema } from "rsuite";

export const initialTikTokDomainData = {
    data: [{id:0, ad_id: '', domain:'', subid:''}]
};

const { NumberType,  StringType, ArrayType, ObjectType} = Schema.Types;

const TikTokDomainModel = Schema.Model({

    data: ArrayType()
        .of(
            ObjectType().shape({
               id: NumberType(),
               adgroup_id: StringType().isRequired('Required.'),
               subid: StringType().isRequired('Required.'),
               domain: StringType().isRequired('Required.')
            }),
        )

});

export default TikTokDomainModel;