import React, { ReactNode } from "react";
import {
  Button,
  Drawer,
  toaster,
  Notification,
  Loader,
} from "rsuite";
import { FormInstance } from "rsuite/Form";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import AdGroupForm from "../Campaigns/AdGroupForm";
import HttpClient from "../../@Utils/HttpClient";
import _ from "lodash";
import { format } from "date-fns";

const API_NAME_TIKTOK = "tiktok";

type TikTokAdGroupDrawerProps = {
  formValue: any;
  setFormValue: any;
  isOpen: boolean;
  setIsOpen: any;
  refreshCallback?: () => void;
  isDataLoading?: boolean;
};

const TikTokAdGroupDrawer: React.FC<TikTokAdGroupDrawerProps> = ({
  formValue,
  setFormValue,
  isOpen,
  setIsOpen,
  refreshCallback,
  isDataLoading = false,
}) => {
  const mainFormRef = React.createRef<FormInstance>();
  const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState(false);
  const [pangleBlockList, setPangleBlockList] = React.useState<any>([]);
  const [appPackageID, setAppPackageID] = React.useState<any>(0);
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    setIsSaveButtonLoading(true);

    // Build data for saving
    const formatTimeDateToStringOrNull = (date: Date | string | null): string | null =>
      (date === null
          ? null
          : (date instanceof Date ? format(date, "yyyy-MM-dd HH:mm:ss") : date)
      );

    const data = _.cloneDeep(formValue);

    data.schedule_start_time = formatTimeDateToStringOrNull(data.schedule_start_time);

    if (data.advertiser_id ) {
        HttpClient.put<any>('tiktok_pangle_block_list', {
            advertiser_id: formValue.advertiser_id.toString(),
            clear_old_app: true,
            add_app_list: JSON.stringify(pangleBlockList.map((item:any) => item.value))
        })
            .then((res) => {
                const categoryId = parseInt(res.data.offer_source_category_id);
                // Add created category in the selector list


            })
              .finally(() => {
              });
    };

    if (data.adgroup_id === 0 || data.adgroup_id === null) {
      // Create a new one
      HttpClient.post<object, any>('ad_group', {
          ...data,
          app_package_id: appPackageID,
          blocked_pangle_app_ids: formValue.enable_pangle_block_list_app =='ENABLE' ? [appPackageID] : []
      })
        .then(res => {
          const currentAdId = parseInt(res.data.adgroup_id);

          // Update form data
          const updatedFormValue = {
            ...data,
            adgroup_id: currentAdId,
          };

          setIsSaveButtonLoading(false);
          setFormValue(updatedFormValue);

          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "TikTok Ad Group has been created" : res.statusText;

          pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);
          setIsOpen(false);

          // Refresh parent grid data
          if (refreshCallback instanceof Function) {
            refreshCallback();
          }
        })
        .catch(error => {
          setIsSaveButtonLoading(false);

          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    } else {
      // Update an existing one

      HttpClient.put<any>(`ad_group/${data.adgroup_id}`, {
          ...data,
          app_package_id: appPackageID,
          blocked_pangle_app_ids: formValue.enable_pangle_block_list_app =='ENABLE' ? [appPackageID] : []
      })
        .then(res => {
          setIsSaveButtonLoading(false);

          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "TikTok Ad Group has been updated" : res.statusText;

          pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...data,
          };
          setIsOpen(false);

          // Refresh parent grid data
          if (refreshCallback instanceof Function) {
            refreshCallback();
          }
        })
        .catch(error => {
          setIsSaveButtonLoading(false);

          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    }
  };

  return (
    <>
      <Drawer open={isOpen} backdrop={false} onClose={() => setIsOpen(false)} size="md">
        <Drawer.Header>
          <Drawer.Title>
            {formValue.adgroup_id > 0 ? 'Edit TikTok Ad Group' : 'Add TikTok Ad Group'}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleSave} appearance="primary" loading={isSaveButtonLoading}>
              Save
            </Button>
            <Button onClick={() => {
              setIsOpen(false)
              //setFormError({});
            }}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <AdGroupForm
            formRef={mainFormRef}
            formValue={prepareAdGroupFormData(formValue)}
            parentCallback={handleCallback}
            pangleBlockList = {pangleBlockList}
            setPangleBlockList = {setPangleBlockList}
            appPackageID = {appPackageID}
            setAppPackageID = {setAppPackageID}
          />
          {isDataLoading ? <Loader size="lg" content="Data loading..." center backdrop /> : null}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

const prepareAdGroupFormData = (record: any) => {
  record.traffic_source_type = API_NAME_TIKTOK;
  record.promotion_type = record?.promotion_type || "WEBSITE";
  record.placement_type = record?.placement_type || "PLACEMENT_TYPE_AUTOMATIC";
  record.creative_material_mode = record?.creative_material_mode || "CUSTOM";
  record.adgroup_id = record.adgroup_id || 0;
  record.schedule_start_time = record.schedule_start_time instanceof Date
    ? record.schedule_start_time
    : (record.schedule_start_time !== null ? new Date(record.schedule_start_time) : null);

  return record;
};

export default TikTokAdGroupDrawer;
