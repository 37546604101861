import React, {ReactNode} from "react";
import { Nav } from 'rsuite';
import PlusRound from "@rsuite/icons/PlusRound";
import DomainMapingToolList from "./DomainMapingToolList";
import Title from "../../@Components/Title";
import SearchIcon from "@rsuite/icons/Search";
import HttpClient from "../../@Utils/HttpClient";
import DomainDrawer from "../Drawers/TikTokDomain";
import { FlexboxGrid, IconButton, Input, InputGroup, Notification, toaster} from "rsuite";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import {LOCAL_STORAGE_KEYS, useStoredValue} from "../../@Utils/useStoredValue";
import {initialTikTokDomainData} from "./TikTokDomainModel";



const DomainMappingToolTree: React.FC = () => {
    const [active, setActive] = React.useState('domains');
    const [path, setPath] = React.useState('tiktok_subid_mapping');
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any[]>([]);
    const [sortType, setSortType] = React.useState();
    const [sortColumn, setSortColumn] = React.useState();
    const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TONIC_DOMAIN_REPORT_PAGE_SEARCH_QUERY, '');
    const [search, setSearch] = React.useState(searchQuery);
    const [formData, setFormData] = React.useState(initialTikTokDomainData);
    const [isDomainDrawerOpen, setIsDomainDrawerOpen] = React.useState(false);
    const [adId, setAdId] = React.useState<number>(0);
    const [action, setAction] = React.useState<string>('create');
    const url = new URL(window.location.href);
    const domain = url.searchParams.get('domain');
    const adgroup_id = url.searchParams.get('adgroup_id');

    const searchByQuery = () => {
        setSearch(searchQuery);
    };

    const handleSearchChange = (value: any) => {
        setSearchQuery(value);
    };

    const handleAfterCreateOrUpdateGridRow = (id: number, item: object | Array<any>) => {
        const updatedItemIndex = data.findIndex((item: any) => parseInt(item.adgroup_id) === id);

        if (updatedItemIndex > -1) {
            // Update data
            data[updatedItemIndex] = item;
            setData([...data]);
        } else if(Array.isArray(item))  {
            // Push a new row at the top
            setData([...item, ...data]);
        } else  {
            setData([item, ...data]);
        }
    };

    const onKeyUpEnter = (event: any) => {
        if (event.key === "Enter") {
            setSearch(searchQuery);
        }
    };
    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };
    const refresh = () => fetchData();
    // Load category list
    const fetchData = () => {
        // Clear and mask a table
        setData([]);
        setLoading(true);
        if(domain && adgroup_id) setIsDomainDrawerOpen(true);
        // Get data
        HttpClient.get<any>(path, {
            query: search,
        })
            .then((res) => {
                setLoading(false);
                setData(res.data.data);
            })
            .catch((error) => {
                pushMessage(
                    <Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>
                );
                setLoading(false);
            });
    };

    React.useEffect(fetchData, [search, path]);

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });
    const isNumber = (n: any) => {

        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);

    }

    const refineURL = () =>
    {
        var currURL= window.location.href;
        var afterDomain= currURL.substring(currURL.lastIndexOf('/') + 1);
        var beforeQueryString= afterDomain.split("?")[0];

        return beforeQueryString;
    }

    const transformedData = (list: any) => {
        return list.map((item: any) => {
            return {
                ...item,
                ...{
                    adgroup_id: parseInt(item.adgroup_id),
                    subid: isNumber(item.subid) ? parseInt(item.subid) :  item.subid
                },
            };
        });
    };
    const handleSelect = (data:any) => {
        setActive(data);
        data === 'domains' ?
            setPath('tiktok_subid_mapping') :
            data === 'missing_domains' ?
                setPath('tiktok_mapping_missing_domains') :
                  setPath('tiktok_subid_mapping_missing_domains') ;
    }
    const AddSubID = (data:any) => {
        setAction('create');
        setIsDomainDrawerOpen(true);
        if (data.domain && data.subid) {
            //@ts-ignore
            setFormData( {
                data:[
                    {id: 0, ad_id: '', domain: data.domain, subid: data.subid.toString()}
                ]
            })
        } else if(data.domain) {
            //@ts-ignore
            setFormData({
                data:[
                    {id: 0, ad_id: '', domain: data.domain, subid: ''}
                ]
            });
        } else {
            setFormData(initialTikTokDomainData);
        }
    }

    return (
        <>
            <Title title="Domain Mapping Tool List"/>
            <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
                <FlexboxGrid.Item colspan={3}>
                    <IconButton
                        size="lg"
                        color="blue"
                        appearance="subtle"
                        style={{marginLeft: 15}}
                        icon={<PlusRound/>}
                        onClick={AddSubID}
                    >
                        New
                    </IconButton>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={15}>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={6} style={{ textAlign: "right", paddingRight: 10 }}>
                    <InputGroup style={{ width: "100%" }}>
                        <InputGroup.Button onClick={searchByQuery}>
                            <SearchIcon />
                        </InputGroup.Button>
                        <Input
                            className="search-filter"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(v) => handleSearchChange(v)}
                            onKeyUp={onKeyUpEnter}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Nav  appearance="tabs"
                  reversed
                  activeKey={active}
                  onSelect={handleSelect}
                  style={{ marginBottom: 50 }}>
                <Nav.Item eventKey="domains" >
                    Domains Mapping Tool
                </Nav.Item>
                <Nav.Item eventKey="missing_domains">
                    Missing Domains
                </Nav.Item>
                <Nav.Item eventKey="subid_view">
                    Missing Domains + SubIDs
                </Nav.Item>
            </Nav>
                <DomainMapingToolList
                    data={transformedData(data)}
                    loading={loading}
                    sortType={sortType}
                    sortColumn={sortColumn}
                    onSortColumn={handleSortColumn}
                    refreshCallback={refresh}
                    setIsDomainDrawerOpen={setIsDomainDrawerOpen}
                    setFormData={setFormData}
                    setAction = {setAction}
                    setAdId={setAdId}
                    active = {active}
                    AddSubID = {AddSubID}
                />
            <DomainDrawer
                formData = {formData}
                isOpen={isDomainDrawerOpen}
                setOpen={setIsDomainDrawerOpen}
                adId={adId}
                setAdId={setAdId}
                action = {action}
                closeDrawer={() => {
                    const url = refineURL();
                    window.location.replace(url);
                    setFormData({...initialTikTokDomainData});
                    setIsDomainDrawerOpen(false);
                }}

                onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
            />
        </>
    );
};

export default DomainMappingToolTree;