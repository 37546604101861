import React, {ReactNode} from "react";
import {
    Button,
    IconButton,
    Table,
    Tooltip,
    Whisper,
    Modal, Notification, toaster,
} from "rsuite";
import { CurrencyCell, NumberCell, PercentCell } from "../Table";
import MenuIcon from '@rsuite/icons/Menu';
import ButtonToolbar from "rsuite/ButtonToolbar";
import HttpClient from "../../@Utils/HttpClient";
import { currency, percent } from "../../@Utils/Format";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import {exportCsv} from "../../@Utils/Export";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";


const {Column, HeaderCell, Cell} = Table;

type SortType = 'asc' | 'desc';

type MediaBuyerPerformanceReportListProps = {
    data?: any;
    loading?: boolean;
    startDate?: string;
    endDate?: string;
    summaryData?: any;
    sortType: SortType;
    sortColumn: string;
    onSortColumn: (column: any, type: any) => void;
}

const MediaBuyerPerformanceReportList: React.FC<MediaBuyerPerformanceReportListProps> = (
    {
        data = [],
        loading = false,
        startDate = '',
        endDate = '',
        summaryData = {},
        sortType,
        sortColumn,
        onSortColumn,
    }
) => {
    const [userData, setUserData] = React.useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<any>('');
    const [userSortType, setUserSortType] = React.useState<"desc" | "asc">("asc");
    const [userSortColumn, setUserSortColumn] = React.useState("stat_date");
    const [userTableLoading, setUserTableLoading] = React.useState(false);

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setUserData([]);
    }

    const handleUserSortColumn = (column: any, type: any) => {
        setUserSortColumn(column);
        setUserSortType(type);
    };

    const handleMenuActionClick = (rowData: any) => {
        setSelectedUser(rowData.user_name);
        setUserTableLoading(true);
        handleOpen();
        const params = {
            user_id: rowData.user_id,
            start_date: startDate,
            end_date: endDate,
        };

        HttpClient.get("tiktok_media_buyer_report", params)
            .then((res: any) => {
                setUserData(res.data.data);
                setUserTableLoading(false);
            })
            .catch((error) => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setUserTableLoading(false);
                handleClose();
            })
    }

    const sortData = (list: any) => {
        return list.sort((a: any, b: any) => {
            let valA = a[userSortColumn];
            let valB = b[userSortColumn];

            // Check if the values are numbers or strings
            if (!isNaN(valA) && !isNaN(valB)) {
                valA = parseFloat(valA);
                valB = parseFloat(valB);
            } else {
                // Convert to lowercase for case insensitive sorting
                valA = valA.toLowerCase();
                valB = valB.toLowerCase();
            }

            if (valA < valB) {
                return userSortType === 'asc' ? -1 : 1;
            }
            if (valA > valB) {
                return userSortType === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    const formatData = (list: any) => {
        return list.map((item: any) => {

            const revenue = isNaN(parseInt(item.revenue)) ? 0 : parseFloat(item.revenue).toFixed(2)
            const cost = isNaN(parseInt(item.cost)) ? 0 : parseFloat(item.cost).toFixed(2)
            const profit = isNaN(parseInt(item.profit)) ? 0 : parseFloat(item.profit).toFixed(2)
            const profit_percent = isNaN(parseFloat(item.profit_percent)) ? 0 : parseFloat(item.profit_percent).toFixed(1)

            return {
                ...item,
                revenue: currency(Number(revenue)),
                cost: currency(Number(cost)),
                profit: currency(Number(profit)),
                profit_percent: percent(Number(profit_percent)),
            };
        });
    };

    const getExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        'Date': item.stat_date,
                        'Revenue': item.revenue,
                        'Cost': item.cost,
                        'Profit': item.profit,
                        'Gross Profit &': item.profit_percent
                    }
                })
        )
    }

    const userSummaryData = userData.reduce((summary: any, row: any) => {
        summary.revenue = parseFloat((Number(summary.revenue) + Number(row.revenue)).toFixed(2));
        summary.cost = parseFloat((Number(summary.cost) + Number(row.cost)).toFixed(2));
        summary.profit = parseFloat((Number(summary.profit) + Number(row.profit)).toFixed(2));
        return summary;
    }, {revenue: 0, cost: 0, profit: 0});

// Calculate profit_percent for the summary
    userSummaryData.profit_percent = userSummaryData.revenue > 0 ? parseFloat((userSummaryData.profit / userSummaryData.revenue * 100).toFixed(1)) : 0;
    userSummaryData.profit_percent = percent(userSummaryData.profit_percent);

    const formattedUserData = Array.isArray(userData) ? formatData(userData) : [];

    const userTableData = [...formattedUserData, userSummaryData];

    const userSummaryRow = userTableData.pop();

// Sort the data without the userSummaryData row
    const sortedUserData = Array.isArray(userTableData) ? sortData(userTableData) : [];

// Append the userSummaryData row to the sorted data
    const finalUserData = [...sortedUserData, userSummaryRow];



    const tableData = [...data, summaryData];

    const summaryRow = tableData.pop();

    const finalData = [...tableData, summaryRow];

    return (
        <>
            <Table
                height={600}
                data={Array.isArray(finalData) ? finalData : []}
                loading={loading}
                virtualized
                // rowClassName="striped-rows"
                rowClassName={(rowData) => rowData === summaryData ? 'bold-row' : 'striped-rows'}
                affixHeader
                affixHorizontalScrollbar
                sortType={sortType}
                sortColumn={sortColumn}
                onSortColumn={onSortColumn}
            >

                <Column width={250} align="left" sortable resizable>
                    <HeaderCell>Buyer</HeaderCell>
                    <Cell dataKey="user_name" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Revenue</HeaderCell>
                    <CurrencyCell dataKey="revenue" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Cost</HeaderCell>
                    <CurrencyCell dataKey="cost" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Profit</HeaderCell>
                    <CurrencyCell dataKey="profit" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Gross Profit %</HeaderCell>
                    <Cell dataKey="profit_percent" />
                </Column>
                <Column width={175} align="center" fixed resizable>
                    <HeaderCell>User Data by Date</HeaderCell>
                    <ActionCell
                        openUserData={handleMenuActionClick}
                        data={finalData}
                    />
                </Column>
            </Table>

            <Modal open={open} onClose={handleClose} size="lg">
                <Modal.Header>
                    <Modal.Title>{selectedUser}       <IconButton
                        size="md"
                        appearance="subtle"
                        style={{marginLeft: 15}}
                        icon={<FileDownloadIcon/>}
                        placement="right"
                        onClick={() =>
                            exportCsv(
                                `media_buyer_performance_user_${selectedUser}_${startDate}_${endDate}.csv`,
                                getExportData(Array.isArray(userData) ? formatData(userData) : [])
                            )
                        }>
                        Download CSV
                    </IconButton></Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <Table
                        height={600}
                        data={Array.isArray(userTableData) ? finalUserData : []}
                        loading={userTableLoading}
                        virtualized
                        rowClassName={(rowData) => rowData === userSummaryData ? 'bold-row' : 'striped-rows'}
                        affixHeader
                        affixHorizontalScrollbar
                        sortType={userSortType}
                        sortColumn={userSortColumn}
                        onSortColumn={handleUserSortColumn}
                    >

                        <Column width={250} align="left" sortable resizable>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="stat_date" />
                        </Column>

                        <Column width={100} align="left" sortable resizable>
                            <HeaderCell>Revenue</HeaderCell>
                            <CurrencyCell dataKey="revenue" />
                        </Column>

                        <Column width={100} align="left" sortable resizable>
                            <HeaderCell>Cost</HeaderCell>
                            <CurrencyCell dataKey="cost" />
                        </Column>

                        <Column width={100} align="left" sortable resizable>
                            <HeaderCell>Profit</HeaderCell>
                            <CurrencyCell dataKey="profit" />
                        </Column>

                        <Column width={175} align="left" sortable resizable>
                            <HeaderCell>Gross Profit %</HeaderCell>
                            <Cell dataKey="profit_percent" />
                        </Column>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const ActionCell = ({
                               rowData,
                               editHandler,
                               removeHandler,
                               copyHandler,
                               openUserData,
    data,
                               ...props
                           }: any) => {
    const buttonStyle = {marginLeft: '6px'};
    if (rowData === data[data.length - 1]) {
        // Return null or a different component for the summary row
        return null;
    } else {
        return (
            <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
                <div>
                    <ButtonToolbar>
                        <Whisper
                            trigger="hover"
                            placement="top"
                            speaker={<Tooltip>Data by Date</Tooltip>}
                        >
                            <IconButton
                                icon={<MenuIcon/>}
                                onClick={(event) => {
                                    // Prevent event bubbling for filtering
                                    event.stopPropagation();

                                    openUserData(rowData);
                                }}
                            />
                        </Whisper>

                    </ButtonToolbar>
                </div>
            </Cell>
        );
    }

};


export default MediaBuyerPerformanceReportList;