import React from "react";
import {
  IconButton,
  Table,
  Tooltip,
  Whisper,
  Notification,
  Pagination
} from "rsuite";

import SplitIcon from '@rsuite/icons/Split';
import ButtonToolbar from "rsuite/ButtonToolbar";
import HttpClient from "../../@Utils/HttpClient";
import {DEFAULT_LIMIT, DEFAULT_PAGE} from "../../@Utils/DataProcessing";
import {
  generalConditionTypeList,
  stringComparisonList,
  tiktokOnlyConditionTypeList
} from "../Rules/ConditionInputRowControl";
import TimeIcon from "@rsuite/icons/Time";
import {consideringDataFromList, intervalList, ruleTypeList} from "../Rules/RuleForm";
import {pushErrorNotification, pushInforming} from "../../@Utils/Messager";

const {Column, HeaderCell, Cell} = Table;

type CampaignRuleListProps = {
  campaignId: number;
  loading: boolean;
  setLoading: any;
  search: any;
};

const CampaignRuleList: React.FC<CampaignRuleListProps> = ({
  campaignId,
  loading,
  setLoading,
  search,
}) => {
  const [data, setData] = React.useState<any[]>([]);
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
  const [page, setPage] = React.useState(DEFAULT_PAGE);
  const [rules, setRules] = React.useState<any[]>([]);
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState("rule_id");

  const fetchData = () => {
    //  Get data
    HttpClient
      .get<any>('rule', {campaign_id: campaignId, query: search})
      .then(res => {
        setRules(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fetchData();
  }, [loading, search])

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleRemoveActionClick = (rowData: any) => {
    removeItem(rowData.rule_id);
  };

  const removeItem = (itemId: number = 0) => {

    if (itemId === 0) {
      return;
    }

    HttpClient
      .delete<any>(`rule_campaign/${itemId}`, {
        data: {
          campaign_id: campaignId,
        }
      })
      .then((_res) => {
        setLoading(true);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  return (
    <>
      <Table
        height={600}
        data={rules}
        loading={loading}
        virtualized
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
        rowHeight={43}
        headerHeight={61}
      >
        <Column width={80} align="center" fixed verticalAlign="top">
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            removeHandler={handleRemoveActionClick}
          />
        </Column>
        <Column width={250} align="left" sortable resizable>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="rule_name" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={110} align="center" sortable>
          <HeaderCell>Status</HeaderCell>
          <CircleCell dataKey="active" style={{lineHeight: '10px'}} resizable/>
        </Column>
        <Column width={210} align="left" resizable>
          <HeaderCell>Type</HeaderCell>
          <Cell dataKey="rule_type" style={{lineHeight: '10px'}}>
            {(rowData: any) => ruleTypeList.filter((rule: any) => rule.value === rowData.rule_type).pop()?.label || ''}
          </Cell>
        </Column>
        <Column width={200} align="left" resizable>
          <HeaderCell>Conditions</HeaderCell>
          <ConditionsCell dataKey="conditions" style={{lineHeight: '10px'}} />
        </Column>
        <Column width={200} align="left" resizable>
          <HeaderCell>Groups</HeaderCell>
          <Cell dataKey="groups" style={{lineHeight: '10px'}}>
            {(rowData: any) => Array.isArray(rowData.groups) && rowData.groups.length
              ? rowData.groups.map((group:any) => group.group_name).join(', ') : ''}
          </Cell>
        </Column>
        <Column width={200} align="left" resizable>
          <HeaderCell>Interval</HeaderCell>
          <Cell dataKey="considering_data_from" style={{lineHeight: '10px'}}>
            {(rowData: any) => consideringDataFromList.filter((rule: any) =>
              rule.value === rowData.considering_data_from).pop()?.label || ''
            }
          </Cell>
        </Column>
        <Column width={200} align="left" resizable>
          <HeaderCell>Frequency</HeaderCell>
          <Cell dataKey="run_interval" style={{lineHeight: '10px'}}>
            {(rowData: any) => intervalList.filter((rule: any) =>
              rule.value === rowData.run_interval).pop()?.label || ''
            }
          </Cell>
        </Column>
      </Table>
      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="lg"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={data.length}
        limitOptions={[10, 20, 50, 100]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </>
  );
};

const CircleCell = ({
  rowData,
  ...props
}: any) => (
  <Cell {...props} className="link-group">
    <Circle isActive={rowData.active}/>
  </Cell>
);

const Circle = ({isActive = false}) => {
  const style = {
    height: 20,
    width: 20,
    marginTop: 8,
    backgroundColor: isActive ? "#00FF00" : "#FFA500",
    borderRadius: "50%",
    display: "inline-block",
  };

  return <span style={style}/>;
};

export const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  changeStatusHandler,
  cloneHandler,
  ...props
}: any) => {
  const buttonStyle = {marginLeft: '6px'};

  return (
    <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
      <div>
        <ButtonToolbar>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Unlink</Tooltip>}
          >
            <IconButton
              icon={<SplitIcon/>}
              onClick={(e) => removeHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>

        </ButtonToolbar>
      </div>
    </Cell>
  );
};

const ConditionsCell = ({
  rowData,
  dataKey,
  ...props
}: any) => {
  const conditions = rowData[dataKey] || [];

  const getLabelFromList = (list: any, value: string) =>
    list.filter((item: any) => item.value === value).pop()?.label || ''

  const outputCondition = (c: any) => {
    const label = getLabelFromList([...generalConditionTypeList, ...tiktokOnlyConditionTypeList], c.if_criteria);

    if (c.if_criteria === 'name') {
      return `${label} ${getLabelFromList(stringComparisonList, c.are_criteria)} ${c.than_criteria}`;
    }

    if (c.if_criteria === 'hour_of_day') {
      return <TimeIcon />;
    }

    if ([
      'tracker_roi',
      'publisher_clicks',
      'traffic_source_clicks',
      'traffic_source_spent',
      'tracker_cvr',
      'tracker_conversions',
      'traffic_source_conversions',
      'spend',
      'rpc',
      'roi',
      'impressions',
      's1_clicks',
      'clicks',
      'conversion',
    ].includes(c.if_criteria)
    ) {
      const operations = new Map([
        ["greater", ">"],
        ["less", "<"],
        ["greater_or_equal", ">="],
        ["less_or_equal", "<="],
      ]);
      const operation = operations.get(c.are_criteria);

      const compareCriteriaList = new Map([
        ["percent", "%"],
        ["dollar", "$"],
        ["dots", ""],
        ["percent_of_campaign", "% of campaign"],
        ["percent_of_adgroup", "% of ad group"],
        ["percent_of_itself", "% of"],
        ["above_campaign_average", "above campaign average"],
        ["below_campaign_average", "below campaign average"],
      ]);
      const compareCriteria = compareCriteriaList.get(c.compare_criteria);

      const value = c.compare_criteria === "dollar"
        ? `${compareCriteria}${c.than_criteria}`
        : `${c.than_criteria}${compareCriteria}`;

      const fieldCriteria = c.field_criteria
        ? getLabelFromList(
          [...generalConditionTypeList, ...tiktokOnlyConditionTypeList],
          c.field_criteria
        )
        : '';

      return `${label} ${operation} ${value} ${fieldCriteria}`;
    }

    return label;
  }

  return (
    <Cell {...props} className='cell-conditions'>
      {Array.isArray(conditions) && conditions.length
        ? conditions
          .map<React.ReactNode>((c: any) =>
            <span className={c.if_criteria} key={c.condition_id}>
              {outputCondition(c)}
            </span>
          )
          .reduce((prev, curr, currentIndex) => [prev, <b key={currentIndex + '_sep'}>&</b>, curr])
        : ''}
    </Cell>
  );
};

export default CampaignRuleList;