import React from "react";
import {Schema, Form} from "rsuite";
import Field from "../Field";

const { NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  group_id: NumberType(),
  group_name: StringType().isRequired("Group Name is required."),
});

type RuleGroupFormType = {
  group_id?: number;
  group_name?: string;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const RuleGroupForm: React.FC<RuleGroupFormType> = ({
  group_id = 0,
  group_name = "",
  formRef = null,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  const [formValue, setFormValue] = React.useState({
    group_id,
    group_name
  });
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Name" name="group_name"/>
    </Form>
  );
};

export default RuleGroupForm;
