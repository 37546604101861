import React from "react";
import Dashboard from "../@Components/Dashboard";
import OfferSourcesTree from "../@Components/OfferSources/OfferSourcesTree";

const OfferSources: React.FC = () => {
  return (
    <Dashboard>
      <OfferSourcesTree/>
    </Dashboard>
  );
};

export default OfferSources;
