import React from 'react';
import {
  Form,
  Grid,
  Row,
  Col,
  Slider,
  IconButton,
  Stack
} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from '@rsuite/icons/Trash';
import EditModal from "../OfferSources/EditModal";
import Campaign from "./Campaign";
import {FormInstance} from "rsuite/Form";
import Textarea from "../Textarea";
import moment from 'moment';
import internal from 'stream';
import {
  FaLock as FaLockIcon,
  FaLockOpen as FaLockOpenIcon
} from 'react-icons/fa';

type OfferSliderTreeProps = {
	offers: any,
	onChange: (offer: any, value: number) => void,
	removeOfferSlider: (offer: any) => void,
	toggleSliderLock: (offer: any) => void
};

const OfferSliderTree: React.FC<OfferSliderTreeProps> = ({
	offers,
	onChange,
	removeOfferSlider,
	toggleSliderLock
}) => {
	return (
		<>
			{offers.map((offer: any) => (
				<Row style={{height:50}} key={offer.funnel_node_id}>
					<Col xs={24} sm={24} md={10} lg={10}>
						<label>{offer.offer_name} </label>
					</Col>
					<Col xs={12} sm={12} md={10} lg={10}>
						<Slider 
							min={0} 
							max={100} 
							step={1} 
							value={offer.offer_split} 
							onChange={(offer_split) => {onChange(offer, offer_split)}} 
							progress={true} 
							disabled={offer.locked} />

						{offer.offer_split}%
					</Col>
					<Col xs={12} sm={12} md={4} lg={4} style={{top:-10}}>
						<IconButton 
							icon={offer.locked ? (
								<FaLockIcon />
							) : (
								<FaLockOpenIcon />
							)}
							onClick={() => {toggleSliderLock(offer)}} />

						<IconButton
							icon={<TrashIcon/>}
							onClick={() => {removeOfferSlider(offer)}}
							style={{marginLeft:10}}
						/>
					</Col>
				</Row>
			))}
		</>
	);
};

export default OfferSliderTree