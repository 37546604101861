import React from "react";

import {
  Modal, Button, Notification
} from "rsuite";

import RuleGroupForm from "./RuleGroupForm";
import HttpClient from "../../@Utils/HttpClient";
import {FormInstance} from "rsuite/Form";

import {pushInforming} from "../../@Utils/Messager";

type CampaignPinedRuleListProps = {
  title: string;
  open: boolean;
  handleClose: any;
  handleOpen: any;
  campaignId: number;
  separatedGroups: any;
  selectedRules: any;
  setLoad:any;
  loadCampaign: any;
  setLoadCampaign: any;
  refreshCallback?: () => void;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const CampaignPinedRuleList: React.FC<CampaignPinedRuleListProps> = ({
  open,
  handleClose,
  campaignId,
  separatedGroups,
  selectedRules,
  refreshCallback,
  setLoad,
  loadCampaign,
  setLoadCampaign
}) => {

  const mainFormRef = React.createRef<FormInstance>();
  const [loading, setLoading] = React.useState(true);
  const [formValue, setFormValue] = React.useState({
    rules: [],
    groups: []
  });
  const [disabledButton, setDisabledButton] = React.useState(true);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleSave = () => {

    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    if( formValue.rules?.length > 0 ) {
      formValue.rules.forEach((rule:any)=> pinnedRuleToCampaign(rule,campaignId));
    }

    if( formValue.groups?.length > 0 ) {
      formValue.groups.forEach((rule:any)=> pinnedGroupToCampaign(rule,campaignId));
    }

    if (refreshCallback instanceof Function) {
      refreshCallback();
    }
    setLoadCampaign(!loadCampaign);

    handleClose();
    setLoad(true);
  };

  const pinnedRuleToCampaign = (ruleId: number, campaignId: number) => {
    HttpClient
      .post<object, any>(`rule_campaign`, {rule_id:ruleId , campaign_id:campaignId})
      .then((_res) => {

      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const pinnedGroupToCampaign = (groupId: number, campaignId: number) => {
    HttpClient
        .post<object, any>(`group_campaign`, {group_id:groupId , campaign_id:campaignId})
        .then((_res) => {

        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
  };

  return (
    <>
      <Modal
        size={"md"}
        overflow={true}
        open={open}
        onClose={handleCloseModal}
        dialogClassName={'modal-group'}
      >
        <Modal.Header>
          <Modal.Title>Add Existing Rule To This Campaign</Modal.Title>
        </Modal.Header>
        <hr/>
        <Modal.Body className="show-container" >
          <RuleGroupForm
            loading={loading}
            setLoading={setLoading}
            setDisabledButton = {setDisabledButton}
            parentCallback={handleCallback}
            formRef={mainFormRef}
            selectedRules = {selectedRules}
            selectedGroups = {separatedGroups}
          />
        </Modal.Body>
        <Modal.Footer className="show-container">
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleSave} appearance="primary"  disabled={disabledButton}  >
            Add Rules
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};


export default CampaignPinedRuleList;
