import React from "react";
import { Button, Modal } from "rsuite";

type ConfirmModalProps = {
  title?: string;
  open?: boolean;
  onClose?: () => void;
  onYes?: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title = "",
  open = false,
  onClose,
  onYes,
  ...props
}) =>
  <Modal open={open} onClose={onClose} {...props}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
    <Modal.Footer>
      <Button onClick={onYes} appearance="primary">Yes</Button>
      <Button onClick={onClose} appearance="subtle">No</Button>
    </Modal.Footer>
  </Modal>;

export default ConfirmModal;