import React, {ReactNode} from "react";
import {Col, Form, Grid, Input, Notification, Row, Schema, SelectPicker, toaster, Toggle} from "rsuite";
import Field from "../Field";
import _ from "lodash";
import HttpClient from "../../@Utils/HttpClient";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import SpinnerIcon from '@rsuite/icons/Reload';

const prepareFormData = (record: any) => {
  if (record === null) {
    return;
  }

  record.traffic_source_id = !isNaN(parseInt(record.traffic_source_id))
    ? parseInt(record.traffic_source_id) : 0;

  record.max_cpc_changes = !isNaN(parseInt(record.max_cpc_changes))
    ? parseInt(record.max_cpc_changes) : 0;

  record.custom_tracking_enabled = !isNaN(parseInt(record.custom_tracking_enabled))
    ? parseInt(record.custom_tracking_enabled) : 0;

  return record;
};

type TrafficSourceProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const TrafficSourceForm: React.FC<TrafficSourceProps> = ({
  formValue,
  formRef = null,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  formValue = prepareFormData(formValue);
  const [formError, setFormError] = React.useState<any>({});
  const [accounts, setAccounts] = React.useState<any>([]);
  const [accountsLoading, setAccountsLoading] = React.useState(true);

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

  React.useEffect(() => {
    setAccountsLoading(true);
    setAccounts([]);

    if (formValue.traffic_source_type) {
      HttpClient
        .get<any>('api_traffic_source_account', {
          traffic_source_type: formValue.traffic_source_type,
        })
        .then(res => {
          setAccountsLoading(false);
          setAccounts(res.data.data.map((item: any) => ({
            ...item,
            id: item.account_id || item.id.toString(),
          })));
        })
        .catch(error => {
          setAccountsLoading(false);
          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    }
  }, [formValue.traffic_source_type]);

  const renderAccountsList = (menu: any) => {
    if (accountsLoading) {
      return (
        <p style={{padding: 4, color: '#999', textAlign: 'center'}}>
          <SpinnerIcon spin/> Loading...
        </p>
      );
    }

    return menu;
  };

  const setFormValue = (data: any) => {
    if (typeof parentCallback === "function") {
      parentCallback({
        ...data,
        account_name: getAccountName(data.account),
      });
    }
  };

  const getAccountName = (account: string) => accounts.find((item: any) => item.id === account)?.name || "";

  const rowStyles = {marginTop: 15};

  return (
    <Form
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
      formValue={formValue}
      formError={formError}
    >
      <Grid fluid>
        <Row style={rowStyles}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Traffic Source Type"
              name="traffic_source_type"
              placeholder="Select Traffic Source Type"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={[
                {
                  label: 'Taboola',
                  value: 'taboola',
                },
                {
                  label: 'Outbrain',
                  value: 'outbrain',
                }
              ]}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Nickname"
              name="traffic_source_name"
              placeholder="Enter Traffic Source Name"
            />
          </Col>
        </Row>
        <Row style={rowStyles}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label>Timezone</label>
            <Input readOnly value="America/New York" style={{width: 300}}/>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <label>Currency</label>
            <Input readOnly value="USD" style={{width: 300}}/>
          </Col>
        </Row>
        <Row style={rowStyles}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Email"
              name="email"
              placeholder="Enter Email please"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Password"
              name="password"
              placeholder="Enter Password please"
            />
          </Col>
        </Row>
        <Row style={rowStyles}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Max CPC changes per campaign"
              name="max_cpc_changes"
              placeholder="Enter Max CPC Changes please"
              message="There are some useful information"
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label={_.startCase(formValue.traffic_source_type)
                + (formValue.traffic_source_type === "outbrain" ? " Marketer" : " Account")}
              name="account"
              placeholder={!formValue.traffic_source_type ? "Select a Traffic Source Type at first" : ""}
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={accounts}
              labelKey="name"
              valueKey="id"
              renderMenu={renderAccountsList}
            />
          </Col>
        </Row>
        <Row style={rowStyles}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Field
              label="Tracking Platform"
              name="tracking_platform"
              placeholder="Select a tracking platform"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={[
                {
                  label: 'System1',
                  value: 'system1',
                },
                {
                  label: 'Tonic',
                  value: 'tonic',
                },
              ]}
            />
            <Toggle
              checked={formValue.custom_tracking_enabled === 1}
              onChange={(checked) => {
                setFormValue({
                  ...formValue,
                  custom_tracking_enabled: checked ? 1 : 0
                })
              }}
            />
            <span style={{marginLeft: 10}}>Enable Custom Tracking</span>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
          </Col>
        </Row>
        <Row style={rowStyles}>
          <Col xs={9} sm={9} md={9} lg={9}>
            <Field
              name="custom_tracking_string"
              placeholder="{campaign_id}{site}{campaign_item_id}"
              disabled={!formValue.custom_tracking_enabled}
            />
          </Col>
          <Col xs={15} sm={15} md={15} lg={15}>
            <Field
              name="custom_tracking_subid"
              placeholder="Select SubID"
              placement="autoVerticalStart"
              disabled={!formValue.custom_tracking_enabled}
              accepter={SelectPicker}
              // TODO Load data via third-party API
              data={[
                {
                  label: 'SubID1',
                  value: 'subid1',
                },
                {
                  label: 'SubID2',
                  value: 'subID2',
                },
              ]}
            />
          </Col>
        </Row>
      </Grid>
    </Form>
  )
};

const {StringType, NumberType} = Schema.Types;

const model = Schema.Model({
  traffic_source_id: NumberType(),
  traffic_source_name: StringType().isRequired("Traffic Source Name is required"),
  traffic_source_type: StringType().isRequired("Traffic Source Type is required"),
  email: StringType().isEmail("Enter valid email please"),
  password: StringType(),
  max_cpc_changes: NumberType(),
  account: StringType(),
  tracking_platform: StringType(),
  custom_tracking_enabled: NumberType(),
  custom_tracking_string: StringType(),
  custom_tracking_subid: StringType(),
});

export default TrafficSourceForm;