import React from "react";
import {Button, Drawer, Notification} from "rsuite";
import {FormInstance} from "rsuite/Form";
import AdGroupForm from "../Campaigns/AdGroupForm";
import CampaignForm from "../Campaigns/CampaignForm";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import _ from "lodash";
import {format} from "date-fns";

const API_NAME_TIKTOK = "tiktok";

const prepareFormData = (record: any) => {

  const parseToArray = (data: string | string[] | null): string[] => {
    if (Array.isArray(data)) {
      return data;
    }

    return data ? JSON.parse(data) : [];
  };

  record.api_campaign_id = record?.api_campaign_id || '';
  record.locations_type = record?.locations_type || "all";
  record.locations = parseToArray(record?.locations);
  record.locations_subtype = record?.locations_subtype || "all";
  record.traffic_source_type = record?.traffic_source_type || null;
  record.locations_sub = parseToArray(record?.locations_sub);
  record.device_type = parseToArray(record?.device_type);
  record.os_target = record?.os_target || "all";
  record.os = parseToArray(record?.os);
  record.browser_target = record?.browser_target || "all";
  record.browser = parseToArray(record?.browser);
  record.offers = parseToArray(record?.offers);
  record.rules = parseToArray(record?.rules);
  record.groups = parseToArray(record?.groups);
  return record;
};

// TODO Move to Funnel model or entity class
const prepareAdGroupFormData = (record: any) => {
  record.traffic_source_type = API_NAME_TIKTOK;
  record.promotion_type = record?.promotion_type || "WEBSITE";
  record.placement_type = record?.placement_type || "PLACEMENT_TYPE_AUTOMATIC";
  record.creative_material_mode = record?.creative_material_mode || "CUSTOM";

  return record;
};

type CampaignProps = {
  formData: any;
  setFormData: any;
  isOpen: any;
  setIsOpen: any;
  campaignId: number;
  setCampaignName: any;
  setCampaignId: any;
  setApiCampaignId: any;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
  callbackCampaignContentDrawer: any;
};

const Campaign: React.FC<CampaignProps> = ({
  formData,
  setFormData,
  isOpen,
  setIsOpen,
  campaignId = 0,
  setCampaignName,
  setCampaignId,
  setApiCampaignId,
  onAfterCreateOrUpdateGridRow,
  callbackCampaignContentDrawer
}) => {
  const [formValue, setFormValue] = React.useState(formData);
  const initialAdGroup = {
    operation_status: 'DISABLE',
    enable_pangle_block_list_app: 'DISABLE',
    blocked_pangle_app_ids: [],
    app_list: [],
    app_package_id: null,
    adgroup_id: null,
    api_adgroup_id: null,
    traffic_source_id: 277, // to get advertiser_id
    advertiser_id: formValue.advertiser_id,
    campaign_id: '',
    adgroup_name: '',
    promotion_type: 'WEBSITE',
    pixel_id: '',
    optimization_event: '',
    placement_type: 'PLACEMENT_TYPE_AUTOMATIC',
    placements: [],
    search_result_enabled: false,
    creative_material_mode: 'CUSTOM',
    auto_targeting_enabled: false,
    location_ids: ["6252001"],
    languages: [],
    gender: 'GENDER_UNLIMITED',
    age_groups: ["AGE_13_100"],
    operating_systems: "All",
    os_versions: null,
    device_model_ids: [],
    budget_mode: "BUDGET_MODE_DAY",
    budget: 0,
    schedule_type: 'SCHEDULE_FROM_NOW',
    optimization_goal: "CONVERT",
    bid_type: "BID_TYPE_CUSTOM", // BID_TYPE_NO_BID
    bid_amount: null,
    billing_event: 'OCPM',
    // Campaign fields
    campaign_budget_optimize_on: 0,
    campaign_budget: 0,
    node_count: false,
    // dayparting: "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111"
    // schedule_start_time: ''
  };

  const [adGroupFormValue, setAdGroupFormValue] = React.useState(initialAdGroup);
  const mainFormRef = React.createRef<FormInstance>();
  const adGroupFormRef = React.createRef<FormInstance>();
  const isNew = campaignId === 0;
  const isNewAdGroup = adGroupFormValue.adgroup_id === null || adGroupFormValue.adgroup_id === 0;
  const [funnelNodeSum, setFunnelNodeSum] = React.useState(100);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [formStep, setFormStep] = React.useState<number>(1);
  const [pangleBlockList, setPangleBlockList] = React.useState<any>([]);
  const [appPackageID, setAppPackageID] = React.useState<any>(0);
  const handleCallback = (formValue: any) => {
    setFormValue(formValue);

  };

  const handleAdGroupCallback = (formValue: any) => {
    setAdGroupFormValue(formValue);
  };

  // TODO Replace it
  React.useEffect(() => {
    setFormValue(formData);
  }, [formData]);


  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    if (funnelNodeSum <= 99.8) {
      pushErrorNotification('Offers List Must Sum to 100%');
      return;
    }

    saveItem(formValue, parseInt(formValue.campaign_id, 10));
  };

  const saveItem = (data: any, itemId: number = 0) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    const formatDateToStringOrNull = (date: Date | null): string | null =>
      (date !== null ? format(date, "yyyy-MM-dd 00:00:00") : null);

    const stringifyArray = (data: any[]): string => JSON.stringify(data || []);

    // Format date to string
    data.end_date = formatDateToStringOrNull(data.end_date);
    data.creative_traffic_allocation_start = formatDateToStringOrNull(data.creative_traffic_allocation_start);
    data.creative_traffic_allocation_end = formatDateToStringOrNull(data.creative_traffic_allocation_end);

    // Format arrays as JSON
    data.locations = stringifyArray(data.locations);
    data.locations_sub = stringifyArray(data.locations_sub);
    data.device_type = stringifyArray(data.device_type);
    data.os = stringifyArray(data.os);
    data.browser = stringifyArray(data.browser);
    data.rules = stringifyArray(data.rules);
    data.groups = stringifyArray(data.groups);

    setSaveButtonLoading(true);

    if (itemId === 0) {
      data.create_funnel = true;

      // Create a new one
      HttpClient.post<object, any>('campaign', data)
        .then(res => {
          const campaignId = parseInt(res.data.campaign_id, 10);
          setCampaignId(res.data.campaign_id);
          setApiCampaignId(res.data.api_campaign_id);
          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "Campaign has been created" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...data,
            ...res.data,
            campaign_id: campaignId,
            api_campaign_id: res.data.api_campaign_id || '',
            api_synchro_status: res.data.api_synchro_status || 'none',
            api_data: res.data.api_data,
            isSynchronizing: false,
          };
          setCampaignId(campaignId);
          setCampaignName(res.data.campaign_name);
          setFormValue(updatedFormValue);
          setFormData(updatedFormValue);

          const apiSynchroStatus = res.data.api_synchro_status || 'none';
          const apiData = isJson(updatedFormValue.api_data) ? JSON.parse(updatedFormValue.api_data) : {};

          if (apiSynchroStatus !== 'failed' && formValue.traffic_source_type === API_NAME_TIKTOK) {
            setAdGroupFormValue({
              ...initialAdGroup,
              campaign_id: res.data.api_campaign_id,
              traffic_source_id: data.traffic_source_id,
              advertiser_id: data.account,
              campaign_budget_optimize_on: data.budget_optimize_on,
              campaign_budget: data.budget_amount,
              budget_mode: data.budget_optimize_on === 1 ? "BUDGET_MODE_INFINITE" : "BUDGET_MODE_DAY",
              bid_type: data.budget_optimize_on === 1 ? "BID_TYPE_NO_BID" : "BID_TYPE_CUSTOM",
            });
            setFormStep(2);
          }

          if (apiSynchroStatus !== 'failed' && formValue.traffic_source_type !== API_NAME_TIKTOK) {
            setIsOpen(false);
          }

          if (apiSynchroStatus === 'failed' && apiData.last_message) {
            const messageObject = isJson(apiData.last_message) ? JSON.parse(apiData.last_message) : {};
            const message = messageObject.message || apiData.last_message;

            pushInforming(<Notification closable type="warning" header="Campaign synchronization via API is failed" duration={60000}>
              <span>
                Error: {message}<br/>
                {messageObject.offending_field ? 'Field: ' + messageObject.offending_field : ''}
              </span>
            </Notification>);
          }

          // Add a new row into a grid data
          onAfterCreateOrUpdateGridRow(campaignId, updatedFormValue);

          if (formValue.traffic_source_type !== API_NAME_TIKTOK) {
            // Open the Campaign Contents drawer
            callbackCampaignContentDrawer(true);
          }
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    } else {
      if (data.offers.length > 0) {
        data.create_funnel = true;
      }
      // Update an existing one
      HttpClient.put<any>(`campaign/${itemId}`, data)
        .then(res => {
          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "Campaign has been updated" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          const updatedFormValue = {
            ...data,
            ...res.data,
            api_campaign_id: res.data.api_campaign_id || '',
            api_synchro_status: res.data.api_synchro_status || 'none',
            api_data: res.data.api_data,
            isSynchronizing: false,
          };

          setFormValue(updatedFormValue);

          if (formValue.traffic_source_type === API_NAME_TIKTOK) {
            setAdGroupFormValue({
              ...adGroupFormValue,
              campaign_id: res.data.api_campaign_id,
              traffic_source_id: data.traffic_source_id,
              advertiser_id: data.account,
              campaign_budget_optimize_on: data.budget_optimize_on,
              campaign_budget: data.budget_amount,
              budget_mode: data.budget_optimize_on === 1 ? "BUDGET_MODE_INFINITE" : "BUDGET_MODE_DAY",
              bid_type: data.budget_optimize_on === 1 ? "BID_TYPE_NO_BID" : "BID_TYPE_CUSTOM",
            });
            setFormStep(2);
          }

          // Update a grid data
          onAfterCreateOrUpdateGridRow(itemId, updatedFormValue);

          const apiSynchroStatus = res.data.api_synchro_status || 'none';
          const apiData = res.data.api_data ? JSON.parse(res.data.api_data) : {};

          if (apiSynchroStatus !== 'failed' && formValue.traffic_source_type !== API_NAME_TIKTOK) {
            setIsOpen(false);
          }

          if (apiSynchroStatus === 'failed' && apiData.last_message) {
            const messageObject = isJson(apiData.last_message) ? JSON.parse(apiData.last_message) : {};
            const message = messageObject.message || apiData.last_message;

            pushInforming(<Notification closable type="warning" header="Campaign synchronization via API is failed" duration={60000}>
              <span>
                Error: {message}<br/>
                {messageObject.offending_field ? 'Field: ' + messageObject.offending_field : ''}
              </span>
            </Notification>);
          }
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    }
  };

  const handleCloseButtonClick = () => {
    setIsOpen(false);
    setFormStep(1);
  };

  const handleBackButtonClick = () => {
    setFormStep(formStep - 1);
  };

  const handleStep2Save = () => {
    const node = adGroupFormRef && adGroupFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    // Save Ad Group
    saveAdGroupItem(adGroupFormValue, adGroupFormValue.adgroup_id);
  };

  const saveAdGroupItem = (data: any, itemId: string|number|null = null) => {
    // We should clone data to change it
    data = _.cloneDeep(data);


    setSaveButtonLoading(true);

    if (itemId === null || itemId === 0) {
      // Create a new one
      HttpClient
        .post<object, any>('ad_group', data)
        .then(res => {
          const adGroupId = parseInt(res.data.adgroup_id, 10);
          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "Add Group has been created" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...data,
            adgroup_id: adGroupId,
          };
          setAdGroupFormValue(updatedFormValue);

          if (formValue.traffic_source_type === API_NAME_TIKTOK) {
            setFormStep(2);
          } else if (formStep === 1) {
            setIsOpen(false);
          }

          const apiData = isJson(updatedFormValue.api_data) ? JSON.parse(updatedFormValue.api_data) : {};

          if (updatedFormValue.api_synchro_status === 'failed' && apiData.last_message) {
            updatedFormValue.isSynchronizing = false;

            pushInforming(<Notification closable type="warning" header="Warning" duration={60000}>
              {"Campaign synchronization via API is failed.<br>Error: " + apiData.last_message}
            </Notification>);
          }

          if (formValue.traffic_source_type !== API_NAME_TIKTOK && formStep === 1) {
            // Open the Campaign Contents drawer
            callbackCampaignContentDrawer(true);
          }
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    } else {
      // Update an existing one
      HttpClient.put<any>(`ad_group/${itemId}`, data)
        .then(res => {
          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "Ad Group has been updated" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          if (formValue.traffic_source_type === API_NAME_TIKTOK) {
            setFormStep(2);
          } else {
            setIsOpen(false);
          }

          const apiResponse = res.data.response ? JSON.parse(res.data.response) : {};

          if (apiResponse.last_message) {
            pushInforming(<Notification closable type="warning" header="Warning" duration={60000}>
              {"Ad Group synchronization via API is failed. Error: " + apiResponse.last_message}
            </Notification>);
          }
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    }
  };

  // Load ad group
  React.useEffect(() => {
    if (formValue.traffic_source_type === API_NAME_TIKTOK && formValue.api_campaign_id) {
      HttpClient
        .get<any>('ad_group', {
          api_campaign_id: formValue.api_campaign_id,
        })
        .then(res => {
          const data = res.data.data;

          if (Array.isArray(data) && data.length) {
            const item = data[0];

            setAdGroupFormValue({
              ...adGroupFormValue,
              ...item,
              adgroup_id: !isNaN(parseInt(data[0].adgroup_id)) ? parseInt(item.adgroup_id) : null,
              bid_amount: item.optimization_goal === 'CONVERT'
                ? (!isNaN(parseFloat(item.conversion_bid_price)) ? parseFloat(item.conversion_bid_price) : 0)
                : (!isNaN(parseFloat(item.bid_price)) ? parseFloat(item.bid_price) : 0),
              search_result_enabled: !!item.search_result_enabled,
              auto_targeting_enabled: !!item.auto_targeting_enabled,
              budget: !isNaN(parseFloat(item.budget)) ? parseFloat(item.budget) : 0,
              schedule_start_time: new Date(item.schedule_start_time),
              dayparting: item.response.data.dayparting
            });
          }
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    } else if (adGroupFormValue !== initialAdGroup) {
      setAdGroupFormValue(initialAdGroup);
    }
  }, [formValue.api_campaign_id, formValue.traffic_source_type]);


  return (
    <>
      <Drawer open={isOpen}  backdrop={false} onClose={handleCloseButtonClick} size="full">
        <Drawer.Header>
          <Drawer.Title>
            {formStep === 1
              ? (isNew ? 'Add Campaign' : 'Edit Campaign')
              : (formStep === 2
                ? (isNewAdGroup ? 'Create Ad Group' : 'Edit Ad Group')
                : (formStep === 3
                  ? 'Create Ad' //(true ? 'Create Ad' : 'Edit Ad')
                  : 'Unknown Step')
              )
            }
          </Drawer.Title>
          <Drawer.Actions>
            {formStep > 1 &&
              <Button onClick={handleBackButtonClick} appearance="primary" loading={saveButtonLoading}>
                Back
              </Button>
            }
            {formStep === 1 &&
              <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
                {formValue.traffic_source_type === API_NAME_TIKTOK ? 'Next' : 'Save'}
              </Button>
            }
            {formStep === 2 &&
              <Button
                onClick={handleStep2Save}
                appearance="primary"
                loading={saveButtonLoading}
                // disabled={!isNewAdGroup}
              >
                {formValue.traffic_source_type === API_NAME_TIKTOK ? 'Next' : 'Save'}
              </Button>
            }
            <Button onClick={handleCloseButtonClick}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {formStep === 1 &&
            <CampaignForm
              formRef={mainFormRef}
              formValue={prepareFormData(formValue)}
              onChangeFunnelNodes={setFunnelNodeSum}
              parentCallback={handleCallback}
            />
          }
          {formStep === 2 &&
            <AdGroupForm
              formRef={adGroupFormRef}
              formValue={prepareAdGroupFormData(adGroupFormValue)}
              parentCallback={handleAdGroupCallback}
              pangleBlockList = {pangleBlockList}
              setPangleBlockList = {setPangleBlockList}
              appPackageID = {appPackageID}
              setAppPackageID = {setAppPackageID}
            />
          }
          {formStep === 3 &&
            <div>Under construction</div>
          }
        </Drawer.Body>
      </Drawer>
    </>
  );
};

const isJson = (str: string) => {
  try { JSON.parse(str);} catch (e) {return false;}
  return true;
};

export default Campaign;