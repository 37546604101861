import React, {ReactNode} from "react";
import { Col,
    Dropdown,
    FlexboxGrid,
    Input,
    InputGroup,
    Notification,
    SelectPicker,
    toaster,} from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from "@rsuite/icons/More";
import Title from "../../@Components/Title";
import {exportCsvRemotely} from "../../@Utils/Export";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import HttpClient from "../../@Utils/HttpClient";
import System1DomainsList from "./System1DomainsList";
import SpinnerIcon from "@rsuite/icons/Reload";
import ManageAccessDomains from "./ManageAccessDomains";

const System1DomainTree: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any[]>([]);
    const [total, setTotal] = React.useState(0);
    const [managers, setManagers] = React.useState([]);
    const [checkedKeys, setCheckedKeys] = React.useState<any[]>([]);
    const defaultManagerID = '-1';
    const defaultManagerType = 'user';
    const [managerType, setManagerType] = React.useState<string>(defaultManagerType);
    const [managerId, setManagerId] = React.useState<string>(defaultManagerID);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [search, setSearch] = React.useState<string>(searchQuery);
    const defaultActiveWindowValue = 7;
    const [activeWindow, setActiveWindow] = React.useState(defaultActiveWindowValue);

    const activeWindowList = [
        { label: "1 Day", value: 1},
        { label: "7 days", value: 7},
        { label: "14 days", value: 14},
        { label: "30 days", value: 30},
    ];

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});
    const refresh = () => fetchData();

    const fetchData = () => {
        // Clear and mask a table
        setData([]);
        setLoading(true);
        setTotal(0);
        setCheckedKeys([]);

        // Get data
        HttpClient
            .get<any>('system1_domain', {
                managerType: managerType,
                managerId: managerId,
                query: search,
                active_window: activeWindow
            })
            .then(res => {
                setLoading(false);
                setData(res.data.data);
                setTotal(parseInt(res.data.total) || 0);
            }).catch(error => {
            pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                {error.response.data?.error || error.toString()}
            </Notification>);
            setLoading(false);
        });
    };

    const searchByQuery = () => {
        setSearch(searchQuery);
    };

    const onKeyUpEnter = (event: any) => {
        if (event.key === "Enter") {
            setSearch(searchQuery);
        }
    };

    const handleSearchChange = (value: any) => {
        setSearchQuery(value);
    };

    React.useEffect(fetchData, [managerId, search, activeWindow]);

    const renderManagerSelector = (menu: any) => {
        if (managers.length === 0) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <SpinnerIcon spin /> Loading...
                </p>
            );
        }

        return menu;
    };

    const handleManagerChange = (managerUid: any) => {
        const [type, id] = managerUid != null ? managerUid.split('::') : ['', ''];

        setManagerType(type);
        setManagerId(id);
    };

    React.useEffect(() => {
        HttpClient
            .get<any>('managed_user_n_group')
            .then(res => {
                let managerData = res.data.data;

                managerData.push({
                    label: 'No Manager',
                    type: 'Not Assigned',
                    uid: 'user::-1'
                })

                setManagers(managerData)
            })
            .catch((_error) => {
                // TODO Add something

            });
    }, []);

    const handleManageAccessItemClick = () => {
        // Show popup
        openManageAccessModal();
    };

    const [isManageAccessModalOpen, setIsManageAccessModalOpen] = React.useState(false);
    const openManageAccessModal = () => setIsManageAccessModalOpen(true);
    const closeManageAccessModal = () => setIsManageAccessModalOpen(false);

    const handleExportCsvButtonClick = () => {
        exportCsvRemotely('system1_domain', {
            entity: 'system1_domain',
            query: search,
        });
    };


    return (
        <>
            <Title title="Domains"/>
            <FlexboxGrid justify="space-between" style={{marginBottom: 25}}>
                <FlexboxGrid.Item colspan={2}>
                    <Col md={2}>
                        <ActionsMenu
                            isActive={checkedKeys.length > 0}
                            manageAccessHandler={handleManageAccessItemClick}
                        />
                    </Col>
                </FlexboxGrid.Item>

               <FlexboxGrid.Item colspan={10}>
                   <label>Active within: </label>
                   <SelectPicker
                       size="md"
                       searchable={false}
                       cleanable={false}
                       placeholder="View Type"
                       // defaultValue={defaultTypeValue}
                       value={activeWindow}
                       data={activeWindowList}
                       valueKey="value"
                       labelKey="label"
                       onChange={(v) => {
                           setActiveWindow(v);
                       }}
                       style={{ marginRight: 15 }}
                   />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={4} style={{paddingRight: 10}}>

                    <SelectPicker
                        className="manager-filter"
                        block
                        name="manager"
                        placeholder="Select Manager"
                        placement="autoVerticalStart"
                        defaultValue='user::-1'
                        data={managers}
                        valueKey="uid"
                        labelKey="label"
                        groupBy="type"
                        onChange={(v:any) =>handleManagerChange(v)}
                        renderMenu={renderManagerSelector}
                    />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={6} style={{textAlign: "right", paddingRight: 10}}>
                    <InputGroup style={{width: "100%"}}>
                        <InputGroup.Button onClick={searchByQuery}>
                            <SearchIcon/>
                        </InputGroup.Button>
                        <Input
                            className="search-filter"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(v) => handleSearchChange(v)}
                            onKeyUp={onKeyUpEnter}
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <System1DomainsList
                data={data}
                loading={loading}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
                refreshCallback={refresh}
            />

            <ManageAccessDomains
                open={isManageAccessModalOpen}
                handleOpen={openManageAccessModal}
                handleClose={closeManageAccessModal}
                trafficSourceKeys={checkedKeys}
                refreshCallback={refresh}
            />
        </>
    )
}

const ActionsMenu = ({
                         isActive,
                         manageAccessHandler,
                         ...props
                     }: any) => {
    const manageAccessKey = "manageAccess";

    const handleSelect = (eventKey: any) => {
        switch (eventKey) {
            case manageAccessKey:
                manageAccessHandler();
                break;
        }
    };

    return (
        <Dropdown
            title="Actions"
            trigger="hover"
            icon={<MoreIcon style={{fontSize: "1.8em"}}/>}
            onSelect={handleSelect}
            {...props}
        >
            <Dropdown.Item disabled={!isActive} eventKey={manageAccessKey}>Assign Manager</Dropdown.Item>
        </Dropdown>
    );
};

export default System1DomainTree;