import React, { ReactNode, useState } from "react";
import {
  Button, Checkbox,
  Col,
  Form,
  Grid,
  IconButton,
  InputNumber,
  Loader,
  Panel,
  PanelGroup,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  TagPicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { FormInstance } from "rsuite/Form";
import Field from "../Field";
import HttpClient from "../../@Utils/HttpClient";
import emailRegex from "../../@Utils/emailRegex";
import SpinnerIcon from "@rsuite/icons/Reload";
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "../OfferSources/EditModal";
import RuleGroupForm from "./RuleGroupForm";
import { pushErrorNotification } from "../../@Utils/Messager";
import RuleModel from "./RuleModel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ConditionInputRowControl, { REQUIRED_CONDITION_VALUE } from "./ConditionInputRowControl";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

export const getDataForNewRuleType = (newRuleType: string) => {
  if (newRuleType === 'day_parting') {
    return {
      considering_data_from: 'yesterday',
      exclude_days_from_interval: 'none',
      action_start: 'start',
      action_end: 'pause',
      run_interval: '1h',
      conditions: [{
        if_criteria: 'hour_of_day',
        are_criteria: REQUIRED_CONDITION_VALUE,
        than_criteria: REQUIRED_CONDITION_VALUE,
        compare_criteria: '',
        field_criteria: REQUIRED_CONDITION_VALUE,
        hours_than_criteria: {
          Mon: [],
          Tue: [],
          Wed: [],
          Thu: [],
          Fri: [],
          Sat: [],
          Sun: [],
        },
      }],
    }
  }

  if (['change_campaign_bid', 'change_campaign_budget', 'change_site_bid', 'adgroup_budget_set', 'adgroup_bid_set'].includes(newRuleType)) {
    return {
      change_b_action: 'set',
      change_b_unit: 'static',
      change_b_min_unit: 'static',
      change_b_max_unit: 'static',
    };
  }

  if (newRuleType === 'change_site_bid') {
    return {switch_bidding_type: 'NO'};
  }

  return {};
};

export const prepareConditions = (conditions: any) => {
  return conditions.map((condition: any) => {
    if (condition.if_criteria === 'hour_of_day' && !condition.hours_than_criteria) {
      return {
        ...condition,
        condition_id: !isNaN(parseInt(condition.condition_id)) ? parseInt(condition.condition_id) : 0,
        are_criteria: REQUIRED_CONDITION_VALUE,
        than_criteria: REQUIRED_CONDITION_VALUE,
        field_criteria: REQUIRED_CONDITION_VALUE,
        hours_than_criteria: JSON.parse(condition.than_criteria || {}),
      }
    }

    condition = condition || {};

    condition.field_criteria = ['percent_of_campaign', 'percent_of_adgroup'].includes(condition.compare_criteria)
     ? condition.field_criteria : REQUIRED_CONDITION_VALUE;

    return condition;
  });
};

export const ruleTypeList = [
  // {value: 'block', label: 'Block Widgets/Publishers'},
  // {value: 'unblock', label: 'Un-Block Widgets/Publishers'},
  {value: 'pause', label: 'Pause Campaign'},
  {value: 'activate', label: 'Activate Campaign'},
  {value: 'change_campaign_bid', label: 'Change Campaign Bid'},
  {value: 'change_campaign_budget', label: 'Change Campaign Budget'},
  // {value: 'change_site_bid', label: 'Change Site Bid'},
  {value: 'day_parting', label: 'Day Parting'},
  {value: 'adgroup_pause', label: 'Ad Group Pause'},
  {value: 'adgroup_unpause', label: 'Ad Group Unpause'},
  {value: 'adgroup_budget_set', label: 'Ad Group Budget Set'},
  {value: 'adgroup_bid_set', label: 'Ad Group Bid Set'},
];

export const consideringDataFromList = [
  // {value: 'today', label: 'Today'},
  {value: 'yesterday', label: 'Yesterday'},
  // {value: 'today+yesterday', label: 'Today & Yesterday'},
  {value: 'last_3_days', label: 'Last 3 Days'},
  {value: 'last_4_days', label: 'Last 4 Days'},
  {value: 'last_5_days', label: 'Last 5 Days'},
  {value: 'last_6_days', label: 'Last 6 Days'},
  {value: 'last_7_days', label: 'Last 7 Days'},
  {value: 'last_8_days', label: 'Last 8 Days'},
  {value: 'last_9_days', label: 'Last 9 Days'},
  {value: 'last_10_days', label: 'Last 10 Days'},
  {value: 'last_11_days', label: 'Last 11 Days'},
  {value: 'last_12_days', label: 'Last 12 Days'},
  {value: 'last_13_days', label: 'Last 13 Days'},
  {value: 'last_14_days', label: 'Last 14 Days'},
  {value: 'last_30_days', label: 'Last 30 Days'},
  {value: 'last_60_days', label: 'Last 60 Days'},
  {value: 'last_90_days', label: 'Last 90 Days'},
];

export const intervalList = [
  {value: '1h', label: 'Every 1 Hour'},
  {value: '2h', label: 'Every 2 Hours'},
  {value: '3h', label: 'Every 3 Hours'},
  {value: '6h', label: 'Every 6 Hours'},
  {value: '12h', label: 'Every 12 Hours'},
  {value: '24h', label: 'Once Daily'},
];

const prepareFormData = (data: any) => {
  data.conditions = Array.isArray(data.conditions) && data.conditions.length && data.conditions[0]
    ? data.conditions : [{}];
  data.conditions = prepareConditions(data.conditions);


  return {
    ...data,
    emails: Array.isArray(data.emails) ? data.emails : JSON.parse(data.emails).length >0 ? JSON.parse(data.emails) : [],
    rule_id: !isNaN(parseInt(data.rule_id)) ? parseInt(data.rule_id) : 0,
    test: !isNaN(parseInt(data.test)) ? parseInt(data.test) : 0,
    groups: Array.isArray(data.groups) ? data.groups : [],
  };
};

type RuleProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  setFormError?: any;
  formError?: any;
  taboolaProviders: any[];
};

const RuleForm: React.FC<RuleProps> = ({
  formValue,
  formRef = null,
  parentCallback = () => {},
  setFormError,
  formError,
  taboolaProviders,
}) => {
  formRef = formRef || React.createRef<FormInstance>();
  formValue = prepareFormData(formValue);
  // const [emails, setEmails] = useState<string[] | []>(formValue.emails);
  const [invalidEmails, setInvalidEmails] = useState<string[] | []>([]);
  const [ruleGroups, setRuleGroups]: any = useState([]);
  const [ruleGroupModalOpen, setRuleGroupModalOpen] = useState(false);
  const [categoryFormValue, setCategoryFormValue] = useState({});
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
  const [adGroups, setAdGroups] = useState<any>([]);
  const isNew = !(formValue.rule_id > 0);
  const ruleGroupFormRef = React.createRef<FormInstance>();

  // Load group list
  React.useEffect(() => {
    HttpClient
      .get<any>('rule_group')
      .then(res => {
        setRuleGroups(res.data.data.map((item: any) => ({
          ...item,
          group_id: !isNaN(parseInt(item.group_id)) ? parseInt(item.group_id) : 0
        })));
      });
  }, []);

  // Load campaign list
  React.useEffect(() => {
    setIsCampaignsLoading(true);

    HttpClient
      .get<any>('campaign', {
        traffic_source_type: 'tiktok',
        is_plain_list: 1,
      })
      .then(res => {
        setCampaigns(res.data.data.map((item: any) => ({
          ...item,
          campaign_id: !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0
        }))
          .filter((campaign: any) => campaign.campaign_id !== 6157)
        );
      })
      .finally(() => setIsCampaignsLoading(false));
  }, []);

  // Load ad group list
  React.useEffect(() => {
    HttpClient
      .get<any>('ad_group', {
        fields: ['adgroup_id', 'adgroup_name', 'campaign_name']
      })
      .then(res => {
        setAdGroups(
          res.data.data
            .map((item: any) => ({
              adgroup_id: !isNaN(parseInt(item.adgroup_id)) ? parseInt(item.adgroup_id) : 0,
              adgroup_label: item.adgroup_name + (item.campaign_name ? (' (Campaign: ' + item.campaign_name + ')') : ''),
            }))
            .filter((item: any) => item.adgroup_id > 0)
        );
      });
  }, []);

  const changeFormValue = (data: any) => {
    if (data.rule_type !== formValue.rule_type) {
      const newData = getDataForNewRuleType(data.rule_type);

      if (Object.keys(newData).length > 0) {
        data = {
          ...data,
          ...newData,
        };
      }

      if (isTikTokAdgroupOnlyRule(data.rule_type)) {
        data.campaigns = [];
      } else {
        data.ad_groups = [];
      }
    }

    if (data.considering_data_from !== formValue.considering_data_from) {
      if (['today', 'yesterday'].includes(data.considering_data_from)
        || data.considering_data_from === data.exclude_days_from_interval
      ) {
        data.exclude_days_from_interval = 'none';
      }
    }

    if (data.action_start !== formValue.action_start) {
      if (data.action_start === 'start') {
        data.action_end = 'pause';
      }
      if (data.action_start === 'pause') {
        data.action_end = 'start';
      }
    }

    if (typeof parentCallback === "function") {
      parentCallback({
        ...data,
      });
    }
  };

  const excludeDaysFromIntervalList = [
    {value: 'none', label: 'None'},
    // {value: 'today', label: 'Today'},
    {value: 'yesterday', label: 'Yesterday'},
    // {value: 'today+yesterday', label: 'Today & Yesterday'},
    {value: 'last_3_days', label: 'Last 3 days'},
  ];

  const widgetFilterTypeList = [
    {value: 'include', label: 'Include'},
    {value: 'exclude', label: 'Exclude'},
  ];

  const renderMenu = (menu: ReactNode, items: any[]) => {
    if (items.length === 0) {
      return (
        <p style={{padding: 4, paddingBottom: 14, color: '#999', textAlign: 'center', fontSize: '1.1em'}}>
          <SpinnerIcon spin/> Loading...
        </p>
      );
    }

    return menu;
  };

  const handleEditModalClose = () => {
    setRuleGroupModalOpen(false);
  };

  const saveRuleGroup = (data: any) => {
    setSaveButtonLoading(true);

    HttpClient.post<object, any>('rule_group', data)
      .then((res) => {
        const ruleGroupId = parseInt(res.data.group_id);

        // Add created group in the selector list
        setRuleGroups([...ruleGroups, {
          ...data,
          group_id: ruleGroupId,
        }]);

        handleEditModalClose();
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const handleCallback = (formValue: any) => {
    setCategoryFormValue(formValue);
  };

  const handleGroupSave = () => {
    const node = ruleGroupFormRef && ruleGroupFormRef.current;

    if (node?.check && node.check()) {
      saveRuleGroup(categoryFormValue);
    }
  };

  const checkConditions = () => {
    const node = formRef && formRef.current;

    return node?.checkForField && node.checkForField('conditions');
  };

  const fluidFieldProperties = {
    className: "adm-form-control-wrapper-fluid",
    formGroupStyle: {width: '100%'},
    style: {width: '100%'},
  };

  const hasAccessToCampaigns = campaigns.length > 0
    ? formValue.campaigns.every((campaign_id: number) =>
      campaigns.findIndex((campaign: any) => campaign.campaign_id === campaign_id) > -1)
    : false;

  return (
    <>
      <Form
        layout="inline"
        ref={formRef}
        checkTrigger="none"
        onChange={changeFormValue}
        onCheck={setFormError}
        model={RuleModel}
        formValue={formValue}
        formError={formError}
        style={{width: 1000}} // TODO Remove after testing
      >
        {/*TODO Remove after testing*/}
        {/*<span>ID: {JSON.stringify(formValue.rule_id)}</span><br/>*/}
        {/*<span>{JSON.stringify(formValue)}</span><br/>*/}
        {/*<span>errors:{JSON.stringify(formError)}</span>*/}
        <PanelGroup accordion bordered>
          <Panel
            header={
              <>
                Rule:
                <Field
                  label=""
                  name="rule_type"
                  placeholder="Select Rule Type"
                  placement="autoVerticalStart"
                  accepter={SelectPicker}
                  data={ruleTypeList}
                  appearance="subtle"
                  cleanable={false}
                  readOnly={!isNew}
                  formGroupStyle={{paddingLeft: 8}}
                />
                <b style={{padding: "0 5px 0 5px", width: 125, display: 'inline-block', textAlign: 'center'}}>

                </b>
                Mark rule as a test:
                <Field
                    label=""
                    name="test"
                    placement="autoVerticalStart"
                    accepter={Checkbox}
                    checked={formValue.test === 1}
                    onChange={(value: string | number ,checked:boolean) => {
                      changeFormValue({
                        ...formValue,
                        test: checked ? 1: 0,
                      })
                    }}
                    appearance="subtle"

                    formGroupStyle={{paddingLeft: 5 }}
                />
              </>
            }
            defaultExpanded={true}
            expanded
          >
            {!!formValue.rule_type &&
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <Field
                      label="Rule Name"
                      name="rule_name"
                      placeholder="Enter Rule Name"
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col xs={6} sm={12} md={11}>
                    <Field
                      label="Rule Group"
                      name="groups"
                      placeholder="Select Rule Group"
                      placement="autoVerticalStart"
                      accepter={TagPicker}
                      data={ruleGroups}
                      valueKey="group_id"
                      labelKey="group_name"
                      renderMenu={(menu: ReactNode) => renderMenu(menu, ruleGroups)}
                      cleanable={true}
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={1}>
                    <Whisper
                      trigger="hover"
                      placement="top"
                      speaker={<Tooltip>Add New Group</Tooltip>}
                    >
                      <IconButton
                        circle
                        size="md"
                        style={{marginTop: 28, marginLeft: -10}}
                        icon={<PlusIcon/>}
                        onClick={() => setRuleGroupModalOpen(true)}
                      />
                    </Whisper>
                  </Col>
                </Row>
                {formValue.rule_type !== 'day_parting' &&
                  <Row style={{marginTop: 4}}>
                    <Col md={12}>
                      <Field
                        label="Considering data from"
                        name="considering_data_from"
                        placeholder="Select Option"
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        data={consideringDataFromList}
                        cleanable={false}
                        searchable={false}
                        {...fluidFieldProperties}
                      />
                    </Col>
                    <Col xs={6} sm={12} md={12}>
                      <Field
                        label="Exclude days from interval"
                        name="exclude_days_from_interval"
                        placeholder="None"
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        data={excludeDaysFromIntervalList.filter((day: Record<string, string>) =>
                          day.value !== formValue.considering_data_from
                        )}
                        cleanable={false}
                        searchable={false}
                        disabled={['today', 'yesterday'].includes(formValue.considering_data_from)}
                        {...fluidFieldProperties}
                      />
                    </Col>
                  </Row>
                }
              </Grid>
            }
          </Panel>

          {!!formValue.rule_type &&
            <Panel header="Rule Conditions" defaultExpanded>
              <Form.Control
                name="conditions"
                accepter={ConditionInputRowControl}
                errors={formError.conditions}
                onChange={(conditions: any) => changeFormValue({...formValue, conditions})}
                onCheck={checkConditions}
                ruleType={formValue.rule_type}
                showActionButtons={!(formValue.rule_type === 'day_parting')}
                {...fluidFieldProperties}
              />
            </Panel>
          }

          {!!formValue.rule_type && formValue.rule_type === 'day_parting' &&
            <Panel header="Rule Action" defaultExpanded>
              <Grid fluid>
                <Row>
                  <Col md={24}>
                    <Field
                      label=""
                      name="action_start"
                      placeholder="Select Rule Type"
                      placement="autoVerticalStart"
                      accepter={SelectPicker}
                      data={[
                        {value: 'pause', label: 'Pause Campaign'},
                        {value: 'start', label: 'Start Campaign'},
                      ]}
                      cleanable={false}
                      searchable={false}
                      readOnly={!isNew}
                      formGroupStyle={{paddingLeft: 8}}
                    />
                    <b style={{padding: "0 5px 0 5px", width: 115, display: 'inline-block', textAlign: 'center'}}>
                      Otherwise
                    </b>
                    <Field
                      label=""
                      name="action_end"
                      placeholder="Select Rule Type"
                      placement="autoVerticalStart"
                      accepter={SelectPicker}
                      data={[
                        {value: 'pause', label: 'Pause Campaign'},
                        {value: 'start', label: 'Start Campaign'},
                        {value: 'do_nothing', label: 'Do Nothing'},
                      ]}
                      disabledItemValues={[formValue.action_start]}
                      cleanable={false}
                      searchable={false}
                      readOnly={!isNew}
                      formGroupStyle={{paddingLeft: 8}}
                    />
                  </Col>
                </Row>
              </Grid>
            </Panel>
          }

          {!!formValue.rule_type
            && ['change_campaign_bid', 'change_campaign_budget', 'change_site_bid', 'adgroup_budget_set', 'adgroup_bid_set'].includes(formValue.rule_type) &&
            <Panel
              header={['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type) ? "Rule Budget" : "Rule Bid"}
              defaultExpanded
            >
              <Grid fluid>
                <Row>
                  <Col md={5}>
                    <Field
                      label=""
                      name="change_b_action"
                      placement="autoVerticalStart"
                      accepter={SelectPicker}
                      data={['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type)
                        ?
                        [
                          {value: 'set', label: 'Set Budget To'},
                          {value: 'increase', label: 'Increase Budget To'},
                          {value: 'decrease', label: 'Decrease Budget To'},
                        ]
                        :
                        [
                          {value: 'set', label: 'Set Bid To'},
                          {value: 'increase', label: 'Increase Bid To'},
                          {value: 'decrease', label: 'Decrease Bid To'},
                        ]
                      }
                      cleanable={false}
                      searchable={false}
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      label=""
                      name="change_b_value"
                      accepter={InputNumber}
                      min={0}
                      step={0.001}
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col md={2}>
                    <Field
                      label=""
                      name="change_b_unit"
                      placement="autoVerticalStart"
                      accepter={SelectPicker}
                      data={[
                        {value: 'static', label: '$'},
                        {value: 'percentage', label: '%'},
                      ]}
                      cleanable={false}
                      searchable={false}
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col md={11}>
                    {formValue.change_b_unit === 'percentage' &&
                      <Field
                        label=""
                        name="change_b_to"
                        placeholder="Select Option"
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        data={
                          ['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type)
                            ? [{value: 'budget', label: 'of Current Budget'}]
                            :
                            [
                              {value: 'current_bid', label: 'of Current Bid'},
                              {value: 'epc', label: 'of EPC'},
                            ]
                        }
                        cleanable={false}
                        searchable={false}
                        {...fluidFieldProperties}
                      />
                    }
                  </Col>
                </Row>
                {(formValue.change_b_action !== 'set' || formValue.change_b_unit !== 'static') &&
                  <>
                    <Row>
                      <Col md={11}>
                        <Field
                          label=""
                          name="change_b_min_value"
                          accepter={InputNumber}
                          min={0}
                          step={0.001}
                          prefix={`Do not allow the
                            ${['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type) ? 'Budget' : 'Bid '}
                            to be lower than`
                          }
                          {...fluidFieldProperties}
                        />
                      </Col>
                      <Col md={2}>
                        <Field
                          label=""
                          name="change_b_min_unit"
                          placement="autoVerticalStart"
                          accepter={SelectPicker}
                          data={[
                            {value: 'static', label: '$'},
                            {value: 'percentage', label: '%'},
                          ]}
                          cleanable={false}
                          searchable={false}
                          readOnly={['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type)}
                          {...fluidFieldProperties}
                        />
                      </Col>
                      <Col md={11}>
                        {formValue.change_b_min_unit === 'percentage' &&
                          <Field
                            label=""
                            name="change_b_min_to"
                            placeholder="Select Option"
                            placement="autoVerticalStart"
                            accepter={SelectPicker}
                            data={[
                              {value: 'current_bid', label: 'of Current Bid'},
                              {value: 'epc', label: 'of EPC'},
                            ]}
                            cleanable={false}
                            searchable={false}
                            {...fluidFieldProperties}
                          />
                        }
                      </Col>
                    </Row>
                    <Row>
                      <Col md={11}>
                        <Field
                          label=""
                          name="change_b_max_value"
                          accepter={InputNumber}
                          min={0}
                          step={0.001}
                          prefix={`Do not allow the
                            ${['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type) ? 'Budget' : 'Bid '}
                            to be higher than`
                          }
                          {...fluidFieldProperties}
                        />
                      </Col>
                      <Col md={2}>
                        <Field
                          label=""
                          name="change_b_max_unit"
                          placement="autoVerticalStart"
                          accepter={SelectPicker}
                          data={[
                            {value: 'static', label: '$'},
                            {value: 'percentage', label: '%'},
                          ]}
                          cleanable={false}
                          searchable={false}
                          readOnly={['change_campaign_budget', 'adgroup_budget_set'].includes(formValue.rule_type)}
                          {...fluidFieldProperties}
                        />
                      </Col>
                      <Col md={11}>
                        {formValue.change_b_max_unit === 'percentage' &&
                          <Field
                            label=""
                            name="change_b_max_to"
                            placeholder="Select Option"
                            placement="autoVerticalStart"
                            accepter={SelectPicker}
                            data={[
                              {value: 'current_bid', label: 'of Current Bid'},
                              {value: 'epc', label: 'of EPC'},
                            ]}
                            cleanable={false}
                            searchable={false}
                            {...fluidFieldProperties}
                          />
                        }
                      </Col>
                    </Row>
                  </>
                }
                {formValue.rule_type === 'change_site_bid' &&
                  <Row style={{marginTop: 16}}>
                    <Toggle
                      checked={formValue.switch_bidding_type === 'YES'}
                      onChange={(checked) =>
                        changeFormValue({
                          ...formValue,
                          switch_bidding_type: checked ? 'YES' : 'NO',
                        })
                      }
                    /> Automatically switch Taboola widgets (sites) from Smart to Fixed bidding
                  </Row>
                }
              </Grid>
            </Panel>
          }

          {!!formValue.rule_type
            && ['block', 'unblock', 'change_site_bid'].includes(formValue.rule_type) &&
            <Panel header="Widget Filtering" defaultExpanded>
              <Grid fluid>
                <Row>
                  <Col md={4}>
                    <Field
                      label=""
                      name="widget_filter_type"
                      placeholder="Filter Type"
                      placement="autoVerticalStart"
                      accepter={SelectPicker}
                      data={widgetFilterTypeList}
                      searchable={false}
                      // cleanable={false}
                      {...fluidFieldProperties}
                    />
                  </Col>
                  <Col md={20}>
                    <Field
                      label=""
                      name="widget_ids"
                      placeholder="Select Widgets"
                      placement="autoVerticalStart"
                      accepter={TagPicker}
                      data={taboolaProviders}
                      valueKey="account_id"
                      labelKey="account_id"
                      renderMenu={(menu: ReactNode) => renderMenu(menu, taboolaProviders)}
                      cleanable={true}
                      virtualized={true}
                      style={{width: 365, marginLeft: 8}}
                    />
                    <CopyToClipboard text={formValue.widget_ids ? formValue.widget_ids.join(',') : ''}>
                      <Button appearance="link">Copy to clipboard</Button>
                    </CopyToClipboard>
                    <span>|</span>
                    <Button
                      appearance="link"
                      onClick={() => changeFormValue({...formValue, widget_ids: []})}
                    >
                      Clear widgets
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          }

          {!!formValue.rule_type && !isTikTokAdgroupOnlyRule(formValue.rule_type) &&
            <Panel header="Apply Rule" defaultExpanded>
              {isCampaignsLoading || !hasAccessToCampaigns
                ?
                <div>
                  {formValue?.campaignsOutput?.length ? <span>{formValue.campaignsOutput.join(', ')}<br/></span> : null}
                  {isCampaignsLoading ? <Loader size="sm" content="Loading campaigns..."/> : null}
                  {!isCampaignsLoading && !hasAccessToCampaigns
                    ? <div style={{paddingTop: 5}}>*<i>You don't have permissions to change rule relations with selected campaigns.</i></div>
                    : null
                  }
                </div>
                : <Field
                  label=""
                  name="campaigns"
                  placeholder="Select Campaigns"
                  placement="autoVerticalStart"
                  accepter={TagPicker}
                  data={campaigns}
                  valueKey="campaign_id"
                  labelKey="campaign_name"
                  renderMenu={(menu: ReactNode) => renderMenu(menu, campaigns)}
                  cleanable={true}
                  {...fluidFieldProperties}
                />
              }
            </Panel>
          }

          {!!formValue.rule_type && isTikTokAdgroupOnlyRule(formValue.rule_type) &&
            <Panel header="Apply Rule" defaultExpanded>
              <Field
                label=""
                name="ad_groups"
                placeholder="Select Ad Groups"
                placement="autoVerticalStart"
                accepter={TagPicker}
                data={adGroups}
                valueKey="adgroup_id"
                labelKey="adgroup_label"
                renderMenu={(menu: ReactNode) => renderMenu(menu, adGroups)}
                cleanable={true}
                {...fluidFieldProperties}
              />
            </Panel>
          }

          {!!formValue.rule_type &&
            <Panel header="Schedule Rule" defaultExpanded>
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    {formValue.rule_type !== 'day_parting' &&
                      <Field
                        label="Run this rule every"
                        name="run_interval"
                        placeholder="Select Option"
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        data={intervalList}
                        searchable={false}
                        cleanable={false}
                        {...fluidFieldProperties}
                      />
                    }
                  </Col>
                  <Col md={12}>
                    <Field
                      label=""
                      name="after_run_action"
                      placeholder="Select Action"
                      placement="autoVerticalStart"
                      accepter={RadioGroup}
                      style={{width: 360, marginTop: formValue.rule_type !== 'day_parting' ? 19 : 0}}
                      inline
                    >
                      <Radio value="alert">Alert Only</Radio>
                      <Radio value="execute">Execute</Radio>
                      <Radio value="execute_and_alert">Execute & Alert</Radio>
                    </Field>
                  </Col>
                </Row>
              </Grid>
            </Panel>
          }
          {formValue.after_run_action === 'alert' || formValue.after_run_action === 'execute_and_alert'?
            <Panel header = "User Emails" defaultExpanded>
                <ReactMultiEmail
                    placeholder="Please enter emails"
                    emails={formValue.emails}
                    validateEmail={email => {
                      console.log("validate email: ", email);
                      const isValid = emailRegex.test(email);
                      if (!isValid) {
                        setFormError({'email': 'is not valid'});
                        setInvalidEmails([...invalidEmails, email]);
                      } else {
                        setFormError({});
                      }
                      return isValid;
                    }}
                    onChange={
                      (_emails: string[]) => {
                        console.log('em',_emails)
                        changeFormValue({
                            ...formValue,
                            emails: _emails,
                          }
                      )}}
                    getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void
                    ) => {
                      return (
                          <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                      );
                    }}
                />
                <br />
            </Panel> : null
            }
        </PanelGroup>
      </Form>

      <EditModal
        title={"Add Group"}
        loading={saveButtonLoading}
        open={ruleGroupModalOpen}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleGroupSave}
      >
        <RuleGroupForm
          formRef={ruleGroupFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  )
};

export const isTikTokAdgroupOnlyRule = (ruleType: string): boolean => {
  return [
    'adgroup_pause',
    'adgroup_unpause',
    'adgroup_budget_set',
    'adgroup_bid_set',
  ]
    .includes(ruleType.toLowerCase());
};

export default RuleForm;