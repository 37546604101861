import React from "react";
import {Form, Schema} from "rsuite";
import Textarea from "../Textarea";

const {StringType} = Schema.Types;

const model = Schema.Model({
  headline_list: StringType()
    .isRequired("Headlines are required")
});

type HeadlinesFormType = {
  formValue?: any;
  formRef?: React.MutableRefObject<any>;
  setFormValue?: any;
};

const HeadlinesForm: React.FC<HeadlinesFormType> = ({
  formValue = "",
  setFormValue = () => {},
  formRef = null,
}) => {
  formRef = formRef || React.createRef();

  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    if (setFormValue instanceof Function) {
      setFormValue(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="vertical"
      formValue={formValue}
      formError={formError}
    >
      <Form.ControlLabel>Headlines</Form.ControlLabel>
      <Form.Control
        name="headline_list"
        placeholder="Enter Headlines"
        rows={8}
        style={{width: '100%'}}
        accepter={Textarea}
      />
    </Form>
  );
};

export default HeadlinesForm;
