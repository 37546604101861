import React from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Form, Input, InputGroup, Grid, Row, Col, IconButton, Whisper, Tooltip} from "rsuite";
import CopyIcon from "@rsuite/icons/Copy";

type GeneralSettingsProps = {
  data: any;
  parentCallback?: any;
  formRef?: React.MutableRefObject<any>;
};

const TrackingTokens: React.FC<GeneralSettingsProps> = ({data}) => {
  const url = `https://www.searchnerd.net/?hit=${data.hit_id_field}payout=${data.offer_payout}txn_id=${data.transaction_id}`;

  return (
    <Grid fluid>
      <Row>
        <Col xs={24} sm={12} md={7}>
          <Form.Group controlId="hit_id_field">
            <Form.ControlLabel style={{textAlign: "left"}}>HIT ID Field</Form.ControlLabel>
            <Form.Control name="hit_id_field" style={{width: 210}}/>
          </Form.Group>
        </Col>
        <Col xs={24} sm={12} md={7}>
          <Form.Group controlId="offer_payout">
            <Form.ControlLabel style={{textAlign: "left"}}>Offer Payout</Form.ControlLabel>
            <Form.Control name="offer_payout" style={{width: 210}}/>
          </Form.Group>
        </Col>
        <Col xs={24} sm={12} md={7}>
          <Form.Group controlId="transaction_id">
            <Form.ControlLabel style={{textAlign: "left"}}>Transaction ID</Form.ControlLabel>
            <Form.Control name="transaction_id" style={{width: 210}}/>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={22}>
          <Form.Group controlId="postback_url" style={{marginTop: 20}}>
            <label>PostBack URL to use at this Offer Source</label>
            <InputGroup
              style={{marginTop: 10}}
            >
              <Input
                size="md"
                value={url}
                readOnly={true}
              />
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={2}>
          <CopyToClipboard text={url}>
          <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Copy to Clipboard</Tooltip>}
            >
            <IconButton size="md" style={{marginTop: 50}} icon={<CopyIcon/>}/>
            </Whisper>
          </CopyToClipboard>
        </Col>
      </Row>
    </Grid>
  );
};

export default TrackingTokens;