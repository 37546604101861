import React from "react";
import { IconButton, Table } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import { CurrencyCell, NumberCell, PercentCell } from "../Table";
import FilterHeaderCell from "../FilterHeaderCell";
import { Filter, filterData } from "../../@Utils/DataProcessing";
import { sortTree } from "../../@Utils/Sorting";

const { Column, HeaderCell, Cell } = Table;

type CampaignContentListProps = {
  data?: any;
  loading?: any;
  sortType?: 'desc' | 'asc';
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  setIsCampaignContentDrawerOpen: any;
  setFormData: any;
  setCampaignContentId: any;
};

const rowKey: string = "node_id";
const rootKey: string = "content_id";

const CampaignContentList: React.FC<CampaignContentListProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn = "content_id",
  onSortColumn,
  setIsCampaignContentDrawerOpen,
  setFormData,
  setCampaignContentId,
}) => {
  const [campaigns, setCampaigns] = React.useState<any[]>([]);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  React.useEffect(() => {
    setCampaigns(data);
  }, [data]);

  const handleEditActionClick = (rowData: any) => {
    setFormData({...rowData});
    setCampaignContentId(rowData.content_id);
    setIsCampaignContentDrawerOpen(true);
  };

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const filteredRows = React.useMemo(() => filterData(campaigns, filters), [campaigns, filters]);

  const preparedData = React.useMemo(() => {
    return sortTree(filteredRows, sortColumn, sortType);
  }, [filteredRows, sortColumn, sortType]);

  return (
    <>
      <Table
        isTree
        defaultExpandAllRows={true}
        shouldUpdateScroll={false}
        height={600}
        data={preparedData}
        loading={loading}
        rowKey={rowKey}
        expandedRowKeys={expandedRowKeys}
        virtualized
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={onSortColumn}
        onRowClick={handleExpanded}
        headerHeight={61}
      >
        <Column width={100} align="right" fixed sortable treeCol={true}>
          <HeaderCell>Content ID</HeaderCell>
          <Cell dataKey="content_id"/>
        </Column>
        <Column width={70} align="center" fixed>
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            editHandler={handleEditActionClick}
          />
        </Column>
        <Column width={90} align="left" sortable>
          <HeaderCell>Media ID</HeaderCell>
          <Cell dataKey="media_id">
            {(rowData) => rowData[rootKey] ? null : rowData.media_id}
          </Cell>
        </Column>
        <Column width={100} align="left" sortable>
          <HeaderCell>Image</HeaderCell>
          <Cell dataKey="image" style={{ padding: 0 }}>
            {(rowData) => rowData[rootKey] ? null : <div
              style={{
                width: 60,
                height: 40,
                background: '#f5f5f5',
                borderRadius: 6,
                marginTop: 2,
                overflow: 'hidden',
                display: 'inline-block'
              }}
            >
              <img src={rowData.image} width="60" alt={rowData.image} />
            </div>}
          </Cell>
        </Column>
        <Column width={200} align="left" sortable>
          <HeaderCell>Headline</HeaderCell>
          <Cell dataKey="headline"/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Cost</FilterHeaderCell>
          <CurrencyCell dataKey="cost" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Revenue</FilterHeaderCell>
          <CurrencyCell dataKey="revenue" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Spent</FilterHeaderCell>
          <CurrencyCell dataKey="spent" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Net</FilterHeaderCell>
          <CurrencyCell dataKey="net" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={120} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>ROI</FilterHeaderCell>
          <PercentCell dataKey="roi" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>ARB Clicks</FilterHeaderCell>
          <NumberCell dataKey="arb_clicks" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Taboola Clicks</FilterHeaderCell>
          <NumberCell dataKey="taboola_clicks" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>CTR</FilterHeaderCell>
          <CurrencyCell dataKey="ctr" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>VCTR</FilterHeaderCell>
          <CurrencyCell dataKey="vctr" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>CPC</FilterHeaderCell>
          <CurrencyCell dataKey="cpc" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>CVR</FilterHeaderCell>
          <CurrencyCell dataKey="cvr" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>CPA</FilterHeaderCell>
          <CurrencyCell dataKey="cpa" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>CPM</FilterHeaderCell>
          <CurrencyCell dataKey="cpm" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>VCPM</FilterHeaderCell>
          <CurrencyCell dataKey="vcpm" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={100} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Impressions</FilterHeaderCell>
          <NumberCell dataKey="impressions" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={150} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Visible Impressions</FilterHeaderCell>
          <NumberCell dataKey="visible_impressions" style={{lineHeight: '10px'}}/>
        </Column>
        <Column width={120} align="right" sortable resizable>
          <FilterHeaderCell filters={filters} setFilters={setFilters}>Actions Number</FilterHeaderCell>
          <NumberCell dataKey="actions" style={{lineHeight: '10px'}}/>
        </Column>
      </Table>
    </>
  );
};

export const ActionCell = ({
  rowData,
  editHandler,
  ...props
}: any) => {
  return (
    <Cell {...props} className="link-group">
      {
        rowData[rootKey]
          ? <div>
            <ButtonToolbar>
              <IconButton
                icon={<EditIcon/>}
                onClick={(e) => {
                  // Prevent event bubbling
                  e.stopPropagation();

                  editHandler(rowData); }
                }
              />
            </ButtonToolbar>
          </div>
          : null
      }
    </Cell>
  );
}

export default CampaignContentList;