import React from "react";
import Dashboard from "../@Components/Dashboard";
import RuleGrid from "../@Components/Rules/RuleGrid";

const RulesReport: React.FC = () => {
  return (
    <Dashboard>
      <RuleGrid />
    </Dashboard>
  );
};

export default RulesReport;