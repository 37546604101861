import React from "react";
import Dashboard from "../@Components/Dashboard";
import System1KeywordStatsTree from "../@Components/System1/KeywordStatsTree";

const System1KeywordStatsPage: React.FC = () => {
    return (
        <Dashboard>
            <System1KeywordStatsTree/>
        </Dashboard>
    );
};

export default System1KeywordStatsPage;
