import * as React from "react";

type TitleProps = {
  title: string;
};

const Title: React.FC<TitleProps> = (props: any) => {
  const title = props.title;
  return (
    <div>
      <div className="tableTitle" style={{color: "#023066"}}>
        <h3 style={{padding: "10px"}}>{title}</h3>
      </div>
      <hr/>
    </div>
  );
};

export default Title;
