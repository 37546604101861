import React, { ReactNode } from "react";
import { Table, toaster, Notification, FlexboxGrid, Checkbox, Tooltip, Whisper } from "rsuite";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import HttpClient from "../../@Utils/HttpClient";
import OutbrainLogo from '../../@Icons/OutbrainLogo';
import TaboolaLogo from '../../@Icons/TaboolaLogo';
import ConfirmModal from "../ConfirmModal";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const { Column, HeaderCell, Cell } = Table;

type TrafficSourcesTreeProps = {
  data?: any;
  loading?: any;
  sortType?: any;
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  openWithHeader: any;
  setModalOpen: any;
  setFormatData: any;
  setTrafficSourceId: any;
  refreshCallback?: () => void;
  checkedKeys: any[];
  setCheckedKeys: any;
};

const TrafficSourcesList: React.FC<TrafficSourcesTreeProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn,
  onSortColumn,
  setModalOpen,
  setFormatData,
  setTrafficSourceId,
  refreshCallback,
  checkedKeys,
  setCheckedKeys,
}) => {
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({ traffic_source_id: 0 });
  const [traffics, setTraffics] = React.useState<any[]>([]);
  const [load, setLoad] = React.useState(true);

  const checked = checkedKeys.length > 0 && checkedKeys.length === traffics.length;
  const indeterminate = checkedKeys.length > 0 && checkedKeys.length < traffics.length;

  React.useEffect(() => {
    setTraffics(data);
    setLoad(loading);
  }, [data, load, loading])

  const handleOpen = () => setRemoveConfirmModalOpen(true);
  const handleClose = () => setRemoveConfirmModalOpen(false);
  const handleRemove = () => {
    removeItem(selectedItem ? selectedItem.traffic_source_id : 0);

    handleClose();
  };

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    HttpClient.delete<any>(`traffic_source/${itemId}`)
      .then(res => {
        const type = res.status === 200 ? "success" : "error";
        const text = res.status === 200 ? "Traffic Source has been removed" : res.statusText;

        pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);

        if (refreshCallback instanceof Function) {
          refreshCallback();
        }
      })
      .catch(error => {
        pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const columns = React.useMemo(() => [
    {
      dataKey: 'traffic_source_id',
      label: 'ID',
      width: 70,
      align: 'right',
      fixed: true,
    },
    {
      dataKey: 'custom_tracking_enabled',
      label: 'Status',
      width: 110,
      fixed: true,
      cellCmp: CircleCell,
    },
    {
      dataKey: 'traffic_source_name',
      label: 'Nickname',
      width: 200,
      fixed: true,
    },
    {
      dataKey: 'account_name',
      label: 'Account Name',
      width: 200,
      fixed: true,
    },
    {
      dataKey: 'traffic_source_type',
      label: 'Traffic Source',
      width: 200,
      renderFn: (rowData: any) => {
        let logo = rowData.traffic_source_type;
        switch (rowData.traffic_source_type) {
          case "outbrain":
            logo = <OutbrainLogo />;
            break;

          case "taboola":
            logo = <TaboolaLogo />;
            break;
        }

        return logo;
      },
    },
    {
      dataKey: 'managers',
      label: 'Manager',
      width: 200,
      renderFn: (rowData: any) => {
        const managers = rowData?.managers && isJson(rowData.managers)
          ? JSON.parse(rowData.managers)
          : [];

        if (!Array.isArray(managers) || managers.length === 0) {
          return <span>&mdash;</span>
        }

        if (managers.length === 1) {
          return <span>{managers[0]}</span>
        }

        const managerOutput = managers.join(', ');

        return <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{managerOutput}</Tooltip>}
        >
          <span><b style={{cursor: 'pointer'}}>several</b></span>
        </Whisper>
      },
    }

  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  const handleCheckAll = (value: any, checked: boolean) => {
    const keys = checked ? traffics.map((item: any) => item.traffic_source_id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value: any, checked: boolean) => {
    const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);

    setCheckedKeys(keys);
  };

  return (
    <>
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
            columns={columns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          height={600}
          data={Array.isArray(traffics) ? traffics : []}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={onSortColumn}
        >
          <Column width={70} align="center" fixed>
            <HeaderCell style={{padding: 0}}>
              <div style={{lineHeight: "38px"}}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell dataKey="traffic_source_id" checkedKeys={checkedKeys} onChange={handleCheck}/>
          </Column>
          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>

      <ConfirmModal
        title="Removing"
        open={removeConfirmModalOpen}
        onClose={handleClose}
        onYes={handleRemove}
      >
        Are you sure you want to remove this Traffic Source?
      </ConfirmModal>
    </>
  );
};

const CircleCell = ({
  rowData,
  dataKey,
  ...props
}: any) => (
  <Cell {...props} className="link-group">
    <Circle enabled={rowData[dataKey] === 1} />
  </Cell>
);

const Circle = ({ enabled }: any) => {
  const style = {
    height: 20,
    width: 20,
    marginTop: 8,
    backgroundColor: enabled ? '#00FF00' : '#FFA500',
    borderRadius: "50%",
    display: "inline-block",
  };

  return (
    <>
      <div className="show-grid">
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={6}>
            <span style={style} />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item style={{ marginTop: 8 }} colspan={6}>
            <span>{enabled ? 'Enabled' : 'Disabled'}</span>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </>
  );
}

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
  <Cell {...props} style={{padding: 0}}>
    <div style={{lineHeight: "35px"}}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Cell>;

const isJson = (str: string) => {
  try { JSON.parse(str);} catch (e) {return false;}
  return true;
};

export default TrafficSourcesList;