import React, { ReactNode } from "react";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import { Message, Notification, toaster } from "rsuite";

/**
 * Push a new message or notification via toaster
 *
 * @param message
 * @param placement
 */
export const pushInforming = (message: ReactNode, placement: PlacementType = "topEnd") =>
  toaster.push(message, {placement});

/**
 * Push a new error notification via toaster
 *
 * @param error
 * @param duration
 */
export const pushErrorNotification = (error: string, duration: number = 60000) =>
  pushInforming(<Notification closable type="error" header="Error" duration={duration}>{error}</Notification>);

/**
 * Push a new error message via toaster
 *
 * @param error
 * @param duration
 * @param full
 * @param closable
 */
export const pushErrorMessage = (error: string, duration: number = 60000, full = true, closable = true) =>
  pushInforming(
    <Message type="error" duration={duration} full={full} closable={closable}>
      {error}
    </Message>,
    "topCenter"
  );