import React, {ReactNode} from "react";
import {Steps, Panel, ButtonGroup, Button, Notification, toaster} from "rsuite";
import UrlKeywordForm from "./UrlKeywordForm";
import UrlAdvertiserForm from "./UrlAdvertiserForm";
import UrlDomainManagerSelect from "./UrlDomainManagerSelect";
import UrlFormsAccountSelect from "./UrlFormsAccountSelect";
import UrlPreview from "./UrlPreview";
import {FormInstance} from "rsuite/Form";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";

type System1UrlFormType = {
    domain?: any;
    parentCallback: any;
    postValue: any;
}

const UrlFormsContainer: React.FC<System1UrlFormType> = ({
domain = '',
parentCallback,
postValue
}) => {
    const [step, setStep] = React.useState(0);
    const initFormValue = {
        forcekeyA: postValue.forcekeyA,
        forcekeyB: postValue.forcekeyB,
        forcekeyC: postValue.forcekeyC,
        forcekeyD: postValue.forcekeyD !== null ? postValue.forcekeyD : '',
        forcekeyE: postValue.forcekeyE !== null ? postValue.forcekeyE : '',
        ttid: postValue.ttid,
        url: postValue.domain,
        domain_id: postValue.domain_id,
        eu_domain: postValue.eu_domain,
        facebook_domain_verification_code: postValue.facebook_domain_verification_code,
        created: postValue.created,
        id: postValue.id,
        last_updated: postValue.last_updated,
        status: postValue.status,
        rskey: postValue.rskey,
        user_id: postValue.user_id,
        advertiser_id: postValue.advertiser_id,
        account_id: postValue.account_id,
    };


    const prepareFormData = (record: any) => {
        if (record === null) {
            return;
        }

        if (!Array.isArray(record.eu_domain)) {
            record.eu_domain = record.eu_domain === '1' ? [1] : [];
        }

        return record;
    }
    const [formValue, setFormValue] = React.useState(prepareFormData(initFormValue));
    const mainFormRef = React.createRef<FormInstance>();
    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});
    const onChange = (nextStep: any) => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
    };

    const onNext = () =>  {
        if (step === 0) {
            if (formValue.forcekeyA === '' || formValue.forcekeyB === '' || formValue.forcekeyC === '') {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    At least 3 keywords are required
                </Notification>);
            } else {
                onChange(step + 1);
            }
        } else if (step === 1) {
            if (formValue.ttid === '' || formValue.ttid === null && formValue.advertiser_id === '' || formValue.advertiser_id === null) {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    Please select a pixel
                </Notification>);
            } else {
                onChange(step + 1);
            }
        } else if (step === 2) {
            if(formValue.user_id === '' || formValue.user_id === null) {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    Please select a manager
                </Notification>);
            } else {
                onChange(step + 1);
            }

        }  else if (step === 3) {
            if(formValue.account_id === '' || formValue.account_id === null) {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    Please select an account
                </Notification>);
            } else {
                onChange(step + 1);
            }

        }

    }
    const onPrevious = () => onChange(step - 1);

    const handleCallback = (formValue: any) => {
        setFormValue(formValue);

        if (typeof parentCallback === "function") {
            parentCallback(formValue);
        }
    };

    console.log(formValue)
    console.log(step)


    return (
        <>
            <Steps current={step}>
                <Steps.Item title="Keywords" description="Enter Keywords"/>
                <Steps.Item title="Pixel" description="Select Pixel" />
                <Steps.Item title="Manager" description="Select Manager" />
                <Steps.Item title="Account" description="Select Account" />
                <Steps.Item title="Preview" description="Preview Url" />
            </Steps>
            <hr />
            <Panel>
                {step === 0 ? (
                    <UrlKeywordForm
                        formRef={mainFormRef}
                        formValue={prepareFormData(formValue)}
                        parentCallback={handleCallback}/>
                ) : ''}
                {step === 1 ? (
                    <UrlAdvertiserForm
                        formValue={formValue}
                        parentCallback={handleCallback}
                    />
                ) : ''}

                {step === 2 ? (
                    <UrlDomainManagerSelect
                        formValue={formValue}
                        parentCallback={handleCallback}
                    />
                ) : ''}

                {step === 3 ? (
                    <UrlFormsAccountSelect
                        formValue={formValue}
                        parentCallback={handleCallback}
                    />
                ) : ''}

                {step === 4 ? (
                    <UrlPreview
                        url={domain}
                        keywords={formValue}
                        parentCallback={handleCallback}
                        formValue={formValue}
                    />
                ) : ''
                }
            </Panel>
            <hr />
            <ButtonGroup>
                <Button onClick={onPrevious} disabled={step === 0}>
                    Previous
                </Button>
                <Button onClick={onNext} disabled={step === 4}>
                    Next
                </Button>
            </ButtonGroup>
        </>
    )

}

export default UrlFormsContainer;