import React from "react";
import {Col, Grid, Row, SelectPicker, Loader, Button} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import {pushErrorNotification} from "../../@Utils/Messager";

type UrlFormsAccountSelectProps = {
    formValue: any,
    parentCallback?: any;
}
const UrlFormsAccountSelect: React.FC<UrlFormsAccountSelectProps> = ({
formValue,
parentCallback = () => {},
}) => {

    const [isLoading, setIsLoading] = React.useState('block');
    const [selectedAccount, setSelectedAccount] = React.useState<any>();
    const [accounts, setAccounts] = React.useState<Record<string, any>[]>([]);
    const [isDisabled, setIsDisabled] = React.useState(true)

    React.useEffect(() => {
        HttpClient
            .get<any>('system1_accounts')
            .then(res => {
                setAccounts(res.data.data)
                setIsLoading('none')

                if(formValue.account_id !== undefined && formValue.account_id !== '' && !isNaN(formValue.account_id)) {
                    handleAccountChange(formValue.account_id)
                }

                if (formValue.domain_id === 0) {
                    setIsDisabled(false);
                }

            }).catch(error => {
            pushErrorNotification(error.response.data?.error || error.toString());
        });
    }, []);

    const handleAccountChange = (accountId: any) => {
        setSelectedAccount(accountId)

        if (typeof parentCallback === "function") {
            parentCallback({
                ...formValue,
                ...{
                    account_id: accountId
                }
            });
        }
    };

    return (
        <>
            <Grid fluid>
                <Row>
                    <Col xs={12} sm={12} md={24} lg={24} style={{ textAlign: 'center'}}>
                        <label style={{fontWeight: 'bold'}}>Select Account: </label>
                        <br/>
                        <Loader size="md" style={{display: isLoading}}/>
                        {accounts.length ? (
                            <SelectPicker
                                size="lg"
                                searchable={true}
                                cleanable={true}
                                placeholder="Select Account"
                                value={selectedAccount}
                                data={accounts}
                                valueKey="account_id"
                                labelKey="account_name"
                                onChange={(v) => {
                                    handleAccountChange(v);
                                }}
                                style={{  }}
                                disabled={isDisabled}
                            />
                        ) : ''}

                    </Col>
                </Row>
            </Grid>
        </>
    )
}

const convertToInt = (value: string | number) => typeof value === 'number' ? value : parseInt(value, 10);

export default UrlFormsAccountSelect;