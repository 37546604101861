import React from "react";
import {Button, Col, DatePicker, Drawer, Form, Grid, Notification, Panel, PanelGroup, Row, Schema,} from "rsuite";
import GeneralSettings from "./GeneralSettings";
import DataPassing from "../OfferSources/DataPassing";
import {FormInstance} from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";
import {pushInforming} from "../../@Utils/Messager";
import moment from "moment/moment";

const {StringType, NumberType} = Schema.Types;

const model = Schema.Model({
    offer_id: NumberType(),
    offer_name: StringType().isRequired("Offer Source Name is required"),
    offer_category_id: NumberType().isRequired("Category is required"),
    offer_source_id: NumberType().isRequired("Source is required"),
    base_offer_url: StringType().maxLength(1000, "Max length is 1,000.").isURL("Please enter the correct URL address with http or https protocol"),
    offer_payout: NumberType().max(99999.9999),
    notes: StringType(),
    data_passing: StringType(),
    traffic_source_type: StringType().isRequired("Traffic Source Provider is required"),
});

type CreateOfferModalProps = {
    formData: any;
    offerId: number;
    parentCallback?: any;
}

const CreateOfferModal: React.FC<CreateOfferModalProps> = ({
    formData,
    offerId,
    parentCallback = () => {
    }
}) => {
    const [formError, setFormError] = React.useState<any>({});
    const [formValue, setFormValue] = React.useState(formData);
    const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
    const mainFormRef = React.createRef<FormInstance>();

    // TODO Replace it
    React.useEffect(() => {
        setFormValue(formData);
    }, [formData]);

    const handleChangeCategory = (categoryId: number, categoryName: string = '') => {
        setFormValue({
            ...formValue,
            offer_category_id: categoryId,
            offer_category_name: categoryName,
        });
    };

    const handleChangeOfferSource = (offerSourceId: number, dataPassing?: string) => {
        if (formValue.offer_id > 0) {
            setFormValue({
                ...formValue,
                offer_source_id: offerSourceId,
            });
        } else {
            setFormValue({
                ...formValue,
                offer_source_id: offerSourceId,
                data_passing: dataPassing,
            });
        }
    };

    const handleChangeDataPassing = (values: any) => {
        setFormValue({
            ...formValue,
            data_passing: JSON.stringify(values),
        });
    };

    const handleSave = () => {
        const node = mainFormRef && mainFormRef.current;

        if (node?.check && !node.check()) {
            return;
        }

        setSaveButtonLoading(true);

        if (offerId === 0) {
            // Create a new one
            HttpClient
                .post<object, any>('offer', formValue)
                .then(res => {
                    const offerId = parseInt(res.data.offer_id);
                    const type = res.status === 201 ? "success" : "error";
                    const text = res.status === 201 ? "Offer has been created" : res.statusText;

                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

                    // Update form data
                    const updatedFormValue = {
                        ...formValue,
                        offer_id: offerId,
                    };
                    const offerCategoryId:string = updatedFormValue.offer_category_id.toString();
                    const key:string ='_' + offerCategoryId;
                    setFormValue(updatedFormValue);
                    setSaveButtonLoading(false);
                    if (parentCallback instanceof Function) {
                        parentCallback(res.data.offer_id)
                    }
                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        } else {
            // Update an existing one
            HttpClient
                .put<any>(`offer/${formValue.offer_id}`, formValue)
                .then(res => {
                    const type = res.status === 200 ? "success" : "error";
                    const text = res.status === 200 ? "Offer has been updated" : res.statusText;

                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

                    setSaveButtonLoading(false);
                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        }

        setFormError({});
    };


    return (
        <>
            <Grid fluid style={{ marginBottom: 30 }}>
                <Row className="show-grid">
                    <Col xs={12} sm={12} md={12} lg={12} mdOffset={12}>
                        <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading} style={{float:'right'}}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Grid>

            <Form
                ref={mainFormRef}
                onChange={setFormValue}
                onCheck={setFormError}
                model={model}
                layout="horizontal"
                formValue={formValue}
                formError={formError}
            >
                <PanelGroup accordion bordered>
                    <Panel header="General Settings" defaultExpanded>
                        <GeneralSettings
                            url={formValue.base_offer_url}
                            onChangeCategory={handleChangeCategory}
                            onChangeOfferSource={handleChangeOfferSource}
                        />
                    </Panel>
                    <Panel header="Configure Data Passing" defaultExpanded={true}>
                        <DataPassing
                            fields={formValue.data_passing ? JSON.parse(formValue.data_passing) : []}
                            onChangeData={handleChangeDataPassing}
                        />
                    </Panel>
                </PanelGroup>
            </Form>
        </>
    )
}

export default CreateOfferModal;