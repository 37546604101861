import React, {RefObject} from "react";
import {Input} from "rsuite";

const Textarea = React.forwardRef((props: any, ref) => {
  // Set default values
  props = {
    rows: 4,
    ...props,
    ...{
      style: {
        width: "100%",
        ...props.style,
      }
    }
  };

  return <Input {...props} as="textarea" ref={ref as RefObject<HTMLTextAreaElement>}/>;
});

export default Textarea;
