import React, { ReactNode } from "react";
import {Table, Tooltip, Whisper, Checkbox} from "rsuite";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { sort } from "../../@Utils/Sorting";

const {Column, HeaderCell, Cell} = Table;

type System1DomainsListProps = {
    data?: any;
    loading?: any;
    checkedKeys: any[];
    setCheckedKeys: any;
    refreshCallback?: () => void;
}

const System1DomainsList: React.FC<System1DomainsListProps> = ({
    data = [],
    loading = false,
   checkedKeys,
   setCheckedKeys,
   refreshCallback,
}) => {
    const [traffics, setTraffics] = React.useState<any[]>([]);
    const [load, setLoad] = React.useState(true);

    const checked = checkedKeys.length > 0 && checkedKeys.length === traffics.length;
    const indeterminate = checkedKeys.length > 0 && checkedKeys.length < traffics.length;

    const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
    const [sortColumn, setSortColumn] = React.useState("domain");

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    React.useEffect(() => {
        setTraffics(data);
        setLoad(loading);
    }, [data, load, loading])

    const columns = React.useMemo(() => [
        {
            dataKey: 'domain',
            label: 'Domain',
            width: 350,
            align: 'right',
            fixed: true,
        },
        {
            dataKey: 'managers',
            label: 'Manager',
            width: 200,
            renderFn: (rowData: any) => {
                const managers = rowData?.managers && isJson(rowData.managers)
                    ? JSON.parse(rowData.managers)
                    : [];

                if (!Array.isArray(managers) || managers.length === 0) {
                    return <span>&mdash;</span>
                }

                if (managers.length === 1) {
                    return <span>{managers[0]}</span>
                }

                const managerOutput = managers.join(', ');

                return <Whisper
                    trigger="hover"
                    placement="top"
                    speaker={<Tooltip>{managerOutput}</Tooltip>}
                >
                    <span><b>...</b></span>
                </Whisper>
            },
        }

    ], []);

    const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
        LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_GRID_SHOW_COLUMNS,
        columns.map(column => column.dataKey)
    );

    const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
        LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_GRID_COLUMNS_ORDER,
        columns.map(column => column.dataKey)
    );

    const builtColumns = React.useMemo(() => {
        return buildColumns(columns,  columns.map(column => column.dataKey),  columns.map(column => column.dataKey));
    }, [columns, visibleColumnKeys, orderedColumnKeys]);

    const handleDragColumn = (sourceId: string, targetId: string) => {
        setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
    };

    const sortColumns = (source: string[], sourceId: string, targetId: string) => {
        // Push source element in the source array if the source doesn't contain it
        if (source.indexOf(sourceId) === -1) {
            source = [...source, sourceId];
        }

        const nextData = source.filter((key: string) => key !== sourceId);
        const dragItem = source.find((key: string) => key === sourceId);
        const index = nextData.findIndex((key: string) => key === targetId);

        if (dragItem)
            nextData.splice(index, 0, dragItem);

        return nextData;
    }

    const handleCheckAll = (value: any, checked: boolean) => {
        const keys = checked ? traffics.map((item: any) => item.domain) : [];
        setCheckedKeys(keys);
    };

    const handleCheck = (value: any, checked: boolean) => {
        const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);

        setCheckedKeys(keys);
    };

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Table
                    height={600}
                    data={Array.isArray(traffics) ? sort(traffics, sortColumn, sortType) : []}
                    loading={loading}
                    virtualized
                    rowClassName="striped-rows"
                    affixHeader
                    affixHorizontalScrollbar
                    sortType={sortType}
                    sortColumn={sortColumn}
                    onSortColumn={handleSortColumn}
                >
                    <Column width={70} align="center" fixed>
                        <HeaderCell style={{padding: 0}}>
                            <div style={{lineHeight: "38px"}}>
                                <Checkbox
                                    inline
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onChange={handleCheckAll}
                                />
                            </div>
                        </HeaderCell>
                        <CheckCell dataKey="domain" checkedKeys={checkedKeys} onChange={handleCheck}/>
                    </Column>
                    {renderColumns(builtColumns, handleDragColumn)}
                </Table>
            </DndProvider>
        </>
    )
}

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
    <Cell {...props} style={{padding: 0}}>
        <div style={{lineHeight: "35px"}}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
            />
        </div>
    </Cell>;

const isJson = (str: string) => {
    try { JSON.parse(str);} catch (e) {return false;}
    return true;
};
export default System1DomainsList;

