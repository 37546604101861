import React, {ReactNode} from "react";
import CopyIcon from '@rsuite/icons/Copy';
import {Schema, Form,IconButton, Notification, toaster, Col, Grid, Row} from "rsuite";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import Field from "../Field";

type UrlPreviewType = {
    url?: any;
    keywords?: any;
    pixelId?: any;
    parentCallback?: any;
    formValue: any;
}

const UrlPreview: React.FC<UrlPreviewType> = ({
url = '',
keywords = {},
pixelId = '',
parentCallback = () => {},
formValue,
}) => {
    const [completeUrl, setCompleteUrl] = React.useState('');
    const [isDisabled, setIsDisabled] = React.useState(true)

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    function objectToUrlParams(obj: any, url: any, pixel: any) {
        const params = [];

        const encodeObject:any = {
            forcekeyA: obj.forcekeyA.replace(/ /g, '+'),
            forcekeyB: obj.forcekeyB.replace(/ /g, '+'),
            forcekeyC: obj.forcekeyC.replace(/ /g, '+'),
            forcekeyD: obj.forcekeyD.replace(/ /g, '+'),
            forcekeyE: obj.forcekeyE.replace(/ /g, '+'),
            ttid: obj.ttid
        }

        for (const key in encodeObject) {
            params.push(`${encodeURIComponent(key)}=${encodeURIComponent(encodeObject[key])}`);
        }

        if (formValue.domain_id === 0) {
        // if (url === '') {
            setIsDisabled(false);
            const tldArray = [
                '.actor', '.agency', '.airforce', '.army', '.auction', '.band', '.careers', '.center', '.cheap', '.community',
                '.consulting', '.cool', '.credit', '.dance', '.democrat', '.email', '.engineer', '.equipment',
                '.events', '.exchange', '.family', '.finance', '.forsale', '.futbol', '.fyi', '.games', '.haus',
                '.immobilien', '.info', '.kaufen', '.live', '.market', '.media', '.moda',
                '.navy', '.news', '.network', '.ninja', '.plus', '.prosperities', '.pub', '.rehab', '.republican', '.reviews',
                '.rip', '.rocks', '.sale', '.school', '.services', '.social', '.software', '.studio', '.style', '.supply',
                '.systems', '.tips', '.tools', '.town', '.vet', '.video', '.voyage', '.works', '.world', '.zone'
            ];

            url = encodeObject.forcekeyA.replace(/[+]/g, '-') + '-' + Math.floor(100000000 + Math.random() * 900000000) + tldArray[Math.floor(Math.random() * tldArray.length)];

            if (typeof parentCallback === "function") {
                parentCallback({
                    ...formValue,
                    ...{
                        url: url,
                        rskey: obj.forcekeyA
                    }
                });
            }

            setCompleteUrl(url)

        } else {
            setCompleteUrl(formValue.url);

            if (typeof parentCallback === "function") {
                parentCallback({
                    ...formValue,
                    ...{
                        rskey: obj.forcekeyA
                    }
                });
            }
        }
    }

    React.useEffect(() => {
        objectToUrlParams(keywords, url, pixelId)
    }, [])

    const handleCopyButtonClick = () => {
        navigator.clipboard.writeText(completeUrl)

        pushMessage(<Notification closable type="success" header="URL Copied" duration={60000}>
            Copied Url
        </Notification>);
    }

    const handleFormChange = (data: any) => {
        if (typeof parentCallback === "function") {
            parentCallback({
                ...formValue,
                ...{
                    url: data.url
                }
            });
        }
    }

    return (
        <>
            <Form
                formValue={formValue}
                onChange={handleFormChange}
                layout="horizontal"
            >
                <Grid fluid>
                    <Row style={{marginBottom: 15}}>
                        <Col xs={12} sm={12} md={24} lg={24} style={{textAlign: 'center'}}>
                            <Field
                                label='Edit Domain'
                                name='url'
                                placeholder='Domain'
                                disabled={isDisabled}
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    )

}

export default UrlPreview;