import React from "react";
import {
  IconButton, Notification,
  Table, TagPicker, Tooltip, Whisper
} from "rsuite";

import ButtonToolbar from "rsuite/ButtonToolbar";
import RemoveIcon from "@rsuite/icons/Trash";
import HttpClient from "../../@Utils/HttpClient";
import {pushInforming} from "../../@Utils/Messager";

const { Column, HeaderCell, Cell} = Table;

type GroupListProps = {
  data?: any;
  setData?: any;
  setLoading?: any;
  setScrollY?: any;
  rules?: any;
  loading?: boolean;
  removeIds?:any;
  setRemoveIds?:any;
  scrollX?:any;
  scrollY?:any;
  parentCallback?:any;
};

const GroupList: React.FC<GroupListProps> = ({
  data = [],
  setData,
  loading,
  removeIds,
  setRemoveIds,
  rules = [],
  setScrollY,
  scrollX,
  scrollY,
  parentCallback
}) => {
  const setShouldUpdateScroll = () => {
    return {
      x: scrollX,
      y: scrollY
    }
  };
  const [load, setLoad] = React.useState(loading);
    const [groups, setGroups] = React.useState<any[]>([]);

  React.useEffect(() => {
    setGroups(data);
  }, [data]);
  const fetchData = () => {
    HttpClient
        .get<any>('rule_group', {includes_rule:true})
        .then(res => {
          setData( res.data.data);
          setScrollY(0);
          setLoad(false);
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
          setLoad(false);
        })
  }

  React.useEffect(fetchData,[]);
  const handleRemoveClick = (rowData: any) => {
    const filtered = groups.filter((item: any) => item.group_id === rowData.group_id && rowData.status !== 'added' ? item.status = 'deleted' : item)
      .filter((item: any) => item.group_id !== rowData.group_id);
    const ids: any = rowData.group_id && rowData.status !== 'added' ? [...removeIds, rowData.group_id] : removeIds.filter((item:any) => item !== rowData.group_id);
    setData(filtered);
    setRemoveIds(ids);
    if (typeof parentCallback === "function") {
      parentCallback();
    }
  };

  const handleChange = (id: number, key: number, value: any) => {
    const nextData = Object.assign([], groups);

    // @ts-ignore
    nextData.find((item: any) => item.group_id === id)[key] = value;
      // @ts-ignore  
    if(nextData.find((item: any) => item.group_id === id)['status'] !=='added')  nextData.find((item: any) => item.group_id === id)['status'] = 'edited';

    setGroups(nextData);
  };

  const handleRulesChange = (id: number, key: number,v:any) => {
    const nextData: any = Object.assign([], groups);

    if(nextData.find((item: any) => item.group_id === id)['status'] !=='added') {
      nextData.find((item: any) => item.group_id === id)['status']  = 'edited';
    }
    if(v === null) {
      nextData.find((item: any) => item.group_id === id)['rules'] = [];
      return;
    }

    nextData.find((item: any) => item.group_id === id)['rules'] = rules.filter((item:any) => v.includes(item.rule_id)).map((r:any) => r.rule_id);
    if(nextData.find((item: any) => item.group_id === id)) {
      nextData.find((item: any) => item.group_id === id)['rules'] = rules.filter((item:any) => v.includes(item.rule_id)).map((r:any) => r.rule_id);
      setGroups(nextData);
    }
  }

  return (
    <>
      <Table
        wordWrap = {true}
        rowHeight={(rowData: any) => {
            if (rowData ) {
              if(rowData.rules?.length == 1 || rowData.rules?.length == 2) {
                return rowData.rules.length * 60
              }
              if(rowData.rules?.length == 3 || rowData.rules?.length == 4) {
                return 180
              }
              else if(rowData.rules?.length ) {
                return rowData.rules.length * 30
              }
              else {
                return 80;
              }
            }
          return 80;
        }}

        data={Array.isArray(data) ? data : []}
        shouldUpdateScroll={setShouldUpdateScroll}
        loading={load}
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        showHeader={true}
        fillHeight={true}
        virtualized
        bordered
        cellBordered
        headerHeight={61}
      >
        <Column width={100} align="left" resizable>
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            removeHandler={handleRemoveClick}
          />
        </Column>
        <Column width={300} align="center" resizable>
          <HeaderCell>Group</HeaderCell>
          <EditCell dataKey="group_name" onChange={handleChange}/>
        </Column>
        <Column width={510} align="center" resizable>
          <HeaderCell>Rule</HeaderCell>
          <RulesCell
            rules ={rules}
            dataKey ="rules"
            onChange={handleRulesChange}
            groups = {groups}
            setData = {setData}
            style = {{height:'auto'}}
          />
        </Column>
      </Table>
    </>
  );
};

export const RulesCell = ({
  rules,
  rowData,
  dataKey,
  groups,
  setData,
  removeHandler,
  parentCallback,
  onChange,
  ...props
}: any) => {

  return (
    <Cell {...props} >
      <TagPicker
        data={rules.map((item: any) => ({
          value: !isNaN(parseInt(item.rule_id)) ? parseInt(item.rule_id) : 0,
          label: item.rule_name,
        }))}
        defaultValue={rowData[dataKey]}
        style={{width: 450}}
        placeholder="Select Rules"
        cleanable
        searchable
        onChange={value => {
            onChange && onChange(rowData.group_id, dataKey, value);
         }}
      />
    </Cell>
  );
};

export const ActionCell = ({
  rowData,
  removeHandler,
  ...props
}: any) => {
  const buttonStyle = {marginLeft: '6px'};
  return (
    <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
      <div>
        <ButtonToolbar>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Remove</Tooltip>}
          >
            <IconButton
              icon={<RemoveIcon style={{color: "red"}}/>}
              onClick={(e) => removeHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
        </ButtonToolbar>
      </div>
    </Cell>
  );
};

export const EditCell = ({rowData, dataKey, onChange, ...props}: any) => {
  const [editing, setEditing] = React.useState(false);
  const handleOpenEdit = () => setEditing(true);
  const handleCloseEdit = () => setEditing(false);

  return (
    <Cell {...props} onClick={handleOpenEdit}>
      {editing ? (
        <input
          className="rs-input table-content-editing"
          defaultValue={rowData[dataKey]}
          onFocus={handleOpenEdit}
          onMouseLeave={handleCloseEdit}
          onChange={event => {
            onChange && onChange(rowData.group_id, dataKey, event.target.value);
          }}
        />
      ) : (
        <a>{rowData[dataKey]}</a>
      )}
    </Cell>
  );
};

export default GroupList;