import React from "react";
import Dashboard from "../@Components/Dashboard";
import UrlManagementTree from "../@Components/System1/UrlManagementTree";


const System1UrlManagement: React.FC = () => {
    return (
        <Dashboard>
            <UrlManagementTree />
        </Dashboard>
    );
};

export default System1UrlManagement;
