import React from "react";
import { Schema, Form } from "rsuite";
import Field from "../Field";

const { NumberType, StringType } = Schema.Types;

const model = Schema.Model({
    funnel_category_id: NumberType(),
    funnel_category_name: StringType()
        .isRequired("Category Name is required."),
});

type FunnelCategoryType = {
    funnel_category_id?: number;
    funnel_category_name?: string;
    formRef?: React.MutableRefObject<any>;
    parentCallback?: any;
};

const FunnelCategory: React.FC<FunnelCategoryType> = ({
    funnel_category_id = 0,
    funnel_category_name = "",
    formRef = null,
    parentCallback = () => {
    },
}) => {
    formRef = formRef || React.createRef();
    const [formValue, setFormValue] = React.useState({
        funnel_category_id,
        funnel_category_name
    });
    const [formError, setFormError] = React.useState({});

    const handleChange = (data: any) => {
        setFormValue(data);

        if (parentCallback instanceof Function) {
            parentCallback(data);
        }
    };

    return (
        <Form
            ref={formRef}
            onChange={handleChange}
            onCheck={setFormError}
            model={model}
            layout="horizontal"
            formValue={formValue}
            formError={formError}
        >
            <Field label="Category"
                name="funnel_category_name" />
        </Form>
    )
        ;
};

export default FunnelCategory;
