import React from "react";
import { Col, Form, Grid, IconButton, Row, SelectPicker, Tooltip, Whisper } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "../OfferSources/EditModal";
import OfferCategory from "./OfferCategory";
import OfferSource from "./OfferSource";
import { FormInstance } from "rsuite/Form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "@rsuite/icons/Copy";
import HttpClient from "../../@Utils/HttpClient";
import Textarea from "../Textarea";

type GeneralSettingsProps = {
  url: string;
  onChangeCategory?: any;
  onChangeOfferSource?: any;
};

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  url,
  onChangeCategory,
  onChangeOfferSource,
}) => {
  const [categories, setCategories]: any = React.useState([]);
  const [sources, setSources]: any = React.useState([]);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [categoryFormValue, setCategoryFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  const handleCallback = (formValue: any) => {
    setCategoryFormValue(formValue);
  };

  // Load category list
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_category')
      .then(res => {
        setCategories(res.data.map((item: any) => ({
          ...item,
          offer_category_id: isNaN(parseInt(item.offer_category_id)) ? 0 : parseInt(item.offer_category_id),
        })));
      });
  }, []);

  // Load source list
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_source', {
        page: 1,
        limit: 5000,
      })
      .then(res => {
        setSources(res.data.data.map((item: any) => ({
          ...item,
          offer_source_id: isNaN(parseInt(item.offer_source_id)) ? 0 : parseInt(item.offer_source_id),
        })));
      });
  }, []);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const changeCategory = (categoryId: number, categoryName: string = '') => {
    if (typeof onChangeCategory === "function") {
      onChangeCategory(categoryId, categoryName);
    }
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && node.check()) {
      saveCategory(categoryFormValue);
    }
  };

  const saveCategory = (data: any) => {
    setSaveButtonLoading(true);

    HttpClient.post<object, any>('offer_category', data)
      .then((res) => {
        const categoryId = parseInt(res.data.offer_category_id);
        // Add created category in the selector list
        setCategories([...categories, {
          ...data,
          offer_category_id: categoryId
        }]);
        changeCategory(categoryId, data.offer_category_name);
        handleEditModalClose();
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const setOfferCategory = (categoryId: number) => {
    const category = categories.find((item: any) => item.offer_category_id === categoryId);

    changeCategory(categoryId, category.offer_category_name);
  };

  const changeOfferSource = (offerSourceId: number) => {
    if (typeof onChangeOfferSource === "function") {
      onChangeOfferSource(offerSourceId);
    }
  };

  const setOfferSourceDataPassing = (offerSourceId: number) => {
    const offerSource = sources.find((item: any) => item.offer_source_id === offerSourceId);

    if (typeof onChangeOfferSource === "function") {
      onChangeOfferSource(offerSourceId, offerSource.data_passing);
    }
  };

  const saveOfferSource = (data: any) => {
    setSaveButtonLoading(true);

    HttpClient.post<object, any>('offer_source', data)
      .then((res) => {
        const offerSourceId = parseInt(res.data.offer_source_id);
        // Add created offer source in the selector list
        setSources([...sources, {
          ...offerSourceFormValue,
          offer_source_id: offerSourceId,
        }]);
        changeOfferSource(offerSourceId);
        handleOfferSourceEditModalClose();
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const [offerSourceFormValue, setOfferSourceFormValue] = React.useState({});
  const [offerSourceEditModalOpen, setOfferSourceEditModalOpen] = React.useState(false);
  const handleOfferSourceEditModalClose = () => {
    setOfferSourceEditModalOpen(false);
  };

  const handleOfferSourceSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && node.check()) {
      saveOfferSource(offerSourceFormValue);
    }
  };

  const handleOfferSourceCallback = (formValue: any) => {
    setOfferSourceFormValue(formValue);
  };

  return (
    <>
      <Grid fluid>
        <Row>
          <Col md={10}>
            <Form.Group controlId="offer_name">
              <Form.ControlLabel style={{ textAlign: "left" }}>Offer Name</Form.ControlLabel>
              <Form.Control name="offer_name" />
            </Form.Group>
          </Col>
          <Col xs={6} sm={12} md={7}>
            <Form.Group controlId="offer_category_id">
              <Form.ControlLabel style={{ textAlign: "left" }}>Source</Form.ControlLabel>
              <Form.Control
                name="offer_source_id"
                accepter={SelectPicker}
                data={sources}
                valueKey="offer_source_id"
                labelKey="offer_source_name"
                size="md"
                style={{ width: 180 }}
                onChange={(offerSourceId: any) => setOfferSourceDataPassing(offerSourceId)}
              />
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Add Offer Source</Tooltip>}
              >
                <IconButton
                  circle
                  size="md"
                  style={{ marginTop: 40, marginLeft: 2, color: 'blue' }}
                  icon={<PlusIcon />}
                  onClick={() => {
                    setOfferSourceEditModalOpen(true);
                  }}
                />
              </Whisper>
            </Form.Group>
          </Col>
          <Col xs={6} sm={12} md={7}>
            <Form.Group controlId="offer_category_id">
              <Form.ControlLabel style={{ textAlign: "left" }}>Category</Form.ControlLabel>
              <Form.Control
                name="offer_category_id"
                accepter={SelectPicker}
                data={categories}
                valueKey="offer_category_id"
                labelKey="offer_category_name"
                size="md"
                style={{ width: 180 }}
                onChange={(categoryId: any) => setOfferCategory(categoryId)}
              />
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Add Offer Category</Tooltip>}
              >
                <IconButton
                  circle
                  size="md"
                  style={{ marginTop: 40, marginLeft: 2, color: 'blue' }}
                  icon={<PlusIcon />}
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                />
              </Whisper>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <Form.Group controlId="traffic_source_id" style={{ marginTop: 20 }}>
              <Form.ControlLabel style={{ textAlign: "left" }}>Traffic Source</Form.ControlLabel>
              <Form.Control
                name="traffic_source_type"
                accepter={SelectPicker}
                data={[
                  {
                    label: "Taboola",
                    value: "taboola",
                  },
                  {
                    label: "Outbrain",
                    value: "outbrain",
                  },
                  {
                    label: "TikTok",
                    value: "tiktok",
                  }
                ]}
                size="md"
                cleanable={false}
                style={{ width: 510 }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={22}>
            <Form.Group controlId="base_offer_url" style={{ marginTop: 20 }}>
              <Form.ControlLabel style={{ textAlign: "left" }}>Base Offer URL</Form.ControlLabel>
              <Form.Control name="base_offer_url" style={{ width: 740 }} />
            </Form.Group>
          </Col>
          <Col md={2}>
            <CopyToClipboard text={url}>
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Copy to Clipboard</Tooltip>}
              >
                <IconButton
                  size="md"
                  style={{ marginTop: 60, marginLeft: 30, backgroundColor: 'blue' }}
                  icon={<CopyIcon style={{ color: 'white' }} />}
                />
              </Whisper>
            </CopyToClipboard>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={6}>
            <Form.Group controlId="offer_payout">
              <Form.ControlLabel style={{ textAlign: "left" }}>Offer Payout</Form.ControlLabel>
              <Form.Control name="offer_payout" style={{ width: 210 }} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Group controlId="notes">
              <Form.ControlLabel style={{ textAlign: "left" }}>Notes</Form.ControlLabel>
              <Form.Control style={{ width: 800 }} name="notes" accepter={Textarea} />
            </Form.Group>
          </Col>
        </Row>
      </Grid>
      <EditModal
        title={"Add Offer Source"}
        loading={saveButtonLoading}
        open={offerSourceEditModalOpen}
        onClose={handleOfferSourceEditModalClose}
        onCancel={handleOfferSourceEditModalClose}
        onSubmit={handleOfferSourceSave}
      >
        <OfferSource
          formRef={mainFormRef}
          parentCallback={handleOfferSourceCallback}
        />
      </EditModal>
      <EditModal
        title={"Add Offer Category"}
        loading={saveButtonLoading}
        open={editModalOpen}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <OfferCategory
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default GeneralSettings;