import React from "react";
import Dashboard from "../@Components/Dashboard";
import DomainDirectSummaryGrid from "../@Components/TikTok/DomainDirectSummaryGrid";

const TikTokDomainDirectSummaryReport: React.FC = () => {
  return (
    <Dashboard>
      <DomainDirectSummaryGrid />
    </Dashboard>
  );
};

export default TikTokDomainDirectSummaryReport;