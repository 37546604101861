import React, {ReactNode} from "react";
import {
    FlexboxGrid,
    Notification,
    SelectPicker,
    toaster,
    InputGroup,
    TagInput,
    IconButton,
    Input
} from "rsuite";
import Title from "../../@Components/Title";
import { exportCsv } from "../../@Utils/Export";
import HttpClient from "../../@Utils/HttpClient";
import TempHttpClient from "../../@Utils/TempHttpClient";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import GoogleKeywordList from "./GoogleKeywordList";
import locationArray from "./locations";
import languageList from "./languages";
import RunningRound from "@rsuite/icons/RunningRound";
import PlusRound from "@rsuite/icons/PlusRound";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import EditModal from "../OfferSources/EditModal";
import UrlFormsContainer from "../System1/UrlFormsContainer";
import _ from "lodash";

const GoogleKeywordTree: React.FC = () => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<any[]>([]);
    const [keywords, setKeywords] = React.useState([]);
    const defaultLanguage = 1000;
    const [language, setLanguage] = React.useState(defaultLanguage);
    const defaultLocation = 2840;
    const [location, setLocation] = React.useState(defaultLocation)
    const [checkedKeywords, setCheckedKeywords] = React.useState<any[]>([]);
    const [selectedKeywordData, setSelectedKeywordData] = React.useState<any[]>([]);
    const [showDomain, setShowDomain] = React.useState(false);
    const [isModalLoading, setIsModalLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const intervalRef: any = React.useRef(null);
    const [searchQuery, setSearchQuery] = React.useState(null);
    const [locationDesc, setLocationDesc] = React.useState('');
    const [languageDesc, setLanguageDesc] = React.useState('');

    const domainCreateData = {
        forcekeyA: '',
        forcekeyB: '',
        forcekeyC: '',
        forcekeyD: '',
        forcekeyE: '',
        ttid: '',
        url: '',
        domain_id: 0,
        eu_domain: [],
        facebook_domain_verification_code: '',
        domain: '',
        created: '',
        id: '',
        last_updated: '',
        status: '',
        rskey: ''
    }

    const [rowData, setRowData] = React.useState(domainCreateData);

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});
    const fetchData = () => {
        if (keywords.length && location !== null && language !== null) {
            setData([]);
            setLoading(true);
            setCheckedKeywords([]);
            setSelectedKeywordData([]);

            TempHttpClient
                .get<any>('google_keywords', {
                    keywords: keywords,
                    language: language.toString(),
                    location: [location.toString()]
                })
                .then(res => {
                    setLoading(false);
                    setData(res.data);
                }).catch(error => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setLoading(false);
            });
        } else {
            setLoading(false);
            if (!keywords.length) {
                pushMessage(<Notification closable type="warning" header="Warning" duration={60000}>
                    Please input at least one keyword to search for
                </Notification>);
            }

            if (location === null) {
                pushMessage(<Notification closable type="warning" header="Warning" duration={60000}>
                    Please Select a location
                </Notification>);
            }

            if (language === null) {
                pushMessage(<Notification closable type="warning" header="Warning" duration={60000}>
                    Please Select a language
                </Notification>);
            }

        }
    };

    const handleButtonClick = () => {
        fetchData();
    }

    const getExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        'Keyword': item.keyword,
                        'Monthly Searches': item.monthly_searches,
                        'Competition': item.competition,
                        'Low Bid': item.low_bid,
                        'High Bid': item.high_bid
                    }
                })
        )
    }

    const handleCreateDomainClick = () => {
        const languageDesc = languageList.find((item) => item.id === language)?.name || '';
        const locationDesc = locationArray.find((item) => item.id === location)?.name || '';

        setRowData({...rowData, ...{
                forcekeyA: checkedKeywords[0],
                forcekeyB: checkedKeywords[1],
                forcekeyC: checkedKeywords[2],
                forcekeyD: checkedKeywords[3] ? checkedKeywords[3] : '',
                forcekeyE: checkedKeywords[4] ? checkedKeywords[4] : '',
            }})
        setIsOpen(true)
        setLanguageDesc(languageDesc);
        setLocationDesc(locationDesc);
    }

    const handleFormValueCallback = (data: any) => {
        setRowData(prepareFormData(data))
    }

    const prepareFormData = (record: any) => {
        if (record === null) {
            return;
        }

        if (!Array.isArray(record.eu_domain)) {
            record.eu_domain = record.eu_domain === 1 ? [1] : [];
        }

        return record;
    }

    const handleEditModalClose = () => {
        setIsOpen(false);
    };

    const handleSave = () => {
        let valid = false;

        valid = rowData.forcekeyA !== '' &&
            rowData.forcekeyB !== '' &&
            rowData.forcekeyC !== '' &&
            rowData.ttid !== '' &&
            rowData.url !== ''

        if (valid) {
            setIsModalLoading(true);
            const data: any = _.cloneDeep(rowData);

            if (Array.isArray(data.eu_domain)) {
                data.eu_domain = data.eu_domain.length
                    ? data.eu_domain[0]
                    : 0;
            }

            const updatedSelectedKeywordData = selectedKeywordData.map(item => ({
                ...item,
                low_bid: String(item.low_bid),
                high_bid: String(item.high_bid)
            })).slice(0, 5);

            let dataWithKeywordData = {
                ...data,
                keywordData: updatedSelectedKeywordData,
                language_id: language,
                language_desc: languageDesc,
                location_id: location,
                location_desc: locationDesc
            };

            console.log(dataWithKeywordData)

            HttpClient.post<object, any>('system1_urls', dataWithKeywordData)
                .then(res => {
                    // refresh()
                    setIsOpen(false);
                    setCheckedKeywords([]);
                    setSelectedKeywordData([]);
                    setLanguageDesc('');
                    setLocationDesc('');
                    setIsModalLoading(false);

                    if (data.domain_id > 0) {
                        pushMessage(<Notification closable type="success" header="Success" duration={60000}>
                            Domain Updated
                        </Notification>);
                    } else {
                        pushMessage(<Notification closable type="success" header="Success" duration={60000}>
                            Domain sent for activation
                        </Notification>);
                    }

                }).catch(error => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setLoading(false);
                setIsModalLoading(false);
            });
        } else {
            pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                Please complete all steps before saving
            </Notification>);
        }


    }

    React.useEffect(() => {
        if (checkedKeywords.length >= 3) {
            setShowDomain(true)
        } else {
            setShowDomain(false)
        }
    }, [checkedKeywords])

    const debounceSearchTerm = (value: any, type: number = 0) => {
        if (intervalRef.current) {
            clearTimeout(intervalRef.current);
        }
        intervalRef.current = setTimeout(() => {
            setSearchQuery(value);
        }, 250);
    };

    /**
     * Filter data by search string as live search
     *
     * @param data
     * @param search
     */
    const filter = (data: any, search: string | null) => {
        if (!search) {
            return data;
        }

        search = search.toLowerCase();

        const include = (v: any) => v.toLowerCase().includes(search);
        const fields = [
            'keyword',
        ];

        return data.filter((item: any) =>
            fields.some((field: string) => {
                return include(item[field]);
            })
        );
    };

    console.log({
        selectedKeywordData: selectedKeywordData,
        checkedKeywords: checkedKeywords
    })

    return (
        <>
            <Title title="Google Keywords"/>
            <FlexboxGrid justify="space-between" style={{marginBottom: 25}}>
                <FlexboxGrid.Item colspan={24} style={{marginBottom: 25, textAlign: 'center'}}>
                        <TagInput
                            trigger={['Enter']}
                            placeholder="Keyword Search (Press Enter to Select Word)"
                            style={{ width: '50%' }}
                            menuStyle={{ width: '50%' }}
                            data={keywords}
                            onChange={(value, item) => {
                                setKeywords(value)
                            }}
                        />
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={24} style={{marginBottom: 25,textAlign: 'center'}}>
                    <label>Language: </label>
                    <SelectPicker
                        size="md"
                        searchable={true}
                        cleanable={true}
                        placeholder="View Type"
                        value={language}
                        data={languageList}
                        valueKey="id"
                        labelKey="name"
                        onChange={(v) => {
                            setLanguage(v);
                        }}
                        style={{ marginRight: 15 }}
                    />

                    <label>Location: </label>
                    <SelectPicker
                        size="md"
                        searchable={true}
                        cleanable={true}
                        placeholder="View Type"
                        value={location}
                        data={locationArray}
                        valueKey="id"
                        labelKey="name"
                        onChange={(v) => {
                            setLocation(v);
                        }}
                        style={{ marginRight: 15 }}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={24} style={{textAlign: 'center', marginBottom: 15}}>
                    <IconButton
                        color="blue"
                        appearance="primary"
                        size="lg"
                        style={{ width: 150 }}
                        icon={<RunningRound />}
                        onClick={handleButtonClick}
                    >
                        Go
                    </IconButton>
                    <IconButton
                        size="lg"
                        appearance="subtle"
                        style={{marginLeft: 15}}
                        icon={<FileDownloadIcon/>}
                        placement="right"
                        onClick={() =>
                            exportCsv(
                                `google_keyword_results.csv`,
                                getExportData(data)
                            )
                        }>
                        Download CSV
                    </IconButton>
                </FlexboxGrid.Item>
                {showDomain ? ( <FlexboxGrid.Item colspan={24} style={{textAlign: 'center', marginBottom: 15}}>
                    <IconButton
                        color="green"
                        appearance="primary"
                        size="lg"
                        icon={<PlusRound />}
                        onClick={handleCreateDomainClick}
                    >
                        Create Domain
                    </IconButton>
                </FlexboxGrid.Item>) : ''}

                <FlexboxGrid.Item colspan={24} style={{textAlign: 'center', marginBottom: 15}}>
                    <InputGroup
                        style={{ width: '250px', margin: '0 auto' }}
                    >
                        <Input
                            placeholder="Search"
                            onChange={(v) => {
                                debounceSearchTerm(v);
                            }}
                            size="lg"
                        />
                    </InputGroup>
                </FlexboxGrid.Item>

                <FlexboxGrid.Item colspan={24} style={{textAlign: 'center', marginBottom: 15}}>

                </FlexboxGrid.Item>
            </FlexboxGrid>

            {/*<div style={{width: '100%'}}>*/}
            <GoogleKeywordList
                data={filter(Array.isArray(data) ? data : [], searchQuery)}
                loading={loading}
                checkedKeywords={checkedKeywords}
                setCheckedKeywords={setCheckedKeywords}
                selectedKeywordData={selectedKeywordData}
                setSelectedKeywordData={setSelectedKeywordData}
            />
            {/*</div>*/}
            <EditModal
                size={'lg'}
                title={rowData.domain}
                loading={isModalLoading}
                open={isOpen}
                onClose={handleEditModalClose}
                onCancel={handleEditModalClose}
                onSubmit={handleSave}
            >
                <UrlFormsContainer
                    domain={rowData.domain}
                    postValue={rowData}
                    parentCallback={handleFormValueCallback}
                />
            </EditModal>
        </>
    )

}

export default GoogleKeywordTree;