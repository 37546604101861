import { useState } from "react";
import HttpClient from "../../@Utils/HttpClient";

const useTaboolaProviderList = () => {
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  if (!providers.length && !isLoading) {
    setIsLoading(true);

    HttpClient
      .get<any>('taboola_provider')
      .then(res => {
        setProviders(res.data.data
          .filter((item: any) => item.account_id !== '')
          .map((item: any) => ({
            name: item.name,
            account_id: item.account_id,
          })));
      });
  }

  return providers;
};

export default  useTaboolaProviderList;