import React from "react";
import { Col, Grid, Row, Schema, Form, Checkbox, CheckboxGroup } from "rsuite";
import Field from "../Field";
import { FormInstance } from "rsuite/Form";

const { ArrayType, BooleanType, DateType, NumberType, StringType } = Schema.Types;

const model = Schema.Model({
    forcekeyA: StringType().isRequired("Keyword 1 is required"),
    forcekeyB: StringType().isRequired("Keyword 2 is required"),
    forcekeyC: StringType().isRequired("Keyword 3 is required"),
    forcekeyD: StringType(),
    forcekeyE: StringType()
})

type UrlKeywordFormType = {
    formValue: any,
    formRef?: React.MutableRefObject<any>;
    parentCallback?: any;
}

const UrlKeywordForm: React.FC<UrlKeywordFormType> = ({
formValue,
formRef = null,
parentCallback = () => {
},
}) => {
    formRef = formRef || React.createRef();
    const [formError, setFormError] = React.useState<any>({});

    const handleFormChange = (data: any) => {
        if (typeof parentCallback === "function") {
            parentCallback({
                ...data
            });
        }
    }

    return (
        <>
            <Form
                ref={formRef}
                onChange={handleFormChange}
                formValue={formValue}
                layout="horizontal"
                model={model}
                formError={formError}
                onCheck={setFormError}
            >
                <Grid fluid>
                    <Row style={{marginBottom: 15}}>
                        <Col xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Field label="EU Domain" name="eu_domain" accepter={CheckboxGroup} style={{display:"inline"}} >
                                <Checkbox value={1} />
                            </Field>


                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} >
                            <Field
                                label='Facebook Domain Verification Code'
                                name='facebook_domain_verification_code'
                                placeholder='Facebook Domain Verification Code'
                            />
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center'}}>
                            <Field
                                label='Keyword 1'
                                name='forcekeyA'
                                placeholder='Keyword 1'
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'center'}}>
                            <Field
                                label='Keyword 2'
                                name='forcekeyB'
                                placeholder='Keyword 2'
                            />
                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Field
                                label='Keyword 3'
                                name='forcekeyC'
                                placeholder='Keyword 3'
                            />
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Field
                                label='Keyword 4'
                                name='forcekeyD'
                                placeholder='Keyword 4'
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Field
                                label='Keyword 5'
                                name='forcekeyE'
                                placeholder='Keyword 5'
                            />
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    )
}

export default UrlKeywordForm;