import { Schema } from "rsuite";
import { MixedType } from "schema-typed/lib/MixedType";
import { format } from "date-fns";

export const initialRuleData = {
  test: 0,
  rule_id: 0,
  rule_type: '',
  action_start: '',
  action_end: '',
  change_b_action: 'set',
  change_b_value: null,
  change_b_min_value: null,
  change_b_max_value: null,
  change_b_unit: 'static',
  change_b_min_unit: 'static',
  change_b_max_unit: 'static',
  change_b_to: '',
  change_b_min_to: '',
  change_b_max_to: '',
  active: 0,
  rule_name: '',
  emails: [],
  groups: [],
  considering_data_from: 'yesterday',
  exclude_days_from_interval: 'none',
  // widget_filter_type: 'include',
  widget_filter_type: '',
  widget_ids: [],
  // run_interval: '20m',
  run_interval: '',
  after_run_action: 'execute',
  campaigns: [],
  ad_groups: [],
  conditions: [{}],
};

const {ArrayType, NumberType, ObjectType, StringType} = Schema.Types;

const RuleModel = Schema.Model({
  rule_id: NumberType(),
  rule_type: StringType().isRequired("Rule Type is required"),
  active: NumberType(),
  test: NumberType(),
  groups: ArrayType(),
  rule_name: StringType().isRequired("Rule Name is required"),
  considering_data_from: StringType().isRequired("Interval is required"),
  exclude_days_from_interval: StringType(),
  change_b_action: StringType(),
  change_b_value: (new MixedType()).when((schema: any) => {
    return ['change_campaign_bid', 'change_campaign_budget', 'change_site_bid', 'adgroup_budget_set', 'adgroup_bid_set'].includes(schema.rule_type.value)
      ? NumberType().isRequired('Bid is required')
      : NumberType();
  }),
  change_b_unit: StringType(),
  change_b_to: (new MixedType()).when((schema: any) => {
    return schema.change_b_unit.value === 'percentage'
      ? StringType().isRequired('Option is required')
      : StringType();
  }),
  change_b_min_value: (new MixedType()).when((schema: any) => {
    return ['change_campaign_bid', 'change_campaign_budget', 'change_site_bid', 'adgroup_budget_set', 'adgroup_bid_set'].includes(schema.rule_type.value)
    && (schema.change_b_action.value !== 'set' || schema.change_b_unit.value !== 'static')
      ? NumberType().isRequired('Min Bid is required')
      : NumberType();
  }),
  change_b_min_unit: StringType(),
  change_b_min_to: (new MixedType()).when((schema: any) => {
    return schema.change_b_min_unit.value === 'percentage'
      ? StringType().isRequired('Option is required')
      : StringType();
  }),
  change_b_max_value: (new MixedType()).when((schema: any) => {
    return ['change_campaign_bid', 'change_campaign_budget', 'change_site_bid', 'adgroup_budget_set', 'adgroup_bid_set'].includes(schema.rule_type.value)
    && (schema.change_b_action.value !== 'set' || schema.change_b_unit.value !== 'static')
      ? NumberType().isRequired('Max Bid is required')
      : NumberType();
  }),
  change_b_max_unit: StringType(),
  change_b_max_to: (new MixedType()).when((schema: any) => {
    return schema.change_b_max_unit.value === 'percentage'
      ? StringType().isRequired('Option is required')
      : StringType();
  }),
  switch_bidding_type: StringType(),
  widget_filter_type: StringType(),
  widget_ids: ArrayType(),
  run_interval: (new MixedType()).when((schema: any) => {
    return schema.rule_type.value !== 'day_parting'
      ? StringType().isRequired('Frequency is required')
      : StringType();
  }),
  after_run_action: StringType(),
  campaigns: ArrayType(),
  emails: ArrayType(),
  ad_groups: ArrayType(),
  conditions: ArrayType().of(
    ObjectType().shape({
      if_criteria: StringType().isRequired('Type Field is required'),
      are_criteria: StringType().isRequired('Condition is required'),
      than_criteria: StringType().isRequired('Value is required'),
      compare_criteria: StringType(),
      field_criteria: StringType().isRequired('Field is required'),
      // It's an extra parameter. We should store this value in the 'than_criteria' field
      hours_than_criteria: ObjectType().addRule((value, data) => {
        if (data.if_criteria !== "hour_of_day") {
          return true;
        }

        const hoursByDays = data.hours_than_criteria;
        const days = Object.keys(hoursByDays);
        const selectedDays = days.filter((day: any) => Array.isArray(hoursByDays[day]) && hoursByDays[day].length > 0);

        return selectedDays.length > 0;
      }, 'Select hours please'),
    })
  ),
});

export default RuleModel;