import React from "react";
import Dashboard from "../@Components/Dashboard";

import DomainMappingToolTree from "../@Components/Domains/DomainMapingToolTree";


const DomainMappingToolTrees: React.FC = () => {
    return (
        <Dashboard>
            <DomainMappingToolTree/>
        </Dashboard>
    );
};

export default DomainMappingToolTrees;
