import React, {ReactNode} from "react";
import {
    FlexboxGrid,
    IconButton,
    Input,
    InputGroup,
    Notification,
    SelectPicker,
    toaster,
    DateRangePicker, Whisper, Tooltip, TagInput
} from "rsuite";
import Title from "../../@Components/Title";
import HttpClient from "../../@Utils/HttpClient";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import {format, lastDayOfMonth, set, startOfMonth, subDays, subMonths} from "date-fns";
import {LOCAL_STORAGE_KEYS, useStoredValue} from "../../@Utils/useStoredValue";
import {DateRange} from "rsuite/esm/DateRangePicker/types";
import RunningRound from "@rsuite/icons/RunningRound";
import MediaBuyerPerformanceReportList from "./MediaBuyerPerformanceReportList";
import { currency, percent } from "../../@Utils/Format";
import { exportCsv } from "../../@Utils/Export";
import { sort } from "../../@Utils/Sorting";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

const MediaBuyerPerformanceReportTree: React.FC = () => {
    const initialValue = {
        start: new Date(),
        end: new Date(),
    };
    const currentDayWithoutTime = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    const startDate = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    const [dateRange, setDateRange] = React.useState({
        start: subDays(new Date(), 7),
        end: subDays(new Date(), 0),
    });
    const initialStartDateRef = React.useRef(dateRange.start);
    const initialEndDateRef = React.useRef(dateRange.end);
    const hasDateRangeEqualToInit = (dateRange.start.getTime() === initialStartDateRef.current.getTime()) && (dateRange.end.getTime() === initialEndDateRef.current.getTime());
    const initialClassName = 'init-by-stored-values';
    const last_month = subMonths(new Date(), 1);
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<any[]>([]);
    const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
    const [sortColumn, setSortColumn] = React.useState("revenue");

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    const formatDates = (value: any, dateFormat: string = "LLL do, yyyy") => {
        if (!value[0] || !value[1]) {
            return null;
        }
        if (value[0].toString() === value[1].toString()) {
            return (
                <span style={{paddingRight: 10}}>
          {format(value[0], dateFormat)}
        </span>
            );
        }
        return (
            <span style={{paddingRight: 10}}>
        From {format(value[0], dateFormat)} to {format(value[1], dateFormat)}
      </span>
        );
    };

    const setDates = (dates: DateRange | null) => {
        const dateRange = {
            start: set(dates ? dates[0] : new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
            end: set(dates ? dates[1] : new Date(), {hours: 23, minutes: 59, seconds: 59, milliseconds: 997}),
        };

        setDateRange(dateRange);
    };

    const fetchData = () => {
        setData([]);
        setLoading(true);

        const params = {
            start_date: format(dateRange.start, "yyyy-MM-dd"),
            end_date: format(dateRange.end, "yyyy-MM-dd"),
        };

        HttpClient.get("tiktok_media_buyer_report", params)
            .then((res: any) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((error) => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setLoading(false);
            })
        }

    const handleReloadButtonClick = () => {
        fetchData();
    };

    const formatData = (list: any) => {
        return list.map((item: any) => {

            const revenue = isNaN(parseInt(item.revenue)) ? 0 : parseFloat(item.revenue).toFixed(2)
            const cost = isNaN(parseInt(item.cost)) ? 0 : parseFloat(item.cost).toFixed(2)
            const profit = isNaN(parseInt(item.profit)) ? 0 : parseFloat(item.profit).toFixed(2)
            const profit_percent = isNaN(parseFloat(item.profit_percent)) ? 0 : parseFloat(item.profit_percent).toFixed(1)

            return {
                ...item,
                revenue: currency(Number(revenue)),
                cost: currency(Number(cost)),
                profit: currency(Number(profit)),
                profit_percent: percent(Number(profit_percent)),
            };
        });
    };

    const summaryData = data.reduce((summary: any, row: any) => {
        summary.revenue = parseFloat((Number(summary.revenue) + Number(row.revenue)).toFixed(2));
        summary.cost = parseFloat((Number(summary.cost) + Number(row.cost)).toFixed(2));
        summary.profit = parseFloat((Number(summary.profit) + Number(row.profit)).toFixed(2));
        return summary;
    }, {revenue: 0, cost: 0, profit: 0});

// Calculate profit_percent for the summary
    summaryData.profit_percent = summaryData.revenue > 0 ? parseFloat((summaryData.profit / summaryData.revenue * 100).toFixed(1)) : 0;
    summaryData.profit_percent = percent(summaryData.profit_percent);

    const sortData = (list: any) => {
        return list.sort((a: any, b: any) => {
            let valA = a[sortColumn];
            let valB = b[sortColumn];

            // Check if the values are numbers or strings
            if (!isNaN(valA) && !isNaN(valB)) {
                valA = parseFloat(valA);
                valB = parseFloat(valB);
            } else {
                // Convert to lowercase for case insensitive sorting
                valA = valA.toLowerCase();
                valB = valB.toLowerCase();
            }

            if (valA < valB) {
                return sortType === 'asc' ? -1 : 1;
            }
            if (valA > valB) {
                return sortType === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    const getExportData = (data: any) => {
        return (
            data.map((item: any) => item)
                .map((item: any) => {
                    return {
                        'Buyer': item.user_name,
                        'Revenue': item.revenue,
                        'Cost': item.cost,
                        'Profit': item.profit,
                        'Gross Profit &': item.profit_percent
                    }
                })
        )
    }


    return (
        <>
            <Title title="Media Buyer Performance Report" />
            <FlexboxGrid justify="space-between" style={{ marginBottom: 25, marginLeft: 25 }}>
                <FlexboxGrid.Item colspan={24} style={{marginBottom: 25}}>
                    <DateRangePicker
                        className={["date-filter", ...[hasDateRangeEqualToInit ? initialClassName : null]].join(' ')}
                        size="lg"
                        placement="autoVerticalEnd"
                        renderValue={formatDates}
                        value={[dateRange.start, dateRange.end]}
                        defaultValue={[dateRange.start, dateRange.end]}
                        onChange={setDates}
                        cleanable={false}
                        style={{marginRight: 15}}
                        // TODO Uncomment it and ***FIX*** a typescript warning
                        // disabledDate={allowedMaxDays(60)}
                        ranges={[
                            {
                                label: "Yesterday",
                                value: [subDays(new Date(), 1), subDays(new Date(), 1)],
                            },
                            {
                                label: "Today",
                                value: [new Date(), new Date()],
                            },
                            {
                                label: "Last 7 days",
                                value: [subDays(new Date(), 7), subDays(new Date(), 0)],
                            },
                            {
                                label: "This Month",
                                value: [startOfMonth(new Date()), new Date()],
                            },
                            {
                                label: "Last Month",
                                value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
                            },
                        ]}
                    />

                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Run Report</Tooltip>}
                    >
                        <IconButton
                            color="blue"
                            appearance="primary"
                            size="md"
                            style={{ width: 100 }}
                            icon={<RunningRound />}
                            onClick={handleReloadButtonClick}
                        >
                            Go
                        </IconButton>
                    </Whisper>

                    <IconButton
                        size="lg"
                        appearance="subtle"
                        style={{marginRight: 15, float: "right"}}
                        icon={<FileDownloadIcon/>}
                        placement="right"
                        onClick={() =>
                            exportCsv(
                                `media_buyer_performance_${format(dateRange.start, "yyyy-MM-dd")}_${format(dateRange.end, "yyyy-MM-dd")}.csv`,
                                getExportData(Array.isArray(data) ? formatData(data) : [])
                            )
                        }>
                        Download CSV
                    </IconButton>
                </FlexboxGrid.Item>
            </FlexboxGrid>

            <MediaBuyerPerformanceReportList
                data={Array.isArray(data) ? formatData(sortData(data))  : []}
                loading={loading}
                startDate={format(dateRange.start, "yyyy-MM-dd")}
                endDate={format(dateRange.end, "yyyy-MM-dd")}
                summaryData={summaryData}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
            />
        </>


    );
}

export default MediaBuyerPerformanceReportTree;