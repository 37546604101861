import React from "react";
import Dashboard from "../@Components/Dashboard";
import System1DomainTree from "../@Components/System1/System1DomainTree";
// import TrafficSources from "../@Components/TrafficSources/TrafficSourcesTree";

const System1DomainPage: React.FC = () => {
    return (
        <Dashboard>
            <System1DomainTree/>
        </Dashboard>
    );
};

export default System1DomainPage;
