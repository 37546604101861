import React, {ReactNode} from "react";
import {Table, Pagination, Checkbox, Loader,} from "rsuite";
import {CurrencyCell, NumberCell, PercentCell} from "../Table";
import {sort} from "../../@Utils/Sorting";
import {thousands, percent, currency, float} from "../../@Utils/Format";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
    ColDef,
    ColGroupDef,
    GridApi,
    GridOptions,
    IDateFilterParams,
    IMultiFilterParams,
    ISetFilterParams,
    ITextFilterParams,
    SideBarDef,
    createGrid,
    SizeColumnsToContentStrategy,
    SizeColumnsToFitGridStrategy,
    SizeColumnsToFitProvidedWidthStrategy,
    SortDirection
} from "@ag-grid-community/core";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { ModuleRegistry } from "@ag-grid-community/core";

ModuleRegistry.registerModules([
    // ClientSideRowModelModule,
    MenuModule,
    MultiFilterModule,
    SetFilterModule,
    RowGroupingModule
]);

type System1KeywordStatsListProps = {
    data?: any[];
    loading?: boolean;
    defaultSortColumn?: string;
}

const System1KeywordStatsList: React.FC<System1KeywordStatsListProps> = ({data = [], loading = false, defaultSortColumn = 'estimated_revenue'}) => {

    const columnDefs = [

        {
            headerName: "Domain",
            field: "domain",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
            resizable: true,
            rowGroup: true,
            hide: true
        },

        {
            headerName: "Keyword",
            field: "keyword",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
            resizable: true,
            rowGroup: true,
            hide: true
        },
        {
            headerName: "Date",
            field: "date",
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 125
        },
        {
            headerName: "Country",
            field: "country",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
            resizable: true,
        },
        {
            headerName: "Device Type",
            field: "device_tye",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                buttons: ["apply", "reset"],
                closeOnApply: true,
            },
            resizable: true,
        },
        {
            headerName: "Searches",
            field: "searches",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            resizable: true,
            aggFunc: 'sum',
        },
        {
            headerName: "Clicks",
            field: "clicks",
            sortable: true,
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            resizable: true,
            aggFunc: 'sum',
        },
        {
            headerName: "Estimated Revenue",
            field: "estimated_revenue",
            sortable: true,
            sort: defaultSortColumn === 'estimated_revenue' ? 'desc' as SortDirection : undefined,
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            resizable: true,
            aggFunc: 'sum',
            valueFormatter: (params: any) => parseFloat(params.value).toFixed(2)
        },
        {
            headerName: "EPC",
            field: "epc",
            sortable: true,
            sort: defaultSortColumn === 'epc' ? 'desc' as SortDirection : undefined,
            filter: "agMultiColumnFilter",
            filterParams: {
                filters: [
                    {
                        filter: "agNumberColumnFilter",
                    },
                    {
                        filter: "agSetColumnFilter",
                    },
                ],
            } as IMultiFilterParams,
            resizable: true,
            aggFunc: 'avg',
            valueFormatter: (params: any) => parseFloat(params.value).toFixed(2)
        },
    ];

    const autoSizeStrategy =  React.useMemo<
        | SizeColumnsToFitGridStrategy
        | SizeColumnsToFitProvidedWidthStrategy
        | SizeColumnsToContentStrategy
    >(() => {
        return {
            type: "fitCellContents",
        };
    }, []);

    const defaultColDef = React.useMemo(() => {
        return {
            flex: 1,
            // minWidth: 150,
            // filter: "agTextColumnFilter",
            // menuTabs: ["filterMenuTab" as const],
        };
    }, []);

    const rowHeight = 50;

    const autoGroupColumnDef = React.useMemo(() => {
        return {
            headerName: 'Domain/Keyword',
            minWidth: 250,
        };
    }, []);

    return (
        <>
            {loading && (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "60%",
                        height: "500px",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Loader />
                    <p style={{ marginLeft: "10px" }}>Loading...</p>
                </div>
            )}

            {!loading && (
                <div className={"ag-theme-quartz"} style={{width: '100%', height: "80vh"}}>
                    {/*<AgGridReact rowData={data} columnDefs={columnDefs}*/}
                    <AgGridReact
                        rowData={data}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowHeight={rowHeight}
                        autoGroupColumnDef={autoGroupColumnDef}
                        // autoSizeStrategy={autoSizeStrategy}
                        // domLayout={"autoHeight"}
                        // autoSizeStrategy={autoSizeStrategy}
                    />
                </div>
            )}
        </>
    )
}

export default System1KeywordStatsList;