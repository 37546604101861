import React from "react";
import { DateRangePicker, FlexboxGrid, IconButton, Notification, Pagination, SelectPicker, Whisper, Tooltip } from "rsuite";
import PlusRound from "@rsuite/icons/PlusRound";
import Reload from "@rsuite/icons/Reload";
import OfferSourcesList from "./OfferSourcesList";
import Title from "../../@Components/Title";
import OfferSource from "../Drawers/OfferSource";
import HttpClient from "../../@Utils/HttpClient";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { format, lastDayOfMonth, set, startOfMonth, subDays, subMonths } from "date-fns";
import { pushInforming } from "../../@Utils/Messager";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";

const OfferSourcesTree: React.FC = () => {
  const defaultActivityStatus = 1;
  const defaultCategoryValue = null;
  const [activityStatus, setActivityStatus] = useStoredValue(LOCAL_STORAGE_KEYS.OFFER_SOURCES_PAGE_FILTER_ACTIVITY_STATUS, defaultActivityStatus);
  const [category, setCategory] = useStoredValue(LOCAL_STORAGE_KEYS.OFFER_SOURCES_PAGE_FILTER_CATEGORY, defaultCategoryValue);
  const [offerSourceId, setOfferSourceId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [formData, setFormData] = React.useState({});
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const defaultSortColumn = "offer_source_id";
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState(defaultSortColumn);
  const limitOptions = [15, 30, 50, 100];
  const [activePage, setActivePage] = React.useState(1);
  const [limit, setLimit] = React.useState(50);
  const [total, setTotal] = React.useState(0);
  const currentDayWithoutTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  const startDate = set(new Date('2021-01-01'), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const [dateRange, setDateRange] = useStoredValue(LOCAL_STORAGE_KEYS.OFFER_SOURCES_PAGE_FILTER_DATE, {
    start: startDate,
    end: currentDayWithoutTime,
  });

  const initFormData = {
    offer_source_id: 0,
    offer_source_name: '',
    offer_source_category_id: '',
    offer_payout: '',
    transaction_id: '',
    hit_id_field: '',
    data_passing: '',
    status: 1,
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleSetLimit = (limit: any) => {
    setActivePage(1);
    setLimit(limit);
  };

  const refresh = () => fetchData();

  const fetchData = (noCache = true) => {
    // Clear and mask a table
    setData([]);
    setLoading(true);
    setTotal(0);

    const params = {
      page: activePage,
      limit,
      sortBy: sortColumn,
      sortDir: sortType,
      category_id: category,
      status: activityStatus,
      start_date: format(dateRange.start, "yyyy-MM-dd HH:mm:ss"),
      end_date: format(dateRange.end, "yyyy-MM-dd HH:mm:ss"),
    };

    // Get data
    HttpClient
      .get<any>('offer_source', params, noCache)
      .then(res => {
        setLoading(false);
        setData(res.data.data);
        setTotal(parseInt(res.data.total) || 0);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
        setLoading(false);
      });
  };

  React.useEffect(fetchData, [activePage, limit, sortColumn, sortType, category, activityStatus, dateRange]);

  const activityStatusList = [
    { value: 1, label: "Active" },
    { value: 2, label: "Archived" },
  ];

  // Load category list
  const [categories, setCategories]: any = React.useState([]);
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_source_category')
      .then(res => {
        setCategories(
          res.data.sort((a: any, b: any) => {
            return (a.offer_source_category_name > b.offer_source_category_name)
              ? 1
              : ((b.offer_source_category_name > a.offer_source_category_name)
                ? -1 : 0)
          })
        );
      });
  }, []);

  // reload category list when drawer is closed 
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_source_category')
      .then(res => {
        setCategories(
          res.data.sort((a: any, b: any) => {
            return (a.offer_source_category_name > b.offer_source_category_name)
              ? 1
              : ((b.offer_source_category_name > a.offer_source_category_name)
                ? -1 : 0)
          })
        );
      });
  }, [isOpenDrawer]);

  const handleReloadButtonClick = () => fetchData();

  const handleRowCreateOrUpdateAfterGrid = (id: number, item: object) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.offer_source_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      // Push a new row at the top
      setData([item, ...data]);
      setTotal(total + 1);
    }
  };

  // TODO Remove after adding these data into the DB table and SP output
  const addTestData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          visits: Math.floor(Math.random() * parseInt(item.offer_source_id) * 10),
          visitors: Math.floor(Math.random() * parseInt(item.offer_source_id) * 10),
          offer_ctr: Math.random() * 100,
          offer_clicks: Math.floor(Math.random() * parseInt(item.offer_source_id) * 10),
          rpov: Math.floor(Math.random() * parseInt(item.offer_source_id)),
          offer_views: Math.floor(Math.random() * parseInt(item.offer_source_id)),
          conv: Math.floor(Math.random() * parseInt(item.offer_source_id)),
          revenue: Math.floor(Math.random() * parseInt(item.offer_source_id)),
          cost: Math.floor(Math.random() * parseInt(item.offer_source_id)),
          roi: Math.random() * 100,
          pnl: Math.floor(Math.random() * parseInt(item.offer_source_id)),
        },
      };
    });
  };

  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setDates = (dates: DateRange | null) => {
    const currentDayWithoutTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const dateRange = {
      start: dates ? dates[0] : currentDayWithoutTime,
      end: set(dates ? dates[1] : new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 997 }),
    };

    setDateRange(dateRange);
  };

  /**
   * Custom function for formatting date ranges. Idea is to make
   * it more human-readable.
   */
  const formatDates = (value: any, dateFormat: string) => {
    if (!value[0] || !value[1]) {
      return null;
    }
    if (value[0].toString() === value[1].toString()) {
      return (
        <span style={{ paddingRight: 10 }}>
          {format(value[0], "LLL do, yyyy")}
        </span>
      );
    }
    return (
      <span style={{ paddingRight: 10 }}>
        From {format(value[0], "LLL do, yyyy")} to{" "}
        {format(value[1], "LLL do, yyyy")}
      </span>
    );
  };

  const last_month = subMonths(new Date(), 1);

  //Filter highlight code
const initialStartDateRef = React.useRef(dateRange.start);
const initialEndDateRef = React.useRef(dateRange.end);
const initialActiveStatus = React.useRef(activityStatus !== null ? activityStatus: {})
const initialCategoryFilter = React.useRef(category !== null ? category: {})
 React.useEffect(() => {

    if (activityStatus === initialActiveStatus.current) {
      const innerDiv = document.querySelector('.activity-status-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.activity-status-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if (category === initialCategoryFilter.current) {
      const innerDiv = document.querySelector('.category-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.category-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if ((dateRange.start.getTime() === initialStartDateRef.current.getTime()) && (dateRange.end.getTime() === initialEndDateRef.current.getTime())) {
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }
  }, [activityStatus,category, dateRange]);

  return (
    <>
      <Title title="Offer Source" />
      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={10}>
          <IconButton
            size="lg"
            color="blue"
            appearance="subtle"
            style={{ marginLeft: 15 }}
            icon={<PlusRound />}
            onClick={() => {
              setOfferSourceId(0);
              setFormData(initFormData);
              setIsOpenDrawer(true);
            }}
          >
            New
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={14} style={{ textAlign: "right", paddingRight: 20 }}>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Reload</Tooltip>}
          >
            <IconButton
              circle
              size="lg"
              style={{ marginRight: 15 }}
              icon={<Reload />}
              onClick={handleReloadButtonClick}
            />
          </Whisper>
          <SelectPicker
            className="activity-status-filter"
            size="md"
            placeholder="Filter by Activity"
            searchable={false}
            cleanable={true}
            defaultValue={defaultActivityStatus}
            value={activityStatus}
            data={activityStatusList}
            onChange={(v: any) => {
              setActivityStatus(v);
            }}
            style={{ marginRight: 15 }}
          />
          <SelectPicker
            className="category-filter"
            size="md"
            placeholder="Filter by Category"
            searchable={true}
            cleanable={true}
            defaultValue={defaultCategoryValue}
            value={category}
            data={categories}
            valueKey="offer_source_category_id"
            labelKey="offer_source_category_name"
            onChange={(v: any) => {
              setCategory(v);
            }}
            style={{ marginRight: 15 }}
          />
          <DateRangePicker
            className="date-filter"
            size="md"
            placement="autoVerticalEnd"
            renderValue={formatDates}
            value={[dateRange.start, dateRange.end]}
            defaultValue={[dateRange.start, dateRange.end]}
            onChange={setDates}
            cleanable={false}
            // TODO Uncomment it and ***FIX*** a typescript warning
            // disabledDate={allowedMaxDays(60)}
            ranges={[
              {
                label: "Yesterday",
                value: [subDays(new Date(), 1), subDays(new Date(), 1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 7), subDays(new Date(), 0)],
              },
              {
                label: "This Month",
                value: [startOfMonth(new Date()), new Date()],
              },
              {
                label: "Last Month",
                value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
              },
            ]}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <OfferSource
        formData={formData}
        open={isOpenDrawer}
        setOpen={setIsOpenDrawer}
        offerSourceId={offerSourceId}
        setOfferSourceId={setOfferSourceId}
        onAfterCreateOrUpdateGridRow={handleRowCreateOrUpdateAfterGrid}
      />

      <OfferSourcesList
        setIsOpenDrawer={setIsOpenDrawer}
        setFormData={setFormData}
        setOfferSourceId={setOfferSourceId}
        data={data}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshCallback={refresh}
        onAfterCreateOrUpdateGridRow={handleRowCreateOrUpdateAfterGrid}
      />

      <Pagination
        style={{ margin: "10px 20px" }}
        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
        size="lg"
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        total={total}
        limit={limit}
        limitOptions={limitOptions}
        maxButtons={7}
        activePage={activePage}
        onChangePage={setActivePage}
        onChangeLimit={handleSetLimit}
      />
    </>
  );
};

export default OfferSourcesTree;