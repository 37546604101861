import React from "react";
import {
  Checkbox,
  IconButton,
  Pagination,
  Table,
  Toggle,
  Tooltip,
  Whisper,
  Notification,
  FlexboxGrid,
} from "rsuite";
import CopyIcon from "@rsuite/icons/Copy";
import RemoveIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import TimeIcon from '@rsuite/icons/Time';
import ButtonToolbar from "rsuite/ButtonToolbar";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import { sort } from "../../@Utils/Sorting";
import { paginateData } from "../../@Utils/DataProcessing";
import ConfirmModal from "../ConfirmModal";
import { consideringDataFromList, intervalList, prepareConditions, ruleTypeList } from "./RuleForm";
import {
  generalConditionTypeList,
  stringComparisonList,
  tiktokOnlyConditionTypeList
} from "./ConditionInputRowControl";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";

const { Column, HeaderCell, Cell } = Table;

type RuleListProps = {
  data?: any;
  page: number;
  setPage: any;
  loading?: any;
  setIsRuleDrawerOpen: any;
  setFormData: any;
  checkedKeys: any[];
  setCheckedKeys: any;
  changeStatus: (ruleIds: number[], checked: boolean) => void;
  refreshCallback?: () => void;
};

const RuleList: React.FC<RuleListProps> = ({
  data = [],
  page = 1,
  setPage,
  loading = false,
  setIsRuleDrawerOpen,
  setFormData,
  checkedKeys,
  setCheckedKeys,
  changeStatus,
  refreshCallback,
}) => {
  const defaultSortColumnType: any = useStoredValue(LOCAL_STORAGE_KEYS.RULE_PAGE_SORT_TYPE,"desc")[0];
  const [sortType, setSortType] = useStoredValue(LOCAL_STORAGE_KEYS.RULE_PAGE_SORT_TYPE,defaultSortColumnType);
  const [sortColumn, setSortColumn] = useStoredValue(LOCAL_STORAGE_KEYS.RULE_PAGE_SORT_COLUMN,'rule_id');
  const [rules, setRules] = React.useState<any[]>([]);
  const [load, setLoad] = React.useState(true);
  const checked = checkedKeys.length > 0 && checkedKeys.length === rules.length;
  const indeterminate = checkedKeys.length > 0 && checkedKeys.length < rules.length;
  const [limit, setLimit] = React.useState(100);
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState({ rule_id: 0 });

  const handleOpen = () => setRemoveConfirmModalOpen(true);
  const handleClose = () => setRemoveConfirmModalOpen(false);

  const handleRemove = () => {
    removeItem(selectedItem ? selectedItem.rule_id : 0);

    handleClose();
  };

  const handleCheckAll = (value: any, checked: boolean) => {
    const keys = checked ? rules.map((item: any) => item.rule_id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value: any, checked: boolean) => {
    const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);

    setCheckedKeys(keys);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  React.useEffect(() => {
    setRules(data);
    setLoad(loading);
  }, [data, load, loading]);

  const handleEditActionClick = (rowData: any) => {
    setFormData(rowData);
    setIsRuleDrawerOpen(true);
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const removeItem = (itemId: number = 0) => {

    if (itemId === 0) {
      return;
    }

    HttpClient.delete<any>(`rule/${itemId}`)
      .then(res => {
        const type = res.status === 200 ? "success" : "error";
        const text = res.status === 200 ? "Rule has been removed" : res.statusText;

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

        if (refreshCallback instanceof Function) {
          refreshCallback();
        }
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
      });
  };

  const handleCloneActionClick = (rowData: any) => {
    // Change campaign name to make it unique
    const matches = rowData.rule_name.match(/^(.*)copy\(([0-9]*)\)$/);
    const index = matches && matches.length ? parseInt(matches[2]) + 1 : 1;
    const newName = (matches && matches.length) ? matches[1] : rowData.rule_name;

    data.campaign_name =
    setFormData({
      ...rowData,
      rule_id: 0,
      rule_name: `${newName} copy(${index})`,
      conditions: prepareConditions(rowData.conditions),
      campaigns: rowData.campaigns
        .map((item: any) => !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0)
        .filter((campaign_id: number) => campaign_id > 0),
      groups: rowData.groups
        .map((item: any) => !isNaN(parseInt(item.group_id)) ? parseInt(item.group_id) : 0)
        .filter((group_id: number) => group_id > 0),
      widget_ids: rowData.widgets
        .map((item: any) => item.widget_id)
        .filter((widget_id: string) => widget_id !== ''),
    });
    setIsRuleDrawerOpen(true);
  };

  const handleChangeStatus = (rowData: any, checked: boolean) => {
    const rulesIds = checkedKeys.length > 0 && (rowData === undefined || checkedKeys.includes(rowData.rule_id))
      ? checkedKeys : [rowData.rule_id];

    changeStatus(rulesIds, checked);
  };

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  const columns = React.useMemo(() => [
    {
      dataKey: 'rule_name',
      label: 'Name',
      fixed: true,
      width: 250,
      enableHiding: false,
    },
    {
      dataKey: 'active',
      label: 'Status',
      width: 110,
      align: 'center',
      cellCmp: CircleCell,
    },
    {
      dataKey: 'rule_type',
      label: 'Type',
      width: 210,
      renderFn: (rowData: any) => ruleTypeList.filter((rule: any) => rule.value === rowData.rule_type).pop()?.label || '',
    },
    {
      dataKey: 'conditions',
      label: 'Conditions',
      width: 200,
      cellCmp: ConditionsCell,
    },
    {
      dataKey: 'groups',
      label: 'Groups',
      width: 200,
      renderFn: (rowData: any) => Array.isArray(rowData.groups) && rowData.groups.length
        ? rowData.groups.map((group:any) => group.group_name).join(', ') : '',
    },
    {
      dataKey: 'considering_data_from',
      label: 'Interval',
      width: 200,
      renderFn: (rowData: any) => consideringDataFromList.filter((rule: any) =>
        rule.value === rowData.considering_data_from).pop()?.label || '',
    },
    {
      dataKey: 'run_interval',
      label: 'Frequency',
      width: 200,
      renderFn: (rowData: any) => intervalList.filter((rule: any) =>
        rule.value === rowData.run_interval).pop()?.label || '',
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.RULES_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.RULES_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  return (
    <>
      <FlexboxGrid justify="end" style={{marginTop: 8}}>
        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
            columns={columns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          autoHeight
          data={paginateData(sort(rules, sortColumn, sortType), page, limit)}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={handleSortColumn}
          rowHeight={43}
          headerHeight={61}
        >
          <Column width={70} align="center" fixed>
            <HeaderCell style={{padding: 0}}>
              <div style={{lineHeight: "38px"}}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell dataKey="rule_id" checkedKeys={checkedKeys} onChange={handleCheck}/>
          </Column>
          <Column width={200} align="center" fixed verticalAlign="top">
            <HeaderCell>Actions</HeaderCell>
            <ActionCell
              editHandler={handleEditActionClick}
              changeStatusHandler={handleChangeStatus}
              cloneHandler={handleCloneActionClick}
              removeHandler={handleRemoveActionClick}
            />
          </Column>
          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>

      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="lg"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={rules.length}
        limitOptions={[10, 20, 50, 100]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />

      <ConfirmModal
        title="Removing"
        open={removeConfirmModalOpen}
        onClose={handleClose}
        onYes={handleRemove}
      >
        Are you sure you want to remove this Rule?
      </ConfirmModal>
    </>
  );
};

const CircleCell = ({
  rowData,
  ...props
}: any) => (
  <Cell {...props} className="link-group">
    <Circle isActive={!!rowData.active}/>
  </Cell>
);

const Circle = ({isActive = false}) => {
  const color = isActive ? "#00FF00" : "#FFA500";
  const style = {
    height: 20,
    width: 20,
    backgroundColor: color,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 5,
  };

  const status = isActive ? 'Running' : 'Paused';

  return <span style={{
    marginTop: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color,
  }}>
    <span style={style}/>{status}
  </span>;
};

export const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  changeStatusHandler,
  cloneHandler,
  ...props
}: any) => {
  const status = !!rowData.active;
  const buttonStyle = {marginLeft: '6px'};

  return (
    <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
      <div>
        <ButtonToolbar>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>{status ? 'Deactivate' : 'Activate'}</Tooltip>}
          >
            <span> {/*Wrap with span to proper work of tooltip*/}
              <Toggle
                loading={rowData.isUpdating}
                checked={status}
                onChange={() => changeStatusHandler(rowData, !status)}
                size="md"
                style={{marginBottom: 'auto', marginTop: 'auto'}}
              />
            </span>
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <IconButton
              icon={<EditIcon/>}
              onClick={(e) => editHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Clone</Tooltip>}
          >
            <IconButton
              icon={<CopyIcon/>}
              onClick={(e) => cloneHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Remove</Tooltip>}
          >
            <IconButton
              icon={<RemoveIcon/>}
              onClick={(e) => removeHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
        </ButtonToolbar>
      </div>
    </Cell>
  );
};

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
  <Cell {...props} style={{padding: 0}}>
    <div style={{lineHeight: "35px"}}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Cell>;

const ConditionsCell = ({
  rowData,
  dataKey,
  ...props
}: any) => {
  const conditions = rowData[dataKey] || [];

  const getLabelFromList = (list: any, value: string) =>
    list.filter((item: any) => item.value === value).pop()?.label || ''

  const outputCondition = (c: any) => {
    const label = getLabelFromList([...generalConditionTypeList, ...tiktokOnlyConditionTypeList], c.if_criteria);

    if (c.if_criteria === 'name') {
      return `${label} ${getLabelFromList(stringComparisonList, c.are_criteria)} ${c.than_criteria}`;
    }

    if (c.if_criteria === 'hour_of_day') {
      return <TimeIcon />;
    }

    if ([
      'tracker_roi',
      'publisher_clicks',
      'traffic_source_clicks',
      'traffic_source_spent',
      'tracker_cvr',
      'tracker_conversions',
      'traffic_source_conversions',
      'spend',
      'roi',
      'rpc',
      'impressions',
      's1_clicks',
      'clicks',
      'conversion',
    ].includes(c.if_criteria)
    ) {
      const operations = new Map([
        ["greater", ">"],
        ["less", "<"],
        ["greater_or_equal", ">="],
        ["less_or_equal", "<="],
      ]);
      const operation = operations.get(c.are_criteria);

      const compareCriteriaList = new Map([
        ["percent", "%"],
        ["dollar", "$"],
        ["dots", ""],
        ["percent_of_campaign", "% of campaign"],
        ["percent_of_adgroup", "% of ad group"],
      ]);
      const compareCriteria = compareCriteriaList.get(c.compare_criteria);

      const value = c.compare_criteria === "dollar"
      ? `${compareCriteria}${c.than_criteria}`
      : `${c.than_criteria}${compareCriteria}`;

      const fieldCriteria = c.field_criteria
        ? getLabelFromList(
          [...generalConditionTypeList, ...tiktokOnlyConditionTypeList],
          c.field_criteria
        )
        : '';

      return `${label} ${operation} ${value} ${fieldCriteria}`;
    }

    return label;
  }

  return (
    <Cell {...props} className='cell-conditions'>
      {Array.isArray(conditions) && conditions.length
      ? conditions
          .map<React.ReactNode>((c: any) =>
            <span className={c.if_criteria} key={c.condition_id}>
              {outputCondition(c)}
            </span>
          )
          .reduce((prev, curr, currentIndex) => [prev, <b key={currentIndex + '_sep'}>&</b>, curr])
      : ''}
    </Cell>
  );
};

export default RuleList;