import React, {ReactNode} from "react";
import {
  Checkbox,
  CheckboxGroup,
  CheckPicker,
  CheckTreePicker,
  Form,
  InputNumber,
  Panel,
  Radio,
  RadioGroup,
  Schema,
  SelectPicker,
  Toggle,
  Row,
  Col,
  DatePicker, Whisper, Tooltip, IconButton, Badge, Button, Loader
} from "rsuite";
import Field from "../Field";
import HttpClient from "../../@Utils/HttpClient";
import {pushErrorNotification} from "../../@Utils/Messager";
import SpinnerIcon from "@rsuite/icons/Reload";
import AdGroupModel from "./AdGroupModel";
import moment from "moment";
import AdGroupDayPartCalendar from "./AdGroupDayPartCalendar";
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "../OfferSources/EditModal";
import {FormInstance} from "rsuite/Form";
import AddAppForm from "./AdAppForm";

const { NumberType } = Schema.Types;

type AdGroupProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  pangleBlockList: any;
  setPangleBlockList: any;
  appPackageID: any;
  setAppPackageID: any;
};

const AdGroupForm: React.FC<AdGroupProps> = ({
  formValue,
  formRef = null,
  parentCallback = (p: any) => {},
  pangleBlockList,
  setPangleBlockList,
  appPackageID,
  setAppPackageID
}) => {
  formRef = formRef || React.createRef();
  const [load, setLoad] = React.useState(true);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [showButton, setShowButton] = React.useState(formValue.enable === "ENABLE");
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [formError, setFormError] = React.useState<any>({});
  const [deviceModels, setDeviceModels] = React.useState<any>([]);
  const [languages, setLanguages] = React.useState<any>([]);
  const [locationIds, setLocationIds] = React.useState<any>([]);
  const [osAndroidVersions, setAndroidVersions] = React.useState<any>([]);
  const [osIOsVersions, setIosVersions] = React.useState<any>([]);
  const [pixelIds, setPixelIds] = React.useState<any>([]);
  const [optimizationEvents, setOptimizationEvents] = React.useState<any>([]);
  const isSaved = formValue.adgroup_id > 0;
  const [dayPartValue, setDayPartValue] = React.useState();
  const mainFormRef = React.createRef<FormInstance>();
  console.log('form', formValue);
  const handleCallback = (data: any) => {
    console.log('change', pangleBlockList);
    setFormValue({
      ...formValue,
      app_list:pangleBlockList.length > 0 ? pangleBlockList.map(function (obj:any) {
        return obj.value;
      }) : [],
    //  app_package_id
    })
  };
  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

  //  if (node?.check && node.check()) {
      saveAppList(pangleBlockList.map(function (obj:any) {
        return obj.value;
      }));
  //  }
    setEditModalOpen(false);
  };

  const saveAppList = (data: any) => {
    setSaveButtonLoading(true);

    if (formValue.advertiser_id) {
      HttpClient.put<any>('tiktok_pangle_block_list', {
        advertiser_id: formValue.advertiser_id.toString(),
        clear_old_app: true,
        add_app_list: JSON.stringify(data)
      })
          .then((res) => {
            const categoryId = parseInt(res.data.offer_source_category_id);
            // Add created category in the selector list

            handleEditModalClose();
          })
          .finally(() => {
            setSaveButtonLoading(false);
          });
    };

    HttpClient.put<any>(`ad_group/${formValue.adgroup_id}`, {
      ...formValue,
      app_package_id: appPackageID,
      blocked_pangle_app_ids: formValue.enable_pangle_block_list_app =='ENABLE' ? [appPackageID] : []
    })
        .then(res => {


        })
        .catch(error => {

        });
  }

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  // Load language list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_languages', {
          advertiser_id: formValue.advertiser_id,
        })
        .then(res => {
          setLanguages(res.data);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [formValue.advertiser_id]);

  // Load location list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_locations', {
          advertiser_id: formValue.advertiser_id,
          placements: formValue.placements.length ? formValue.placements : ["PLACEMENT_TIKTOK", "PLACEMENT_TOPBUZZ", "PLACEMENT_PANGLE"],
          objective_type: 'WEB_CONVERSIONS',
        })
        .then(res => {
          const data = res.data;

          // Get countries only
          const countries = data
            .filter((region: any) => region.level === "COUNTRY")
            .sort((regionA: any, regionB: any) => regionA.name < regionB.name ? -1 : 1)
            .map((region: any) => ({
              label: region.name,
              value: region.location_id,
              area_type: region.area_type,
              region_code: region.region_code,
              level: region.level,
              next_level_ids: region.next_level_ids,
              children: null,
            }));

          setLocationIds(countries);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [formValue.advertiser_id, formValue.placements]);

  // Load location list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
          .get<any>('tiktok_pangle_block_list', {
            advertiser_id: formValue.advertiser_id
          })
          .then(res => {
            const data = res.data.add_app_list.filter((item:any)=>item != res.data.app_package_id).map((item:any, index:number) => ({ id:index,label: item, value: item }));

            setPangleBlockList(data);
            setAppPackageID(res.data.app_package_id);
            setLoad(false);
          })
          .catch(error => {
            setLoad(false);
            pushErrorNotification(error.response.data?.error || error.toString());
          });
    }
  }, [formValue.advertiser_id, editModalOpen, saveButtonLoading]);

  // Load operating system version list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_os_versions', {
          advertiser_id: formValue.advertiser_id,
          os_type: 'ANDROID',
        })
        .then(res => {
          setAndroidVersions(res.data);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });

      HttpClient
        .get<any>('tiktok_os_versions', {
          advertiser_id: formValue.advertiser_id,
          os_type: 'IOS',
        })
        .then(res => {
          setIosVersions(res.data);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [formValue.advertiser_id]);

  // Load device model list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_devices', {
          advertiser_id: formValue.advertiser_id,
        })
        .then(res => {

          const data = res.data.filter((device: any) => device.is_active);

          // Convert from list to tree
          const tree = data
            .filter((device: any) => device.level === "BRAND")
            .sort((deviceA: any, deviceB: any) => deviceA.device_model_name < deviceB.device_model_name ? -1 : 1)
            .map((device: any) => ({
              label: device.device_model_name,
              value: device.device_model_id,
              os_type: device.os_type,
              level: device.level,
              child_device_ids: device.child_device_ids,
              children: null,
            }));

          const addChildren = (nodes: any, data: any) =>
            nodes.forEach((node: any) => {
              if (node.child_device_ids.length) {
                node.child_device_ids.forEach((id: any) => {
                  const device = data.find((item: any) => item.device_model_id === id);

                  if (device) {
                    if (node.children === null) {
                      node.children = [];
                    }

                    node.children.push({
                      label: device.device_model_name,
                      value: device.device_model_id,
                      os_type: device.os_type,
                      level: device.level,
                      child_device_ids: device.child_device_ids,
                      children: null,
                    });
                  }
                });
              }

              if (node.children && node.children.length > 0) {
                addChildren(node.children, data);
              }
            });

          addChildren(tree, data);

          setDeviceModels(tree);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [formValue.advertiser_id]);

  // Load pixel list
  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_pixels', {
          advertiser_id: formValue.advertiser_id,
        })
        .then(res => {
          setPixelIds(
            res.data
              .filter((pixel: any) => pixel.events && pixel.events.length)
              .map((pixel: any) => ({
                ...pixel,
                pixel_output: `${pixel.pixel_name} (ID=${pixel.pixel_code})`
              }))
          );
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [formValue.advertiser_id]);

  React.useEffect(() => {
    setDayPartValue(formValue.dayparting);
  }, [formValue.advertiser_id]);

  React.useEffect(() => {
    if (JSON.stringify(dayPartValue) !== JSON.stringify(formValue.dayparting)) {
      setDayPartValue(formValue.dayparting);
    }
  }, [formValue.adgroup_id]);

  // Change optimization event list by pixel id value
  React.useEffect(() => {
    if (pixelIds.length === 0 || !formValue.pixel_id) {
      setOptimizationEvents([]);
    } else {
      const find = pixelIds.find((pixel: any) => pixel.pixel_id === formValue.pixel_id);

      if (find && find.events) {
        setOptimizationEvents(find.events.filter((pixel: any) => pixel.event_type));
      }
    }
  }, [pixelIds, formValue.pixel_id]);

  const renderMenu = (menu: ReactNode, items: []) => {
    if (items.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  const setFormValue = (data: any) => {
    if (data.placement_type !== formValue.placement_type ) {
      if (data.placement_type === "PLACEMENT_TYPE_AUTOMATIC") {
        data.placements = [];
      }
      if (data.placement_type === "PLACEMENT_TYPE_NORMAL") {
        data.placements = ["PLACEMENT_TIKTOK", "PLACEMENT_TOPBUZZ", "PLACEMENT_PANGLE"];
        data.search_result_enabled = true;
      }
    }

    if (!data.placements.includes("PLACEMENT_TIKTOK")) {
      data.search_result_enabled = false;
    }

    if (data.age_groups.length === 0) {
      // Exclude All
      data.age_groups = ["AGE_13_100"];
    }

    if (formValue.age_groups.includes("AGE_13_100") && data.age_groups.length > 1) {
      // Exclude All option
      data.age_groups = data.age_groups.filter((group: string) => group !== "AGE_13_100");
    }

    if (data.age_groups.includes("AGE_13_100") && data.age_groups.length > 1) {
      // Exclude not-All options
      data.age_groups = ["AGE_13_100"];
    }

    if (data.operating_systems !== formValue.operating_systems) {
      data.os_versions = null;
    }

    data.auto_targeting_enabled = data.auto_targeting_enabled === "AUTOMATIC_TARGETING";

    // To fix wrong validation warnings
    const node = formRef && formRef.current;
    if (node && node.check) {
      node.checkForField('auto_targeting_enabled');
    }

    if (typeof parentCallback === "function") {
      parentCallback({
        ...data,
        optimization_event: data.pixel_id ? data.optimization_event : null,
      });
    }
  };

  const MIN_BUDGET = formValue.budget_mode === 'BUDGET_MODE_DAY' ? 20.00 : 280.00;

  const handleDayParting = (dayPartValue: any) => {
    if (dayPartValue !== formValue.dayparting) {
      setFormValue({...formValue, ...{dayparting: dayPartValue}})
    }

  }

  return (
    <Form
      layout="horizontal"
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      model={AdGroupModel}
      formValue={formValue}
      formError={formError}
    >
      {/*TODO Remove after testing*/}
      {/*<JSONTree data={formValue}/>*/}
      {/*<JSONTree data={formError}/>*/}
      {/*<span>ID: {JSON.stringify(formValue.adgroup_id)}</span><br/>*/}
      {/*<span>{JSON.stringify(formValue)}</span><br/>*/}
      {/*<span>placement_type:{formValue.placement_type}</span><br/>*/}
      {/*<span>placements:{JSON.stringify(formValue.placements)}</span>*/}
      {/*<span>errors:{JSON.stringify(formError)}</span>*/}
      <Panel>
        <Form.Group>
          <Form.ControlLabel style={{ display: "block" }}>Operation Status</Form.ControlLabel>
          <Toggle
            size="lg"
            style={{ display: "inline-block", paddingTop: 5 }}
            checked={formValue.operation_status === 'ENABLE'}
            checkedChildren="Enable"
            unCheckedChildren="Disable"
            onChange={(checked) =>
              setFormValue({
                ...formValue,
                operation_status: checked ? 'ENABLE' : 'DISABLE',
              })
            }
          />
        </Form.Group>
        <Field
          label="Ad Group Name"
          name="adgroup_name"
          placeholder="Enter Ad Group Name"
        />
      </Panel>
      <Panel header="Website"  defaultExpanded>
        <Field
          label="Promotion Type"
          name="promotion_type"
          placeholder="Select Promotion Type"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={[{ value: "WEBSITE", label: "External Website" }]}
          cleanable={false}
          disabled={true}
        />
        <Field
          label="TikTok Pixel"
          name="pixel_id"
          placeholder="Select Pixel"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          renderMenu={(menu: ReactNode) => renderMenu(menu, pixelIds)}
          data={pixelIds}
          valueKey="pixel_id"
          labelKey="pixel_output"
          disabled={isSaved}
        />
        <Field
          label="Optimization Event"
          name="optimization_event"
          placeholder={formValue.pixel_id ? "Select Optimization Event" : "Select a TikTok Pixel first"}
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={optimizationEvents}
          valueKey="event_type"
          labelKey="event_type"
          disabled={!formValue.pixel_id || isSaved}
        />
      </Panel>
      <Panel header="Placements"  defaultExpanded>
        <Field
          name="placement_type"
          accepter={RadioGroup}
          formGroupStyle={{marginBottom: 0}}
          disabled={isSaved}
        >
          <Radio value="PLACEMENT_TYPE_AUTOMATIC">Automatic placement</Radio>
          <Radio value="PLACEMENT_TYPE_NORMAL">Select placement</Radio>
        </Field>
        {formValue.placement_type === "PLACEMENT_TYPE_NORMAL" &&
        <>
        <Field
          name="placements"
          accepter={CheckboxGroup}
          style={{marginLeft: 28}}
          disabled={isSaved}
        >
          <Checkbox value="PLACEMENT_TIKTOK">TikTok<br/>
            <Toggle
              size="sm"
              style={{ display: "inline-block"}}
              checked={formValue.search_result_enabled}
              disabled={isSaved}
              onChange={(checked) =>
                setFormValue({
                  ...formValue,
                  search_result_enabled: checked,
                })
              }
            /> include search results
          </Checkbox>
          <Checkbox value="PLACEMENT_TOPBUZZ">News feed app series BuzzVideo/Babe</Checkbox>
          <Checkbox value="PLACEMENT_PANGLE">Pangle Premium global publisher network, available in some regions.</Checkbox>
        </Field>
        { formValue.placements.includes('PLACEMENT_PANGLE') ?
        <Panel  defaultExpanded>
          <Row>
            <Col md={3} sm={12}>
                <Toggle
                    size="lg"
                    style={{ display: "inline-block" }}
                    checked={ formValue.enable_pangle_block_list_app === 'ENABLE'}
                    checkedChildren="Enable"
                    unCheckedChildren="Disable"
                    onChange={(checked) => {
                      setShowButton(checked);
                      setFormValue({
                        ...formValue,
                        enable_pangle_block_list_app: checked ? 'ENABLE' : 'DISABLE',
                        app_package_id: appPackageID
                      })
                    }}
                />

            </Col>
            <Col md={6} sm={12}>
              <label style={{ display: "block", marginLeft: 20 }}>Pangle block list</label>

            </Col>
          </Row>
            { formValue.enable_pangle_block_list_app === 'ENABLE' ?
                <Row  style={{ marginTop: 40 }}>
                  <Col lg={4} md={12} sm={12}>
                    { saveButtonLoading ?
                        <Loader size="md" content="Refreshing" /> :
                        <>
                          <Badge content={pangleBlockList.length > 0  ? pangleBlockList.length : 0}>
                            <Button>Apps blocked on Pangle</Button>
                          </Badge>
                        </>
                    }
                  </Col>
                  <Col  lg={4} md={12} >

                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Add New App</Tooltip>}
                        style={{ display: "block" }}
                    >
                      <IconButton
                          circle
                          size="md"
                          style={{   color: 'blue',   marginLeft:90}}
                          icon={<PlusIcon />}
                          onClick={() => {
                            setEditModalOpen(true);
                          }}
                      >
                        Manage block list
                      </IconButton>
                    </Whisper>
                  </Col>
                </Row>
                : null }

        </Panel> : null }
        </>
        }
        <EditModal
            title={"Add App"}
            loading={saveButtonLoading}
            open={editModalOpen}
            onClose={handleEditModalClose}
            onCancel={handleEditModalClose}
            onSubmit={handleSave}
        >
          <AddAppForm
              formRef={mainFormRef}
              parentCallback={handleCallback}
              data = {pangleBlockList}
              setData = {setPangleBlockList}
              load = {load}
          />
        </EditModal>
      </Panel>

      <Panel header="Targeting"  defaultExpanded>
        <Field
          label="Select Targeting Mode"
          name="auto_targeting_enabled"
          value={formValue.auto_targeting_enabled ? "AUTOMATIC_TARGETING" : "CUSTOM_TARGETING"}
          placeholder="Select Targeting Mode"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={[
            { value: "CUSTOM_TARGETING", label: "Custom Targeting" },
            { value: "AUTOMATIC_TARGETING", label: "Automatic Targeting" },
          ]}
          cleanable={false}
          searchable={false}
        />
        <Panel header="Demographics"  defaultExpanded>
          <Field
            label="Location"
            name="location_ids"
            placeholder="Select a location"
            placement="autoVerticalStart"
            accepter={CheckPicker}
            renderMenu={(menu: ReactNode) => renderMenu(menu, locationIds)}
            data={locationIds}
            cleanable={true}
          />
          <Field
            label="Languages"
            name="languages"
            placeholder="All"
            placement="autoVerticalStart"
            accepter={CheckPicker}
            renderMenu={(menu: ReactNode) => renderMenu(menu, languages)}
            data={languages}
            labelKey="name"
            valueKey="code"
            cleanable={true}
          />
          <Field
            label="Gender"
            name="gender"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={[
              { value: "GENDER_UNLIMITED", label: "All" },
              { value: "GENDER_FEMALE", label: "Female" },
              { value: "GENDER_MALE", label: "Male" },
            ]}
            cleanable={false}
            searchable={false}
          />
          <Field
            label="Age"
            name="age_groups"
            placement="autoVerticalStart"
            accepter={CheckPicker}
            data={[
              { value: "AGE_13_100", label: "All" },
              { value: "AGE_13_17", label: "13-17" },
              { value: "AGE_18_24", label: "18-24" },
              { value: "AGE_25_34", label: "25-34" },
              { value: "AGE_35_44", label: "35-44" },
              { value: "AGE_45_54", label: "45-54" },
              { value: "AGE_55_100", label: "55+" },
            ]}
            cleanable={false}
            searchable={false}
            countable={false}
          />
        </Panel>
        <Panel header="Device"  defaultExpanded>
          <Field
            label="Operating System"
            name="operating_systems"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={[
              { value: "All", label: "All" },
              { value: "ANDROID", label: "Android" },
              { value: "IOS", label: "iOS" },
            ]}
            cleanable={false}
            searchable={false}
          />
          <Field
            label="OS Versions"
            name="os_versions"
            placeholder="All"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            renderMenu={(menu: ReactNode) =>
              renderMenu(menu, formValue.operating_systems === 'ANDROID' ? osAndroidVersions : osIOsVersions)
            }
            data={formValue.operating_systems === 'ANDROID' ? osAndroidVersions : osIOsVersions}
            valueKey="version"
            labelKey="name"
            cleanable={true}
            disabled={formValue.operating_systems === 'All'}
          />
          <Field
            label="Device Model"
            name="device_model_ids"
            placeholder="All"
            placement="autoVerticalStart"
            accepter={CheckTreePicker}
            renderMenu={(menu: ReactNode) => renderMenu(menu, deviceModels)}
            data={formValue.operating_systems === "All"
              ? deviceModels
              : deviceModels.filter((device: any) => device.os_type === formValue.operating_systems)
            }
            cleanable={true}
          />
        </Panel>
      </Panel>
      <Panel header={"Budget & Schedule"}  defaultExpanded>
        {formValue.campaign_budget_optimize_on === 1
          ?
          <Form.Group>
            <Form.ControlLabel style={{display: "block"}}>Budget</Form.ControlLabel>
            <div style={{marginTop: 7}}>
              Uses campaign budget Daily budget: USD {formValue.campaign_budget}
            </div>
          </Form.Group>
          :
          <>
            <Field
              label="Budget Type"
              name="budget_mode"
              searchable={false}
              cleanable={false}
              placeholder="Select Budget Type"
              placement="autoVerticalStart"
              accepter={SelectPicker}
              data={[
                {value: "BUDGET_MODE_TOTAL", label: "Lifetime budget"},
                {value: "BUDGET_MODE_DAY", label: "Daily budget"},
              ]}
              disabled={isSaved}
            />
            <Field
              label="Budget"
              name="budget"
              accepter={InputNumber}
              prefix="USD"
              placeholder={"At least " + MIN_BUDGET}
              rule={NumberType().min(MIN_BUDGET).isRequired('Budget is required')}
            />
          </>
        }
      </Panel>
      <Panel header={"Bidding & Optimization"}  defaultExpanded>
        <Field
          label="Optimization Goal"
          name="optimization_goal"
          searchable={false}
          cleanable={false}
          placeholder="Select Optimization Goal"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={[
            {value: "CONVERT", label: "Conversion"},
            {value: "CLICK", label: "Click"},
          ]}
          disabled={formValue.campaign_budget_optimize_on === 1 || isSaved}
        />
        <Field
          label="Bid Strategy"
          name="bid_type"
          searchable={false}
          cleanable={false}
          placeholder="Select Bid Strategy"
          placement="bottomStart"
          accepter={SelectPicker}
          data={[
            { value: "BID_TYPE_CUSTOM", label: "Cost Cap" },
            { value: "BID_TYPE_NO_BID", label: "Lower cost" },
          ]}
          disabled={formValue.campaign_budget_optimize_on === 1 || isSaved}
        />
        {formValue.bid_type === 'BID_TYPE_CUSTOM' &&
          <Field
            label="Bid"
            name="bid_amount"
            accepter={InputNumber}
            prefix="USD/Conversion"
            rule={formValue.bid_type === "BID_TYPE_CUSTOM"
              ? NumberType()
                  .isRequired("Bid is required")
                  .addRule((value, data) => {
                    value = typeof value === "string" ? parseFloat(value) : value;
                    const budget = typeof data.budget === "string" ? parseFloat(data.budget) : data.budget;

                    return value < budget;
                  }, "Bid must be less than  " + formValue.budget + 'and greater than 0' )
              : NumberType()
            }
          />
        }
        <Form.Group>
          <Form.ControlLabel>
            Start Time
          </Form.ControlLabel>
          <Form.Control
              name="schedule_start_time"
              accepter={DatePicker}
              format="yyyy-MM-dd HH:mm:ss"
              disabledDate={(date: any) => moment(date).isBefore(moment().subtract(12, "h"))}
              style={{ width: 300 }}
              placement="autoVerticalStart"
          />
        </Form.Group>
      </Panel>


      <Panel header={"Day Parting"}  defaultExpanded>
        <Form.Group>
          <AdGroupDayPartCalendar
              binaryString={dayPartValue}
              handleDayParting={handleDayParting}
          />
        </Form.Group>
      </Panel>
    </Form>
  )
};

export default AdGroupForm;