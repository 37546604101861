import React from "react";
import { Pagination, Table } from "rsuite";
import { DEFAULT_LIMIT, paginateData } from "../../@Utils/DataProcessing";
import { sort } from "../../@Utils/Sorting";
import { FloatCell, NumberCell } from "../Table";
import { Dimensions } from "../Drawers/CampaignWidgetsRemotely";

const {Column, HeaderCell, Cell} = Table;

type CampaignStatisticsReportListProps = {
  dimension: string;
  data?: any;
  loading?: any;
  page?: number;
  setPage: any;
};

const CampaignStatisticsReportList: React.FC<CampaignStatisticsReportListProps> = ({
  dimension,
  data = [],
  loading = false,
  page = 1,
  setPage,
}) => {
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
  const [sortType, setSortType] = React.useState<"desc" | "asc">("asc");
  const [sortColumn, setSortColumn] = React.useState("dimension_value");

  // Move to the first page if current page is empty
  React.useEffect(() => {
    if (data.length < (page -1) * limit) {
      setPage(1);
    }
  }, [data, limit, page]);

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const geDimensionColumnTitleByDimension = (dimension: string) => {
    switch(dimension) {
      case Dimensions.Day:
        return 'Day';
      case Dimensions.DayOfWeek:
        return 'Day of Week';
      case Dimensions.HourOfDay:
        return 'Hour of Day';
      case Dimensions.Country:
        return 'Country';
      case Dimensions.Region:
        return 'Region';
      case Dimensions.Platform:
        return 'Platform';
      case Dimensions.OsFamily:
        return 'OS Family';
      case Dimensions.OsVersion:
        return 'OS Version';
      case Dimensions.Browser:
        return 'Browser';
    }

    return 'Unknown';
  };

  const geDimensionColumnValueByDimension = (item: any, dimension: string) => {
    switch(dimension) {
      case Dimensions.Day:
        return item.date;
      case Dimensions.DayOfWeek:
        // Return numbers for sorting
        switch(item.date) {
          case 'Monday':
            return 0;
          case 'Tuesday':
            return 1;
          case 'Wednesday':
            return 2;
          case 'Thursday':
            return 3;
          case 'Friday':
            return 4;
          case 'Saturday':
            return 5;
          case 'Sunday':
            return 6;
        }
        break;
      case Dimensions.HourOfDay:
        return item.hour_of_day;
      case Dimensions.Country:
        return item.country_name;
      case Dimensions.Region:
        return `${item.region}, ${item.country}`;
      case Dimensions.Platform:
        return item.platform_name;
      case Dimensions.OsFamily:
        return `${item.platform_name}, ${item.os_family}`;
      case Dimensions.OsVersion:
        return `${item.platform_name}, ${item.os_version}`;
      case Dimensions.Browser:
        return `${item.platform_name}, ${item.browser}`;
    }

    return 'Unknown';
  };

  const addDimensionValue = (data: any[], dimension: string): any[] =>
    data.map((item: any) => ({
      ...item,
      dimension_value: geDimensionColumnValueByDimension(item, dimension),
    }));

  return (
    <>
      <Table
        data={paginateData(sort(addDimensionValue(data, dimension), sortColumn, sortType), page, limit)}
        loading={loading}
        height={575}
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        autoHeight
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
      >
        <Column width={110} align="left" resizable sortable fixed>
          <HeaderCell>{geDimensionColumnTitleByDimension(dimension)}</HeaderCell>
          <Cell dataKey="dimension_value">
            {(rowData: any) => {
              switch (dimension) {
                case Dimensions.DayOfWeek:
                  switch (rowData.dimension_value) {
                    case 0:
                      return 'Monday';
                    case 1:
                      return 'Tuesday';
                    case 2:
                      return 'Wednesday';
                    case 3:
                      return 'Thursday';
                    case 4:
                      return 'Friday';
                    case 5:
                      return 'Saturday';
                    case 6:
                      return 'Sunday';
                  }
                break;
              }

              return rowData.dimension_value;
            }}
          </Cell>
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Taboola Clicks</HeaderCell>
          <NumberCell dataKey="clicks" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Impressions</HeaderCell>
          <NumberCell dataKey="impressions" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Visible Impressions</HeaderCell>
          <NumberCell dataKey="visible_impressions" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Spent</HeaderCell>
          <Cell dataKey="spent" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Revenue</HeaderCell>
          <Cell dataKey="arb_revenue" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>Conversions</HeaderCell>
          <Cell dataKey="cpa_actions_num" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>ROAS</HeaderCell>
          <Cell dataKey="roas" />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CTR</HeaderCell>
          <FloatCell dataKey="ctr" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>VCTR</HeaderCell>
          <FloatCell dataKey="vctr" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPM</HeaderCell>
          <FloatCell dataKey="cpm" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>VCPM</HeaderCell>
          <FloatCell dataKey="vcpm" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPC</HeaderCell>
          <FloatCell dataKey="cpc" digits={4} />
        </Column>
        <Column width={150} align="right" resizable sortable>
          <HeaderCell>CPA</HeaderCell>
          <FloatCell dataKey="cpa" digits={4} />
        </Column>
        <Column width={170} align="right" resizable sortable>
          <HeaderCell>CPA Conversion Rate</HeaderCell>
          <FloatCell dataKey="cpa_conversion_rate" digits={4} />
        </Column>
        <Column width={150} align="left" resizable sortable>
          <HeaderCell>Currency</HeaderCell>
          <Cell dataKey="currency" />
        </Column>
        <Column width={100} align="right" resizable sortable>
          <HeaderCell>Arb Clicks</HeaderCell>
          <NumberCell dataKey="arb_clicks" />
        </Column>
        <Column width={130} align="right" resizable sortable>
          <HeaderCell>S1/Tonic Clicks</HeaderCell>
          <NumberCell dataKey="tonic_clicks" />
        </Column>
        <Column width={100} align="right" resizable sortable>
          <HeaderCell>Arb Cost</HeaderCell>
          <FloatCell dataKey="arb_cost" digits={4} />
        </Column>
        <Column width={120} align="right" resizable sortable>
          <HeaderCell>Arb Revenue</HeaderCell>
          <FloatCell dataKey="arb_revenue" digits={4} />
        </Column>
      </Table>

      <div style={{ padding: 20 }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={data.length}
          limitOptions={[10, 20]}
          limit={limit}
          activePage={page}
          onChangePage={setPage}
          onChangeLimit={handleChangeLimit}
        />
      </div>
    </>
  );
};

export default CampaignStatisticsReportList;
