import React, { ReactNode } from "react";
import { DateRangePicker, FlexboxGrid, IconButton, Input, InputGroup, Notification, toaster } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import TonicKeywordList from "./TonicKeywordList";
import Title from "../../@Components/Title";
import { exportCsv } from "../../@Utils/Export";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import HttpClient from "../../@Utils/HttpClient";
import { format, lastDayOfMonth, parse, set, startOfMonth, subDays, subMonths } from "date-fns";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";

const TonicKeywordGrid: React.FC = () => {
  const lastMonth = subMonths(new Date(), 1);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);

  // @ts-ignore
  const [sortType, setSortType] =  useStoredValue(LOCAL_STORAGE_KEYS.TONIC_KEYWORD_GRID_PAGE_SORT_TYPE,"asc");
  const [sortColumn, setSortColumn] = useStoredValue(LOCAL_STORAGE_KEYS.TONIC_KEYWORD_GRID_PAGE_SORT_COLUMN,'campaign_name');
  const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TONIC_KEYWORD_REPORT_PAGE_SEARCH_QUERY, '');
  const [search, setSearch] = React.useState(searchQuery);

  const startDate = set(subDays(new Date(), 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  const endDate = set(subDays(new Date(), 1), { hours: 23, minutes: 59, seconds: 59, milliseconds: 59 });
  const [dateRange, setDateRange] = useStoredValue(LOCAL_STORAGE_KEYS.TONIC_KEYWORD_REPORT_PAGE_FILTER_DATE, {
    start: startDate,
    end: endDate,
  });

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });

  /**
   * Custom function for formatting date ranges. Idea is to make it more human-readable.
   */
  const formatDates = (value: any, dateFormat: string = "LLL do, yyyy") => {
    if (!value[0] || !value[1]) {
      return null;
    }

    const doesStartEqualEnd = value[0].toString() === value[1].toString();
    const start = format(value[0], dateFormat);
    const end = format(value[1], dateFormat);

    return <span style={{ paddingRight: 10 }}>{doesStartEqualEnd ? start : `From ${start} to ${end}`}</span>;
  };

  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setDates = (dates: DateRange | null) => {
    const currentDayWithoutTime = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const dateRange = {
      start: dates ? dates[0] : currentDayWithoutTime,
      end: set(dates ? dates[1] : new Date(), {
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 997,
      }),
    };

    setDateRange(dateRange);
  };

  const fetchData = () => {
    // Clear and mask a table
    setData([]);
    setLoading(true);

    // Get data
    HttpClient.get<any>("tonic_keyword", {
      query: search,
      start_date: format(dateRange.start, "yyyy-MM-dd 00:00:00.000"),
      end_date: format(dateRange.end, "yyyy-MM-dd 23:59:59.997"),
    })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => {
        pushMessage(
          <Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>
        );
        setLoading(false);
      });
  };

  React.useEffect(fetchData, [search, dateRange]);

  const searchByQuery = () => {
    setSearch(searchQuery);
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      setSearch(searchQuery);
    }
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const handleExportCsvButtonClick = () => {
    exportCsv("tonic-keywords", formatData(Array.isArray(data) ? data : []));
  };

  //Filter highlight code
const initialStartDateRef = React.useRef(dateRange.start);  
const initialEndDateRef = React.useRef(dateRange.end); 
const initialSearchFilter = React.useRef(searchQuery !== '' ? searchQuery: {})
 React.useEffect(() => {
    
    if (searchQuery === initialSearchFilter.current) {
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if ((dateRange.start.getTime() === initialStartDateRef.current.getTime()) && (dateRange.end.getTime() === initialEndDateRef.current.getTime())) {
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }
  }, [searchQuery, dateRange]);

  return (
    <>
      <Title title="Tonic Keyword Report" />

      <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
        <FlexboxGrid.Item colspan={16}>
          <IconButton
            size="lg"
            appearance="subtle"
            style={{ marginLeft: 15 }}
            icon={<FileDownloadIcon />}
            placement="right"
            onClick={handleExportCsvButtonClick}
          >
            Download CSV
          </IconButton>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4} style={{ textAlign: "right", paddingRight: 10 }}>
          <InputGroup style={{ width: "100%" }}>
            <InputGroup.Button onClick={searchByQuery}>
              <SearchIcon />
            </InputGroup.Button>
            <Input
              className="search-filter"
              placeholder="Search"
              value={searchQuery}
              onChange={(v) => handleSearchChange(v)}
              onKeyUp={onKeyUpEnter}
            />
          </InputGroup>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={4}>
          <DateRangePicker
            className="date-filter"
            size="md"
            placement="autoVerticalEnd"
            renderValue={formatDates}
            value={[dateRange.start, dateRange.end]}
            defaultValue={[dateRange.start, dateRange.end]}
            onChange={setDates}
            cleanable={false}
            // TODO Uncomment it and ***FIX*** a typescript warning
            // disabledDate={allowedMaxDays(60)}
            ranges={[
              {
                label: "Yesterday",
                value: [subDays(new Date(), 1), subDays(new Date(), 1)],
              },
              {
                label: "Today",
                value: [new Date(), new Date()],
              },
              {
                label: "Last 7 days",
                value: [subDays(new Date(), 7), subDays(new Date(), 0)],
              },
              {
                label: "This Month",
                value: [startOfMonth(new Date()), new Date()],
              },
              {
                label: "Last Month",
                value: [startOfMonth(lastMonth), lastDayOfMonth(lastMonth)],
              },
            ]}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <TonicKeywordList
        data={Array.isArray(data) ? formatData(data) : []}
        loading={loading}
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={handleSortColumn}
      />
    </>
  );
};

/**
 * Rewrite list data with formatted data
 * @param list
 */
const formatData = (list: any) =>
  list.map((item: any) => ({
    ...item,
    stat_date: format(parse(item.stat_date, "yyyy-MM-dd HH:mm:ss", new Date()), "yyyy-MM-dd"),
    clicks: !isNaN(parseInt(item.clicks, 10)) ? parseInt(item.clicks) : 0,
    rpc: !isNaN(parseFloat(item.rpc)) ? parseFloat(item.rpc) : 0,
    revenue: !isNaN(parseFloat(item.revenue)) ? parseFloat(item.revenue) : 0,
  }));

export default TonicKeywordGrid;
