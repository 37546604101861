import React from "react";
import {Drawer, IconButton, Grid, Row, Col, InputGroup, Input} from "rsuite";
import PlusRound from "@rsuite/icons/PlusRound";
import PinedIcon from '@rsuite/icons/Pined';
import SearchIcon from "@rsuite/icons/Search";
import CloseIcon from "@rsuite/icons/Close";
import Card from "../Campaigns/Card";
import CampaignRuleList from "../Campaigns/CampaignRuleList";
import CampaignPinedRuleList from "../Campaigns/CampaignPinedRuleList";
import RuleDrawer from "../Drawers/Rule";
import {initialRuleData} from "../Rules/RuleModel";
import useTaboolaProviderList from "../Rules/useTaboolaProviderList";

type CampaignRuleListDrawerProps = {
  formData: any;
  setFormData: any;
  isOpen: any;
  setIsOpen: any;
  campaignId: number;
  campaignData: any;
  setCampaignData:any;
  setCampaignName: any;
  setCampaignId: any;
  setApiCampaignId: any;
  callbackCampaignContentDrawer: any;
  loadCampaign: any;
  setLoadCampaign: any;
};

const CampaignRuleListDrawer: React.FC<CampaignRuleListDrawerProps> = ({
  formData,
  isOpen,
  setIsOpen,
  campaignId = 0,
  campaignData,
  loadCampaign,
  setLoadCampaign
}) => {
  const [searchQuery, setSearchQuery] = React.useState();
  const [search, setSearch] = React.useState<string>('');
  const [handleRulesPinedToCampaignModalOpen, setHandleRulesPinedToCampaignModalOpen] = React.useState(false);
  const handleOpen = () => setHandleRulesPinedToCampaignModalOpen(true);
  const handleClose = () => setHandleRulesPinedToCampaignModalOpen(false);
  const [loading, setLoading] = React.useState(true);
  const removeSearchContent = '';
  const [data, setData] = React.useState<any[]>([]);
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = React.useState(false);
  const [formDataRule, setFormDataRule] = React.useState(initialRuleData);
  const taboolaProviders: any = useTaboolaProviderList();


  const handleAfterCreateOrUpdateGridRow = (id: number, item: object) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.rule_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      // Push a new row at the top
      setData([item, ...data]);
    }
  };

  const handleCloseButtonClick = () => {
    setIsOpen(false);
    setLoading(true);
    setData([]);
  };

  const searchByQuery = () => {
    setSearch(searchQuery || '');
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      searchByQuery();
    }
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  //Removing content from search
  const searchRemoveContent = () => {
    setSearch(removeSearchContent);
    handleSearchChange('');
  };

  return (
    <>
      <Drawer open={isOpen}  backdrop={true} onClose={handleCloseButtonClick} size="full" >
        <Drawer.Header >
          <Drawer.Title>
            <Grid fluid>
              <Row className="show-grid">
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  Campaign ID: {campaignData.campaign_id}
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  Campaign Name: {campaignData.campaign_name}
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                </Col>
              </Row>
              <Row className="show-grid">
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  <Card label="Cost" type="currency" value={campaignData.total_cost} color={"#e26868"} average={campaignData.total_cost}
                        averageTitle="Avg CPC"/>
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  <Card label="Revenue" type="currency" value={campaignData.total_revenue} color={"#38c3b2"} average={campaignData.total_cost}
                        averageTitle="Avg CPA"/>
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  <Card label="Net" type="currency" value={campaignData.total_net} color={"#e3ab1a"} average={campaignData.total_cost}
                        averageTitle="Avg EPC"/>
                </Col>
                <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                  <Card label="ROI" type="percent" value={campaignData.total_roi} color={"#20aeda"}
                        averageTitle="Return On Investment"
                        hasAverage={false}
                  />
                </Col>
              </Row>
              <Row className="show-grid">
                <Col md={3}>
                  <IconButton
                    color="blue"
                    appearance="primary"
                    icon={<PlusRound/>}
                    onClick={() => {
                      // @ts-ignore
                      setFormDataRule({...initialRuleData, campaigns: [campaignId]});
                      setIsRuleDrawerOpen(true);
                    }}
                  >
                    New Rule
                  </IconButton>
                </Col>
                <Col md={14}>
                  <IconButton
                    color="blue"
                    appearance="primary"
                    icon={<PinedIcon />}
                    onClick={() => {
                      setHandleRulesPinedToCampaignModalOpen(true);
                    }}
                  >
                    Add Existing Rule to This Campaign
                  </IconButton>
                </Col>
                <Col md={7}>
                  <InputGroup style={{width: "100%"}}>
                    <InputGroup.Button onClick={searchByQuery}>
                      <SearchIcon/>
                    </InputGroup.Button>
                    <Input
                      placeholder="Search"
                      value={searchQuery}
                      onChange={(v) => handleSearchChange(v)}
                      onKeyUp={onKeyUpEnter}
                    />
                    <InputGroup.Button onClick={searchRemoveContent}>
                      <CloseIcon/>
                    </InputGroup.Button>
                  </InputGroup>
                </Col>
              </Row>
            </Grid>
          </Drawer.Title>
          <Drawer.Actions>

          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body >
          <CampaignPinedRuleList
            title={"Add Existing Rule To This Campaign"}
            open={handleRulesPinedToCampaignModalOpen}
            onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
            handleClose={handleClose}
            handleOpen={handleOpen}
            campaignId = {campaignId}
            selectedRules = {formData.rules}
            separatedGroups = {formData.rules_groups}
            setLoad = {setLoading}
            loadCampaign = {loadCampaign}
            setLoadCampaign = {setLoadCampaign}
          />
          <RuleDrawer
            formData={formDataRule}
            isOpen={isRuleDrawerOpen}
            closeDrawer={() => {
              // Clean form
              setFormDataRule({...initialRuleData});
              // Close drawer
              setIsRuleDrawerOpen(false);
            }}
            onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
             taboolaProviders={taboolaProviders}
          />
          <CampaignRuleList
            campaignId={campaignId}
            loading={loading}
            setLoading={setLoading}
            search={search}
          />

        </Drawer.Body>
      </Drawer>
    </>
  );
};



export default CampaignRuleListDrawer;