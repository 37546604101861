import React from "react";
import { FlexboxGrid, Pagination, Table } from "rsuite";
import { sort } from "../../@Utils/Sorting";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const DEFAULT_PAGE = 1;
const MIN_LIMIT = 15;
const DEFAULT_LIMIT = 50;
const limitOptions = [MIN_LIMIT, 30, DEFAULT_LIMIT, 100];

/**
 * Get page rows when we use the local pagination
 */
const paginateData = (data: any[], page: number = DEFAULT_PAGE, limit: number = MIN_LIMIT) =>
  data.slice((page - 1) * limit, page * limit) || [];

type TrafficSourcesTreeProps = {
  data?: any;
  loading?: any;
  sortType?: any;
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
};

const TonicKeywordList: React.FC<TrafficSourcesTreeProps> = ({
  data = [],
  loading = false,
  sortType = "desc",
  sortColumn = "stat_date",
  onSortColumn,
}) => {
  const [activePage, setActivePage] = React.useState(DEFAULT_PAGE);
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
  const [total, setTotal] = React.useState(0);
  const [rows, setRows] = React.useState<any[]>([]);
  const [load, setLoad] = React.useState(true);

  React.useEffect(() => {
    setRows(data);
    setTotal(data.length);
    setLoad(loading);
  }, [data, load, loading]);

  const handleSetLimit = (limit: any) => {
    setActivePage(DEFAULT_PAGE);
    setLimit(limit);
  };

  const columns = React.useMemo(() => [
    {
      dataKey: 'stat_date',
      label: 'Date',
      width: 100,
      fixed: true,
    },
    {
      dataKey: 'campaign_name',
      label: 'Campaign',
      width: 300,
      fixed: true,
    },
    {
      dataKey: 'keyword',
      label: 'Keyword',
      width: 300,
      fixed: true,
    },
    {
      dataKey: 'clicks',
      label: 'Clicks',
      width: 85,
      align: 'right',
      type: 'number',
    },
    {
      dataKey: 'rpc',
      label: 'RPC',
      width: 85,
      align: 'right',
      type: 'currency',
    },
    {
      dataKey: 'revenue',
      label: 'Revenue',
      width: 90,
      align: 'right',
      type: 'currency',
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TONIC_KEYWORD_REPORT_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.TONIC_KEYWORD_REPORT_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  return (
    <>
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item colspan={1}>
          <TableColumnsMenu
            columns={columns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          height={600}
          data={paginateData(sort(rows, sortColumn, sortType), activePage, limit)}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={onSortColumn}
        >
          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>

      <Pagination
        style={{ margin: "10px 20px" }}
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        size="lg"
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        disabled={total < MIN_LIMIT}
        total={total}
        limit={limit}
        limitOptions={limitOptions}
        maxButtons={7}
        activePage={activePage}
        onChangePage={setActivePage}
        onChangeLimit={handleSetLimit}
      />
    </>
  );
};

export default TonicKeywordList;
