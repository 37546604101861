import React from "react";
import { ConfigType } from "rsuite/esm/MaskedInput/types";
import { InputGroup, MaskedInput, Popover, Table, Whisper } from "rsuite";
import { FaFilter } from "react-icons/fa";
import { Filter } from "../@Utils/DataProcessing";
import DraggableHeaderCell from "./DraggableHeaderCell";

const { HeaderCell } = Table;

type FilterHeaderCellProps = {
  dataKey?: string;
  filters: Filter[];
  setFilters: any;
  onDrag?: any;
  id?: string;
};

const FilterHeaderCell: React.FC<FilterHeaderCellProps> = ({
  dataKey,
  filters,
  setFilters,
  onDrag = null,
  id = '',
  ...rest
}) => {
  const props = {
    dataKey,
    ...rest,
  };
  const [mask, setMask] = React.useState<RegExp[]>([]);
  const [filterValue, setFilterValue] = React.useState("");

  const handleMaskDynamicChanging = (currentValue: string, config: ConfigType) => {
    const currentPosition = config.currentCaretPosition || 1;
    const numberRegExp = /(\d)/;
    const numberOrEqualRegExp = /(\d)|(=)/;
    const numberOrCompareOperationRegExp = /(\d)|(<)|(>)/;
    const numberOrAnyOperationRegExp = /(\d)|(<)|(>)|(=)/;
    const previousConformedValue = config.previousConformedValue;
    const count = previousConformedValue?.length || 0;
    const hasEqual = ['='].some((v: string) => previousConformedValue?.includes(v));
    const hasCompareOperation = ['<', '>'].some((v: string) => previousConformedValue?.includes(v));

    // Build and update the mask after changing only
    if (previousConformedValue !== undefined) {
      const newMask: RegExp[] = [];

      for (let i = 0; i < count; i++) {
        // const prevChar = previousConformedValue[i-1] || '';
        const processChar = previousConformedValue[i] || '';
        // const nextChar = previousConformedValue[i+1] || '';

        if (processChar === '=') {
          if (hasCompareOperation) {
            newMask.push(numberOrEqualRegExp);
          } else {
            newMask.push(numberOrAnyOperationRegExp);
          }
        } else if ((processChar === '<' || processChar === '>')) {
          newMask.push(numberOrCompareOperationRegExp);
        } else if (hasEqual && hasCompareOperation) {
          newMask.push(numberRegExp);
        } else if (hasEqual) {
          newMask.push(numberOrCompareOperationRegExp);
        } else if (hasCompareOperation) {
          newMask.push(numberOrEqualRegExp);
        } else {
          newMask.push(numberOrAnyOperationRegExp);
        }
      }

      if (currentPosition > count) {
        if (hasEqual) {
          newMask.push(numberRegExp);
        } else if (hasCompareOperation) {
          newMask.push(numberOrEqualRegExp);
        } else {
          newMask.push(numberOrAnyOperationRegExp);
        }
      } else {
        if (hasEqual) {
          newMask.splice(currentPosition - 1, 0, numberRegExp);
        } else if (hasCompareOperation) {
          newMask.splice(currentPosition - 1, 0, numberOrEqualRegExp);
        } else {
          newMask.splice(currentPosition - 1, 0, numberOrAnyOperationRegExp);
        }
      }

      // console.log(`New MASK(${newMask.length}): `, newMask);

      setMask(newMask);

      return newMask;
    }

    // console.log(`Old MASK(${mask.length}): `, mask);

    return mask;
  };

  const buildFilters = (filterValue: string) : Filter[] => {
    const operations = ['<=', '>=', '<' , '>', '='];
    const allFilterParts = filterValue.split(/(<=)|(>=)|(<)|(>)|(=)/).filter((filter: any) => !!filter);
    const [firstPart, secondPart, thirdPart] = [allFilterParts[0], allFilterParts[1], allFilterParts[2]];

    let leftPart = '';
    let operation = '';
    let rightPart = '';
    if (operations.includes(firstPart)) {
      operation = firstPart;
      rightPart = secondPart;
    } else if (operations.includes(secondPart)) {
      leftPart = firstPart;
      operation = secondPart;
      rightPart = thirdPart;
    }

    // console.log(`leftPart="${leftPart}"`);
    // console.log(`Operation="${operation}"`);
    // console.log(`rightPart="${rightPart}"`);

    const functions = [];
    switch (operation) {
      case '=':
        functions.push((value: any) => value === parseFloat(rightPart));
        break;

      case '>':
        if (leftPart) {
          functions.push((value: any) => leftPart > value);
        }
        if (rightPart) {
          functions.push((value: any) => value > rightPart);
        }
        break;

      case '<':
        if (leftPart) {
          functions.push((value: any) => leftPart < value);
        }
        if (rightPart) {
          functions.push((value: any) => value < rightPart);
        }
        break;

      case '<=':
        if (leftPart) {
          functions.push((value: any) => leftPart <= value);
        }
        if (rightPart) {
          functions.push((value: any) => value <= rightPart);
        }
        break;

      case '>=':
        if (leftPart) {
          functions.push((value: any) => leftPart >= value);
        }
        if (rightPart) {
          functions.push((value: any) => value >= rightPart);
        }
        break;
    }

    return functions.map((fn: any) => ({
      column: dataKey || '',
      template: filterValue,
      fn,
    }));
  };

  const changeFilters = () => {
    const filtersExceptForCurrentColumn: Filter[] = filters.filter((filter: Filter) => filter.column !== dataKey);

    const isEmptyFilterValue = filterValue === '';

    if (isEmptyFilterValue) {
      // Remove the current column filters
      setFilters([...filtersExceptForCurrentColumn]);

      return;
    }

    const isValid = /^(\d*((<=)|(>=)|(<)|(>)|(=))\d+)|\d+((<=)|(>=)|(<)|(>)|(=))\d*$/.test(filterValue);

    if (!isValid) {
      return;
    }

    const columnFilters: Filter[] = buildFilters(filterValue);

    // Include new filters or replace the old current column filters
    setFilters([
      ...filtersExceptForCurrentColumn,
      ...columnFilters,
    ]);
  };

  const speaker =
    <Popover title="Filtering">
      <p>You can use next comparison operators: <b>{"<"}</b>, <b>{">"}</b>, <b>=</b>, <b>{"<="}</b>, <b>{">="}</b></p>
      <p><strong>Examples:</strong></p>
      <p>Values between <b>10</b> and <b>20</b>: <b>10{"<"}20</b> or <b>20{">"}10</b></p>
      <p>Values between <b>10</b> and <b>20</b> (including borders): <b>10{"<="}20</b> or <b>20{">="}10</b></p>
      <p>Values less than <b>30</b>: <b>{"<"}30</b></p>
      <p>Values greater than <b>30</b> and equal to it: <b>{">="}30</b></p>
      <p>Values equal to <b>30</b>: <b>=30</b></p>
    </Popover>;

  const headerInnerEl = <>
    <div className="adm-column-title">
      {rest.children}
    </div>
    <div>
      <InputGroup size="sm" style={{ width: "100%" }}>
        <MaskedInput
          guide={false}
          showMask={false}
          keepCharPositions={true}
          value={filterValue}
          onChange={setFilterValue}
          mask={handleMaskDynamicChanging}
          onClick={(e) => {
            // Prevent event bubbling for filtering
            e.stopPropagation();
          }}
          onKeyUp={(event: any) => {
            if (event.key === "Enter") {
              changeFilters();
            }
          }}
        />
        <Whisper
          trigger="hover"
          placement="auto"
          speaker={speaker}
        >
          <InputGroup.Button
            onClick={(e) => {
              // Prevent event bubbling for filtering
              e.stopPropagation();

              changeFilters();
            }}
          >
            <FaFilter />
          </InputGroup.Button>
        </Whisper>
      </InputGroup>
    </div>
  </>;

  return onDrag !== null
    ? <DraggableHeaderCell onDrag={onDrag} id={id} className="adm-filter-header" {...props}>
        {headerInnerEl}
      </DraggableHeaderCell>
    : <HeaderCell className="adm-filter-header" {...props}>{headerInnerEl}</HeaderCell>;
};

export default FilterHeaderCell;