import React from "react";
import { Table } from "rsuite";
import { useDrag, useDrop } from "react-dnd";

const { HeaderCell } = Table;

const ItemTypes = {
  COLUMN: "column",
  ROW: "row"
};

type DraggableHeaderCellProps = {
  onDrag: any;
  id: string;
  className?: string;
};

const DraggableHeaderCell: React.FC<DraggableHeaderCellProps> = ({
  onDrag,
  id,
  children,
  className = '',
  ...rest
}) => {
  const ref = React.useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop(item: any, monitor) {
      onDrag(item.id, id);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id },
    type: ItemTypes.COLUMN,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isActive = canDrop && isOver;

  drag(drop(ref));

  const styles = {
    cursor: "grab",
    opacity: isDragging ? 0 : 1,
    borderLeft: isActive ? "2px solid #2589f5" : null,
    display: 'inline-block',
  };

  // @ts-ignore
  const inner = <div ref={ref} style={styles}>
    {children}
  </div>;

  return (
    <HeaderCell
      {...rest}
      className={className}
    >
      {inner}
    </HeaderCell>
  );
};

export default DraggableHeaderCell;