import React from "react";
import { Button, Drawer, Loader, Notification } from "rsuite";
import { FormInstance } from "rsuite/Form";
import FunnelForm from "../Funnels/FunnelForm";
import HttpClient from "../../@Utils/HttpClient";
import _ from "lodash";
import { format } from "date-fns";
import { pushInforming, pushErrorNotification } from "../../@Utils/Messager";

const API_NAME_TABOOLA = "taboola";

// TODO Move to Funnel model or entity class
const prepareFormData = (record: any) => {
  const parseToArray = (data: string | string[] | null): string[] => {
    if (Array.isArray(data)) {
      return data;
    }

    return data ? JSON.parse(data) : [];
  };

  record.funnel_id = !isNaN(parseInt(record.funnel_id)) ? parseInt(record.funnel_id) : 0;
  record.campaign_id = !isNaN(parseInt(record.campaign_id)) ? parseInt(record.campaign_id) : 0;
  record.bid_amount = !isNaN(parseFloat(record.bid_amount)) ? parseFloat(record.bid_amount) : 0;
  record.spending_limit = !isNaN(parseFloat(record.spending_limit)) ? parseFloat(record.spending_limit) : 0;
  record.daily_cap = !isNaN(parseFloat(record.daily_cap)) ? parseFloat(record.daily_cap) : 0;
  record.locations_type = record?.locations_type || "all";
  record.locations = parseToArray(record?.locations);
  record.locations_subtype = record?.locations_subtype || "all";
  record.locations_sub = parseToArray(record?.locations_sub);
  record.device_type = parseToArray(record?.device_type);
  record.os_target = record?.os_target || "all";
  record.os = parseToArray(record?.os);
  record.browser_target = record?.browser_target || "all";
  record.browser = parseToArray(record?.browser);
  record.offers = parseToArray(record?.offers);

  record.start_date = record?.hasOwnProperty("start_date") && record?.start_date !== null
    ? new Date(record.start_date) : null;
  record.end_date = record?.hasOwnProperty("end_date") && record?.end_date !== null
    ? new Date(record.end_date) : null;
  record.creative_traffic_allocation_start = record?.hasOwnProperty("creative_traffic_allocation_start") && record?.creative_traffic_allocation_start !== null
    ? new Date(record.creative_traffic_allocation_start) : null;
  record.creative_traffic_allocation_end = record?.hasOwnProperty("creative_traffic_allocation_end") && record?.creative_traffic_allocation_end !== null
    ? new Date(record.creative_traffic_allocation_end) : null;

  return record;
};

type FunnelProps = {
  formData: any;
  open: any;
  setOpen: any;
  funnelId: number;
  setFunnelId: any;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const Funnel: React.FC<FunnelProps> = ({
  formData,
  open,
  setOpen,
  funnelId = 0,
  setFunnelId,
  onAfterCreateOrUpdateGridRow
}) => {
  const [formValue, setFormValue] = React.useState(formData);
  const mainFormRef = React.createRef<FormInstance>();
  const isNew = funnelId === 0;
  const [funnelNodeSum, setFunnelNodeSum] = React.useState(100);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  // TODO Replace it
  React.useEffect(() => {
    setFormValue(formData);
  }, [formData]);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleChangeCampaign = (campaignIn: number, campaignName: string = '', trafficSourceType = '') => {
    const chromeOsValue = 'chrome';
    const shouldChromeOsExclude = trafficSourceType === API_NAME_TABOOLA && formValue.os.includes(chromeOsValue);

    setFormValue({
      ...formValue,
      campaign_id: campaignIn,
      campaign_name: campaignName,
      traffic_source_type: trafficSourceType,
      os: shouldChromeOsExclude ? formValue.os.filter((item: string) => item !== chromeOsValue) : formValue.os,
    });
  };

  const handleChangeCategory = (categoryId: number, categoryName: string = '') => {
    setFormValue({
      ...formValue,
      funnel_category_id: categoryId,
      funnel_category_name: categoryName,
    });
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    if (funnelNodeSum <= 99.8) {
      pushErrorNotification('Offers List Must Sum to 100%');
      return;
    }

    saveItem(formValue, parseInt(formValue.funnel_id));
  };

  const saveItem = (data: any, itemId: number = 0) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    const formatDateToStringOrNull = (date: Date | null): string | null =>
      (date !== null ? format(date, "yyyy-MM-dd 00:00:00") : null);

    const stringifyArray = (data: any[]): string => JSON.stringify(data || []);

    // Format date to string
    data.start_date = formatDateToStringOrNull(data.start_date);
    data.end_date = formatDateToStringOrNull(data.end_date);
    data.creative_traffic_allocation_start = formatDateToStringOrNull(data.creative_traffic_allocation_start);
    data.creative_traffic_allocation_end = formatDateToStringOrNull(data.creative_traffic_allocation_end);

    // Format arrays as JSON
    data.locations = stringifyArray(data.locations);
    data.locations_sub = stringifyArray(data.locations_sub);
    data.device_type = stringifyArray(data.device_type);
    data.os = stringifyArray(data.os);
    data.browser = stringifyArray(data.browser);

    setSaveButtonLoading(true);

    if (itemId === 0) {
      // Create a new item
      HttpClient.post<object, any>('funnel', data)
        .then(res => {
          const funnelId = parseInt(res.data.funnel_id);
          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "Funnel has been created" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...data,
            funnel_id: funnelId,
          };
          setFunnelId(funnelId);
          setFormValue(updatedFormValue);
          setOpen(false)
          // Add a new row into a grid data
          onAfterCreateOrUpdateGridRow(funnelId, updatedFormValue);
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    } else {
      // Update an existing one
      HttpClient.put<any>(`funnel/${itemId}`, data)
        .then(res => {
          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "Funnel has been updated" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
          setOpen(false)
          // Update a grid data
          onAfterCreateOrUpdateGridRow(parseInt(data.funnel_id), data);
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    }
  };

  return (
    <>
      <Drawer open={open} backdrop={false} onClose={() => setOpen(false)} size="lg">
        <Drawer.Header>
          <Drawer.Title>
            {isNew ? 'Add Funnel' : 'Edit Funnel'}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
              Save
            </Button>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {(formValue === null || _.isEmpty(formValue)) ?
            <Loader content="Loading..." /> :
            <FunnelForm
              formRef={mainFormRef}
              formValue={prepareFormData(formValue)}
              onChangeCampaign={handleChangeCampaign}
              onChangeCategory={handleChangeCategory}
              onChangeFunnelNodes={setFunnelNodeSum}
              parentCallback={handleCallback}
            />
          }
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default Funnel;
