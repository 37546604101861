import React from "react";
import {IconButton, Table, Whisper, Tooltip, Toggle, Notification, FlexboxGrid, Pagination} from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import {sort} from "../../@Utils/Sorting";
import TableColumnsMenu, {buildColumns, renderColumns} from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import {LOCAL_STORAGE_KEYS, useStoredValue} from "../../@Utils/useStoredValue";
import ConfirmModal from "../ConfirmModal";
import HttpClient from "../../@Utils/HttpClient";
import {pushErrorNotification, pushInforming} from "../../@Utils/Messager";
import PlusRound from "@rsuite/icons/PlusRound";


const {Column, HeaderCell, Cell} = Table;

const DEFAULT_PAGE = 1;
const MIN_LIMIT = 15;
const DEFAULT_LIMIT = 50;
const limitOptions = [MIN_LIMIT, 30, DEFAULT_LIMIT, 100];

/**
 * Get page rows when we use the local pagination
 */
const paginateData = (data: any[], page: number = DEFAULT_PAGE, limit: number = MIN_LIMIT) =>
    data.slice((page - 1) * limit, page * limit) || [];

type DomainMapingToolListProps = {
    data?: any;
    loading?: any;
    sortType?: "desc" | "asc";
    sortColumn?: string;
    onSortColumn: (column: any, type: any) => void;
    refreshCallback?: () => void;
    setIsDomainDrawerOpen: any;
    setFormData: any;
    setAction: any;
    setAdId: any;
    active: string;
    AddSubID: any;
};

const DomainMapingToolList: React.FC<DomainMapingToolListProps> = ({
    data = [],
    loading = false,
    sortType = "desc",
    sortColumn = "stat_date",
    onSortColumn,
    refreshCallback,
    setIsDomainDrawerOpen,
    setFormData,
    setAction,
    setAdId,
    active,
    AddSubID
}) => {
    const [activePage, setActivePage] = React.useState(DEFAULT_PAGE);
    const [limit, setLimit] = React.useState(DEFAULT_LIMIT);
    const [total, setTotal] = React.useState(0);
    const [rows, setRows] = React.useState<any[]>([]);
    const [load, setLoad] = React.useState(true);
    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({ adgroup_id: 0,subid: ''});
    const handleClose = () => setRemoveConfirmModalOpen(false);
    const handleOpen = () => setRemoveConfirmModalOpen(true);

    const handleRemove = () => {
      removeItem(selectedItem ? selectedItem.adgroup_id : 0);
      handleClose();
    };
    React.useEffect(() => {
      setRows(data);
      setTotal(data.length);
      setLoad(loading);
    }, [data, load, loading]);
    const handleSetLimit = (limit: any) => {
       setActivePage(DEFAULT_PAGE);
       setLimit(limit);
    };
    const handleRemoveActionClick = (rowData: any) => {
       setSelectedItem(rowData);
       handleOpen();
    };
    const removeItem = (itemId: number = 0) => {

      if (itemId === 0) {
        return;
      }

      HttpClient.delete<any>(`tiktok_subid_mapping/${itemId}`, {
          data: {...selectedItem, subid: selectedItem.subid.toString()}
      })
        .then(res => {
          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "Sub Id mapping domain has been removed" : res.statusText;
          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
                if (refreshCallback instanceof Function) {
                    refreshCallback();
                }
            })
            .catch(error => {
                pushErrorNotification(error.response.data?.error || error.toString());
            });
    }
    const handleEditActionClick = (rowData: any) => {
      setAction('edit');
      setAdId(rowData.adgroup_id);
      rowData = {...rowData, adgroup_id:rowData.adgroup_id.toString(), subid: rowData.subid.toString() }
      setFormData({...rowData,   data:[rowData]});
      setIsDomainDrawerOpen(true);
    };
    const fields = active === 'domains' ? [
        {
            dataKey: 'adgroup_id',
            label: 'Adgroup ID',
            width: 200,
            align: 'left',
            type: 'integer',
            sortable: true,
        },
        {
            dataKey: 'subid',
            label: 'SubID',
            width: 200,
            align: 'left',
            type: 'integer',
            sortable: true,
        },
    ] : active === 'subid_view' ? [
        {
            dataKey: 'subid',
            label: 'SubID',
            width: 200,
            align: 'left',
            type: 'integer',
            sortable: true,
        },
    ] : [];
    const columns = React.useMemo(() => fields , [active]);

    const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
        LOCAL_STORAGE_KEYS.OFFERS_PAGE_GRID_SHOW_COLUMNS,
        columns.map(column => column.dataKey)
    );

    const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
        LOCAL_STORAGE_KEYS.OFFERS_PAGE_GRID_COLUMNS_ORDER,
        columns.map(column => column.dataKey)
    );
    const builtColumns = React.useMemo(() => {
        return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
    }, [columns, visibleColumnKeys, orderedColumnKeys]);


    const handleDragColumn = (sourceId: string, targetId: string) => {
        setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
    };

    const sortColumns = (source: string[], sourceId: string, targetId: string) => {
        // Push source element in the source array if the source doesn't contain it
        if (source.indexOf(sourceId) === -1) {
            source = [...source, sourceId];
        }

        const nextData = source.filter((key: string) => key !== sourceId);
        const dragItem = source.find((key: string) => key === sourceId);
        const index = nextData.findIndex((key: string) => key === targetId);

        if (dragItem)
            nextData.splice(index, 0, dragItem);

        return nextData;
    }

    return (
        <>
            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={1}>
                <TableColumnsMenu
                   columns={columns}
                   visibleColumnKeys={visibleColumnKeys}
                   setVisibleColumnKeys={setVisibleColumnKeys}
                />
            </FlexboxGrid.Item>
        </FlexboxGrid>
        <DndProvider backend={HTML5Backend}>
            <Table
                shouldUpdateScroll={false}
                height={600}
                data={paginateData(sort(rows, sortColumn, sortType), activePage, limit)}
                loading={loading}
                virtualized
                rowClassName="striped-rows"
                affixHeader
                affixHorizontalScrollbar
                sortType={sortType}
                sortColumn={sortColumn}
                onSortColumn={onSortColumn}
            >
                <Column width={200} align="left" fixed sortable resizable>
                    <HeaderCell>Domain</HeaderCell>
                    <Cell dataKey="domain"/>
                </Column>
                {renderColumns(builtColumns, handleDragColumn)}
                <Column width={250} align="center" fixed>
                    <HeaderCell>Action</HeaderCell>
                    <ActionCell
                        editHandler={handleEditActionClick}
                        removeHandler={handleRemoveActionClick}
                        addHandler = {AddSubID}
                        active = {active}
                    />
                </Column>
            </Table>
        </DndProvider>
            <Pagination
                style={{ margin: "10px 20px" }}
                layout={["total", "-", "limit", "|", "pager", "skip"]}
                size="lg"
                prev={true}
                next={true}
                first={true}
                last={true}
                ellipsis={true}
                boundaryLinks={true}
                disabled={total < MIN_LIMIT}
                total={total}
                limit={limit}
                limitOptions={limitOptions}
                maxButtons={7}
                activePage={activePage}
                onChangePage={setActivePage}
                onChangeLimit={handleSetLimit}
            />
            <ConfirmModal
                title="Removing"
                open={removeConfirmModalOpen}
                onClose={handleClose}
                onYes={handleRemove}
            >
                Are you sure you want to remove this Rule?
            </ConfirmModal>
        </>
    );
};

export const ActionCell = ({
    rowData,
    editHandler,
    removeHandler,
    addHandler,
    active,
    ...props
  }: any) => {

    return (
        <Cell {...props} className="link-group">
            {
                active === 'domains' ?
                <ButtonToolbar>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Edit</Tooltip>}
                    >
                    <IconButton
                        icon={<EditIcon/>}
                        onClick={(e) => editHandler(rowData, e)}
                    />
                    </Whisper>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Delete</Tooltip>}
                    >
                    <IconButton
                        icon={<TrashIcon/>}
                        onClick={(e) => removeHandler(rowData, e)}
                    />
                     </Whisper>
                </ButtonToolbar> :
                <ButtonToolbar>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Edit</Tooltip>}
                    >
                        <IconButton
                            icon={<PlusRound/>}
                            onClick={(e) => addHandler(rowData, e)}
                        />
                    </Whisper>
                </ButtonToolbar>
            }
        </Cell>);
};

export default DomainMapingToolList;