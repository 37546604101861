export const thousands = (value: string) =>
  `${value}`.replace(/(?=(?!(\b))(\d{3})+$)/g, "$1,");

export const float = (value: number = 0, digits: number = 2) =>
  value.toFixed(digits);

export const currency = (value: number = 0, digits = 2) =>
  value.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: digits });

export const percent = (value: number = 0, digits: number = 1) =>
  float(value, digits) + "%";
