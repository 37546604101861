import React, { ReactNode } from "react";
import { Col,
    Dropdown,
    FlexboxGrid,
    Input,
    InputGroup,
    Notification,
    SelectPicker,
    toaster,} from "rsuite";
import Title from "../../@Components/Title";
import {exportCsvRemotely} from "../../@Utils/Export";
import HttpClient from "../../@Utils/HttpClient";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import KeywordStatsList from "./KeywordStatsList";

const System1KeywordStatsTree: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any[]>([]);
    const [orderBy, setOrderBy] = React.useState<string>('estimated_revenue');

    const orderByList = [
        { label: "Estimated Revenue", value: 'estimated_revenue'},
        { label: "EPC", value: 'epc'},
    ];

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    const fetchData = () => {
        // Clear and mask a table
        setData([]);
        setLoading(true);

        // Get data
        HttpClient
            .get<any>('system1_keyword_stats', {
                order_by: orderBy,
            })
            .then(res => {
                setLoading(false);
                setData(res.data.data);
            }).catch(error => {
            pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                {error.response.data?.error || error.toString()}
            </Notification>);
            setLoading(false);
        });
    };

    console.log(data)

    React.useEffect(fetchData, [orderBy]);

    const addCalculatedValues = (row: any) => {
        row.clicks = isNaN(parseFloat(row.clicks)) ? 0 : parseFloat(row.clicks);
        row.epc = isNaN(parseFloat(row.epc)) ? 0 : parseFloat(parseFloat(row.epc).toFixed(2));
        row.estimated_revenue = isNaN(parseFloat(row.estimated_revenue)) ? 0 : parseFloat(row.estimated_revenue);
        row.searches = isNaN(parseFloat(row.searches)) ? 0 : parseFloat(row.searches);


        return {
            ...row,
            // ...{
            //     completed_percent:
            //         row.presented !== 0 ? (row.completed / row.presented) * 100 : 0,
            //     profit: profit > 0 ? profit : 0,
            //     loss:
            //         row.paid_amount > 0 && profit <= 0 ? "-" + Number(loss).toFixed(2) : 0,
            //     form_percent: isNaN(formFillRate) ? 0 : formFillRate,
            // },
        };
    };

    const addCalculatedValuesRecursively = (list: any) => {
        return list.map((row: any) => {
            if (row.children && Array.isArray(row.children)) {
                row.children = addCalculatedValuesRecursively(row.children);
            }

            return addCalculatedValues(row);
        });
    };


    return (
        <>
            <Title title="Keyword Stats" />
            <FlexboxGrid justify="space-between" style={{marginBottom: 25}}>
                <FlexboxGrid.Item colspan={24} style={{textAlign: 'center'}}>
                    <label style={{fontWeight: 'bold'}}>Order By: </label>
                    <SelectPicker
                        size="lg"
                        searchable={false}
                        cleanable={false}
                        placeholder="Order By"
                        // defaultValue={defaultTypeValue}
                        value={orderBy}
                        data={orderByList}
                        valueKey="value"
                        labelKey="label"
                        onChange={(v) => {
                            setOrderBy(v);
                        }}
                        style={{ marginRight: 15 }}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <KeywordStatsList
                data={Array.isArray(data) ? addCalculatedValuesRecursively(data) : []}
                loading={loading}
                defaultSortColumn={orderBy}
            />
        </>
    )
}

export default System1KeywordStatsTree;