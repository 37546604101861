import React from "react";
import {Icon} from '@rsuite/icons';

const SvgIcon = React.forwardRef((props, ref) => (
  <svg {...props}
    // width="2em" height="2em"
       fill="currentColor"
    // ref={ref}
       version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 652 652"
    // style="enable-background:new 0 0 652 652;"
       xmlSpace="preserve">
    <path className="st0" d="M55.6,281.1H20.7v-31.8h108v31.8H93.8v93.2H55.6L55.6,281.1z"/>
    <path className="st0" d="M160.5,333.5c-4.4,1.9-8.9,3.3-13.6,4.1c-7.7,1.7-11.7,3.6-11.8,9.7c0.8,5.2,5.6,8.8,10.8,8.3
	c7.6,0.5,14.1-5.3,14.6-12.8c0-0.3,0-0.6,0-0.9V333.5z M193.6,354.2c-0.6,7,0.8,14.1,4.1,20.3l-35.3-0.5c-0.9-2.7-1.3-5.5-1.2-8.3
	h-0.4c-7.4,8.3-18,10.7-29.2,10.6c-17.5,0-31.9-8.6-31.8-27.6c0-28.6,33.7-27.4,51.3-30.6c4.8-1,9.3-2.2,9.3-8.1
	c0-5.9-5.7-8.3-11.3-8.3c-10.6,0-12.8,5.3-13,9.2h-32.1c1.2-25.4,25.3-29.5,46.8-29.4c43.4-0.1,43,17.8,42.7,35.3V354.2z"/>
    <path className="st0" d="M250.7,307c-12.4,0-15.2,11.6-15.3,22.2c-0.1,10.6,2.6,22.6,15.1,22.6c12.4,0,15.3-11.6,15.3-22.4
	S263.1,307,250.7,307 M202.6,249h34.6l-0.2,44.9h0.3c5.8-7.9,15.1-12.4,24.8-12.1c30.3,0,38.4,26,38.3,47.3
	c0,22.7-12.7,47.9-38.3,47.8c-16.9,0-22.1-6.6-26.4-12.4h-0.3v9.7h-32.6L202.6,249z"/>
    <path className="st0" d="M348.8,274.4c-13.1,0-14.9,12.7-15,22.2c-0.1,9.6,1.6,22.5,14.7,22.6c13.1,0.1,14.9-12.8,15-22.4
	C363.6,287.2,361.9,274.7,348.8,274.4 M348.4,344.4c-34,0-49.2-22.8-49.1-47.9c0.1-25.1,15.6-47.4,49.7-47.2s49.2,22.6,49.1,47.8
	C397.9,322.2,382.4,344.6,348.4,344.4"/>
    <path className="st0" d="M434.5,274.4c-13.1,0-14.9,12.7-14.9,22.3c0,9.6,1.6,22.5,14.7,22.6c13.1,0.1,14.9-12.8,14.9-22.4
	C449.2,287.3,447.6,274.5,434.5,274.4 M434.1,344.4c-34,0-49.2-22.8-49.1-47.9c0.2-25.1,15.6-47.4,49.7-47.2s49.2,22.6,49.1,47.8
	C483.6,322.2,468.1,344.6,434.1,344.4"/>
    <path className="st0" d="M487.4,249.3h33.8v125h-33.8V249.3z"/>
    <path className="st0" d="M584.2,333.2c-4.4,1.9-8.9,3.3-13.6,4.1c-7.7,1.7-11.7,3.6-11.8,9.7c0.8,5.2,5.6,8.8,10.8,8.3
	c7.5,0.5,14.1-5.2,14.5-12.8c0-0.4,0-0.9,0-1.3L584.2,333.2z M617.3,353.9c0,6.8-0.4,14.8,3.4,20.7H586c-0.9-2.9-1.3-6-1.2-9.1h-0.4
	c-7.4,8.3-18,10.7-29.2,10.6c-17.5,0-31.9-8.6-31.8-27.6c0-28.6,33.6-27.4,51.3-30.6c4.7-0.9,9.4-2.2,9.5-8c0-5.8-5.7-8.3-11.3-8.3
	c-10.6,0-12.8,5.3-13,9.2h-32.1c1.2-25.5,25.3-29.5,46.8-29.4c43.3,0.2,42.8,18.2,42.7,35.7V353.9z"/>
    <path className="st0" d="M312.2,344.4c24.5,10.8,51,16.2,77.7,16c29,0,51.2-5.3,79.2-16v30.2c-24.6,12.4-51.7,18.9-79.2,19
	c-29.5,0-50.7-6.2-77.7-19L312.2,344.4z"/>
  </svg>
));

type IconProps = {
  style?: object;
};

const TaboolaLogo: React.FC<IconProps> = (props) => {
  const style = {
    fontSize: "7em",
    marginTop: "-39px",
    color: "#004B7A",
  };

  const newProps = {
    ...props,
    style: {
      ...style,
      ...props.style,
    }
  };

  return <Icon as={SvgIcon} {...newProps} />
};

export default TaboolaLogo;