import React from "react";
import PlusIcon from "@rsuite/icons/Plus";
import CloseIcon from '@rsuite/icons/Close';

import {
  FlexboxGrid,
  IconButton,
  Input,
  Table,
  Form,
  Whisper,
  Tooltip
} from "rsuite";
import {TableInstance} from "rsuite/Table";

const {Column, HeaderCell, Cell} = Table;

type ConfigureDataProps = {
  fields: any;
  onChangeData?: any;
};

const DataPassing: React.FC<ConfigureDataProps> = ({
  fields,
  onChangeData
}) => {
  const data = addIds(fields);
  const gridRef = React.createRef<TableInstance>();

  const handleAddItemClick = () => {
    const maxId = getMaxId(data);
    const newItem = {
      id: maxId + 1,
      field: "",
      value: "",
    };

    gridRef.current?.scrollTop(0);

    changeData([newItem, ...data]);
  };

  const handleRemoveActionClick = (row: any) => {
    removeItem(row.id);
  };

  const changeData = (data: any) => {
    if (typeof onChangeData === "function") {
      onChangeData(removeIds(data));
    }
  };

  const changeItem = (id: number, newData: object) => {
    const index = data.findIndex((item: any) => item.id === id);
    data[index] = {
      ...data[index],
      ...newData,
    };

    changeData(data);
  };

  const removeItem = (id: number) => {
    changeData(data.filter((item: any) => item.id !== id));
  };

  const handleFieldChange = (field: string, rowData: any) => {
    changeItem(rowData.id, {field});
  };

  const handleValueChange = (value: string, rowData: any) => {
    changeItem(rowData.id, {value});
  };

  return (
    <>
      <FlexboxGrid justify="space-between" style={{marginBottom: 15}}>
        <FlexboxGrid.Item colspan={24}>
          <IconButton
            onClick={handleAddItemClick}
            icon={<PlusIcon/>}
            placement="left"
            size="sm"
            appearance="ghost"
            style={{marginRight: 15}}
          >
            Add Parameter
          </IconButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <Form.Group>
        <Table
          data={Array.isArray(data) ? data : []}
          shouldUpdateScroll={false}
          ref={gridRef}
        >
          <Column flexGrow={1}>
            <HeaderCell>Field</HeaderCell>
            <InputCell dataKey="field" onChange={handleFieldChange}/>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Passing Value</HeaderCell>
            <InputCell dataKey="value" onChange={handleValueChange}/>
          </Column>
          <Column width={100} align={"center"}>
            <HeaderCell>Action</HeaderCell>
            <RemoveActionCell
              removeHandler={handleRemoveActionClick}
            />
          </Column>
        </Table>
      </Form.Group>
    </>
  );
};

const defaultIdKey = 'id';

const addIds = (data: any, idKey: string = defaultIdKey) =>
  data.map((item: any, index: number) => ({
    ...item,
    [idKey]: index + 1
  }));

const removeIds = (data: any, idKey: string = defaultIdKey) =>
  data.map((item: any,) => {
    const {[idKey]: remove, ...rest} = item;

    return rest;
  });

const getMaxId = (data: object[], idKey: string = defaultIdKey) => Math.max(...data.map((item: any) => item[idKey]));

const InputCell = ({rowData, dataKey, onChange, ...props}: any) =>
  <Cell {...props} dataKey={dataKey} className="editor-cell">
    <Input
      value={rowData[dataKey]}
      onChange={(value) => onChange(value, rowData)}
    />
  </Cell>;

const RemoveActionCell = ({
  rowData,
  removeHandler,
  ...props
}: any) =>
<Whisper
trigger="hover"
placement="top"
speaker={<Tooltip>Remove Row</Tooltip>}>
  <Cell {...props} className="action-cell">
    {removeHandler instanceof Function && <IconButton
      appearance="subtle"
      onClick={(e) => removeHandler(rowData, e)}
      icon={<CloseIcon/>}
    />}
  </Cell>
  </Whisper>;

export default DataPassing;