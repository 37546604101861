const languageList = [
    {
        "name": "Arabic",
        "id": 1019
    },
    {
        "name": "Bengali",
        "id": 1056
    },
    {
        "name": "Bulgarian",
        "id": 1020
    },
    {
        "name": "Catalan",
        "id": 1038
    },
    {
        "name": "Chinese (simplified)",
        "id": 1017
    },
    {
        "name": "Chinese (traditional)",
        "id": 1018
    },
    {
        "name": "Croatian",
        "id": 1039
    },
    {
        "name": "Czech",
        "id": 1021
    },
    {
        "name": "Danish",
        "id": 1009
    },
    {
        "name": "Dutch",
        "id": 1010
    },
    {
        "name": "English",
        "id": 1000
    },
    {
        "name": "Estonian",
        "id": 1043
    },
    {
        "name": "Filipino",
        "id": 1042
    },
    {
        "name": "Finnish",
        "id": 1011
    },
    {
        "name": "French",
        "id": 1002
    },
    {
        "name": "German",
        "id": 1001
    },
    {
        "name": "Greek",
        "id": 1022
    },
    {
        "name": "Gujarati",
        "id": 1072
    },
    {
        "name": "Hebrew",
        "id": 1027
    },
    {
        "name": "Hindi",
        "id": 1023
    },
    {
        "name": "Hungarian",
        "id": 1024
    },
    {
        "name": "Icelandic",
        "id": 1026
    },
    {
        "name": "Indonesian",
        "id": 1025
    },
    {
        "name": "Italian",
        "id": 1004
    },
    {
        "name": "Japanese",
        "id": 1005
    },
    {
        "name": "Kannada",
        "id": 1086
    },
    {
        "name": "Korean",
        "id": 1012
    },
    {
        "name": "Latvian",
        "id": 1028
    },
    {
        "name": "Lithuanian",
        "id": 1029
    },
    {
        "name": "Malay",
        "id": 1102
    },
    {
        "name": "Malayalam",
        "id": 1098
    },
    {
        "name": "Marathi",
        "id": 1101
    },
    {
        "name": "Norwegian",
        "id": 1013
    },
    {
        "name": "Persian",
        "id": 1064
    },
    {
        "name": "Polish",
        "id": 1030
    },
    {
        "name": "Portuguese",
        "id": 1014
    },
    {
        "name": "Punjabi",
        "id": 1110
    },
    {
        "name": "Romanian",
        "id": 1032
    },
    {
        "name": "Russian",
        "id": 1031
    },
    {
        "name": "Serbian",
        "id": 1035
    },
    {
        "name": "Slovak",
        "id": 1033
    },
    {
        "name": "Slovenian",
        "id": 1034
    },
    {
        "name": "Spanish",
        "id": 1003
    },
    {
        "name": "Swedish",
        "id": 1015
    },
    {
        "name": "Tamil",
        "id": 1130
    },
    {
        "name": "Telugu",
        "id": 1131
    },
    {
        "name": "Thai",
        "id": 1044
    },
    {
        "name": "Turkish",
        "id": 1037
    },
    {
        "name": "Ukrainian",
        "id": 1036
    },
    {
        "name": "Urdu",
        "id": 1041
    },
    {
        "name": "Vietnamese",
        "id": 1040
    }
];

export default languageList;