import { Schema } from "rsuite";

export const AD_GROUP_STATUS_ENABLE = 'ENABLE';
export const AD_GROUP_STATUS_DISABLE = 'DISABLE';

export const initialData = {
  operation_status: AD_GROUP_STATUS_DISABLE,
  blocked_pangle_app_ids: [],
  app_list: [],
  app_package_id: '',
  enable_pangle_block_list_app: AD_GROUP_STATUS_DISABLE,
  adgroup_id: 0,
  api_adgroup_id: '',
  traffic_source_id: 277, // to get advertiser_id
  advertiser_id: '',
  campaign_id: '',
  adgroup_name: '',
  promotion_type: 'WEBSITE',
  pixel_id: '',
  optimization_event: '',
  placement_type: 'PLACEMENT_TYPE_AUTOMATIC',
  placements: [],
  search_result_enabled: false,
  creative_material_mode: 'CUSTOM',
  auto_targeting_enabled: false,
  location_ids: ["6252001"],
  languages: [],
  gender: 'GENDER_UNLIMITED',
  age_groups: ["AGE_13_100"],
  operating_systems: "All",
  os_versions: null,
  device_model_ids: [],
  budget_mode: "BUDGET_MODE_DAY",
  budget: null,
  schedule_start_time: null,
  schedule_type: 'SCHEDULE_FROM_NOW',
  optimization_goal: "CONVERT",
  bid_type: "BID_TYPE_CUSTOM", // BID_TYPE_NO_BID
  bid_amount: null,
  billing_event: 'OCPM',
  // Campaign fields
  campaign_budget_optimize_on: 0,
  campaign_budget: 0,
  node_count: false,
};

const { ArrayType, BooleanType, DateType, NumberType, StringType } = Schema.Types;

const AdGroupModel = Schema.Model({
  adgroup_id: NumberType(),
  api_adgroup_id: StringType(),
  advertiser_id: StringType(),
  campaign_id: StringType(),
  adgroup_name: StringType().isRequired("Ad Group Name is required"),
  operation_status: StringType(), // [ENABLE|DISABLE] Default is ENABLE
  enable_pangle_block_list_app: StringType(), // [ENABLE|DISABLE] Default is ENABLE
  promotion_type: StringType(), // External website/WEBSITE
  pixel_id: StringType().isRequired("Pixel ID is required"),
  optimization_event: StringType().isRequired("Optimization Event is required"),
  placement_type: StringType().isRequired("Placement Type is required"),
  // PLACEMENT_TYPE_AUTOMATIC(Automatic placement)
  // PLACEMENT_TYPE_NORMAL(Select placement)
  // Default value is : PLACEMENT_TYPE_NORMAL
  placements: ArrayType(), //  Required when placement_type is PLACEMENT_TYPE_NORMAL
  search_result_enabled: BooleanType(),
  creative_material_mode: StringType(),
  auto_targeting_enabled: BooleanType(),
  location_ids: ArrayType().isRequired("Location is required"),
  languages: ArrayType(),
  gender: StringType(),
  age_groups: ArrayType(),
  operating_systems: StringType(),
  os_versions: StringType(), // used for the min_android_version and the min_ios_version
  device_model_ids: ArrayType(),
  budget_mode: StringType(),
  budget: NumberType(),
  schedule_start_time: DateType(),
  schedule_type: StringType(),
  optimization_goal: StringType(),
  bid_type: StringType(), // ["BID_TYPE_CUSTOM", "BID_TYPE_NO_BID"]
  // It used as value for the bid_price or the conversion_bid_price
  bid_amount: NumberType(),
});

export default AdGroupModel;