import React from "react";
import {Schema, Form, SelectPicker} from "rsuite";
import Field from "../Field";
import HttpClient from "../../@Utils/HttpClient";

const {NumberType, StringType} = Schema.Types;

const model = Schema.Model({
  offer_source_id: NumberType(),
  offer_source_name: StringType()
    .isRequired("Category Name is required."),
  offer_source_category_id: NumberType()
    .isRequired("Category Name is required."),
});

type OfferSourceType = {
  offer_source_id?: number;
  offer_source_name?: string;
  offer_source_category_id?: number;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const OfferSource: React.FC<OfferSourceType> = ({
  offer_source_id = 0,
  offer_source_name = "",
  offer_source_category_id,
  formRef = null,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  const [formValue, setFormValue] = React.useState({
    offer_source_id,
    offer_source_name,
    offer_source_category_id,
  });
  const [formError, setFormError] = React.useState({});
  const [categories, setCategories]: any = React.useState([]);

  // Load category list
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_source_category')
      .then(res => {
        setCategories(res.data);
      });
  }, []);

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={formValue}
      formError={formError}
    >
      <Field label="Offer Source Name" name="offer_source_name"/>
      <Field
        name="offer_source_category_id"
        label="Offer Source Category"
        accepter={SelectPicker}
        data={categories}
        valueKey="offer_source_category_id"
        labelKey="offer_source_category_name"
      />
    </Form>
  );
};

export default OfferSource;
