import React from "react";
import {
  ButtonGroup,
  Col,
  Dropdown,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Popover,
  Row,
  Whisper,
  Toggle
} from "rsuite";
import Title from "../../@Components/Title";
import ManageGroupModal from "./ManageGroups";
import SearchIcon from '@rsuite/icons/Search';
import CloseIcon from '@rsuite/icons/Close';
import PlusRound from "@rsuite/icons/PlusRound";
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ManageGroupIcons from '@rsuite/icons/FolderFill';
import HistoryIcon from '@rsuite/icons/History';
import RuleList from "./RuleList";
import MoreIcon from '@rsuite/icons/More';
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";
import RuleDrawer from "../Drawers/Rule";
import { initialRuleData } from "./RuleModel";
import { getDataForNewRuleType, prepareConditions, ruleTypeList } from "./RuleForm";
import useTaboolaProviderList from "./useTaboolaProviderList";
import {format} from "date-fns";
import RuleHistoryModal from "./RuleHistoryModal";
import RuleHistoryTable from "./RuleHistoryTable";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";

const RuleGrid: React.FC<any> = () => {
  const defaultActivityStatus = 0;
  const [test, setTest] = useStoredValue(LOCAL_STORAGE_KEYS.RULES_PAGE_SHOW_TEST_RULES, defaultActivityStatus);
  const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.RULES_PAGE_SEARCH_QUERY, '');
  const [search, setSearch] = React.useState<string>(searchQuery);
  const removeSearchContent = '';
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [formData, setFormData] = React.useState(initialRuleData);
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [idList, setIdList] = React.useState<any[]>([]);
  const [groups, setGroups] = React.useState<any[]>([]);
  const [ruleId, setRuleId] = React.useState(0);
  const [isFormDataLoading, setIsFormDataLoading] = React.useState(false);
  const taboolaProviders: any = useTaboolaProviderList();
  const [isRuleHistoryModalOpen, setIsRuleHistoryModalOpen] = React.useState(false);

  const [handleManageModalOpen, setHandleManageModalOpen] = React.useState(false);
  const handleOpen = () => setHandleManageModalOpen(true);
  const handleClose = () => setHandleManageModalOpen(false);
  //Removing content from search
  const searchRemoveContent = () => {
    setSearch(removeSearchContent);
    handleSearchChange('');
  };

  const initFormData = {
    rule_id: 0,
    // rule_type: '',
    rule_type: 'block', // TODO Remove after testing
    test: 0,
    active: 0,
    rule_name: 'LexTest Rule N' + format((new Date()), "MM.dd"),
    group_id: 0,
    considering_data_from: 'yesterday',
    exclude_days_from_interval: 'none',
    widget_filter_type: 'include',
    run_interval: '20m',
    after_run_action: 'execute',
    emails: []
  };

  const [formManageData, setFormManageData] = React.useState(initFormData);
  const refresh = () => fetchData();

  const fetchData = () => {
    setLoading(true);

    // Get data
    HttpClient
      .get<any>('rule', {
        query: search,
        ids: idList.join(),
        test: test
      })
      .then(res => {
        setData(formatData(res.data.data));
        setLoading(false);
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        setLoading(false);
      });
  };

  React.useEffect(fetchData, [search, idList, test]);

  // Load rule data
  React.useEffect(() => {
    if (formData.rule_id) {
      setIsFormDataLoading(true);

      HttpClient
        .get<any>(`rule/${formData.rule_id}`)
        .then(res => {
          const rule = res.data.data;

          setFormData({
            ...formData,
            ...rule,
            rule_id: !isNaN(parseInt(rule.rule_id)) ? parseInt(rule.rule_id, 10) : 0,
            conditions: prepareConditions(rule.conditions),
            emails: Array.isArray(rule.emails) ? JSON.stringify(rule.emails) : '[]',
            campaigns: rule.campaigns
              .map((item: any) => !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0)
              .filter((campaign_id: number) => campaign_id > 0),
            groups: rule.groups
              .map((item: any) => !isNaN(parseInt(item.group_id)) ? parseInt(item.group_id) : 0)
              .filter((group_id: number) => group_id > 0),
            widget_ids: rule.widgets
              .map((item: any) => item.widget_id)
              .filter((widget_id: string) => widget_id !== ''),
            ad_groups: rule.ad_groups
              .map((item: any) => !isNaN(parseInt(item.adgroup_id)) ? parseInt(item.adgroup_id) : 0)
              .filter((adgroup_id: number) => adgroup_id !== 0),
            campaignsOutput: rule.campaigns
              .map((item: any) => item.campaign_name || '')
              .filter((campaign_name: string) => campaign_name !== ''),
          });
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        })
        .finally(() => setIsFormDataLoading(false)) ;
    }
  }, [formData.rule_id]);

  const addCalculatedData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,

      };
    });
  };

  const searchByQuery = () => {
    setPage(1);
    setSearch(searchQuery || '');
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      searchByQuery();
    }
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const [checkedKeys, setCheckedKeys] = React.useState<any[]>([]);

  const changeStatusBulk = (ruleIds: number[], checked: boolean) => {
    const activeValue = checked ? 1 : 0;

    // Clone data with changed 'active' field values
    const changedData = data.map((item: any) => {
      return ruleIds.includes(item.rule_id)
        ? {...item, active: activeValue, isUpdating: true} : item;
    });

    setData(changedData);

    // Change status for each selected row
    ruleIds.forEach((ruleId) => {
      const rule = changedData.find((item: any) => item.rule_id === ruleId);

      changeStatus(ruleId, activeValue, rule, () => changeIsUpdating(rule, false));
    });
  };

  const changeIsUpdating = (rule: any, newValue: boolean) => {
    const current = data.findIndex((item: any) => item.rule_id === rule.rule_id);

    rule.isUpdating = newValue;
    data[current] = rule;

    setData([...data]);
  };

  const changeStatus = (id: number, newValue: number, item: object, success: any) => {
    HttpClient
      .put<any>(`rule/${id}`, {...item, active: newValue})
      .then((_res) => {
        success();
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
      });
  };

  const handleActivateMenuItemClick = () => {
    changeStatusBulk(checkedKeys, true);
  };

  const handleDeactivateMenuItemClick = () => {
    changeStatusBulk(checkedKeys, false);
  };

  const handleViewReportMenuItemClick = () => {
    setIdList(checkedKeys);
  };

  const handleShowAllMenuItemClick = () => {
    setIdList([]);
  };

  const handleAfterCreateOrUpdateGridRow = (id: number, item: object) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.rule_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      // Push a new row at the top
      setData([item, ...data]);
    }
    refresh();
  };

  const formatData = (data: any[]) => {
    return data.map((item: any) => ({
      ...item,
      active: !isNaN(parseInt(item.active)) ? parseInt(item.active, 10) : 0,
      test: !isNaN(parseInt(item.active)) ? parseInt(item.active) : 0,
      rule_id: !isNaN(parseInt(item.rule_id)) ? parseInt(item.rule_id, 10) : 0,
      groups: Array.isArray(item.groups) ? item.groups : [],
    }));
  };

  const closeRuleHistoryModal = () => {
    setIsRuleHistoryModalOpen(false);
  };

  const calculatedData = addCalculatedData(data);
    //Filter highlight code
const initialSearchFilter = React.useRef(searchQuery !== '' ? searchQuery: {})
 React.useEffect(() => {


    if (searchQuery === initialSearchFilter.current) {
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }
  }, [searchQuery]);
  return (
    <>
      <Title title="Rules"/>
      <Grid fluid>
        <Row className="show-grid">
          <Col md={2}>
            <ActionsMenu
              isActive={checkedKeys.length > 0}
              selectedIds={idList}
              activateHandler={handleActivateMenuItemClick}
              deactivateHandler={handleDeactivateMenuItemClick}
              viewReportHandler={handleViewReportMenuItemClick}
              showAllHandler={handleShowAllMenuItemClick}
            />
          </Col>
          <Col md={3}>
            <ButtonGroup>
              <IconButton
                color="blue"
                appearance="primary"
                icon={<PlusRound/>}
                onClick={() => {
                  setFormData({...initialRuleData});
                  setIsRuleDrawerOpen(true);
                }}
              >
                New Rule
              </IconButton>
              <Whisper
                placement="bottomEnd"
                trigger="click"
                speaker={({onClose, left, top, className}, ref) => {
                  const handleSelect = (eventKey: any) => {
                    onClose();
                    setFormData({
                      ...initialRuleData,
                      rule_type: eventKey,
                      ...getDataForNewRuleType(eventKey),
                    });
                    setIsRuleDrawerOpen(true);
                  };
                  return (
                    <Popover ref={ref} className={className} style={{left, top}} full>
                      <Dropdown.Menu onSelect={handleSelect}>
                        {ruleTypeList.map((item: any, index) => (
                          <Dropdown.Item key={index} eventKey={item.value}>
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Popover>
                  );
                }}
              >
                <IconButton appearance="primary" icon={<ArrowDownIcon/>}/>
              </Whisper>
            </ButtonGroup>
          </Col>
          <Col md={3}>
            <IconButton
              size="md"
              color="blue"
              appearance="subtle"
              icon={<ManageGroupIcons/>}
              onClick={() => {
                setRuleId(0);
                setFormManageData(initFormData);
                handleOpen();
              }}
            >
              Manage Groups
            </IconButton>
          </Col>
          <Col md={2}>
            <IconButton
              size="md"
              color="blue"
              appearance="subtle"
              icon={<HistoryIcon />}
              onClick={() => {
                setIsRuleHistoryModalOpen(true);
              }}
            >
              View History
            </IconButton>
          </Col>
          {/* Filler */}

          <Col md={3}>
            <Toggle
                style={{marginLeft:20}}
                checked={test == 1}
                onChange={(v:any) =>setTest(v)}
                checkedChildren={'Show test Rules'}
                unCheckedChildren={'Hide test rules'}
                size={"lg"}
              />
          </Col>
          <Col md={3}>
          </Col>
          <Col md={8}>

            <InputGroup style={{width: "100%"}}>
              <InputGroup.Button onClick={searchByQuery}>
                <SearchIcon/>
              </InputGroup.Button>
              <Input
                className="search-filter"
                placeholder="Search"
                value={searchQuery}
                onChange={(v) => handleSearchChange(v)}
                onKeyUp={onKeyUpEnter}
              />
              <InputGroup.Button onClick={searchRemoveContent}>
                <CloseIcon/>
              </InputGroup.Button>
            </InputGroup>
          </Col>

        </Row>
      </Grid>

      <RuleList
        data={calculatedData}
        page={page}
        setPage={setPage}
        loading={loading}
        setIsRuleDrawerOpen={setIsRuleDrawerOpen}
        setFormData={setFormData}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        changeStatus={changeStatusBulk}
        refreshCallback={refresh}
      />
      <ManageGroupModal
        open={handleManageModalOpen}
        groups={groups}
        rules={data}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
      <RuleDrawer
        formData={formData}
        isOpen={isRuleDrawerOpen}
        closeDrawer={() => {
          // Clean form
          setFormData({...initialRuleData});
          // Close drawer
          setIsRuleDrawerOpen(false);
        }}
        onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
        taboolaProviders={taboolaProviders}
        isFormLoading={isFormDataLoading}
      />

      <RuleHistoryModal
        title="Rule Execution History"
        open={isRuleHistoryModalOpen}
        onClose={closeRuleHistoryModal}
      >
        <RuleHistoryTable />
      </RuleHistoryModal>

    </>
  );
};

const ActionsMenu = ({
  isActive,
  selectedIds,
  activateHandler,
  deactivateHandler,
  viewReportHandler,
  showAllHandler,
  ...props
}: any) => {
  const activateKey = "activate";
  const deactivateKey = "deactivate";
  const viewReportKey = "view_report";
  const showAllKey = "show_all";

  const handleSelect = (eventKey: any) => {
    switch (eventKey) {
      case activateKey:
        activateHandler();
        break;

      case deactivateKey:
        deactivateHandler();
        break;

      case viewReportKey:
        viewReportHandler();
        break;

      case showAllKey:
        showAllHandler();
        break;
    }
  };

  return (
    <Dropdown
      title="Actions"
      trigger="hover"
      icon={<MoreIcon style={{fontSize: "1.8em"}}/>}
      onSelect={handleSelect}
      {...props}
    >
      <Dropdown.Item disabled={!isActive} eventKey={activateKey}>Activate</Dropdown.Item>
      <Dropdown.Item disabled={!isActive} eventKey={deactivateKey}>Deactivate</Dropdown.Item>
    </Dropdown>
  );
};

export default RuleGrid;