import {Parser} from "json2csv";
import HttpClient from "./HttpClient";
import {pushErrorNotification} from "./Messager";

/**
 * Export data as a CSV file
 * @param filename
 * @param data
 */
export const exportCsv = (filename: string, data: any) => {
  if (data.length > 0) {
    try {
      const parser = new Parser();
      const csv = parser.parse(data);
      const href = "data:text/csv;charset=utf-8," + encodeURI(csv);

      downloadFile(href, filename);
    } catch (err) {
      console.log(err);
    }
  }
};

/**
 * Export data as a CSV file remotely
 * @param filename File name without extension
 * @param params
 */
export const exportCsvRemotely = (filename: string, params: any) => {
  exportDataRemotely(filename, 'csv', params);
};

/**
 * Export data as a XLSX file remotely
 * @param filename File name without extension
 * @param params
 */
export const exportXlsxRemotely = (filename: string, params: any) => {
  exportDataRemotely(filename, 'xlsx', params);
};

const downloadFile = (href: string, filename: string) => {
  const hiddenElement = document.createElement("a");
  hiddenElement.href = href;
  hiddenElement.target = "_blank";
  hiddenElement.download = filename;
  hiddenElement.click();
};

const exportDataRemotely = (filename: string, type: 'csv' | 'xlsx', params: any) => {
  // Get data
  HttpClient
    .get<any>('export', {
      type,
      ...params,
      file_name: filename,
    })
    .then(res => {
      downloadFile(res.data.fileUrl, `${filename}.${type}`);
    })
    .catch(error => {
      pushErrorNotification(error.response.data?.error || error.toString());
    });
};