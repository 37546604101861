import * as React from "react";
import { Button, ButtonGroup, Form, IconButton, Popover, Table, Toggle, Whisper } from "rsuite";
import MenuIcon from "@rsuite/icons/Menu";
import { CurrencyCell, NumberCell, PercentCell } from "./Table";
import FilterHeaderCell from "./FilterHeaderCell";
import DraggableHeaderCell from "./DraggableHeaderCell";
import { Filter } from "../@Utils/DataProcessing";
import { currency, percent, thousands } from "../@Utils/Format";

const { Column, HeaderCell, Cell } = Table;

/*export type TableColumn = {
  dataKey: string,
  label: string,
  fixed?: boolean,
  width?: number,
  enableHiding?: boolean,
  align?: 'center' | 'left' | 'right',
  renderFn?: (rowData: any) => string;
  exportFormatFn?: (value: any) => string;
  cellCmp?: JSX.Element;
  type?: string, // It's not used when cellCmp is set. Default is 'string'
  isFiltered?: boolean,
};*/

export const formatExportData = (
  data: any[],
  columns: any[]
) => {
  return data
    .map((item: any) => {
        return columns.reduce((row, column) => {
          let value = item[column.dataKey];

          if (column.exportFormatFn) {
            value = column.exportFormatFn(value);
          }

          if (column.type === 'currency') {
            value = currency(value);
          }

          if (column.type === 'number') {
            value = thousands(value);
          }

          if (column.type === 'percent') {
            value = percent(value);
          }

          return {
            ...row,
            [column.label]: value,
          };
        }, {});
      }
    );
};

export const buildColumns = (
  columns: any[],
  visibleColumnKeys: string[],
  orderedColumnKeys: string[],
) => {
  return columns
    .filter((column: any) => visibleColumnKeys.some(key => key === column.dataKey))
    .sort(
      (col1, col2) => {
        const index1 = orderedColumnKeys.indexOf(col1.dataKey);

        if (index1 === -1) {
          return 0;
        }

        const index2 = orderedColumnKeys.indexOf(col2.dataKey);

        return index1 - index2;
      });
}

export const renderColumns = (
  columns: any[],
  handleDragColumn: any, // = (source: string, target: string) => void,
  filters?: Filter[],
  changeHeaderFiltersHandler?: (filters: Filter[]) => void,
) => {
  return columns
    .map((column: any) => {
      const {
        dataKey,
        label,
        type = 'string',
        cellCmp,
        align = 'left',
        isFiltered = false,
        enableHiding,
        renderFn,
        exportFormatFn,
        ...rest
      } = column;
      const CellByType = ((type: string) => {
        switch (type) {
          case 'number':
            return NumberCell;
          case 'percent':
            return PercentCell;
          case 'currency':
            return CurrencyCell;
          // case 'circle':
          //   return CircleCell;
          case 'string':
          default:
            return Cell;
        }
      })(type);

      const CustomCell = cellCmp || CellByType;

      const colProps = {
        ...rest,
        sortable: rest.sortable !== false,
        resizable: rest.resizable !== false,
        align: align === 'center' ? 'center' : (align === 'right' ? 'right' : 'left'),
      };

      const isDraggable = !colProps.fixed;

      const headerCell =
        isDraggable
          ? (
            isFiltered && filters
              ? <FilterHeaderCell onDrag={handleDragColumn} id={dataKey} filters={filters}
                                  setFilters={changeHeaderFiltersHandler}>{label}</FilterHeaderCell>
              : <DraggableHeaderCell onDrag={handleDragColumn} id={dataKey}>{label}</DraggableHeaderCell>
          ) : (
            isFiltered && filters
              ? <FilterHeaderCell filters={filters} setFilters={changeHeaderFiltersHandler}>{label}</FilterHeaderCell>
              : <HeaderCell>{label}</HeaderCell>
          );

      return (
        <Column {...colProps} key={dataKey}>
          {headerCell}
          <CustomCell dataKey={dataKey} style={{lineHeight: '17px'}}>
            {renderFn}
          </CustomCell>
        </Column>
      );
    });
};

// TODO Use TableColumn type instead of any
type TableColumnsMenuProps = {
  columns: any[],
  visibleColumnKeys: string[],
  setVisibleColumnKeys: any;
};

const TableColumnsMenu: React.FC<TableColumnsMenuProps> = ({
  columns = [],
  visibleColumnKeys = [],
  setVisibleColumnKeys = null,
}) => {
  return (
    <Whisper
      placement="bottomEnd"
      trigger="click"
      speaker={
        <Popover style={{minWidth: 200, overflowX: "auto"}}>
          <ButtonGroup justified>
            <Button
              appearance="default"
              onClick={() => setVisibleColumnKeys(columns.filter(column => column.enableHiding === false).map(column => column.dataKey))}
            >
              Hide All
            </Button>
            <Button
              appearance="default"
              onClick={() => setVisibleColumnKeys(columns.map(column => column.dataKey))}
            >
              Show all
            </Button>
          </ButtonGroup>
          <hr/>
          <Form layout="horizontal" style={{maxHeight: 300}}>
            {columns.map((column: any) => {
              const {dataKey, label, enableHiding} = column;

              return (
                <Form.ControlLabel
                  style={{
                    display: 'block',
                    paddingLeft: 5,
                    cursor: enableHiding === false ? 'not-allowed' : 'pointer'
                  }} key={dataKey}
                >
                  <Toggle
                    size="md"
                    style={{display: "inline-block", width: 75, paddingTop: 5}}
                    checked={visibleColumnKeys.some((key: string) => key === column.dataKey)}
                    disabled={enableHiding === false}
                    checkedChildren="Show"
                    unCheckedChildren="Hide"
                    onChange={(checked) => {
                      if (checked) {
                        // Show
                        setVisibleColumnKeys([...visibleColumnKeys, column.dataKey]);
                      } else {
                        // Hide
                        setVisibleColumnKeys(visibleColumnKeys.filter((key: string) => key !== column.dataKey));
                      }
                    }
                    }
                  />
                  {label}
                </Form.ControlLabel>
              );
            })}
          </Form>
        </Popover>
      }
    >
      <IconButton icon={<MenuIcon/>}/>
    </Whisper>
  );
};

export default TableColumnsMenu;
