import React from "react";
import {
    Button,
    CheckTree,
    FlexboxGrid,
    IconButton,
    Loader,
    Modal,
    Panel, SelectPicker,
} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";
import CheckIcon from "@rsuite/icons/Check";
import RemindFillIcon from '@rsuite/icons/RemindFill';
import {format} from "date-fns";

type ManageAccessDomainsProps = {
    open: boolean;
    handleClose: any;
    handleOpen: any;
    trafficSourceKeys: any;
    refreshCallback?: () => void;
};

const ManageAccessDomains: React.FC<ManageAccessDomainsProps> = ({
                                                       open,
                                                       handleClose,
                                                       trafficSourceKeys,
                                                       refreshCallback,
                                                   }) => {
    const [users, setUsers] = React.useState<Record<string, any>[]>([]);
    const [groups, setGroups] = React.useState<Record<string, any>[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<any>();
    const [selectedGroups, setSelectedGroups] = React.useState<number[]>([]);
    const [isUsersLoading, setIsUsersLoading] = React.useState<boolean>(false);
    const [isGroupsLoading, setIsGroupsLoading] = React.useState<boolean>(false);
    const [isMappingLoading, setIsMappingLoading] = React.useState<boolean>(false);
    const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState<boolean>(false);
    const [isDifferentAccessMappingsConfirmationOpen, setIsDifferentAccessMappingsConfirmationOpen] = React.useState<boolean>(false);

    const handleGroupsOnChange = (values: (string | number)[]) =>
        setSelectedGroups(
            values.map((item: string | number) => convertToInt(item))
        );

    // const handleUsersOnChange = (values: (string | number)[]) =>
    //     setSelectedUsers(
    //         values.map((item: string | number) => convertToInt(item))
    //     );
    const handleUsersOnChange = (values: (number)) => {
        setSelectedUsers(convertToInt(values))
    }
        // setSelectedUsers(
        //     values.map((item: string | number) => convertToInt(item))
        // );

    const handleSave = () => {
        setIsSaveButtonLoading(true);
        if (!isNaN(selectedUsers) && selectedUsers !== undefined) {

            HttpClient
                .put<any>('access_system1_domain_mapping', {
                    domain_name: trafficSourceKeys,
                    mapping: selectedUsers !== -1 ? [
                        // ...selectedGroups.map((id: number) => ({group_id: id})),
                        // ...selectedUsers.map((id: number) => ({user_id: id})),
                        {user_id: selectedUsers},
                    ] : []
                })
                .then((res) => {
                    handleClose();

                    if (refreshCallback instanceof Function) {
                        refreshCallback();
                    }
                }).catch(error => {
                pushErrorNotification(error.response.data?.error || error.toString());
            })
                .finally(() => {
                    setIsSaveButtonLoading(false);
                });
        } else {
            pushErrorNotification("Please select a user to save");
            setIsSaveButtonLoading(false);
        }

    };

    const handleCloseModal = () => {
        // setRemoveIds([]);
        handleClose();
    };

    React.useEffect(() => {
        if (open) {
            setIsUsersLoading(true);
            setIsGroupsLoading(true);

            // Get users
            HttpClient
                .get<any>('access_user')
                .then(res => {
                    const data = res.data.data;

                    const formatUsers =  data.map((item: any) => ({...item, user_id: convertToInt(item.user_id)}))

                    formatUsers.push({
                        active: '1',
                        email: 'Unassign',
                        user_id: -1
                    })

                    setUsers(formatUsers)
                    // setUsers(
                    //     data.map((item: any) => ({...item, user_id: convertToInt(item.user_id)}))
                    // );
                    setIsUsersLoading(false);
                }).catch(error => {
                pushErrorNotification(error.response.data?.error || error.toString());
                setIsUsersLoading(false);
            });

            // Get groups
            HttpClient
                .get<any>('access_group')
                .then(res => {
                    const data = res.data.data;

                    setGroups(
                        data.map((item: any) => ({...item, group_id: convertToInt(item.group_id)}))
                    );
                    setIsGroupsLoading(false);
                }).catch(error => {
                pushErrorNotification(error.response.data?.error || error.toString());
                setIsGroupsLoading(false);
            });
        }
    }, [open]);

    React.useEffect(() => {
        if (open) {
            setIsMappingLoading(true);

            // Get users
            HttpClient
                .get<any>('access_system1_domain_mapping', {
                    domain_name: trafficSourceKeys,
                })
                .then(res => {
                    const data = res.data.data;

                    setIsMappingLoading(false);

                    const areMappingIdentical = (Array.isArray(data) && data.length === 1)
                        ? true : checkIsMappingIdentical(data);

                    // Show message if mappings are not the same
                    if (!areMappingIdentical) {
                        setIsDifferentAccessMappingsConfirmationOpen(true);
                    }

                    // If mappings are the same we can get the first one
                    const mappings = areMappingIdentical ? data[0] : [];

                    setSelectedGroups(mappings
                        .filter((item: any) => item.group_id !== null && item.group_id !== '')
                        .map((item: any) => convertToInt(item.group_id))
                    );

                    const userID = mappings
                        .filter((item: any) => item.user_id !== null && item.user_id !== '')
                        .map((item: any) => convertToInt(item.user_id))

                    setSelectedUsers(userID[0]);
                }).catch(error => {
                pushErrorNotification(error.response.data?.error || error.toString());
                setIsMappingLoading(false);
            });
        }
    }, [open]);

    const handleDifferentAccessMappingsConfirmationClose = () => setIsDifferentAccessMappingsConfirmationOpen(false);
    const handleDifferentAccessMappingsConfirmationCancel = () => {
        handleClose();

        setIsDifferentAccessMappingsConfirmationOpen(false);
    }

    const isFormLoading = isUsersLoading || isGroupsLoading || isMappingLoading;

    return (
        <>
            <Modal
                size="xs"
                overflow
                open={open}
                onClose={handleCloseModal}
                dialogClassName={'modal-group'}
            >
                <Modal.Header>
                    <Modal.Title as={'h4'} classPrefix={'modal-title'}>Assign Manager</Modal.Title>
                </Modal.Header>
                <hr/>
                <Modal.Body>
                    <Panel bodyFill>

                        <FlexboxGrid>
                            {/*<FlexboxGrid.Item colspan={12} style={{minHeight: 190}}>*/}
                            {/*    <h5>Groups</h5>*/}
                            {/*    {(isGroupsLoading || isMappingLoading) ?*/}
                            {/*        <Loader size="lg" center /> :*/}
                            {/*        <CheckTree*/}
                            {/*            defaultExpandAll*/}
                            {/*            data={groups}*/}
                            {/*            value={selectedGroups}*/}
                            {/*            labelKey="group_name"*/}
                            {/*            valueKey="group_id"*/}
                            {/*            onChange={handleGroupsOnChange}*/}
                            {/*        />*/}
                            {/*    }*/}
                            {/*</FlexboxGrid.Item>*/}
                            <FlexboxGrid.Item colspan={24} style={{minHeight: 190}}>
                                <h5>Users</h5>
                                {(isUsersLoading || isMappingLoading) ?
                                    <Loader size="lg" center /> :
                                    // <CheckTree
                                    //     defaultExpandAll
                                    //     data={users}
                                    //     value={selectedUsers}
                                    //     labelKey="email"
                                    //     valueKey="user_id"
                                    //     onChange={handleUsersOnChange}
                                    // />
                                    <SelectPicker
                                        size="lg"
                                        searchable={true}
                                        cleanable={true}
                                        placeholder="Select User"
                                        // defaultValue={defaultTypeValue}
                                        value={selectedUsers}
                                        data={users}
                                        valueKey="user_id"
                                        labelKey="email"
                                        onChange={(v) => {
                                            handleUsersOnChange(v);
                                        }}
                                        style={{ marginRight: 15, minWidth: '100%' }}
                                    />
                                }
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                </Modal.Body>
                <Modal.Footer className="show-container">
                    <IconButton
                        size="lg"
                        color="blue"
                        appearance="primary"
                        icon={<CheckIcon/>}
                        onClick={handleSave}
                        disabled={isFormLoading}
                        loading={isSaveButtonLoading}
                    >
                        Save
                    </IconButton>
                </Modal.Footer>
            </Modal>

            <Modal
                backdrop="static"
                role="alertdialog"
                open={isDifferentAccessMappingsConfirmationOpen}
                onClose={handleDifferentAccessMappingsConfirmationClose}
                size="xs"
            >
                <Modal.Body>
                    <RemindFillIcon style={{ color: '#ffb300', fontSize: 24 }} />
                    These domains have different access mappings and won’t be displayed.<br/>
                    Any changes made will overwrite the current configuration. Proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDifferentAccessMappingsConfirmationClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleDifferentAccessMappingsConfirmationCancel} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const convertToInt = (value: string | number) => typeof value === 'number' ? value : parseInt(value, 10);

// Check for identity of mappings for multiple traffic sources
const checkIsMappingIdentical = ((allMappings: any[]) => {
    // Check that each traffic source has an array of mappings
    if (allMappings.some((item: any) => !Array.isArray(item))) {
        return false;
    }

    const mappingCount = allMappings[0].length;

    // Check that each traffic source has the same number of mappings
    if (allMappings.some((item: any) => item.length !== mappingCount)) {
        return false;
    }

    // Check that each traffic source has the same mappings
    const firstMappingList = allMappings[0];

    return allMappings.every((mappingList: any[]) =>
        firstMappingList.every((firstMapping: any) =>
            mappingList.some((mapping: any) =>
                mapping.email === firstMapping.email
                && mapping.active === firstMapping.active
                && mapping.group_id === firstMapping.group_id
                && mapping.user_id === firstMapping.user_id
            )
        )
    );
});

export default ManageAccessDomains;