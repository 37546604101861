import React from "react";
import { Nav, Sidebar, Sidenav, Button, Loader } from "rsuite";
import { useLocation } from "react-router-dom";
import DashboardIcon from '@rsuite/icons/Dashboard';
import DetailIcon from '@rsuite/icons/Detail';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import PeoplesIcon from '@rsuite/icons/Peoples';
import FunnelIcon from '@rsuite/icons/Funnel';
import EventDetailIcon from '@rsuite/icons/EventDetail';
import PauseOutlineIcon from '@rsuite/icons/PauseOutline';
import RuleIcon from '@rsuite/icons/RemindFill';
import GlobalIcon from '@rsuite/icons/Global';
import TableIcon from '@rsuite/icons/Table';
import SettingHorizontalIcon from '@rsuite/icons/SettingHorizontal';
import TrendIcon from '@rsuite/icons/Trend';
import SiteIcon from '@rsuite/icons/Site';
import WavePointIcon from '@rsuite/icons/WavePoint';
import CreativeIcon from '@rsuite/icons/Creative';
import IconNavLink from "./IconNavLink";
import { FirebaseAuthContext } from "../../../@Context/FirebaseAuthContext"
import { useUserPermissions } from "../../../@Context/UserPermissionsContext";

interface SideBarProps {
  styles?: any;
}

const header_styles = {
  // padding: 18,
  fontSize: 16,
  height: 50,
  background: "#021b3b",
  color: " #fff",
  whiteSpace: "nowrap" as const,
  overflow: "hidden",
};

const SideBar: React.FC<SideBarProps> = (props) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isExpanded, setIsExpanded] = React.useState(true);
  const Firebase = React.useContext(FirebaseAuthContext);
  const {isLoading} = useUserPermissions();

  const activeKey = '1';
  const style = {
    ...props.styles,
    width: isExpanded ? 260 : 54,
    height: '100vh',
    overflow: 'auto'
  };
  const iconProps = { style: { fontSize: "2em", color: '#FFF' } };
  const navProps = { currentRoute, isExpanded };

  const logoutUser = () => {
    Firebase.logout();
    window.location.reload();
  };

  return (
    <div>
      <Sidebar
        //     className="side-navigation"
        style={style}
        collapsible
      >
        {/* <Toggle
          onChange={setIsExpanded}
          checked={isExpanded}
          style={{marginLeft: 5, marginTop: 10}}
        /> */}
        <div className={'nav-head-container'}>
          {isExpanded &&
            <Sidenav.Header>
              <div style={header_styles}>
                <DashboardIcon style={{ fontSize: "2em", marginLeft: 10, marginTop: 10 }} /><span
                  style={{ marginLeft: 5 }}>AdMediary</span>

              </div>

            </Sidenav.Header>
          }
        </div>
        <div>
          <hr />
          <div id='nav'>
            <Sidenav
            // isExpanded={isExpanded}
            // defaultOpenKeys={['3', '4']}
            // style={{
            //
            //     width: isExpanded ? 260 : 54
            // }}
            // onSelect={setActiveKey}

            >
              <Sidenav.Body>
                {(isLoading) ?
                  <Loader size="md" center content="loading..." inverse />
                  :
                <Nav pullRight className={'menu1'} activeKey={activeKey}>
                  <IconNavLink link={"offer-sources"} title={"Offer Sources"} icon={<DashboardIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/offers"} title={"Offers"} icon={<DetailIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/traffic-sources"} title={"Traffic Sources"}
                    icon={<SingleSourceIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/campaigns"} title={"Campaigns"}
                    icon={<PeoplesIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/funnels"} title={"Funnels"} icon={<FunnelIcon {...iconProps} />} {...navProps} />
                  {/*<Nav.Menu icon={<EventDetailIcon />} title="Reports">*/}
                    <IconNavLink link={"/tonic-keyword-report"} title={"Tonic Keyword Report"} icon={<EventDetailIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/rules"} title={"Rules"}
                               icon={<RuleIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/tiktok-campaign-report"} title={"TikTok Campaign Report"}
                               icon={<PauseOutlineIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/tiktok-subid-mapping"} title={"DomainMappingTool"}
                               icon={<GlobalIcon {...iconProps} />} {...navProps} />
                  <IconNavLink link={"/tiktok-domain-direct-summary"} title={"TikTok Domain Direct Summary"}
                               icon={<TableIcon {...iconProps} />} isPublic={true} {...navProps} />
                  <IconNavLink link={"/domains"} title={"Domains"}
                               icon={<SettingHorizontalIcon {...iconProps} />} {...navProps} />
                    <IconNavLink link={"/url-management"} title={"Landing Page Domains"}
                                 icon={<SiteIcon {...iconProps} />} {...navProps} />
                    <IconNavLink link={"/keywords"} title={"Google Keywords"}
                                 icon={<TrendIcon {...iconProps} />} {...navProps} />
                    <IconNavLink link={"/keyword-stats"} title={"Keywords Stats"}
                                 icon={<CreativeIcon {...iconProps} />} {...navProps} />
                    <IconNavLink link={"/media-buyer-report"} title={"Media Buyer Report"}
                                 icon={<WavePointIcon {...iconProps} />} {...navProps} />
                  {/*</Nav.Menu>*/}
                </Nav>
                }
              </Sidenav.Body>
              <Button
                onClick={logoutUser}
                size="sm"
                style={{
                  bottom: 20,
                  position: 'absolute',
                  display: 'inline-block',
                  left: 0,
                  right: 0,
                  width: '50%',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>Sign Out</Button>
            </Sidenav>
          </div>

        </div>

      </Sidebar>
    </div>);
}

export default SideBar;