import React from "react";
import {Schema, Table, Whisper, Tooltip, IconButton, Pagination, FlexboxGrid} from "rsuite";
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import ButtonToolbar from "rsuite/ButtonToolbar";
import RemoveIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import PlusIcon from "@rsuite/icons/Plus";
const { Column, HeaderCell, Cell } = Table;


const {NumberType, StringType} = Schema.Types;

const model = Schema.Model({
    offer_source_category_id: NumberType(),
    offer_source_category_name: StringType()
        .isRequired("Category Name is required."),
});

type AddAppType = {
    data: any,
    setData: any,
    load: any,
    formRef?: React.MutableRefObject<any>;
    parentCallback?: any;
};

const DEFAULT_PAGE = 1;
const MIN_LIMIT = 15;
const DEFAULT_LIMIT = 50;
const limitOptions = [MIN_LIMIT, 30, DEFAULT_LIMIT, 100];
const paginateData = (data: any[], page: number = DEFAULT_PAGE, limit: number = MIN_LIMIT) =>
    data.slice((page - 1) * limit, page * limit) || [];
const AddAppForm: React.FC<AddAppType> = ({
    data,
    setData,
    load,
    formRef = null,
    parentCallback = (p: any) => {}
}) => {

    const handleSetLimit = (limit: any) => {
        setPage(DEFAULT_PAGE);
        setLimit(limit);
    };


    const [page, setPage] = React.useState(DEFAULT_PAGE);
    const [limit, setLimit] = React.useState(DEFAULT_LIMIT);

    const handleEditState = (id:any) => {
        const nextData = Object.assign([], data);
        const activeItem = nextData.find((item:any) => item.id === id);
        activeItem.status = activeItem.status ? null : 'EDIT';
        setData(nextData);
    };
    const handleChange = (id:any, key:any, value:any) => {
        const nextData = Object.assign([], data);
        nextData.find((item: any) => item.id === id)[key] = value;
        nextData.find((item: any) => item.id === id)['label'] = value;
        setData(nextData);
        if (typeof parentCallback === "function" && data.find((item: any) => item.id === id)['value'] == value) {
            parentCallback(nextData.map(function (obj:any) {
                return obj.value;
            }));
        }
    };
    const handleRemoveClick = (rowData: any) => {
        const filtered = data.filter((item: any) => item.id !== rowData.id);
        setData(filtered);

        if (typeof parentCallback === "function") {
            parentCallback(filtered.map(function (obj:any) {
                return obj.value;
            }));
        }
    };
    const defaultIdKey = 'id';
    const getMaxId = (data: object[], idKey: string = defaultIdKey) => data.length > 0 ? Math.max(...data.map((item: any) => item[idKey])) : 0;
    const handleAddItemClick = () => {
        const maxId = getMaxId(data);
        const newItem = {
            id: maxId + 1,
            status: "EDIT",
            label: "",
            value: "",
        };
        console.log('handleAddItemClick', data);
        setData([newItem, ...data]);
        if (typeof parentCallback === "function" && newItem.value ) {

            parentCallback([newItem, ...data].map(function (obj:any) {
                return obj.value;
            }));
        }
    };
    return (
        <>
        <FlexboxGrid justify="space-between" style={{marginBottom: 15}}>
            <FlexboxGrid.Item colspan={24}>
                    <IconButton
                        onClick={handleAddItemClick}
                        icon={<PlusIcon/>}
                        placement="left"
                        size="sm"
                        appearance="ghost"
                        style={{marginRight: 15}}
                    >
                        Add New App
                    </IconButton>
            </FlexboxGrid.Item>
            </FlexboxGrid>
        <Table
            height={420}
            rowHeight ={50}
            shouldUpdateScroll={false}
            data={paginateData(Array.isArray(data) ? data : [],page,limit)}
            loading={load}
            virtualized
            rowClassName="striped-rows"
            affixHeader
            affixHorizontalScrollbar
        >
            <Column width={450}>
                <HeaderCell>App Name</HeaderCell>
                <EditableCell dataKey="value" onChange={handleChange} />
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>...</HeaderCell>
                <ActionCell
                    dataKey="id"
                    onClick={handleEditState}
                    removeHandler={handleRemoveClick}
                />
            </Column>
        </Table>
            <div style={{ padding: 10 }}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={["total", "-", "limit", "|", "pager", "skip"]}
                    total={data.length}
                    limitOptions={[10, 20]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleSetLimit}
                />
            </div>
        </>
    );
};
const EditableCell = ({ rowData, dataKey, onChange, ...props }:any) => {

    const editing = rowData.status === 'EDIT';
    return (
        <Cell {...props} className={editing ? 'table-content-editing' : ''}>
            {editing ? (
                <input
                    className="rs-input"
                    defaultValue={rowData[dataKey]}
                    onChange={event => {
                        onChange && onChange(rowData.id, dataKey, event.target.value);
                    }}
                />
            ) : (
                <span className="table-content-edit-span">{rowData[dataKey]}</span>
            )}
        </Cell>
    );
};

const ActionCell = ({ rowData, dataKey, onClick,   removeHandler, ...props }:any) => {
    const buttonStyle = {marginLeft: '6px'};
    return (
        <Cell {...props} style={{ padding: '6px' }}>
            <div>
                <ButtonToolbar>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Edit</Tooltip>}
                    >
                        <IconButton
                            icon={rowData.status === 'EDIT' ? <CheckRoundIcon/> : <EditIcon/>}
                            onClick={() => {
                                onClick(rowData.id);
                            }}
                        />

                    </Whisper>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Remove</Tooltip>}
                    >
                        <IconButton
                            icon={<RemoveIcon style={{color: "red"}}/>}
                            onClick={(e) => removeHandler(rowData, e)}
                            size='sm'
                            style={buttonStyle}
                        />
                    </Whisper>
                </ButtonToolbar>
            </div>
        </Cell>
    );
};
export default AddAppForm;
