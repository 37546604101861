import React from "react";
import Dashboard from "../@Components/Dashboard";
import CampaignGrid from "../@Components/TikTok/CampaignGrid";

const TiktokCampaignReport: React.FC = () => {
  return (
    <Dashboard>
      <CampaignGrid />
    </Dashboard>
  );
};

export default TiktokCampaignReport;