import { useCallback } from "react";
import { useUserPermissions } from "../@Context/UserPermissionsContext";

const useShowAllowedValues = () => {
  const {isLoading, permissions} = useUserPermissions();

  return useCallback((resource: string, action: string, values: any[], key: string) => {
    if (isLoading) return [];

    // At first checked denied resources
    const deniedResource = permissions.find((item: any) => {
      return item.type !== undefined && item.type === 'deny'
        && (item.resource === resource)
        && (item.action === undefined
          || (Array.isArray(item.action) && item.action.includes(action))
          || item.action === action || item.action === '*'
          )
        && item.value_list !== undefined;
    });

    // console.log('deniedResource: ', deniedResource);
    // console.log('deniedResource.values:', deniedResource?.value_list);

    if (deniedResource) {
      return values.filter((item: any) => !deniedResource.value_list.includes(item[key]));
    }

    const allowedResource = permissions.find((item: any) => {
        return (item.type === undefined || item.type === 'allow')
          && (item.resource === resource)
          && (item.action === undefined
            || (Array.isArray(item.action) && item.action.includes(action))
            || item.action === action || item.action === '*'
          )
          && item.value_list !== undefined;
      });

    // console.log('allowedResource: ', allowedResource);
    // console.log('allowedResource.values:', allowedResource?.value_list);

    if (allowedResource) {
      return values.filter((item: any) => allowedResource.value_list.includes(item[key]));
    }

    // console.log('Returns unchanged values');

    return values;
  }, [isLoading, permissions]);
};

export default useShowAllowedValues;
