import {Table, Checkbox} from "rsuite";
import { currency, float, percent, thousands } from "../@Utils/Format";
import React from "react";

const { Cell } = Table;

export const ParentCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell
    {...props}
    className={rowData.entry_date && "adm-rs-tree-cell-parent-date"}
  >
    {rowData[dataKey]}
  </Cell>
);
export const HourCell = ({ rowData, dataKey, ...props }: any) => {
  const hours =
    parseInt(rowData[dataKey]) < 10 ? "0" + rowData[dataKey] : rowData[dataKey];
  return <Cell {...props}>{hours + ":00"}</Cell>;
};

export const NumberCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props}>{thousands(rowData[dataKey])}</Cell>
);

export const FloatCell = ({
  rowData,
  dataKey,
  digits = 2,
  postfix = "",
  ...props
}: any) => {
  return <Cell {...props}>{float(rowData[dataKey], digits) + postfix}</Cell>;
};

export const CurrencyCell = ({ rowData, dataKey, digits = 2, ...props }: any) => (
  <Cell {...props}>{currency(rowData[dataKey] || 0, digits)}</Cell>
);

export const PercentCell = ({
  rowData,
  dataKey,
  digits = 1,
  ...props
}: any) => <Cell {...props}>{percent(rowData[dataKey], digits)}</Cell>;

export const CheckCell = ({ rowData, dataKey, ...props }: any) => (
    <Cell {...props}>
      {/*<Checkbox checked={parseInt(rowData[dataKey]) === 1 ? ['1'] : ['0']}*/}
      <Checkbox checked={parseInt(rowData[dataKey]) === 1}

      />
    </Cell>
);

/*export const ToolTipCell = ({
  rowData,
  dataKey,
  content = "",
  ...props
}: any) => (
  <Cell {...props}>
    <Whisper
      trigger="hover"
      placement={dataKey === "sms_message" ? "topStart" : "auto"}
      speaker={
        <Tooltip style={{ width: 400 }}>{content || rowData[dataKey]}</Tooltip>
      }
    >
      <span>{rowData[dataKey]}</span>
    </Whisper>
  </Cell>
);*/

/*export const CheckCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props}>
    <Icon icon={parseInt(rowData[dataKey]) === 1 ? "check-square-o" : "square-o"}/>
  </Cell>
);*/
