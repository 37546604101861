import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Form,
  Grid,
  IconButton,
  Panel,
  PanelGroup,
  Row,
  Schema,
  SelectPicker,
  TagPicker,
  Whisper,
  Tooltip
} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager"
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "../OfferSources/EditModal";
import Campaign from "../Funnels/Campaign";
import { FormInstance } from "rsuite/Form";
import moment from 'moment';
import OfferSliderTree from "./OfferSliderTree";
import FunnelCategory from "./FunnelCategory";

const API_NAME_TABOOLA = "taboola";

type Offer = {
  funnel_node_id: number;
  offer_id: number;
  funnel_id: number;
  name: string;
  locked: boolean;
  offer_split: number;
}

type FunnelProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  onChangeCampaign?: any;
  parentCallback?: any;
  onChangeCategory?: any;
  onChangeFunnelNodes?: any;
};

const FunnelForm: React.FC<FunnelProps> = ({
  formValue,
  formRef = null,
  onChangeCampaign,
  onChangeCategory,
  onChangeFunnelNodes,
  parentCallback = () => { },
}) => {
  formRef = formRef || React.createRef();
  const [formError, setFormError] = React.useState<any>({});
  const [categories, setCategories]: any = React.useState([]);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [categoryFormValue, setCategoryFormValue] = React.useState({});
  const [offerList, setOfferList]: any = React.useState([]);
  const [offerSearchValue, setOfferSearchValue] = React.useState('');
  const [offerSliderError, setOfferSliderError] = React.useState('');
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  const saveCampaign = (data: any) => {
    // Set default values
    data = {
      ...data,
      campaign_id: 0,
      objective: '',
      client_id: '',
      client_secret: '',
      description: '',
      api_campaign_id: '',
      unique_window: 1,
      active: 1,
      set_campaign_budget: 0,
      budget_optimize_on: 0,
      budget_amount: 0,
      budget_type: '',
      bid_amount: null,
      bid_strategy: "FIXED",
      end_date: null,
      spending_limit: null,
      locations_type: 'all',
      locations: '[]',
      locations_subtype: 'all',
      locations_sub: '[]',
      device_type: JSON.stringify(['desktop', 'tablet', 'mobile']),
      os_target: 'all',
      os: '[]',
      browser_target: 'all',
      browser: '[]',
      spending_limit_type: "MONTHLY",
      creative_traffic_allocation: "EVEN",
      creative_traffic_allocation_start: null,
      creative_traffic_allocation_end: null,
      daily_ad_delivery_type: "STRICT",
      daily_cap: null,
    };

    setSaveButtonLoading(true);

    HttpClient.post<object, any>('campaign', data)
      .then((res) => {
        const campaignId = parseInt(res.data.campaign_id);

        // Add created campaign to the selector list
        setCampaigns([...campaigns, {
          ...campaignFormValue,
          campaign_id: campaignId,
        }]);

        changeCampaign(campaignId, data.campaign_name);
        closeCampaignEditModal();
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const changeCampaign = (campaignId: number, campaignName: string = '', trafficSourceType = '') => {
    if (typeof onChangeCampaign === "function") {
      onChangeCampaign(campaignId, campaignName, trafficSourceType);
    }
  };

  const getOfferSliderSum = (newOffers: Offer[], ignoreUnlockedSliders = false) => {
    let sum = 0;
    newOffers.forEach((offer: Offer) => {
      if (offer.locked || !ignoreUnlockedSliders) {
        sum += offer.offer_split;
      }
    });
    return sum;
  };

  const getOfferSliderCount = (newOffers: Offer[], ignoreLockedSliders = false) => {
    let count = 0;
    newOffers.forEach((offer: Offer) => {
      count += (!offer.locked || !ignoreLockedSliders) ? 1 : 0
    });
    return count;
  };

  const equalizeOfferSliders = (newOffers: Offer[], sum = 0) => {
    sum = 100 - sum;
    const count = getOfferSliderCount(newOffers, true);

    return newOffers.map((offer: Offer) => {
      //If unedited, then adjust for new value
      if (!offer.locked) {
        offer.offer_split = Number((sum / count).toFixed(1));

        //set constraints between 0 and sum
        if (offer.offer_split < 0) {
          offer.offer_split = 0;
        }
      }
      return offer;
    });
  };

  const addFunnelNode = (val: string, item: any) => {

    if (item) {

      if (formValue.offers.find((offer: Offer) => offer.offer_id == item.value)) {
        pushErrorNotification("Offer Already Exists in List");
        return;
      }

      let newOffers: Offer[] = [
        ...formValue.offers,
        {
          funnel_node_id: 0,
          offer_id: item.value,
          offer_name: item.label,
          offer_split: 0,
          locked: false
        }
      ];

      let newFormValue = { ...formValue, 'offers': equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true)) };
      setFormValue(newFormValue);
    }
    setOfferSearchValue('');
  };

  const updateOfferSliders = (sliderOffer: any, value: number) => {
    if (!sliderOffer.locked) {
      let sum = 100 - getOfferSliderSum(formValue.offers, true);

      sum = Math.round(sum * 10) / 10;  //Set precision to 3 sigfigs
      value = value > 0 ? value : 0;  //No negative values

      let newOffers = formValue.offers.map((offer: Offer) => {
        if (offer.offer_id == sliderOffer.offer_id) {
          offer.offer_split = value;

          if (offer.offer_split > sum) { //Top out the slider at max sum
            offer.offer_split = sum;
          }
          offer.locked = true;  //Lock updated slider so equalize doesn't change it.
        }
        return offer;
      });

      //Moves all the unlocked sliders to accommodate the change.
      newOffers = equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true));

      //If they do not sum to 100, update current slider to make up difference
      if (getOfferSliderCount(newOffers, true) != 0) {
        let newSum = getOfferSliderSum(newOffers);
        sliderOffer.offer_split = (100 - newSum) + sliderOffer.offer_split;
        sliderOffer.offer_split = Math.round(sliderOffer.offer_split * 10) / 10;
        sliderOffer.offer_split = sliderOffer.offer_split > 0 ? sliderOffer.offer_split : 0;
      }

      //Unlock the updated slider
      let newFormValue = {
        ...formValue, 'offers': newOffers.map((offer: Offer) => {
          if (offer.offer_id == sliderOffer.offer_id) {
            offer.locked = false;
            offer.offer_split = sliderOffer.offer_split;
          }
          return offer;
        })
      };

      setFormValue(newFormValue);
      checkFunnelNodeSum(newFormValue.offers);
    }
  }

  const checkFunnelNodeSum = (offers: Offer[]) => {
    let newSum = getOfferSliderSum(offers);
    onChangeFunnelNodes(newSum);
    if (newSum < 99.8) {
      setOfferSliderError('*Offer Sliders Should Sum to 100%');
    } else {
      setOfferSliderError('');
    }
  }

  const toggleSliderLock = (offer: Offer) => {
    let newFormValue = {
      ...formValue, 'offers': formValue.offers.map((j: Offer) => {
        if (j.offer_id == offer.offer_id) {
          j.locked = !j.locked;
        }
        return j;
      })
    };
    setFormValue(newFormValue);
  }

  const removeOfferSlider = (offer: Offer) => {
    let newOffers = formValue.offers.filter((i: Offer) => {
      return i.offer_id != offer.offer_id;
    });

    let newFormValue = { ...formValue, 'offers': equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true)) };
    setFormValue(newFormValue);
  }

  const [campaignEditModalOpen, setCampaignEditModalOpen] = React.useState(false);
  const closeCampaignEditModal = () => {
    setCampaignEditModalOpen(false);
  };

  const handleCampaignEditModalClose = () => {
    closeCampaignEditModal();
  };

  const handleCampaignSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && node.check()) {
      saveCampaign(campaignFormValue);
    }
  };

  const handleCampaignCallback = (formValue: any) => {
    setCampaignFormValue(formValue);
  };

  // Load campaigns list
  const [campaigns, setCampaigns]: any = React.useState([]);

  React.useEffect(() => {
    let newFormValue = {
      ...formValue, 'offers': formValue.offers.map((offer: any) => {
        if (typeof (offer.locked) === 'string') {
          offer.locked = offer.locked === '1';
        }
        if (typeof (offer.offer_split) === 'string') {
          offer.offer_split = parseFloat(offer.offer_split.replace(/^0+/, ""));
        }
        return offer;
      })
    };
    setFormValue(newFormValue);

    HttpClient
      .get<any>('campaign', {
        traffic_source_type: 'tiktok',
        is_plain_list: 1,
      })
      .then(res => {
        setCampaigns(res.data.data.map((item: any) => ({
          ...item,
          campaign_id: !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0
        })));
      });

    HttpClient
      .get<any>('funnel_category')
      .then(res => {
        setCategories(res.data.map((item: any) => ({
          ...item,
          funnel_category_id: isNaN(parseInt(item.funnel_category_id))
            ? 0 : parseInt(item.funnel_category_id),
        })));
      });

    HttpClient.get<any>('offer', {
        status: 1,
        limit: 1000,
      })
      .then(res => {
        setOfferList(res.data.data.map((item: any) => ({
          value: item.offer_id,
          label: item.offer_name,
          category: item.offer_category_name
        })));
      });

  }, []);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const changeCategory = (categoryId: number, categoryName: string = '') => {
    if (typeof onChangeCategory === "function") {
      onChangeCategory(categoryId, categoryName);
    }
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && node.check()) {
      saveCategory(categoryFormValue);
    }
  };

  const saveCategory = (data: any) => {
    setSaveButtonLoading(true);

    HttpClient.post<object, any>('funnel_category', data)
      .then((res) => {
        const categoryId = parseInt(res.data.funnel_category_id);
        // Add created category in the selector list
        setCategories([...categories, {
          ...data,
          funnel_category_id: categoryId
        }]);
        changeCategory(categoryId, data.funnel_category_name);
        handleEditModalClose();
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const setFunnelCategory = (categoryId: number) => {
    const category = categories.find((item: any) => item.funnel_category_id === categoryId);

    changeCategory(categoryId, category.funnel_category_name);
  };

  const handleCallback = (formValue: any) => {
    setCategoryFormValue(formValue);
  };

  const [campaignFormValue, setCampaignFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();

  const locationTypeList = [
    { value: "all", label: "All Locations" },
    { value: "target", label: "Target Locations" },
    { value: "block", label: "Block Locations" },
  ];

  // Load country list
  const [locationList, setLocationList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('country')
      .then(res => {
        setLocationList(res.data.data);
      });
  }, []);

  const locationSubtypeList = [
    { value: "all", label: "Entire Country" },
    { value: "region", label: "Region/State" }
  ];

  // Load region list
  const [locationSubList, setLocationSubList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('region')
      .then(res => {
        setLocationSubList(res.data.data);
      });
  }, []);

  const osTargetList = [
    { value: "all", label: "Target All" },
    { value: "include", label: "Include" },
    { value: "exclude", label: "Exclude" },
  ];

  const browserTargetList = [
    { value: "all", label: "All Browsers" },
    { value: "include", label: "Include" },
    { value: "exclude", label: "Exclude" },
  ];

  const osList = [
    { value: "win", label: "Windows" },
    { value: "mac", label: "Mac" },
    { value: "linux", label: "Linux" },
    { value: "ios", label: "iOS" },
    { value: "android", label: "Android" },
    ...formValue.traffic_source_type !== API_NAME_TABOOLA ? [{ value: "chrome", label: "ChromeOS" }] : [],
  ];

  const browserList = [
    { value: "chrome", label: "Chrome" },
    { value: "firefox", label: "FireFox" },
    { value: "edge", label: "Edge" },
    { value: "safari", label: "Safari" },
  ];

  const setFormValue = (data: any) => {
    if (typeof parentCallback === "function") {
      parentCallback(data);
    }
  };

  const setFunnelCampaign = (campaignId: number) => {
    const campaign = campaigns.find((item: any) => item.campaign_id === campaignId);

    changeCampaign(campaignId, campaign.campaign_name, campaign.traffic_source_type);
  };
  
  if(formValue.campaign_id===0){
    formValue.campaign_id = null
  }
 

  return (
    <Form
      layout="horizontal"
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
      formValue={formValue}
      formError={formError}
    >
      {/*TODO Remove after testing*/}
      {/*<div>
        <span>ID: {JSON.stringify(formValue.funnel_id)}</span><br/>
        <span>{JSON.stringify(formValue)}</span><br/>
        <hr/>
      </div>*/}
      <PanelGroup accordion bordered>
        <Panel header="General" defaultExpanded>
          <Grid fluid>
            <Row className="show-grid" style={{marginBottom:20}}>
              <Col xs={14} sm={14} md={14} lg={14}>
                <Form.Group>
                  <Form.ControlLabel style={{ textAlign: "left" }}>Campaigns</Form.ControlLabel>
                  <Form.Control
                    name="campaign_id"
                    style={{ width: 300 }}
                    accepter={SelectPicker}
                    cleanable={false}
                    placeholder="Select Campaigns"
                    valueKey="campaign_id"
                    labelKey="campaign_name"
                    data={campaigns}
                    onChange={(campaignId: any) => setFunnelCampaign(campaignId)}
                  />
                    {/* <IconButton
                      circle
                      size="md"
                      style={{ marginLeft: 310, marginTop: -55, color: 'blue' }}
                      icon={<PlusIcon />}
                      onClick={() => {
                        setCampaignEditModalOpen(true);
                      }}
                    /> */}
                </Form.Group>
              </Col>
              {/* <Col xs={6} sm={12} md={6}>
                <Form.Group controlId="funnel_category_id">
                  <Form.ControlLabel style={{ textAlign: "left" }}>Category</Form.ControlLabel>
                  <Form.Control
                    name="funnel_category_id"
                    accepter={SelectPicker}
                    data={categories}
                    valueKey="funnel_category_id"
                    labelKey="funnel_category_name"
                    size="md"
                    style={{ width: 224 }}
                    onChange={(categoryId: any) => setFunnelCategory(categoryId)}
                  />
                </Form.Group>
              </Col> */}
              {/* <Col xs={24} sm={12} md={2}>
                <Whisper
                  trigger="hover"
                  placement="top"
                  speaker={<Tooltip>Add New Category</Tooltip>}
                >
                  <IconButton
                    circle
                    size="md"
                    style={{ marginTop: 40, marginLeft: 30 }}
                    icon={<PlusIcon />}
                    onClick={() => {
                      setEditModalOpen(true);
                    }}
                  />
                </Whisper>
              </Col> */}
            </Row>
          </Grid>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.ControlLabel style={{ textAlign: "left" }}>Name</Form.ControlLabel>
                <Form.Control
                  name="funnel_name"
                  style={{ width: 300 }}
                  placeholder="Funnel Name"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.ControlLabel style={{ textAlign: "left" }}>Branding Text</Form.ControlLabel>
                <Form.Control
                  name="branding_text"
                  style={{ width: 300 }}
                />
              </Col>
            </Row>
          </Grid>
        </Panel>
        <Panel header="Conditions" defaultExpanded>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group>
                  <Form.ControlLabel style={{ textAlign: "left", width: 350 }}>Start Date</Form.ControlLabel>
                  <Form.Control
                    name="start_date"
                    accepter={DatePicker}
                    disabledDate={(date: any) => moment(date).isBefore(moment().subtract(1, 'days'))}
                    placement="autoVerticalStart"
                    style={{ width: 350 }}
                  />
                  <span><i>*Leave empty to start immediately</i></span>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group controlId="end_date">
                  <Form.ControlLabel style={{ textAlign: "left", width: 350 }}>End Date</Form.ControlLabel>
                  <Form.Control
                    name="end_date"
                    accepter={DatePicker}
                    disabledDate={(date: any) => moment(date).isBefore(formValue.start_date)}
                    placement="autoVerticalEnd"
                    style={{ width: 350 }}
                  />
                  <span><i>*Leave empty to stop manually</i></span>
                </Form.Group>
              </Col>
            </Row>
          </Grid>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group controlId="locations_type">
                  <Form.ControlLabel style={{ textAlign: "left" }}>Location</Form.ControlLabel>
                  <Form.Control
                    name="locations_type"
                    searchable={false}
                    cleanable={false}
                    style={{ width: 350 }}
                    accepter={SelectPicker}
                    placement="autoVerticalStart"
                    data={locationTypeList}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                {formValue.locations_type !== "all" &&
                  <Form.Group>
                    <Form.ControlLabel style={{ textAlign: "left" }}>Countries</Form.ControlLabel>
                    <Form.Control
                      name="locations"
                      style={{ width: 350 }}
                      accepter={TagPicker}
                      placement="autoVerticalEnd"
                      searchable={true}
                      data={locationList}
                      labelKey="country_name"
                      valueKey="country_iso_code"
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
            <Row className="show-grid" style={{ marginTop: 15 }}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group controlId="locations_subtype">
                  <Form.ControlLabel style={{ textAlign: "left" }}>Locations to be included</Form.ControlLabel>
                  <Form.Control
                    name="locations_subtype"
                    searchable={false}
                    cleanable={false}
                    style={{ width: 350 }}
                    accepter={SelectPicker}
                    placement="autoVerticalStart"
                    data={locationSubtypeList}
                    disabled={formValue.locations_type === "all" || formValue.locations.length === 0}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                {(formValue.locations.length > 0 && formValue.locations_subtype !== "all") &&
                  <Form.Group>
                    <Form.ControlLabel style={{ textAlign: "left" }}>Regions</Form.ControlLabel>
                    <Form.Control
                      name="locations_sub"
                      style={{ width: 350 }}
                      accepter={TagPicker}
                      placement="autoVerticalEnd"
                      searchable={true}
                      data={locationSubList.filter((item: any) => formValue.locations.includes(item.country_iso_code))}
                      labelKey="subdivision_1_name"
                      valueKey="subdivision_1_iso_code"
                      groupBy="country_name"
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
          </Grid>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group controlId="device_type">
                  <Form.ControlLabel style={{ textAlign: "left" }}>Platform</Form.ControlLabel>
                  <Form.Control name="device_type" accepter={CheckboxGroup} inline>
                    <Checkbox value="desktop">Desktop</Checkbox>
                    <Checkbox value="tablet">Tablet</Checkbox>
                    <Checkbox value="mobile">Mobile</Checkbox>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="show-grid" style={{ marginTop: 15 }}>
              <Col xs={8} sm={8} md={8} lg={8}>
                <Form.Group>
                  <Form.ControlLabel style={{ textAlign: "left" }}>Operating System</Form.ControlLabel>
                  <Form.Control
                    name="os_target"
                    searchable={false}
                    cleanable={false}
                    accepter={SelectPicker}
                    placement="autoVerticalStart"
                    data={osTargetList}
                    style={{ marginRight: 15, width: 200 }}
                  />
                </Form.Group>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16}>
                {formValue.os_target !== "all" &&
                  <Form.Group>
                    <Form.Control
                      name="os"
                      accepter={TagPicker}
                      placement="autoVerticalEnd"
                      data={osList}
                      style={{ marginRight: 15, marginTop: 40, width: 400 }}
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
            <Row className="show-grid" style={{ marginTop: 15 }}>
              <Col xs={8} sm={8} md={8} lg={8}>
                <Form.Group>
                  <Form.ControlLabel style={{ textAlign: "left" }}>Browser</Form.ControlLabel>
                  <Form.Control
                    name="browser_target"
                    placement="autoVerticalStart"
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    data={browserTargetList}
                    style={{ marginRight: 15, width: 200 }}
                  />
                </Form.Group>
              </Col>
              <Col xs={16} sm={16} md={16} lg={16}>
                {formValue.browser_target !== "all" &&
                  <Form.Group>
                    <Form.Control
                      name="browser"
                      placement="autoVerticalEnd"
                      accepter={TagPicker}
                      data={browserList}
                      style={{ marginRight: 15, marginTop: 40, width: 400 }}
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
          </Grid>
        </Panel>
        <Panel header="Edit Offers" defaultExpanded>
          <Grid fluid>
            <Row className="show-grid">
              <Col xs={12} sm={12} md={12} lg={12} style={{ height: 50 }}>
                <Form.Group controlId="addFunnelNode">
                  <SelectPicker
                    placeholder="Add Offer..."
                    groupBy="category"
                    style={{ width: '100%' }}
                    data={offerList}
                    placement="topStart"
                    onSelect={addFunnelNode}
                    onChange={(val) => { setOfferSearchValue(val); }}
                    onExited={() => { setOfferSearchValue('') }}
                    value={offerSearchValue} />

                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <label style={{ color: 'red' }}>{offerSliderError}</label>
              </Col>
            </Row>
            <OfferSliderTree
              offers={formValue.offers}
              onChange={updateOfferSliders}
              removeOfferSlider={removeOfferSlider}
              toggleSliderLock={toggleSliderLock} />
          </Grid>
        </Panel>
      </PanelGroup>

      <EditModal
        title={"Add Campaign"}
        loading={saveButtonLoading}
        open={campaignEditModalOpen}
        onClose={handleCampaignEditModalClose}
        onCancel={handleCampaignEditModalClose}
        onSubmit={handleCampaignSave}
      >
        <Campaign
          formRef={mainFormRef}
          parentCallback={handleCampaignCallback}
        />
      </EditModal>
      <EditModal
        title={"Add Category"}
        loading={saveButtonLoading}
        open={editModalOpen}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <FunnelCategory
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>


    </Form>
  )
};

const { ArrayType, DateType, StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  funnel_id: NumberType(),
  campaign_id: NumberType().isRequired("Select a Campaign. It's required"),
  funnel_name: StringType().isRequired("Funnel Name is required"),
  branding_text: StringType(),
  start_date: DateType(),
  end_date: DateType(),
  locations_type: StringType(),
  locations: ArrayType(),
  locations_subtype: StringType(),
  locations_sub: ArrayType(),
  device_type: ArrayType(),
  os_target: StringType(),
  os: ArrayType(),
  browser_target: StringType(),
  browser: ArrayType(),
  bid_amount: NumberType().max(99999.99),
  bid_strategy: StringType(),
  spending_limit: NumberType(),
  spending_limit_type: StringType(),
  creative_traffic_allocation: StringType(),
  creative_traffic_allocation_start: DateType(),
  creative_traffic_allocation_end: DateType(),
  daily_ad_delivery_type: StringType(),
  daily_cap: NumberType(),
  offers: ArrayType(),
  traffic_source_type: StringType(),
});

export default FunnelForm;