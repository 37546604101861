import React from "react";
import Dashboard from "../@Components/Dashboard";
import OffersTree from "../@Components/Offers/OffersTree";

const OfferSTree: React.FC = () => {
  return (
    <Dashboard>
      <OffersTree/>
    </Dashboard>
  );
};

export default OfferSTree;
