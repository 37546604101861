import React from "react";
import { Schema, Form, InputNumber } from "rsuite";
import Field from "../Field";

const {NumberType, StringType} = Schema.Types;

const model = Schema.Model({
  adgroup_id: StringType(),
  adgroup_name: StringType(),
  budget: NumberType().isRequired("is required."),
});

type AdGroupBudgetFormType = {
  adGroupBudgetFormValue: Record<string, any>;
  onFormDataChange?: (data: object) => void;
  formRef: React.MutableRefObject<any>;
};

const AdGroupBudgetForm: React.FC<AdGroupBudgetFormType> = ({
  adGroupBudgetFormValue ,
  onFormDataChange,
  formRef ,
}) => {
  formRef = formRef || React.createRef();
  // const BUDGET_MODE_DAY = 'BUDGET_MODE_DAY';
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: Record<string, any>) => {
    if (onFormDataChange instanceof Function) {
      onFormDataChange(data);
    }
  };

  // We use only BUDGET_MODE_DAY with 20 minimum
  const MIN_BUDGET = 20.00; // adGroupBudgetFormValue.budget_mode === BUDGET_MODE_DAY ? 20.00 : 280.00;

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="horizontal"
      formValue={adGroupBudgetFormValue}
      formError={formError}
    >
      <Field label="Ad Group ID" name="adgroup_id" disabled />
      <Field label="Ad Group Name" name="adgroup_name" disabled />
      <Field
        label="Budget"
        name="budget"
        accepter={InputNumber}
        prefix="USD"
        min={MIN_BUDGET}
        placeholder={"At least " + MIN_BUDGET}
        rule={NumberType().min(MIN_BUDGET).isRequired('Budget is required')}
      />
    </Form>
  );
};

export default AdGroupBudgetForm;
