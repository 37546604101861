import React from "react";
import {Form, Loader, Schema, Uploader} from "rsuite";
import {getBaseApiUrl} from "../../@Utils/HttpClient";
import ImageIcon from '@rsuite/icons/Image';
import {FileType} from "rsuite/esm/Uploader/Uploader";

const {StringType} = Schema.Types;

const model = Schema.Model({
  headline: StringType().isRequired("Headline is required"),
  image: StringType()
    .isRequired("Image is required")
    .pattern(/^(http|https):\/\/[^ "]+$/, 'URL is not valid')
});

type MediaFormType = {
  formValue?: any;
  formRef?: React.MutableRefObject<any>;
  setFormValue?: any;
};

const MediaForm: React.FC<MediaFormType> = ({
  formValue = "",
  setFormValue = () => {},
  formRef = null,
}) => {
  formRef = formRef || React.createRef();

  const [uploading, setUploading] = React.useState(false);
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    if (setFormValue instanceof Function) {
      setFormValue(data);
    }
  };

  return (
    <Form
      layout="horizontal"
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      formValue={formValue}
      formError={formError}
    >
      <Form.ControlLabel>Headline</Form.ControlLabel>
      <Form.Control
        name="headline"
        placeholder="Enter Headline"
      />
      <Form.ControlLabel>Image</Form.ControlLabel>
      <Form.Control
        name="image"
        placeholder="Enter image"
      />
      <Uploader
        fileListVisible={false}
        listType="picture"
        action={`${getBaseApiUrl()}/image`}
        draggable={true}
        multiple={false}
        onUpload={file => {
          setUploading(true);
        }}
        onSuccess={(response: any, file: FileType) => {
          setUploading(false);

          setFormValue({
            ...formValue,
            image: response.url,
          });
        }}
        onError={(reason: any) => {
          setUploading(false);
        }}
      >
        <button style={{
          width: 300,
          height: 200,
        }}>
          {uploading && <Loader backdrop center/>}
          {formValue.image ? (
            <img src={formValue.image} width="100%" height="100%" alt={formValue.image}/>
          ) : (
            <ImageIcon style={{fontSize: 80}}/>
          )}
        </button>
      </Uploader>
    </Form>
  );
};

export default MediaForm;
