import React from "react";
import { Modal } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

type RuleHistoryModalType = {
  title?: string;
  open?: boolean;
  size?: TypeAttributes.Size;
  loading?: boolean;
  hideButtons?: boolean;
  hideSubmitButton?: boolean;
  hideCancelButton?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
};

const RuleHistoryModal: React.FC<RuleHistoryModalType> = ({
  title = "History",
  open = false,
  size = "lg",
  loading = false,
  onClose,
  onCancel,
  ...props
}) => {
  return (
    <Modal
      open={open}
      size={size}
      onClose={() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      {...props}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};

export default RuleHistoryModal;