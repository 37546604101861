import React from "react";
import {
  FlexboxGrid,
  Form,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Placeholder,
  Schema,
  SelectPicker,
  Uploader
} from "rsuite";
import Field from "../Field";
import HttpClient, {getBaseApiUrl} from "../../@Utils/HttpClient";
import MinusIcon from "@rsuite/icons/Minus";
import {FileType} from "rsuite/esm/Uploader/Uploader";
import ImageIcon from "@rsuite/icons/Image";
import TaskIcon from "@rsuite/icons/Task";
import ListIcon from '@rsuite/icons/List';
import EditModal from "../OfferSources/EditModal";
import {FormInstance} from "rsuite/Form";
import ImageUrlsForm from "./ImageUrlsForm";
import HeadlinesForm from "./HeadlinesForm";
import MediaList from "./MediaList";

const API_NAME_TABOOLA = "taboola";

const prepareFormData = (record: any) => {
  record.content_id = !isNaN(parseInt(record.content_id)) ? parseInt(record.content_id) : 0;
  record.campaign_id = !isNaN(parseInt(record.campaign_id)) ? parseInt(record.campaign_id) : 0;
  record.traffic_source_id = !isNaN(parseInt(record.traffic_source_id)) ? parseInt(record.traffic_source_id) : 0;
  record.media_list = Array.isArray(record.media_list) ? record.media_list : [];

  return record;
};

type CampaignProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  onAfterMediaFileUploading?: any;
  onAfterUrlsPasting?: any;
  onAfterHeadlinesPasting?: any;
  onAfterMediaListSelection?: any;
  fileList: any,
  setFileList: any;
  isFormLoading?: boolean;
  trafficSourceType?: string;
};

const CampaignContentForm: React.FC<CampaignProps> = ({
  formValue,
  formRef = null,
  parentCallback = (data: any) => {},
  onAfterMediaFileUploading = (file: any, url: string) => {},
  onAfterUrlsPasting = (urls: string[]) => {},
  onAfterHeadlinesPasting = (headlines: string[]) => {},
  onAfterMediaListSelection = (list: any[]) => {},
  fileList = [],
  setFileList = (value: any[]) => {},
  isFormLoading = false,
  trafficSourceType = '',
}) => {
  formValue = prepareFormData(formValue);

  const [formError, setFormError] = React.useState<any>({});

  const setFormValue = (data: any) => {
    if (typeof parentCallback === "function") {
      parentCallback(data);
    }
  };

  const handleFileRemove = (value: any) => {
    const mediaList = formValue.media_list;
    const index = mediaList.findIndex((media: any) => media.media_id === value.fileKey);

    if (index > -1) {
      mediaList[index].remove = 1;
    }

    setFormValue({
      ...formValue,
      media_list: index > -1 ? mediaList : mediaList.filter((media: any) => {
        return !media.fileKey || media.fileKey !== value.fileKey; }
      ),
    });
  };

  const handleFileListChange = (value: any) => {
    setFileList(value);
  };

  const handleFileUploadingSuccess = (response: any, file: FileType) => {
    onAfterMediaFileUploading(file, response.url);
  };

  const handlePasteUrlClick = () => {
    setImageUrlsEditModalOpen(true);
  };

  const handleAddFromExistingClick = () => {
    setMediaListEditModalOpen(true);
  };

  const handlePasteHeadlinesClick = () => {
    setHeadlinesEditModalOpen(true);
  };

  const [headlinesEditModalOpen, setHeadlinesEditModalOpen] = React.useState(false);
  const [headlines, setHeadlines] = React.useState({headline_list: ""});
  const headlinesFormRef = React.createRef<FormInstance>();

  const handleHeadlinesEditModalClose = () => {
    setHeadlinesEditModalOpen(false);
  };

  const handleHeadlinesSave = () => {
    const node = headlinesFormRef && headlinesFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    setHeadlinesEditModalOpen(false);

    onAfterHeadlinesPasting(headlines.headline_list.split(/\r?\n/).filter((headline: string) => headline.trim() !== ''));
  };

  const [imageUrlsEditModalOpen, setImageUrlsEditModalOpen] = React.useState(false);
  const [imageUrls, setImageUrls] = React.useState({image_urls: ""});
  const imageUrlsFormRef = React.createRef<FormInstance>();

  const handleImageUrlsEditModalClose = () => {
    setImageUrlsEditModalOpen(false);
  };

  const handleImageUrlsSave = () => {
    const node = imageUrlsFormRef && imageUrlsFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    setImageUrlsEditModalOpen(false);

    onAfterUrlsPasting(imageUrls.image_urls.split(/\r?\n/).filter((url: string) => url.trim() !== ''));
  };

  const [mediaList, setMediaList] = React.useState<any[]>([]);
  const [mediaListLoading, setMediaListLoading] = React.useState(true);
  const [mediaListSortType, setMediaListSortType] = React.useState();
  const [mediaListSortColumn, setMediaListSortColumn] = React.useState();
  const [mediaListEditModalOpen, setMediaListEditModalOpen] = React.useState(false);
  const [checkedKeys, setCheckedKeys] = React.useState<number[]>([]);

  // Load content media list
  React.useEffect(() => {
    setMediaList([]);

    setMediaListLoading(true);

    HttpClient
      .get<any>('campaign_content_media', {
        page: 1,
        limit: 5000,
        contentId: 0,
      })
      .then(res => {
        const mediaList = res.data.data.map((item: any) => ({
          ...item,
          content_id: !isNaN(parseInt(item.content_id)) ? parseInt(item.content_id) : 0,
          media_id: !isNaN(parseInt(item.media_id)) ? parseInt(item.media_id) : 0,
        }));

        setMediaListLoading(false);
        setMediaList(mediaList);
      });
  }, []);

  const handleMediaListEditModalClose = () => {
    setMediaListEditModalOpen(false);
  };

  const handleMediaListSave = () => {
    const checkedMediaList = mediaList.filter((media: any) => checkedKeys.includes(media.media_id));

    setMediaListEditModalOpen(false);
    setCheckedKeys([]);

    onAfterMediaListSelection(checkedMediaList);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setMediaListSortColumn(column);
    setMediaListSortType(type);
  };

  return (
    <>
      <Form
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        model={CampaignContentModel}
        formValue={formValue}
        formError={formError}
        fluid
      >
        <Field
          label="Campaign"
          name="campaign_name"
          readOnly={true}
          style={{width: 'inherit'}}
        />
        <Field
          label="Target URL"
          name="target_url"
          style={{width: 'inherit'}}
          outerPostfix={
            ((placeholders: string[]) =>
                <div>
                  {placeholders.map((holder: string, index) =>
                    <span key={index} style={{border: "1px solid #CCC", padding: 4, margin: '0 5px 0 0',}}>
                  {`{${holder}}`}
                </span>)}
                </div>
            )(["api_campaign_id", "site", "title"])
          }
        />
        {trafficSourceType === API_NAME_TABOOLA ? <>
          <Field
            label="Description"
            name="description"
            style={{width: 'inherit'}}
          />
          <Field
            label="CTA"
            name="cta_type"
            style={{width: 'inherit'}}
            accepter={SelectPicker}
            data={[
              'DOWNLOAD',
              'INSTALL_NOW',
              'LEARN_MORE',
              'SHOP_NOW',
              'CLICK_HERE',
              'SIGN_UP',
              'PLAY_NOW',
              'NONE',
            ].map(value => ({ value, label: value }))}
          />
          </>: null
        }

        {isFormLoading ?
          <>
            <Loader center size="lg" content="Loading..."/>
            <Placeholder.Paragraph rows={8}/>
          </>
          :
          <>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12}>
                <IconButton
                  onClick={handlePasteHeadlinesClick}
                  icon={<ListIcon/>}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{marginTop: 15, marginBottom: 15, width: '95%'}}
                >
                  Paste Headlines
                </IconButton>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12}>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <Form.Control
              name="media_list"
              style={{width: 'inherit'}}
              accepter={HeadlineInputGroup}
            />
            <Form.ControlLabel>Image:</Form.ControlLabel>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12}>
                <IconButton
                  onClick={handlePasteUrlClick}
                  icon={<ImageIcon/>}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{marginTop: 15, marginBottom: 15, width: '95%'}}
                >
                  Paste URL
                </IconButton>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12}>
                <IconButton
                  onClick={handleAddFromExistingClick}
                  icon={<TaskIcon/>}
                  placement="left"
                  size="sm"
                  appearance="ghost"
                  style={{marginTop: 15, marginBottom: 15, width: '100%'}}
                >
                  Add from existing contents
                </IconButton>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            <Uploader
              fileList={fileList}
              onRemove={handleFileRemove}
              onChange={handleFileListChange}
              onSuccess={handleFileUploadingSuccess}
              action={`${getBaseApiUrl()}/image`}
              listType="picture-text"
              draggable
            >
              <div style={{lineHeight: '100px'}}>Click or Drag files to this area to upload</div>
            </Uploader>
          </>
        }
      </Form>

      <EditModal
        title={"Paste Headlines"}
        open={headlinesEditModalOpen}
        onClose={handleHeadlinesEditModalClose}
        onCancel={handleHeadlinesEditModalClose}
        onSubmit={handleHeadlinesSave}
      >
        <HeadlinesForm
          formValue={headlines}
          formRef={headlinesFormRef}
          setFormValue={setHeadlines}
        />
      </EditModal>

      <EditModal
        title={"Paste URLs"}
        open={imageUrlsEditModalOpen}
        onClose={handleImageUrlsEditModalClose}
        onCancel={handleImageUrlsEditModalClose}
        onSubmit={handleImageUrlsSave}
      >
        <ImageUrlsForm
          formValue={imageUrls}
          formRef={imageUrlsFormRef}
          setFormValue={setImageUrls}
        />
      </EditModal>

      <EditModal
        title={"Add from existing contents"}
        open={mediaListEditModalOpen}
        size="lg"
        onClose={handleMediaListEditModalClose}
        onCancel={handleMediaListEditModalClose}
        onSubmit={handleMediaListSave}
      >
        <MediaList
          data={mediaList}
          loading={mediaListLoading}
          sortColumn={mediaListSortColumn}
          sortType={mediaListSortType}
          onSortColumn={handleSortColumn}
          checkedKeys={checkedKeys}
          setCheckedKeys={setCheckedKeys}
        />
      </EditModal>
    </>
  )
};

const {ArrayType, NumberType, ObjectType, StringType} = Schema.Types;

export const CampaignContentModel = Schema.Model({
  campaign_id: NumberType(),
  content_id: NumberType(),
  target_url: StringType().isRequired("Target URL is required"),
  description: StringType().maxLength(250, 'Max length is 250.'),
  cta_type: StringType(),
  media_list: ArrayType()
    .addRule((value, data) => {
      const headlineList = data.media_list.filter((media: any) => media.headline !== '');
      const imageList = data.media_list.filter((media: any) => media.image !== null);

      if (imageList && headlineList && headlineList.length > 0 && imageList.length > 0) {
        return true;
      }

      return false;
    }, 'Please submit at least 1 headline and 1 image to proceed.')
    .of(
      ObjectType().shape({
        content_id: NumberType(),
        media_id: NumberType(),
        order_id: NumberType(),
        fileKey: StringType(),
        headline: StringType(),
        image: StringType(),
        url: StringType(),
      }),
    ),
});

const HeadlineInputGroup = ({
  value = [],
  onChange,
}: any) => {
  const [headlineList, setHeadlineList] = React.useState(value);

  // TODO Replace it
  React.useEffect(() => {
    setHeadlineList(value);
  }, [value]);

  const handleHeadlineListChange = (nextHeadlines: any) => {
    setHeadlineList(nextHeadlines);
    onChange(nextHeadlines);
  };

  const handleHeadlineChange = (orderId: number, value: any) => {
    const media = headlineList.find((media: any) => media.order_id === orderId);

    media.headline = value;
    
    handleHeadlineListChange([...headlineList]);
  };

  const handleMinusClick = (orderId: number) => {
    // Don't remove the last headline
    if (headlineList.length < 2) {
      return;
    }

    const headline = value.find((media: any) => media.order_id === orderId);

    if (headline.image === null) {
      handleHeadlineListChange(
        value
          // Remove current element
          .filter((media: any) => media.order_id !== orderId)
      );
    } else {
      if (headline.media_id > 0) {
        headline.remove = 1;
      } else {
        // Replace a media headline value by a next media value from a selected one to save image values
        value.forEach((media: any, index: number) => {
          if (media.order_id < orderId) return;

          let nextMedia = headlineList[index + 1];

          if (!nextMedia) return;

          media.headline = nextMedia.headline;
        });

        const lastMedia = value[value.length - 1];

        if (lastMedia.image === null) {
          // remove last element
          value.pop();
        }
      }

      handleHeadlineListChange([...value]);
    }
  };

  // Show all not-empty headlines with only first empty headline
  const notEmptyHeadlines = headlineList.filter((media: any) => media.headline !== '' && media.headline !== null);
  const firstEmptyHeadlines = headlineList.find((media: any) => media.headline === '' || media.headline === null);
  const headlines = headlineList.length !== notEmptyHeadlines.length && firstEmptyHeadlines !== null && firstEmptyHeadlines !== undefined
    ? [...notEmptyHeadlines, firstEmptyHeadlines] : notEmptyHeadlines;

  return headlines
    .filter((media: any) => media.remove !== 1)
    .map((media: any, index: number) =>
    <HeadlineInputWithButton
      key={`headline_${media.order_id}_${index}`}
      label={`Headline ${index + 1}`}
      style={{width: 'inherit'}}
      value={media.headline}
      buttonHandler={() => handleMinusClick(media.order_id)}
      changeHandler={(value: string) => handleHeadlineChange(media.order_id, value)}
    />);
};

const HeadlineInputWithButton = ({
  label,
  buttonHandler,
  changeHandler,
  value,
  ...props
}: any) => {
  const styles = {
    width: 'inherit',
  };

  return (
    <>
      <Form.ControlLabel>{label}</Form.ControlLabel>
      <InputGroup {...props} inside style={styles}>
        <Input value={value || ''} onChange={changeHandler}/>
        <InputGroup.Button onClick={() => buttonHandler()}>
          <MinusIcon/>
        </InputGroup.Button>
      </InputGroup>
    </>
  );
};

export default CampaignContentForm;