import React from "react";
import {Icon} from '@rsuite/icons';

const SvgIcon = React.forwardRef((props, ref) => (
  <svg {...props}
    // width="2em" height="2em"
       fill="currentColor"
    // ref={ref}
       version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 652 652"
    // style="enable-background:new 0 0 652 652;"
       xmlSpace="preserve">
    <g>
      <path className="st0" d="M144.6,312.8c0-2.7,0.8-4.7,2.4-5.9c1.6-1.2,3.8-1.8,6.7-1.8c1.8,0,3.4,0.2,4.9,0.7c1.5,0.4,2.5,0.8,3,0.9V345
		c0,6.6,1.6,11.6,4.8,15.1c3.2,3.4,8.1,5.2,14.6,5.2c6.6,0,11.4-1.7,14.6-5.2c3.2-3.4,4.8-8.5,4.8-15.1v-32.2c0-2.7,0.8-4.7,2.4-5.9
		c1.6-1.2,3.8-1.8,6.6-1.8c1.9,0,3.6,0.2,5.1,0.7c1.5,0.4,2.5,0.8,3,0.9v42c0,5-0.9,9.4-2.6,13.3c-1.7,3.9-4.2,7.3-7.5,10
		c-3.2,2.7-7.1,4.8-11.6,6.2c-4.5,1.4-9.4,2.1-14.8,2.1c-5.4,0-10.3-0.7-14.8-2.1c-4.5-1.4-8.3-3.5-11.5-6.2
		c-3.2-2.7-5.7-6.1-7.5-10c-1.8-3.9-2.7-8.4-2.7-13.3L144.6,312.8L144.6,312.8z"/>
      <path className="st0" d="M230.9,292.1c0-2.7,0.8-4.7,2.4-5.9c1.6-1.2,3.8-1.8,6.6-1.8c1.8,0,3.5,0.2,5,0.7c1.5,0.4,2.6,0.8,3.1,0.9
		v19.1h21.6c3.3,0,5.6,0.7,6.9,2c1.4,1.3,2,3.2,2,5.8c0,1.7-0.3,3.2-0.7,4.6c-0.5,1.4-0.9,2.4-1.2,3h-28.7v32.5c0,2.5,0.4,4.6,1,6.2
		c0.7,1.6,1.7,2.9,2.9,3.9c1.2,1,2.7,1.7,4.3,2c1.6,0.4,3.4,0.6,5.1,0.6c2.9,0,5.6-0.4,8.1-1.3c2.5-0.9,4.3-1.7,5.4-2.5l6.9,11.1
		c-0.8,0.6-1.9,1.3-3.4,2.2c-1.4,0.9-3.2,1.7-5.3,2.5c-2.1,0.8-4.4,1.4-7,2c-2.6,0.5-5.4,0.8-8.5,0.8c-8.8,0-15.4-2.2-19.9-6.6
		c-4.5-4.4-6.7-10.2-6.7-17.4V292.1z"/>
      <path className="st0" d="M288.6,278.6c0-2.6,0.8-4.6,2.4-5.8c1.6-1.3,3.8-1.9,6.7-1.9c1.8,0,3.4,0.2,4.9,0.7c1.5,0.4,2.5,0.8,3,0.9
		v41.9c2.5-3.1,5.9-5.8,10.3-7.9c4.4-2.2,9.4-3.3,15.1-3.3c5.5,0,10.5,1,15.2,2.9c4.6,1.9,8.6,4.6,12.1,8c3.4,3.5,6.1,7.5,8.1,12.2
		c1.9,4.7,2.9,9.9,2.9,15.5c0,5.5-1,10.7-3,15.4c-2,4.8-4.8,8.8-8.4,12.3c-3.6,3.4-7.8,6.1-12.8,8c-4.9,1.9-10.3,2.9-16.2,2.9
		c-5.9,0-11.3-0.9-16.2-2.6c-4.9-1.7-9.2-4.4-12.8-7.9c-3.6-3.5-6.4-7.9-8.4-13.1c-2-5.2-3-11.4-3-18.5L288.6,278.6L288.6,278.6z
		 M328.6,365.3c3.4,0,6.5-0.6,9.3-1.8c2.8-1.2,5.3-2.9,7.3-5c2-2.1,3.6-4.6,4.7-7.4c1.1-2.9,1.6-6,1.6-9.4c0-3.4-0.5-6.6-1.6-9.5
		c-1.1-2.9-2.7-5.4-4.7-7.5c-2-2.1-4.5-3.7-7.3-5c-2.8-1.2-6-1.8-9.3-1.8c-3.5,0-6.6,0.6-9.5,1.8c-2.8,1.2-5.2,2.9-7.2,5
		c-2,2.1-3.6,4.7-4.7,7.6c-1.1,2.9-1.7,6-1.7,9.3c0,3.5,0.6,6.7,1.7,9.6c1.1,2.9,2.7,5.4,4.8,7.4c2,2,4.5,3.6,7.3,4.8
		C322.2,364.7,325.3,365.3,328.6,365.3"/>
      <path className="st0" d="M379.3,331.2c0-9,2.8-15.9,8.4-20.8c5.6-4.9,13.4-7.4,23.4-7.4c6.3,0,11.4,0.9,15.3,2.6
		c3.9,1.7,5.9,4.3,5.9,7.8c0,1.7-0.5,3.3-1.4,4.8c-1,1.6-1.7,2.5-2.3,2.9c-1.7-0.8-3.8-1.5-6.3-2.2c-2.5-0.7-5.3-1-8.5-1
		c-11.6,0-17.5,6-17.5,18.1v42.3h-17V331.2z"/>
      <path className="st0" d="M505.8,378c-1,0.2-2.6,0.4-4.7,0.7c-2.1,0.2-4.7,0.5-7.5,0.7c-2.9,0.2-6.1,0.5-9.7,0.7
		c-3.6,0.2-7.3,0.3-11.2,0.3c-7.8,0-14.1-0.7-19-2c-4.9-1.3-8.8-3.1-11.6-5.2c-2.8-2.2-4.8-4.7-5.8-7.6c-1-2.9-1.6-5.8-1.6-8.8
		c0-4,0.6-7.5,1.9-10.5c1.2-3,3.3-5.5,6.1-7.6c2.8-2,6.5-3.6,11.1-4.6c4.6-1,10.1-1.5,16.7-1.5c3.8,0,7.4,0.1,10.7,0.4
		c3.4,0.2,5.9,0.5,7.6,0.7c0-3.4-0.6-6.2-1.7-8.5c-1.1-2.2-2.7-4-4.7-5.2c-2-1.3-4.4-2.1-7.2-2.6c-2.8-0.5-5.9-0.7-9.3-0.7
		c-5.7,0-10.2,0.6-13.7,1.9c-3.4,1.3-5.7,2.3-6.8,3.2c-0.6-0.5-1.4-1.4-2.5-2.8c-1-1.4-1.6-3-1.6-5c0-1.4,0.4-2.7,1.1-4
		c0.7-1.3,2.1-2.5,4-3.5c1.9-1,4.4-1.8,7.6-2.4c3.2-0.6,7.2-0.9,12.1-0.9c5.9,0,11.2,0.6,16,1.7c4.8,1.1,9,2.9,12.5,5.3
		s6.2,5.6,8.1,9.6c1.9,4,2.9,8.8,2.9,14.4L505.8,378L505.8,378z M489,345.6c-1-0.2-2.9-0.5-5.6-0.9c-2.7-0.4-6.8-0.6-12.3-0.6
		c-6.8,0-11.7,1-14.8,3c-3.1,2-4.6,5.1-4.6,9.4c0,1.8,0.3,3.4,1,4.8c0.6,1.5,1.8,2.7,3.4,3.7c1.6,1,3.8,1.8,6.5,2.3
		c2.7,0.5,6.1,0.8,10.2,0.8c4.6,0,8.2-0.2,10.9-0.5c2.7-0.3,4.5-0.6,5.4-0.8L489,345.6L489,345.6z"/>
      <path className="st0" d="M529.5,272.3c2.9,0,5.3,1,7.3,2.9c2,1.9,3,4.3,3,7.1c0,2.8-1,5.2-3,7.1c-2,2-4.4,2.9-7.3,2.9s-5.3-1-7.3-2.9
		c-2-1.9-3-4.3-3-7.1c0-2.8,1-5.2,3-7.1C524.2,273.3,526.7,272.3,529.5,272.3 M521,312.8c0-2.7,0.8-4.7,2.4-5.9
		c1.6-1.2,3.8-1.8,6.7-1.8c1.8,0,3.4,0.2,4.9,0.7c1.5,0.4,2.5,0.7,3,0.9v71.6h-17V312.8z"/>
      <path className="st0" d="M553.3,334.1c0-4.9,0.9-9.2,2.8-13c1.8-3.8,4.4-7.1,7.6-9.8c3.2-2.7,7.1-4.7,11.5-6.1
		c4.4-1.4,9.3-2.1,14.5-2.1c5.3,0,10.2,0.7,14.6,2.1c4.5,1.4,8.3,3.5,11.6,6.1c3.2,2.7,5.8,5.9,7.5,9.8c1.8,3.8,2.7,8.2,2.7,13v44.2
		h-17v-40c0-6.6-1.7-11.6-4.9-15.1c-3.3-3.5-8.1-5.2-14.5-5.2c-6.5,0-11.3,1.7-14.6,5.2c-3.2,3.4-4.9,8.5-4.9,15.1v40h-17V334.1z"/>
      <path className="st0" d="M131.2,307.4c-2.8-6.4-6.6-12-11.6-16.7c-4.9-4.7-10.7-8.4-17.3-11.1c-6.6-2.7-13.8-4-21.6-4
		c-7.8,0-15,1.3-21.6,4c-6.6,2.7-12.4,6.4-17.3,11.1c-4.9,4.7-8.8,10.3-11.6,16.7c-2.8,6.4-4.2,13.4-4.2,20.9
		c0,7.5,1.4,14.4,4.2,20.9c2.8,6.4,6.6,12,11.6,16.7c4.9,4.7,10.7,8.4,17.3,11.1c6.6,2.7,13.8,4,21.6,4c7.8,0,14.9-1.3,21.6-4
		c6.6-2.7,12.4-6.4,17.3-11.1c4.9-4.7,8.8-10.3,11.6-16.7c2.8-6.4,4.2-13.4,4.2-20.9C135.4,320.8,134,313.8,131.2,307.4 M48.1,324.4
		c6.1-2,14.2-4.5,14.2-4.5c17.8-5.5,20.1-12,20.1-12s3.7,6.5,16.6,10.8c0,0,7.1,2.3,13.8,4.6c0.3,0.7,0.7,1.3,1,2
		c1.2,2.7,2,5.5,2.4,8.5h-9.1c0-0.1,0-0.1-0.1-0.2c-0.7-1.5-1.6-2.8-2.8-4c-1.2-1.1-2.6-2-4.1-2.6c-1.6-0.6-3.3-1-5.1-1
		c-1.8,0-3.6,0.3-5.1,1c-1.6,0.6-2.9,1.5-4.1,2.6c-1.2,1.1-2.1,2.4-2.7,4c-0.2,0.5-0.4,1-0.5,1.5H79c-0.1-0.5-0.3-1-0.5-1.5
		c-0.7-1.5-1.6-2.8-2.7-4c-1.2-1.1-2.5-2-4.1-2.6c-1.6-0.6-3.3-1-5.1-1c-1.8,0-3.6,0.3-5.1,1c-1.6,0.6-3,1.5-4.1,2.6
		c-1.2,1.1-2.1,2.4-2.8,4c0,0.1,0,0.1-0.1,0.2h-9.1c0.4-3,1.2-5.8,2.4-8.5C47.8,325,48,324.7,48.1,324.4 M102.7,338.6
		c0,1.1-0.2,2-0.6,2.9c-0.4,0.9-0.9,1.7-1.6,2.4c-0.7,0.7-1.5,1.2-2.4,1.6c-0.9,0.4-2,0.6-3,0.6c-1.1,0-2.1-0.2-3.1-0.6
		c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4c-0.4-0.9-0.6-1.9-0.6-2.9c0-1.1,0.2-2,0.6-2.9c0.4-0.9,0.9-1.7,1.6-2.4
		c0.7-0.7,1.5-1.2,2.4-1.6c0.9-0.4,2-0.6,3.1-0.6c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.8,0.9,2.4,1.6c0.7,0.7,1.2,1.5,1.6,2.4
		C102.5,336.6,102.7,337.6,102.7,338.6 M74.2,338.6c0,1.1-0.2,2-0.6,2.9c-0.4,0.9-0.9,1.7-1.6,2.4c-0.7,0.7-1.5,1.2-2.4,1.6
		c-0.9,0.4-2,0.6-3.1,0.6c-1.1,0-2.1-0.2-3-0.6c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4c-0.4-0.9-0.6-1.9-0.6-2.9
		c0-1.1,0.2-2,0.6-2.9c0.4-0.9,0.9-1.7,1.6-2.4c0.7-0.7,1.5-1.2,2.4-1.6c0.9-0.4,1.9-0.6,3-0.6c1.1,0,2.1,0.2,3.1,0.6
		c0.9,0.4,1.7,0.9,2.4,1.6c0.7,0.7,1.2,1.5,1.6,2.4C74,336.6,74.2,337.6,74.2,338.6 M106.2,363.6c-3.2,3.1-7,5.5-11.3,7.3
		c-4.3,1.8-9.1,2.6-14.1,2.6c-5.1,0-9.8-0.9-14.1-2.6c-4.3-1.8-8.1-4.2-11.4-7.3c-3.2-3.1-5.8-6.7-7.6-10.9
		c-1.8-4.1-2.7-8.5-2.7-13.2h8.6c0.1,1.5,0.4,2.8,1,4.1c0.7,1.5,1.6,2.9,2.8,4c1.2,1.1,2.5,2,4.1,2.6c1.6,0.6,3.3,1,5.1,1
		c1.8,0,3.6-0.3,5.1-1c1.6-0.6,3-1.5,4.1-2.6c1.2-1.1,2.1-2.4,2.7-4c0.4-1,0.7-2.1,0.9-3.2h2.7c0.1,1.1,0.4,2.2,0.9,3.2
		c0.7,1.5,1.6,2.9,2.8,4c1.2,1.1,2.6,2,4.1,2.6c1.6,0.6,3.3,1,5.1,1c1.9,0,3.6-0.3,5.1-1c1.6-0.6,2.9-1.5,4.1-2.6
		c1.2-1.1,2.1-2.4,2.7-4c0.6-1.3,0.9-2.6,1-4.1h8.6c-0.1,4.7-1,9.1-2.7,13.2C112,356.9,109.4,360.5,106.2,363.6"/>
    </g>
  </svg>
));

// return () => {
//   return <Icon as={SvgIcon} />;
// };

type IconProps = {
  style?: object;
};

const OutbrainLogo: React.FC<IconProps> = (props) => {
  const style = {
    fontSize: "7em",
    marginTop: "-41px",
    color: "#EE8600",
  };

  const newProps = {
    ...props,
    style: {
      ...style,
      ...props.style,
    }
  };

  return <Icon as={SvgIcon} {...newProps} />
};

export default OutbrainLogo;