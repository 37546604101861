import { Schema } from "rsuite";

export const AD_STATUS_ENABLE = 'ENABLE';
export const AD_STATUS_DISABLE = 'DISABLE';
export const AD_IDENTITY_TYPE_CUSTOM = 'CUSTOMIZED_USER';
export const AD_IDENTITY_TYPE_ACCOUNT = 'TT_USER';

export const initialData = {
  operation_status: AD_STATUS_ENABLE,
  advertiser_id: 0,
  ad_id: 0,
  adgroup_id: 0,
  ad_name: '',
  ad_text: "",
  ad_format: "SINGLE_VIDEO",
  image_id: "",
  video_id: "",
  use_tiktok_account: false,
  identity_id: "",
  identity_type: AD_IDENTITY_TYPE_CUSTOM,
  cta_type: "DYNAMIC",
  call_to_action: "",
  landing_page_url: "https://charmingkoala.com/?cid=__CAMPAIGN_ID__&clickid=__CLICKID__&citemid=__CID__"
};

const {BooleanType, NumberType, StringType} = Schema.Types;

const TikTokAdModel = Schema.Model({
  operation_status: StringType(), // [ENABLE|DISABLE] Default is ENABLE
  ad_id: NumberType(),
  adgroup_id: NumberType().isRequired("Ad Group ID is required"),
  ad_name: StringType().isRequired("Ad Name is required"),
  ad_text: StringType().isRequired("Ad Text is required"),
  ad_format: StringType().isRequired("Ad Format is required"),
  image_id: StringType(),//.isRequired("Image for cover is required"),
  video_id: StringType().isRequired("Video is required"),
  use_tiktok_account: BooleanType(),
  identity_id: StringType().isRequired("Identity ID is required"),
  identity_type: StringType().isRequired("Identity Type is required"),
  cta_type: StringType(),
  call_to_action: StringType()
    .addRule((value, data) => {
      if (data.cta_type === 'DYNAMIC') return true;

      return !!value;
    }, "CTA is required for 'Standard' type", true),
  landing_page_url: StringType().isRequired("Landing Page URL is required"),
});

export default TikTokAdModel;