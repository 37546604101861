import React from "react";
import {Col, DateRangePicker, Grid, IconButton, Input, InputGroup, Row, TagPicker, Tooltip, Whisper} from "rsuite";

import Title from "../../@Components/Title";
import {DateRange} from "rsuite/esm/DateRangePicker/types";
import SearchIcon from '@rsuite/icons/Search';
import CampaignList from "./CampaignList";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";
import {format, lastDayOfMonth, set, startOfMonth, subDays, subMonths} from "date-fns";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import CloseIcon from "@rsuite/icons/Close";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import _ from "lodash";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import {exportCsv} from "../../@Utils/Export";
import { currency, percent } from "../../@Utils/Format";
import Reload from "@rsuite/icons/Reload";
import RunningRound from '@rsuite/icons/RunningRound';
import AdGroupDayPartCalendar from "../Campaigns/AdGroupDayPartCalendar";

function addCalculatedValues(row: any) {
  const spend = isNaN(parseFloat(row.spend)) ? 0 : parseFloat(row.spend);
  const conversions = isNaN(parseInt(row.conversion)) ? 0 : parseInt(row.conversion);
  const clicks = isNaN(parseInt(row.clicks)) ? 0 : parseInt(row.clicks);
  const impressions = isNaN(parseFloat(row.impressions)) ? 0 : parseFloat(row.impressions);

  return {
    ...row,
    ...{
      campaign_id: isNaN(parseInt(row.campaign_id)) ? 0 : parseInt(row.campaign_id),
      spend: isNaN(parseFloat(row.spend)) ? 0 : parseFloat(row.spend),
      conversion: isNaN(parseInt(row.conversion)) ? 0 : parseInt(row.conversion),
      tt_app_id: isNaN(parseInt(row.tt_app_id)) ? 0 : parseInt(row.tt_app_id),
      ctr: impressions !== 0 ?(clicks/impressions)*100 : 0,
      ad_type: isNaN(parseInt(row.ad_type)) ? 0 : parseInt(row.ad_type),
      cpc: isNaN(parseFloat(row.cpc)) ? 0 : parseFloat(row.cpc),
      cpm: isNaN(parseFloat(row.cpm)) ? 0 : parseFloat(row.cpm),
      revenue: isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue),
      clicks: isNaN(parseInt(row.clicks)) ? 0 : parseInt(row.clicks),
      conversion_rate: isNaN(parseFloat(row.conversion_rate)) ? 0 : parseFloat(row.conversion_rate),
      tt_app_name: isNaN(parseFloat(row.tt_app_name)) ? 0 : parseFloat(row.tt_app_name),
      cpa: conversions !== 0 ? (spend / conversions) : 0,
      impressions: isNaN(parseFloat(row.impressions)) ? 0 : parseFloat(row.impressions),
      profit: row.revenue - row.spend
    },
  };
}

function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}
const CampaignGrid: React.FC<any> = () => {
  const [columns, setColumns] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_GRID_SHOW_COLUMNS, '');
  const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_SEARCH_QUERY, '');
  const [search, setSearch] = React.useState<string>(searchQuery);
  const [searchAccount, setSearchAccount] = React.useState<string>('');
  const removeSearchContent = '';
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [accounts, setAccounts] = React.useState([]);
  const [duplicateAccounts, setDuplicateAccounts] = React.useState([]);
  const [advertiserIds, setAdvertiserIds] = useStoredValue<any[]>(LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_FILTER_ADVERTISER_IDS, []);
  const [page, setPage] = React.useState(1);
  const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
  const [sortColumn, setSortColumn] = React.useState("campaign_id");
  const currentDayWithoutTime = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const startDate = set(new Date('2023-01-01'), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const [dateRange, setDateRange] = useStoredValue(LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_FILTER_DATE, {
    start: startDate,
    end: currentDayWithoutTime,
  });
  const last_month = subMonths(new Date(), 1);
  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setDates = (dates: DateRange | null) => {
    const dateRange = {
      start: set(dates ? dates[0] : new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
      end: set(dates ? dates[1] : new Date(), {hours: 23, minutes: 59, seconds: 59, milliseconds: 997}),
    };

    setDateRange(dateRange);
  };
  const handleBack = (columns:any) => {
      setColumns(columns);
  }
  const treeData =
    !loading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];
  //Removing content from search
  const searchRemoveContent = () => {
    setSearch(removeSearchContent);
    handleSearchChange('');
  };
  /**
   * Custom function for formatting date ranges. Idea is to make
   * it more human-readable.
   */
  const formatDates = (value: any, dateFormat: string = "LLL do, yyyy") => {
    if (!value[0] || !value[1]) {
      return null;
    }
    if (value[0].toString() === value[1].toString()) {
      return (
        <span style={{paddingRight: 10}}>
          {format(value[0], dateFormat)}
        </span>
      );
    }
    return (
      <span style={{paddingRight: 10}}>
        From {format(value[0], dateFormat)} to {format(value[1], dateFormat)}
      </span>
    );
  };
  React.useEffect(() => {
    HttpClient
      .get<any>('advertiser')
      .then(res => {
        // @ts-ignore
        setAccounts(_.sortBy(res.data,'advertiser_name'));
        // @ts-ignore
        setDuplicateAccounts(_.sortBy(res.data,'advertiser_name'));
      })
      .catch((_error) => {
        // TODO Add something
        // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
        //   {error.response.data?.error || error.toString()}
        // </Notification>);
      });
  }, []);

  const fetchData = (searchQuery?:string) => {
    setLoading(true);

    // Get data
    HttpClient
      .get<any>('tiktok_campaign', {
        ids: advertiserIds.join(),
        query: searchQuery ? searchQuery: search,
        start_date: format(dateRange.start, "yyyy-MM-dd 00:00:00.000"),
        end_date: format(dateRange.end, "yyyy-MM-dd 23:59:59.997"),
      })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        setLoading(false);
      });
  };


  //  React.useEffect(fetchData, [advertiserIds,search,dateRange]);

  const exportData = getExportData(treeData);

  function getExportData(data: any, order?: any) {

    // TODO Rewrite conversion from 'children' tree structure to an array for the deepest nodes
    const flattenTree = (list: any, parent?: any, order?: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        parent = parent || item;
       var current = {};
        current = Object.keys(item)
            .filter(key => columns.includes(key))
            .reduce((obj, key) => {
           if(key =='advertiser_name') {
               obj = {
                   ...obj,
                   ...{
                       "Account Name": item.advertiser_name,
                   }
               }
           } else if(key =='placement_type') {

                   obj = {
                       ...obj,
                       ...{
                           "Placement Type": item.placement_type,
                       }
                   }
           } else if (key =='spend') {
               obj = {
                   ...obj,
                   ...{
                       "Spend": currency(item.spend),
                   }
               }
           } else if(key =='conversion') {
               obj = {
                   ...obj,
                   ...{
                       "Conversion": item.conversion,
                   }
               }
           } else if(key =='cpa') {
               obj = {
                   ...obj,
                   ...{
                       "CPA" : currency(item.cpa),
                   }
               }
           } else if(key =='impressions') {
               obj = {
                   ...obj,
                   ...{
                       "Impressions": item.impressions,
                   }
               }
           } else if(key =='ctr') {
               obj = {
                   ...obj,
                   ...{
                       "CTR": percent(item.ctr),
                   }
               }
           } else if(key =='ad_type') {
               obj = {
                   ...obj,
                   ...{
                       "Ad Type": item.ad_type,
                   }
               }
           } else if(key =='cpc') {
               obj = {
                   ...obj,
                   ...{
                       "CPC": currency(item.cpc),
                   }
               }
           } else if(key =='cpm') {
               obj = {
                   ...obj,
                   ...{
                       "CPM": currency(item.cpm),
                   }
               }
           } else if(key =='clicks') {
               obj = {
                   ...obj,
                   ...{
                       "Clicks": item.clicks,
                   }
               }
           } else if(key =='conversion_rate') {
               obj = {
                   ...obj,
                   ...{
                       "Conversion rate": percent(item.conversion_rate),
                   }
               }
           } else if(key =='revenue') {
               obj = {
                   ...obj,
                   ...{
                       "Revenue": currency(item.revenue)
                   }
               }
           } else if(key =='profit') {
               obj = {
                   ...obj,
                   ...{
                       "Profit": currency(item.profit)
                   }
               }
           }
                return obj;
            }, {});

       const    currentItem = {
                    ...{
                        campaign_name: item.campaign_name || parent.campaign_name,
                        "Adgroup Name": item.adgroup_name || parent.adgroup_name
                    },
                    ...{...current}
                };

        if (item.children && Array.isArray(item.children)) {
          result.push(...flattenTree(item.children, currentItem));
        } else {
          result.push(currentItem);
        }

      });

      return result;
    };

    return (
        flattenTree(data.map((item: any) => item))
            // Clean, format and reorder by fields with right headers
            .map((item: any) => {
              return item;
            })
    );
  }

//  React.useEffect(fetchData, [advertiserIds,search,dateRange]);
  // Set Loading icon or built menu into the Account selector
  const renderMenu = (menu: any) => {
    if (accounts.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  const searchByQuery = () => {
    setPage(1);
    setSearch(searchQuery || '');
    fetchData(searchQuery);
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      searchByQuery();
    }
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const handleReloadButtonClick = () => fetchData(searchQuery);


  const handleSelect = (value: any, item: any, event:any) => {
    const selected = accounts.filter((v:any)=> value.includes(v.advertiser_id));
    const search = accounts.filter((v:any)=> v.advertiser_name.toLowerCase().includes(searchAccount.toLowerCase()));
    if(value.length == 0) {
      setAccounts(duplicateAccounts);
    } else {
        //@ts-ignore
      setAccounts([...new Set([...search ,...selected])]);
    }
  };
  const searchChange = (value: any, search:any) => {
      setAccounts(duplicateAccounts);
      setSearchAccount(value);
  };

  const handleChange = (v:any) => {
    if (v == null) {
      setAdvertiserIds([])
      setAccounts(duplicateAccounts);
    } else {
      setAdvertiserIds(v)
    }
  };
//Filter highlight code
const initialStartDateRef = React.useRef(dateRange.start);  
const initialEndDateRef = React.useRef(dateRange.end); 
const initialSearchFilter = React.useRef(searchQuery !== '' ? searchQuery: {})
const initialAdvertiserFilter = React.useRef(advertiserIds !== null && advertiserIds.length > 0 ? advertiserIds : {});
 React.useEffect(() => {

    if (advertiserIds === initialAdvertiserFilter.current) {
      const innerDiv = document.querySelector('.account-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.account-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if (searchQuery === initialSearchFilter.current) {
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.search-filter');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if ((dateRange.start.getTime() === initialStartDateRef.current.getTime()) && (dateRange.end.getTime() === initialEndDateRef.current.getTime())) {
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.date-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }
  }, [searchQuery, advertiserIds, dateRange]);

  return (
    <>
      <Title title="TikTok Campaign Report"/>
      <Grid fluid>
        <Row className="show-grid">
          <Col md={7}>
              <IconButton
                  size="lg"
                  appearance="subtle"
                  style={{marginLeft: 15}}
                  icon={<FileDownloadIcon/>}
                  placement="right"
                  onClick={() =>
                      exportCsv(
                          `campaign_tiktok.csv`,
                          exportData
                      )
              }
              >
                Download CSV
              </IconButton>
          </Col>
          <Col md={5}>
            <TagPicker
              className="account-filter"
              block
              name="traffic_source_account"
              placeholder="Select Account"
              placement="autoVerticalStart"
              data={accounts}
              valueKey="advertiser_id"
              labelKey="advertiser_name"
              groupBy="traffic_source_type"
              value={advertiserIds}
              onSelect = {handleSelect}
              onSearch={searchChange}
              onChange={(v:any) =>handleChange(v)}
              renderMenu={renderMenu}
            />
          </Col>
          <Col md={5}>
            <DateRangePicker
              className="date-filter"
              size="md"
              placement="autoVerticalEnd"
              renderValue={formatDates}
              value={[dateRange.start, dateRange.end]}
              defaultValue={[dateRange.start, dateRange.end]}
              onChange={setDates}
              cleanable={false}
              style={{width: "100%"}}
              // TODO Uncomment it and ***FIX*** a typescript warning
              // disabledDate={allowedMaxDays(60)}
              ranges={[
                {
                  label: "Yesterday",
                  value: [subDays(new Date(), 1), subDays(new Date(), 1)],
                },
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "Last 7 days",
                  value: [subDays(new Date(), 7), subDays(new Date(), 0)],
                },
                {
                  label: "This Month",
                  value: [startOfMonth(new Date()), new Date()],
                },
                {
                  label: "Last Month",
                  value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
                },
              ]}
            />
          </Col>
          <Col md={5}>
            <InputGroup style={{width: "100%", }}>
              <InputGroup.Button onClick={searchByQuery}>
                <SearchIcon/>
              </InputGroup.Button>
              <Input
                className="search-filter"
                placeholder="Search"
                value={searchQuery}
                onChange={(v) => handleSearchChange(v)}
                onKeyUp={onKeyUpEnter}
              />
              <InputGroup.Button onClick={searchRemoveContent}>
                <CloseIcon/>
              </InputGroup.Button>
            </InputGroup>
          </Col>
          <Col md={2}>
            <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Run Report</Tooltip>}
            >
              <IconButton
                  color="blue"
                  appearance="primary"
                  size='sm'
                  style={{  height: 35, width:100
              }}
                  icon={<RunningRound />}
                  onClick={handleReloadButtonClick}
              >
                Go
              </IconButton>
            </Whisper>
          </Col>
        </Row>
      </Grid>
      <CampaignList
        parentCallback = {handleBack}
        data={treeData}
        page={page}
        setPage={setPage}
        loading={loading}
      />
    </>
  );
};



export default CampaignGrid;