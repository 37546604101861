import React from "react";
import {Content, Divider, FlexboxGrid, Loader} from "rsuite";

enum CardTypes {
  currency,
  number,
  string,
  percent,
}

type CardProps = {
  label: string;
  type: keyof typeof CardTypes;
  value: any;
  color?: string;
  averageTitle?: string;
  average?: number | null;
  hasAverage?: boolean;
};

const Card: React.FC<CardProps> = (props) => {
  const color = props.color || "#e5e5ea";
  const hasAverage = props.hasAverage !== false;

  const formatValue = (value: any): string => {
    if ([null, NaN, undefined].includes(value)) {
      value = 0;
    }
    switch (props.type) {
      case "currency":
        return parseFloat(value).toLocaleString(undefined, {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 3,
        });
      case "number":
        return parseFloat(value).toLocaleString();
      case "percent":
        return `${parseFloat(value).toLocaleString()}%`;
      default:
        return value;
    }
  };

  return (
    <Content className="visualization-stat-card" style={{padding: 20}}>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}><div style={{color: color, fontSize: '24px'}}><strong>Total {props.label}</strong></div></FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12} style={{textAlign: "right"}}>
          <div style={{fontSize: '24px'}}>
            <strong>
            {props.value === null ? (
              <Loader size="sm" speed="slow"/>
            ) : (
              formatValue(props.value)
            )}
            </strong>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Divider style={{backgroundColor: color}}/>
      {hasAverage ?
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={12}>{props.averageTitle}</FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12} style={{textAlign: "right"}}>
            {props.average === null
              ? <Loader size="sm" speed="slow"/>
              : formatValue(props.average)}
          </FlexboxGrid.Item>
        </FlexboxGrid>
        :
        <div style={{textAlign: "right"}}>{props.averageTitle}</div>}
    </Content>
  );
};

export default Card;
