import React from "react";
import { Button, DatePicker, Drawer, FlexboxGrid, Form, IconButton, Notification, Schema } from "rsuite";
import CampaignContentList from "../Campaigns/CampaignContentList";
import HttpClient from "../../@Utils/HttpClient";
import { pushInforming } from "../../@Utils/Messager";
import CampaignContent, { addEmptyMedia } from "./CampaignContent";
import PlusRound from "@rsuite/icons/PlusRound";
import { format } from "date-fns";

type CampaignContentListDrawerProps = {
  itemData: any;
  isOpen: any;
  setIsOpen: any;
  campaignId: number;
  campaignName: any;
  setCampaignId: any;
  apiCampaignId: any;
  start: Date;
  end: Date;
  setContentDrawerStart: any;
  setContentDrawerEnd: any;
};

const CampaignContentListDrawer: React.FC<CampaignContentListDrawerProps> = ({
  itemData,
  isOpen,
  setIsOpen,
  campaignId = 0,
  campaignName = '',
  apiCampaignId = 0,
  start,
  end,
  setContentDrawerStart,
  setContentDrawerEnd,
}) => {
  const initFormData = {
    api_campaign_id: itemData.api_campaign_id ? itemData.api_campaign_id : null,
    campaign_id: campaignId,
    campaign_name: itemData.campaign_name,
    content_id: 0,
    target_url: `https://charmingkoala.com?cid=${apiCampaignId}`,
    traffic_source_id: itemData.traffic_source_id,
    traffic_source_name: itemData.traffic_source_name,
    traffic_source_type: itemData.traffic_source_type,
    account: itemData.account,
    media_list: addEmptyMedia(),
  };

  itemData.campaign_id = campaignId;
  itemData.campaign_name = itemData.campaign_name ? itemData.campaign_name :campaignName;

  const [formData, setFormData] = React.useState(initFormData);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [isCampaignContentDrawerOpen, setIsCampaignContentDrawerOpen] = React.useState(false);
  const [campaignContentId, setCampaignContentId] = React.useState(0);
  const [formError, setFormError] = React.useState<any>({});
  const [formValue, setFormValue] = React.useState({
    start: start,
    end: end,
  });

  React.useEffect(() => {
    setFormValue({
      start: start,
      end: end,
    });
  }, [start, end]);

  const fetchData = () => {
    // Clear and mask a table
    setData([]);
    setLoading(true);

    if (campaignId > 0 && isOpen) {
      // Get data
      HttpClient
        .get<any>('campaign_content_media_stats', {
          campaignId,
          startDate: format(start, "yyyy-MM-dd 00:00:00.000"),
          endDate: format(end, "yyyy-MM-dd 23:59:59.997"),
        })
        .then(res => {
          setLoading(false);
          setData(formatDataRecursively(res.data.data));
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
          setLoading(false);
        });
    }
  };

  const handleAfterCreateOrUpdateGridRow = (id: number, item: any) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.content_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      item.content_id = id;
      // Push a new row at the top
      setData([item, ...data]);
    }

    fetchData();
  };

  const formatRow = React.useCallback((item: any) => {
    const formatAsInt = (value: any, defVal: number | null = 0) => !isNaN(parseInt(value)) ? parseInt(value) : defVal;
    const formatAsFloat = (value: any, defVal: number = 0) => !isNaN(parseFloat(value)) ? parseFloat(value) : defVal;

    const cost = formatAsFloat(item.cost);
    const revenue = formatAsFloat(item.revenue);
    const spent = formatAsFloat(item.spent);
    const net = formatAsFloat(item.net) || (revenue - spent) || 0;
    const roi = item.roi || (spent ? (net / spent) * 100 : 0);

    return {
      ...item,
      campaign_id: formatAsInt(item.campaign_id),
      content_id: formatAsInt(item.content_id, null),
      media_id: formatAsInt(item.media_id, null),
      cost,
      revenue,
      spent,
      net,
      ctr: formatAsFloat(item.ctr),
      vctr: formatAsFloat(item.vctr),
      cpc: formatAsFloat(item.cpc),
      cvr: formatAsFloat(item.cvr),
      cpa: formatAsFloat(item.cpa),
      cpm: formatAsFloat(item.cpm),
      vcpm: formatAsFloat(item.vcpm),
      arb_clicks: formatAsInt(item.arb_clicks),
      taboola_clicks: formatAsInt(item.taboola_clicks),
      impressions: formatAsInt(item.impressions),
      visible_impressions: formatAsInt(item.visible_impressions),
      actions: formatAsInt(item.actions),
      roi,
      media_list: [],
    };
  }, []);

  const formatDataRecursively = React.useCallback((list: any) => {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = formatDataRecursively(row.children);
      }

      return formatRow(row);
    });
  }, [formatRow]);

  React.useEffect(fetchData, [isOpen, campaignId, start, end, formatDataRecursively]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const updateDataListOnClose = (rowData: any) => {
    const newData = data.map((row) => {
      if(rowData.content_id == row.content_id) {
        return rowData;
      } else {
        return row;
      }
    });
    setData(newData);
  };
  
  const handleFormChange = (formValue: any) => {
    setFormValue(formValue);
    setContentDrawerStart(formValue.start);
    setContentDrawerEnd(formValue.end);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} size="full">
        <Drawer.Header>
          <Drawer.Title>Campaign Contents</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <div>
            <span>Campaign ID: {itemData.campaign_id}</span><br/>
            <span>Campaign Name: {itemData.campaign_name}</span>
          </div>

          <FlexboxGrid justify="space-between" style={{marginTop: 15, marginBottom: 15, padding: "0 15px"}}>
            <FlexboxGrid.Item colspan={16}>
              <IconButton
                size="lg"
                color="blue"
                appearance="subtle"
                icon={<PlusRound/>}
                onClick={() => {
                  setCampaignContentId(0);
                  setFormData(initFormData);
                  setIsCampaignContentDrawerOpen(true);
                }}
              >
                New
              </IconButton>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={8} style={{textAlign: "right"}}>
              <Form
                layout="inline"
                model={model}
                formValue={formValue}
                formError={formError}
                onChange={handleFormChange}
                onCheck={setFormError}
              >
                <Form.Group controlId="start">
                  <Form.ControlLabel></Form.ControlLabel>
                  <Form.Control
                    name="start"
                    accepter={DatePicker}
                    cleanable={false}
                    style={{width: 150}}
                    placement="autoVerticalStart"
                  />
                </Form.Group>
                <Form.Group controlId="end">
                  <Form.ControlLabel></Form.ControlLabel>
                  <Form.Control
                    name="end"
                    accepter={DatePicker}
                    cleanable={false}
                    style={{width: 150}}
                    placement="autoVerticalStart"
                  />
                </Form.Group>

                <Button
                  appearance="primary"
                  color="green"
                  onClick={() => {setContentDrawerStart(start); setContentDrawerEnd(end);}}
                  style={{marginRight: 0}}
                >
                  Apply
                </Button>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <CampaignContentList
            data={data.map((item: object) => ({
              ...item,
              campaign_name: itemData.campaign_name,
            }))}
            loading={loading}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            setIsCampaignContentDrawerOpen={setIsCampaignContentDrawerOpen}
            setFormData={setFormData}
            setCampaignContentId={setCampaignContentId}
          />
        </Drawer.Body>
      </Drawer>

      <CampaignContent
        formValue={formData}
        setFormValue={setFormData}
        isOpen={isCampaignContentDrawerOpen}
        setIsOpen={setIsCampaignContentDrawerOpen}
        campaignContentId={campaignContentId}
        campaignId={campaignId}
        setCampaignContentId={setCampaignContentId}
        updateDataListOnClose={updateDataListOnClose}
        onAfterCreateOrUpdateGridRow = {handleAfterCreateOrUpdateGridRow}
      />
    </>
  );
};

const { DateType } = Schema.Types;

const model = Schema.Model({
  start: DateType(),
  end: DateType(),
});

export default CampaignContentListDrawer;
