import React from "react";
import { ButtonToolbar, FlexboxGrid, IconButton, Notification, Table, Toggle, Tooltip, Whisper } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import FolderFillIcon from "@rsuite/icons/FolderFill";
import HttpClient from "../../@Utils/HttpClient";
import { pushInforming } from "../../@Utils/Messager";
import ExitIcon from "@rsuite/icons/Exit";
import TrashIcon from "@rsuite/icons/Trash";
import ConfirmModal from "../ConfirmModal";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, renderColumns } from "../TableColumnsMenu";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { sort } from "../../@Utils/Sorting";

const { Column, HeaderCell, Cell } = Table;

type OfferSourcesTreeProps = {
  data?: any;
  loading?: any;
  sortType?: any;
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  setIsOpenDrawer: any;
  setFormData: any;
  setOfferSourceId: any;
  refreshCallback?: () => void;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const OfferSourcesList: React.FC<OfferSourcesTreeProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn,
  onSortColumn,
  setIsOpenDrawer,
  setFormData,
  setOfferSourceId,
  refreshCallback,
  onAfterCreateOrUpdateGridRow
}) => {
  const rowKey: string = "node_id";
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [selectedItem, setSelectedItem] = React.useState({ offer_source_id: 0 });
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = React.useState(false);

  const handleOpen = () => setRemoveConfirmModalOpen(true);

  const handleClose = () => setRemoveConfirmModalOpen(false);

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleEditActionClick = (rowData: any) => {
    setOfferSourceId(rowData.offer_source_id)
    setFormData(rowData);
    setIsOpenDrawer(true);
  };

  /**
   * Rewrite list data with formatted data
   * @param list
   */
  const formatData = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        ...{
          offer_source_id: isNaN(parseInt(item.offer_source_id)) ? 0 : parseInt(item.offer_source_id),
          offer_source_category_id: isNaN(parseInt(item.offer_source_category_id)) ? 0 : parseInt(item.offer_source_category_id),
          offer_payout: isNaN(parseFloat(item.offer_payout)) ? 0 : parseFloat(item.offer_payout),
          status: isNaN(parseInt(item.status)) ? 0 : parseInt(item.status),
        },
      };
    });
  };

   const sortData = (list: any) => {
    if (sortColumn && sortType) {
      return formatData(sort(list, sortColumn, sortType));
    }
    return formatData(list);
  };

  /**
   * Transform flat list into a tree structure
   * @param list
   */
  const transformListToTree = (list: any) => {
    list = Array.isArray(list) ? list : [];

    const nodeIdDelimiter = '_';

    if (!list.length) {
      return [];
    }

    // Add node title and node ID values
    const listData = list.map((item: any) => ({
      ...item,
      [rowKey]: nodeIdDelimiter + item.offer_source_category_id + nodeIdDelimiter + item.offer_source_id,
      node_level: 2,
      node_title: item.offer_source_name,
    }));

    // Build groups by category ID
    const groupsByCategory: any = {};
    const categoriesOrder: number[] = [];
    listData.forEach((item: any) => {
      if (!categoriesOrder.find(value => value === item.offer_source_category_id)) {
        categoriesOrder.push(item.offer_source_category_id);
      }

      groupsByCategory[item.offer_source_category_id] = {
        ...item,
        [rowKey]: nodeIdDelimiter + item.offer_source_category_id,
        node_level: 1,
        node_title: item.offer_source_category_name,
        children: [],
      };
    });

    // Add items into groups by category ID as children
    listData.forEach((item: any) => {
      groupsByCategory[item.offer_source_category_id].children.push(item);
    });

    // Convert object into array
    const tree = categoriesOrder.map((categoryId) => groupsByCategory[categoryId]);

    return aggregateDataByColumns(tree, [

    ], ["roi", "offer_ctr"]);
  };

  const aggregateDataByColumns = (data: any, columns: string[], avgColumns: string[]) => {
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    data.forEach((node: any) => {
      columns.forEach((field: string) => {
        const values = node.children.map((item: any): number => {
          return parseFloat(item[field]);
        });

        node[field] = values.reduce(summarizer, 0) || 0;
      });

      avgColumns.forEach((field: string) => {
        const values = node.children.map((item: any): number => {
          return parseFloat(item[field]);
        });

        node[field] = (values.reduce(summarizer, 0) / values.length) || 0;
      });
    });

    return data;
  };

   const toggleFunction = (rowData:any) => {
    if (rowData.status===2) {  
       handleActivateActionClick(rowData);
    } else {
      handleArchiveActionClick(rowData);
    }
  };

  const handleActivateActionClick = (rowData: any) => {
    activateOffer(rowData.offer_source_id, rowData);
  };

  const handleArchiveActionClick = (rowData: any) => {
    archiveOffer(rowData.offer_source_id, rowData);
  };

  const activateOffer = (offerId: number, offer: any) => {
    changeOfferSourceStatus(offerId, offer, 1);
  };

  const archiveOffer = (offerId: number, offer: any) => {
    changeOfferSourceStatus(offerId, offer, 2);
  };

  const changeOfferSourceStatus = (offerSourceId: number, offerSource: any, status: number) => {
    offerSource = {
      ...offerSource,
      status,
    };

    // Update status of offer source
    HttpClient.put<any>(`offer_source/${offerSourceId}`, offerSource)
      .then((res) => {
        const isSuccess = res.status === 200;
        const type = isSuccess ? "success" : "error";
        const text = isSuccess ? "Offer Source has been " + (status === 1 ? "activated" : "archived") : res.statusText;

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);
         if (refreshCallback instanceof Function) {
          refreshCallback();
        }

        // Update a grid data
        isSuccess && onAfterCreateOrUpdateGridRow(offerSourceId, offerSource);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const removeItem = (itemId: number = 0) => {
    if (itemId === 0) {
      return;
    }

    HttpClient.delete<any>(`offer_source/${itemId}`)
      .then(res => {
        const type = res.status === 200 ? "success" : "error";
        const text = res.status === 200 ? "Offer Source has been removed" : res.statusText;

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

        if (refreshCallback instanceof Function) {
          refreshCallback();
        }
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.toString()}
        </Notification>);
      });
  };

  const handleRemove = () => {
    removeItem(selectedItem ? selectedItem.offer_source_id : 0);

    handleClose();
  };

  const handleRemoveActionClick = (rowData: any) => {
    setSelectedItem(rowData);

    handleOpen();
  };

  const columns = React.useMemo(() => [

  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.OFFER_SOURCES_PAGE_GRID_SHOW_COLUMNS,
    columns
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.OFFER_SOURCES_PAGE_GRID_COLUMNS_ORDER,
    columns
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    // @ts-ignore
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  }

  return (
    <>
      <FlexboxGrid justify="end">
        <FlexboxGrid.Item colspan={1}>

        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DndProvider backend={HTML5Backend}>
        <Table
          isTree
          defaultExpandAllRows={true}
          shouldUpdateScroll={false}
          height={600}
          data={formatData(data)}
          rowKey={rowKey}
          expandedRowKeys={expandedRowKeys}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={onSortColumn}
          // onRowClick={handleExpanded}
        >
          <Column width={250} align="left" fixed sortable >
            <HeaderCell>Offer Sources</HeaderCell>
            <Cell dataKey="offer_source_name"/>
          </Column>
          <Column width={150} align="left" fixed sortable>
            <HeaderCell>Offer Sources ID</HeaderCell>
            <Cell dataKey="offer_source_id"/>
          </Column>
          
          <Column width={200} align="center" fixed>
            <HeaderCell>Action</HeaderCell>
            <ActionCell
              editHandler={handleEditActionClick}
              removeHandler={handleRemoveActionClick}
              changeStatusHandler={toggleFunction}
            />
          </Column>
          <Column width={250} align="center" fixed sortable>
            <HeaderCell>Offer Source Category</HeaderCell>
            <Cell dataKey="offer_source_category_name"/>
          </Column>
          {renderColumns(builtColumns, handleDragColumn)}
        </Table>
      </DndProvider>

      <ConfirmModal
        title="Removing"
        open={removeConfirmModalOpen}
        onClose={handleClose}
        onYes={handleRemove}
      >
        Are you sure you want to remove this Offer Source?
      </ConfirmModal>
    </>
  );
};

export const ActionCell = ({
  rowData,
  editHandler,
  removeHandler,
  changeStatusHandler,
  ...props
}: any) =>{
const status = rowData.status;
return(
  <Cell {...props} className="link-group">
    {/* {rowData.node_level === 2 && */}
      <ButtonToolbar>
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>Edit</Tooltip>}
        >
          <IconButton
            icon={<EditIcon />}
            onClick={(e) => editHandler(rowData, e)}
          />
        </Whisper>
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>Delete</Tooltip>}
        >
          <IconButton
            icon={<TrashIcon />}
            onClick={(e) => removeHandler(rowData, e)}
          />
        </Whisper>
        <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Enable/Disable Toggle</Tooltip>}
          >
            <Toggle
              loading={rowData.isUpdating}
              checked={status === 1}
              onChange={() => changeStatusHandler(rowData)}
              size="md"
              style={{marginBottom: 'auto', marginTop: 'auto'}}
            />
        </Whisper>
        {/* {
          rowData.status === 2
            ?
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Exit</Tooltip>}
            >
              <IconButton
                icon={<ExitIcon />}
                onClick={(e) => activateHandler(rowData, e)}

              />
            </Whisper>
            :
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Activate</Tooltip>}
            >
              <IconButton
                icon={<FolderFillIcon />}
                onClick={(e) => archiveHandler(rowData, e)}
              />
            </Whisper>
        } */}
      </ButtonToolbar>
    {/* } */}
  </Cell>);};

export default OfferSourcesList;