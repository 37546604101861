import React, { ReactNode } from "react";
import {
  Button,
  Drawer,
  toaster,
  Notification
} from "rsuite";
import { FormInstance } from "rsuite/Form";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import TikTokAdForm from "../Campaigns/TikTokAdForm";
import HttpClient from "../../@Utils/HttpClient";

type TikTokAdDrawerProps = {
  formValue: any;
  setFormValue: any;
  isOpen: any;
  setIsOpen: any;
  refreshCallback?: () => void;
};

const TikTokAdDrawer: React.FC<TikTokAdDrawerProps> = ({
  formValue,
  setFormValue,
  isOpen,
  setIsOpen,
  refreshCallback,
}) => {
  const mainFormRef = React.createRef<FormInstance>();
  const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState(false);

  const handleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      return;
    }

    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    setIsSaveButtonLoading(true);

    if (formValue.ad_id === 0) {
      // Create a new one
      HttpClient.post<object, any>('tiktok_ad', formValue)
        .then(res => {
          const currentAdId = parseInt(res.data.ad_id);

          // Update form data
          const updatedFormValue = {
            ...formValue,
            ad_id: currentAdId,
          };

          setIsSaveButtonLoading(false);
          setFormValue(updatedFormValue);

          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "TikTok Ad has been created" : res.statusText;

          pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);
          setIsOpen(false);

          // Refresh parent grid data
          if (refreshCallback instanceof Function) {
            refreshCallback();
          }
        })
        .catch(error => {
          setIsSaveButtonLoading(false);

          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    } else {
      // Update an existing one
      HttpClient.put<any>(`tiktok_ad/${formValue.ad_id}`, formValue)
        .then(res => {
          setIsSaveButtonLoading(false);

          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "TikTok Ad has been updated" : res.statusText;

          pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...formValue,
          };
          setIsOpen(false)

          // Refresh parent grid data
          if (refreshCallback instanceof Function) {
            refreshCallback();
          }
        })
        .catch(error => {
          setIsSaveButtonLoading(false);

          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    }
  }

  return (
    <>
      <Drawer open={isOpen} backdrop={false} onClose={() => setIsOpen(false)} size="md">
        <Drawer.Header>
          <Drawer.Title>
            {formValue.ad_id === 0 ? 'Add TikTok Ad' : 'Edit TikTok Ad'}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleSave} appearance="primary" loading={isSaveButtonLoading}>
              Save
            </Button>
            <Button onClick={() => {
              setIsOpen(false)
              //setFormError({});
            }}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <TikTokAdForm
            formRef={mainFormRef}
            formValue={formValue}
            parentCallback={handleCallback}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default TikTokAdDrawer;
