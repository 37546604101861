import React, { Dispatch } from "react";
import { parseISO } from "date-fns";

export const LOCAL_STORAGE_KEYS = {
  CAMPAIGN_PAGE_SORT_TYPE: "campaigns_page.sort_type",
  CAMPAIGN_PAGE_SORT_COLUMN: "campaigns_page.sort_column",
  CAMPAIGNS_PAGE_FILTERS: "campaigns_page.filters",
  CAMPAIGNS_PAGE_SEARCH_QUERY: "campaigns_page.filter.search",
  CAMPAIGNS_PAGE_GRID_COLUMNS_ORDER: "campaigns_page.grid.columns_order",
  CAMPAIGNS_PAGE_GRID_SHOW_COLUMNS: "campaigns_page.grid.show_columns",
  CAMPAIGNS_REPORT_PAGE_FILTER_DATE: "campaigns_page.filter_date",
  FUNNELS_PAGE_FILTER_ACTIVE: "funnels_page.filter.active",
  FUNNELS_PAGE_FILTER_CAMPAIGN: "funnels_page.filter.campaign",
  FUNNELS_PAGE_FILTER_SOURCE: "funnels_page.filter.source",
  FUNNELS_PAGE_FILTER_DATE: "funnels_page.filter.date",
  FUNNELS_PAGE_GRID_COLUMNS_ORDER: "funnels_page.grid.columns_order",
  FUNNELS_PAGE_GRID_SHOW_COLUMNS: "funnels_page.grid.show_columns",
  FUNNELS_PAGE_SORT_TYPE: "funnels_page.sort_type",
  FUNNELS_PAGE_SORT_COLUMN: "funnels_page.sort_column",
  OFFERS_PAGE_FILTER_ACTIVITY_STATUS: "offers_page.filter.activity_status",
  OFFERS_PAGE_FILTER_CATEGORY: "offers_page.filter.category",
  OFFERS_PAGE_FILTER_DATE: "offers_page.filter.date",
  OFFERS_PAGE_SEARCH_QUERY: "offers_page.filter.search",
  OFFERS_PAGE_GRID_COLUMNS_ORDER: "offers_page.grid.columns_order",
  OFFERS_PAGE_GRID_SHOW_COLUMNS: "offers_page.grid.show_columns",
  OFFERS_PAGE_SORT_TYPE: "offers_page.filter_sort_type",
  OFFERS_PAGE_SORT_COLUMN: "offers_page.filter_sort_column",
  OFFER_SOURCES_PAGE_FILTER_SORT_TYPE: "offer_sources_page.filter.sort_type",
  OFFER_SOURCES_PAGE_FILTER_SORT_COLUMN: "offer_sources_page.filter.sort_column",
  TRAFFIC_SOURCES_PAGE_SORT_TYPE: "traffic_sources_page.filter.sort_type",
  TRAFFIC_SOURCES_PAGE_SORT_COLUMN: "traffic_sources_page.filter.sort_column",
  OFFER_SOURCES_PAGE_FILTER_ACTIVITY_STATUS: "offer_sources_page.filter.activity_status",
  OFFER_SOURCES_PAGE_FILTER_CATEGORY: "offer_sources_page.filter.category",
  OFFER_SOURCES_PAGE_FILTER_DATE: "offer_sources_page.filter.date",
  OFFER_SOURCES_PAGE_GRID_COLUMNS_ORDER: "offer_sources_page.grid.columns_order",
  OFFER_SOURCES_PAGE_GRID_SHOW_COLUMNS: "offer_sources_page.grid.show_columns",
  RULES_PAGE_SEARCH_QUERY: "rules_page.filter.search",
  RULES_PAGE_SHOW_TEST_RULES: "rules_page.show.test.rules",
  RULES_PAGE_GRID_COLUMNS_ORDER: "rules_page.filter.columns_order",
  RULES_PAGE_GRID_SHOW_COLUMNS: "rules_page.filter.show_columns",
  RULE_PAGE_SORT_TYPE: "rules_page.filter.sort_type",
  RULE_PAGE_SORT_COLUMN: "rules_page.filter.sort_column",
  TONIC_KEYWORD_REPORT_PAGE_FILTER_DATE: "tonic_keyword_report_page.filter.date",
  TONIC_KEYWORD_REPORT_PAGE_SEARCH_QUERY: "tonic_keyword_report_page.filter.search",
  TONIC_KEYWORD_REPORT_PAGE_GRID_COLUMNS_ORDER: "tonic_keyword_report_page.grid.columns_order",
  TONIC_KEYWORD_REPORT_PAGE_GRID_SHOW_COLUMNS: "tonic_keyword_report_page.grid.show_columns",
  TONIC_DOMAIN_REPORT_PAGE_SEARCH_QUERY: "tonic_domain_report_page.filter.search",
  TONIC_KEYWORD_GRID_PAGE_SORT_TYPE: "tonic_keyword_report_page.grid.sort_type",
  TONIC_KEYWORD_GRID_PAGE_SORT_COLUMN: "tonic_keyword_report_page.grid.sort_columns",
  TIKTOK_CAMPAIGN_REPORT_PAGE_FILTER_ADVERTISER_IDS: "tiktok_campaign_report_page.filter.account",
  TIKTOK_CAMPAIGN_REPORT_PAGE_FILTER_DATE: "tiktok_campaign_report_page.filter.date",
  TIKTOK_CAMPAIGN_REPORT_PAGE_SEARCH_QUERY: "tiktok_campaign_report_page.grid.search",
  TIKTOK_CAMPAIGN_REPORT_PAGE_GRID_COLUMNS_ORDER: "tiktok_campaign_report_page.grid.columns_order",
  TIKTOK_CAMPAIGN_REPORT_PAGE_GRID_SHOW_COLUMNS: "tiktok_campaign_report_page.grid.show_columns",
  TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_FILTER_DATE: "tiktok_domain_direct_summary_report_page.filter.date",
  TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_SEARCH_QUERY: "tiktok_domain_direct_summary_report_page.grid.search",
  TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_GROUPED_BY_DATE: "tiktok_domain_direct_summary_report_page.grid.grouped_by_date",
  TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_COLUMNS_ORDER: "tiktok_domain_direct_summary_report_page.grid.columns_order",
  TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_SHOW_COLUMNS: "tiktok_domain_direct_summary_report_page.grid.show_columns",
  TRAFFIC_SOURCES_PAGE_SEARCH_QUERY: "traffic_sources_page.filter.search",
  TRAFFIC_SOURCES_PAGE_GRID_COLUMNS_ORDER: "traffic_sources_page.grid.columns_order",
  TRAFFIC_SOURCES_PAGE_GRID_SHOW_COLUMNS: "traffic_sources_page.grid.show_columns",
  SYSTEM1_DOMAINS_PAGE_GRID_COLUMNS_ORDER: "system1_domains_page.grid.columns_order",
  SYSTEM1_DOMAINS_PAGE_GRID_SHOW_COLUMNS: "system1_domains_page.grid.show_columns",
};

export const useStoredValue = function <T>(localStorageKey: string, initialValue: T): [T, Dispatch<T>] {
  const storedValue = localStorage.getItem(localStorageKey);

  const parseStoredValue = (value: string) => {
    const parsed = JSON.parse(value);

    // Parse dates values from string format
    if (parsed?.dates && parsed.dates?.start) {
      parsed.dates.start = parseISO(parsed.dates.start);
    }

    if (parsed?.dates && parsed.dates?.end) {
      parsed.dates.end = parseISO(parsed.dates.end);
    }

    if (parsed?.start) {
      parsed.start = parseISO(parsed.start);
    }

    if (parsed?.end) {
      parsed.end = parseISO(parsed.end);
    }

    return parsed;
  };

  /**
   * Helper function for parsing storage date range filters
   * @param value
   */
    // Set state via useState Hook
  const [value, setValue] = React.useState<T>(
      !storedValue ? initialValue : parseStoredValue(storedValue)
    );

  // Save values to local storage once state updates
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};