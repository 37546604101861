import React, { useState, useEffect, useRef } from 'react';

interface Slot {
    day: number;
    slotIndex: number;
}

interface DayPartingTableProps {
    binaryString?: string;
    handleDayParting?: (binaryString: string) => void;
}

const DayPartingTable: React.FC<DayPartingTableProps> = ({ binaryString = '', handleDayParting }) => {
    // State to keep track of selected slots and binary states
    const [selectedSlots, setSelectedSlots] = useState<Slot[]>([]);
    const [binaryStates, setBinaryStates] = useState<string[]>([]);

    // When the component mounts or binaryString prop changes, update the selected slots and binary states
    useEffect(() => {
        if(binaryString) {
            setSelectedSlots(getSelectedSlotsFromBinary(binaryString));
            setBinaryStates(splitBinaryString(binaryString));
        }

    }, [binaryString]);

    // When selectedSlots state changes, update binaryStates state
    useEffect(() => {
        updateBinaryState();
    }, [selectedSlots]);

    // When binaryStates state changes, call handleDayParting if the prop is provided
    useEffect(() => {
        if (handleDayParting) {
            handleDayParting(joinBinaryStates(binaryStates));
        }
    }, [binaryStates, handleDayParting]);

    // Refs to handle mouse interaction
    const isMouseDownRef = useRef(false);
    const startSlotRef = useRef<Slot | null>(null);

    // Function to handle individual slot clicks
    const handleSlotClick = (day: number, slotIndex: number) => {
        const updatedSlots = [...selectedSlots];
        const existingIndex = updatedSlots.findIndex((slot) => slot.day === day && slot.slotIndex === slotIndex);
        if (existingIndex !== -1) {
            updatedSlots.splice(existingIndex, 1);
        } else {
            updatedSlots.push({ day, slotIndex });
        }
        setSelectedSlots(updatedSlots);
    };

    // Function to handle mouse down on a slot
    const handleSlotMouseDown = (day: number, slotIndex: number) => {
        isMouseDownRef.current = true;
        startSlotRef.current = { day, slotIndex };
    };

    // Function to handle mouse enter on a slot
    const handleSlotMouseEnter = (day: number, slotIndex: number) => {
        if (isMouseDownRef.current && startSlotRef.current) {
            const { day: startDay, slotIndex: startSlotIndex } = startSlotRef.current;
            const updatedSlots = getSlotsBetween(startDay, startSlotIndex, day, slotIndex);
            setSelectedSlots((prevSelectedSlots) => {
                const filteredSlots = updatedSlots.filter(
                    (slot) => !prevSelectedSlots.some((prevSlot) => prevSlot.day === slot.day && prevSlot.slotIndex === slot.slotIndex)
                );
                return [...prevSelectedSlots, ...filteredSlots];
            });
        }
    };

    // Function to handle mouse up on a slot
    const handleSlotMouseUp = () => {
        isMouseDownRef.current = false;
        startSlotRef.current = null;
    };

    // Function to handle double-click on a slot
    const handleSlotDoubleClick = (day: number, slotIndex: number) => {
        const updatedSlots = selectedSlots.filter((slot) => !(slot.day === day && slot.slotIndex === slotIndex));
        setSelectedSlots(updatedSlots);
    };

    // Function to handle click on a header cell
    const handleHeaderClick = (slotIndex: number) => {
        const updatedSlots = [...selectedSlots];
        const columnSelected = selectedSlots.some((slot) => slot.slotIndex === slotIndex || slot.slotIndex === slotIndex + 1);
        if (columnSelected) {
            const filteredSlots = updatedSlots.filter((slot) => slot.slotIndex !== slotIndex && slot.slotIndex !== slotIndex + 1);
            setSelectedSlots(filteredSlots);
        } else {
            for (let day = 0; day < 7; day++) {
                updatedSlots.push({ day, slotIndex });
                updatedSlots.push({ day, slotIndex: slotIndex + 1 });
            }
            setSelectedSlots(updatedSlots);
        }
    };

    // Function to handle click on a row header cell
    const handleRowHeaderClick = (day: number) => {
        const updatedSlots = [...selectedSlots];
        const rowSelected = selectedSlots.some((slot) => slot.day === day);
        if (rowSelected) {
            const filteredSlots = updatedSlots.filter((slot) => slot.day !== day);
            setSelectedSlots(filteredSlots);
        } else {
            for (let slotIndex = 0; slotIndex < 48; slotIndex++) {
                updatedSlots.push({ day, slotIndex });
            }
            setSelectedSlots(updatedSlots);
        }
    };

    // Function to generate the time cell for a given slot
    const generateTimeCell = (day: number, slotIndex: number) => {
        const timeStart = slotIndexToTime(slotIndex);
        const timeEnd = slotIndexToTime(slotIndex + 1);
        const title = `${timeStart} - ${timeEnd}`;
        return (
            <td
                key={slotIndex}
                className={`daypartingCell ${isSlotSelected(day, slotIndex) ? 'selected' : ''}`}
                onMouseDown={() => handleSlotMouseDown(day, slotIndex)}
                onMouseEnter={() => handleSlotMouseEnter(day, slotIndex)}
                onMouseUp={handleSlotMouseUp}
                onDoubleClick={() => handleSlotDoubleClick(day, slotIndex)}
                onClick={() => handleSlotClick(day, slotIndex)}
                title={title}
            ></td>
        );
    };

    // Function to convert slotIndex to time format
    const slotIndexToTime = (slotIndex: number) => {
        const hours = String(Math.floor(slotIndex / 2)).padStart(2, '0');
        const minutes = slotIndex % 2 === 0 ? '00' : '30';
        return `${hours}:${minutes}`;
    };

    // Function to generate the day labels (header row)
    const generateDayLabels = () => {
        return (
            <tr>
                {/* Add a custom CSS class for the first column */}
                <th className="dayparting-day-name"></th>
                {Array.from({ length: 24 }).map((_, slotIndex) => (
                    <th key={slotIndex} className="daypartingHeader daypartingHeaderCell" colSpan={2} onClick={() => handleHeaderClick(slotIndex * 2)}>
                        {slotIndex}
                    </th>
                ))}
            </tr>
        );
    };

    // Function to generate the time slots for a specific day
    const generateDayLabel = (day: number) => {
        return (
            <tr key={day}>
                {/* Add a custom CSS class for the first column */}
                <td className="dayparting-day-name" onClick={() => handleRowHeaderClick(day)}>{['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][day]}</td>
                {/* Removed the <></> wrapper to prevent "Each child in a list should have a unique "key" prop" warning */}
                {Array.from({length: 24})
                  .map((_, slotIndex) => ([
                    generateTimeCell(day, slotIndex * 2),
                    generateTimeCell(day, slotIndex * 2 + 1)
                  ]))
                  .flat()
                }
            </tr>
        );
    };

    // Function to get all slots between two points (start and end slots)
    const getSlotsBetween = (startDay: number, startSlotIndex: number, endDay: number, endSlotIndex: number): Slot[] => {
        const updatedSlots: Slot[] = [];
        const minDay = Math.min(startDay, endDay);
        const maxDay = Math.max(startDay, endDay);
        const minSlotIndex = Math.min(startSlotIndex, endSlotIndex);
        const maxSlotIndex = Math.max(startSlotIndex, endSlotIndex);
        for (let day = minDay; day <= maxDay; day++) {
            for (let slotIndex = minSlotIndex; slotIndex <= maxSlotIndex; slotIndex++) {
                updatedSlots.push({ day, slotIndex });
            }
        }
        return updatedSlots;
    };

    // Function to check if a slot is selected
    const isSlotSelected = (day: number, slotIndex: number) => {
        return selectedSlots.some((slot) => slot.day === day && slot.slotIndex === slotIndex);
    };

    // Function to split the binary string into binary states for each day
    const splitBinaryString = (binaryString: string) => {
        const binaryStates: string[] = [];
        for (let i = 0; i < binaryString.length; i += 48) {
            binaryStates.push(binaryString.substring(i, i + 48));
        }
        return binaryStates;
    };

    // Function to join the binary states into a single binary string
    const joinBinaryStates = (binaryStates: string[]) => {
        return binaryStates.join('');
    };

    // Function to get selected slots from the binary string
    const getSelectedSlotsFromBinary = (binaryString: string): Slot[] => {
        const selectedSlots: Slot[] = [];
        for (let day = 0; day < 7; day++) {
            for (let slotIndex = 0; slotIndex < 48; slotIndex++) {
                if (binaryString.charAt(day * 48 + slotIndex) === '1') {
                    selectedSlots.push({ day, slotIndex });
                }
            }
        }
        return selectedSlots;
    };

    // Function to update binaryStates based on selectedSlots
    const updateBinaryState = () => {
        const newBinaryStates = Array.from({ length: 7 }, (_, day) => {
            let binaryState = '';
            for (let slotIndex = 0; slotIndex < 48; slotIndex++) {
                binaryState += isSlotSelected(day, slotIndex) ? '1' : '0';
            }
            return binaryState;
        });
        setBinaryStates(newBinaryStates);
    };

    // Render the DayPartingTable component
    return (
        <div className="dayparting-table-container">
            <div className="dayparting-table">
                <table className="daypartingMainTable">
                    <tbody>
                    {generateDayLabels()}
                    {Array.from({ length: 7 }).map((_, day) => generateDayLabel(day))}
                    </tbody>
                </table>
            </div>
            {/*<div className="formatted-daypart">*/}
            {/*    <h3>Formatted Daypart:</h3>*/}
            {/*    <p>{joinBinaryStates(binaryStates)}</p>*/}
            {/*</div>*/}
        </div>
    );
};

export default DayPartingTable;
