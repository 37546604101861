import React from "react";
import {Button, Modal} from "rsuite";
import {TypeAttributes} from "rsuite/esm/@types/common";

type AddModalType = {
  title?: string;
  open?: boolean;
  size?: TypeAttributes.Size;
  loading?: boolean;
  hideButtons?: boolean;
  hideSubmitButton?: boolean;
  hideCancelButton?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  backdrop?: any
};

const EditModal: React.FC<AddModalType> = ({
  title = "",
  open = false,
  size = "md",
  loading = false,
  onClose,
  onCancel,
  onSubmit,
  hideButtons = false,
  hideSubmitButton = false,
  hideCancelButton = false,
  backdrop = true,
  ...props
}) => {

  return (
    <Modal
      backdrop = {backdrop}
      open={open}
      size={size}
      onClose={() => {
        if (typeof onClose === "function") {
          onClose();
        }
      }}
      {...props}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        {!hideButtons && !hideSubmitButton && (
          <Button
            appearance="primary"
            loading={loading}
            onClick={() => {
              if (typeof onSubmit === "function") {
                onSubmit();
              }
            }}
          >
            Submit
          </Button>
        )}
        {!hideButtons && !hideCancelButton && (
          <Button
            appearance="subtle"
            onClick={() => {
              if (typeof onCancel === "function") {
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
