import React from "react";
import {Schema, Form} from "rsuite";
import Field from "../Field";

const {StringType} = Schema.Types;

const model = Schema.Model({
  image_uri: StringType(),
  display_name: StringType()
    .isRequired("Display Name is required")
    .maxLength(40, "Max length is 40"),
});

type TikTokIdentityFormType = {
  image_uri?: string;
  display_name?: string;
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const TikTokIdentityForm: React.FC<TikTokIdentityFormType> = ({
  image_uri = "",
  display_name = "",
  formRef = null,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  const [formValue, setFormValue] = React.useState({
    image_uri: image_uri,
    display_name: display_name
  });
  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    setFormValue(data);

    if (parentCallback instanceof Function) {
      parentCallback(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="inline"
      formValue={formValue}
      formError={formError}
    >
      <Field
        label=""
        placeholder="Enter a display name"
        name="display_name"
      />
    </Form>
  );
};

export default TikTokIdentityForm;
