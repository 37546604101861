import React from "react";
import {
  Col,
  DateRangePicker,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Notification,
  Row,
  SelectPicker,
  TagPicker,
  Tooltip,
  Whisper
} from "rsuite";
import Title from "../../@Components/Title";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import SearchIcon from '@rsuite/icons/Search';
import DomainDirectSummaryList from "./DomainDirectSummaryList";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import { format, lastDayOfMonth, set, startOfMonth, subDays, subMonths } from "date-fns";
import CloseIcon from "@rsuite/icons/Close";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { exportCsv } from "../../@Utils/Export";
import { currency, percent, thousands } from "../../@Utils/Format";
import RunningRound from '@rsuite/icons/RunningRound';
import { AD_GROUP_STATUS_DISABLE, AD_GROUP_STATUS_ENABLE } from "../Campaigns/AdGroupModel";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import _ from "lodash";

function addCalculatedValues(row: any) {
  const campaign_id = isNaN(parseInt(row.campaign_id)) ? 0 : parseInt(row.campaign_id);
  const campaign_active = isNaN(parseInt(row.campaign_active)) ? 0 : parseInt(row.campaign_active);
  const revenue = isNaN(parseFloat(row.revenue)) ? 0 : parseFloat(row.revenue);
  const spend = isNaN(parseFloat(row.spend)) ? 0 : parseFloat(row.spend);
  const conversion = isNaN(parseInt(row.conversion)) ? 0 : parseInt(row.conversion);
  const profit = revenue - spend;
  const cpa = conversion !== 0 ? (spend / conversion) : 0;
  const roi = spend !== 0 ? (profit / spend) * 100 : 0;
  const clicks = isNaN(parseFloat(row.clicks)) ? 0 : parseFloat(row.clicks);
  const s1Clicks = isNaN(parseInt(row.s1clicks)) ? 0 : parseInt(row.s1clicks)
  const rpc = s1Clicks !== 0 ? (revenue / s1Clicks) : 0;
  // (Math.floor(rpc * 100) / 100).toFixed(2)

  return {
    ...row,
    campaign_id,
    campaign_active,
    spend,
    revenue,
    conversion,
    domain: row.domain,
    subid: row.subid,
    impressions: isNaN(parseInt(row.impressions)) ? 0 : parseInt(row.impressions),
    clicks: isNaN(parseInt(row.clicks)) ? 0 : parseInt(row.clicks),
    ctr: isNaN(parseFloat(row.ctr)) ? 0 : parseFloat(row.ctr),
    cpc: isNaN(parseFloat(row.cpc)) ? 0 : parseFloat(row.cpc),
    cpm: isNaN(parseFloat(row.cpm)) ? 0 : parseFloat(row.cpm),
    cost: isNaN(parseFloat(row.cost)) ? 0 : parseFloat(row.cost),
    conversion_rate: isNaN(parseFloat(row.conversion_rate)) ? 0 : parseFloat(row.conversion_rate),
    s1clicks: isNaN(parseInt(row.s1clicks)) ? 0 : parseInt(row.s1clicks),
    s1impressions: isNaN(parseInt(row.s1impressions)) ? 0 : parseInt(row.s1impressions),
    roi: roi,
    profit,
    cpa,
    rpc: (Math.floor(rpc * 100) / 100)
  };
}


function addCalculatedValuesRecursively(list: any) {
  return list.map((row: any) => {
    if (row.children && Array.isArray(row.children)) {
      row.children = addCalculatedValuesRecursively(row.children);
    }

    return addCalculatedValues(row);
  });
}


const rowKey: string = "node_id";

const DomainDirectSummaryGrid: React.FC<any> = () => {
  const [columns, setColumns] = React.useState<string[]>([]);
  const [columnsOrder, setColumnsOrder] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_SEARCH_QUERY, '');
  const [search, setSearch] = React.useState<string>(searchQuery);
  const removeSearchContent = '';
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any[]>([]);
  const [page, setPage] = React.useState(1);
  const currentDayWithoutTime = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const startDate = set(new Date('2023-01-01'), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const [accounts, setAccounts] = React.useState([]);
  const [advertiserIds, setAdvertiserIds] = useStoredValue<any[]>(LOCAL_STORAGE_KEYS.TIKTOK_CAMPAIGN_REPORT_PAGE_FILTER_ADVERTISER_IDS, []);
  const [duplicateAccounts, setDuplicateAccounts] = React.useState([]);
  const [searchAccount, setSearchAccount] = React.useState<string>('');
  const [managers, setManagers] = React.useState([]);
  const [managerType, setManagerType] = React.useState<string>('');
  const [managerId, setManagerId] = React.useState<string>('');

  const handleSelect = (value: any, item: any, event:any) => {
    const selected = accounts.filter((v:any)=> value.includes(v.advertiser_id));
    const search = accounts.filter((v:any)=> v.advertiser_name.toLowerCase().includes(searchAccount.toLowerCase()));

    if (value.length === 0) {
      setAccounts(duplicateAccounts);
    } else {
      //@ts-ignore
      setAccounts([...new Set([...search ,...selected])]);
    }
  };
  const [dateRange, setDateRange] = useStoredValue(LOCAL_STORAGE_KEYS.TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_FILTER_DATE, {
    start: startDate,
    end: currentDayWithoutTime,
  });
  const last_month = subMonths(new Date(), 1);

  const [isGropedByDate, setIsGropedByDate] = useStoredValue(LOCAL_STORAGE_KEYS.TIKTOK_DOMAIN_DIRECT_SUMMARY_REPORT_PAGE_GRID_GROUPED_BY_DATE, false);
  const [fetchParams, setFetchParams] = React.useState<Record<string, any>>({});


  /**
   * Proxy for capturing state changes with DateRangePicker
   * Changes update the DataFiltersContext
   */
  const setDates = (dates: DateRange | null) => {
    const dateRange = {
      start: set(dates ? dates[0] : new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
      end: set(dates ? dates[1] : new Date(), {hours: 23, minutes: 59, seconds: 59, milliseconds: 997}),
    };

    setDateRange(dateRange);
  };

  const handleGridConfigurationChange = (columns: string[], columnsOrder: string[]) => {
    setColumns(columns);
    setColumnsOrder(columnsOrder);
  };

  const treeData =
    !loading && data !== null
      ? addCalculatedValuesRecursively(Array.isArray(data) ? data : [])
      : [];

  //Removing content from search
  const searchRemoveContent = () => {
    setSearch(removeSearchContent);
    handleSearchChange('');
  };

  /**
   * Custom function for formatting date ranges. Idea is to make
   * it more human-readable.
   */
  const formatDates = (value: any, dateFormat: string = "LLL do, yyyy") => {
    if (!value[0] || !value[1]) {
      return null;
    }
    if (value[0].toString() === value[1].toString()) {
      return (
        <span style={{paddingRight: 10}}>
          {format(value[0], dateFormat)}
        </span>
      );
    }
    return (
      <span style={{paddingRight: 10}}>
        From {format(value[0], dateFormat)} to {format(value[1], dateFormat)}
      </span>
    );
  };

  const fetchData = (searchQuery?: string) => {
    setData([]);
    setLoading(true);

    const params = {
      query: searchQuery ? searchQuery : search,
      ids: advertiserIds.join(),
      start_date: format(dateRange.start, "yyyy-MM-dd 00:00:00.000"),
      end_date: format(dateRange.end, "yyyy-MM-dd 23:59:59.997"),
      is_grouped_by_date: isGropedByDate ? 1 : 0,
      manager_type: managerType,
      manager_id: managerId,
    };

    setFetchParams(params);

    // Get data
    HttpClient
      .get<any>('tiktok_domain_direct_summary', params)
      .then(res => {
        setData(res.data.data.map((item: any) => {
          item.isParentRow = 1;

          if (!item.children) {
            return item;
          }

          if (item.children.length === 1) {
            // If there is only one child then it's the same row and we remove it
            item.children = [];
          } else if (item.children.length > 1) {
            // Clear some values for parent with children
            item.adgroup_name = '';
          }

          return item;
        }));

        setLoading(false);
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        setLoading(false);
      });
  };

  const getExportData = (data: any) => {
    const flattenTree = (list: any) => {
      const result: any[] = [];

      list.forEach((item: any) => {
        result.push(item);

        if (item.children && Array.isArray(item.children) && item.children.length) {
          result.push(...flattenTree(item.children));
        }
      });

      return result;
    };

    const defaultColumns = ['stat_date', 'domain', 'subid', 'adgroup_name', 'campaign_name', 'adgroup_id', 'roi'];
    const allColumns = [...defaultColumns, ...columns];
    const columnsToExport = [...defaultColumns, ...columnsOrder]
      .filter(key => allColumns.includes(key));

    return (
      flattenTree(data).filter((item:any)=> item.subid != 0)
        // Clean, format and reorder by fields with right headers
        .map((item: any) => {
          return columnsToExport
            .reduce((row: {}, columnKey: string) => {
              const value = item[columnKey];

              switch (columnKey) {
                case 'stat_date':
                  return {...row, "Date": value};
                case 'domain':
                  return {...row, "Domain": value};
                case 'subid':
                  return {...row, "SubID": value};
                case 'adgroup_name':
                  return {...row, "AdGroup Name": value};
                case 'spend':
                  return {...row, "Spend": value};
                case 'revenue':
                  return {...row, "Revenue": item.stat_date ? value : ''};
                case 'profit':
                  return {...row, "Profit": item.stat_date ? value : ''};
                case 'conversion':
                  return {...row, "Conversion": thousands(value)};
                case 'cpa':
                  return {...row, "CPA": value};
                case 'impressions':
                  return {...row, "Impressions": thousands(value)};
                case 's1impressions':
                  return {...row, "G Impressions": item.stat_date ? thousands(value) : ''};
                case 'clicks':
                  return {...row, "Clicks": thousands(value)};
                case 's1clicks':
                  return {...row, "G Clicks": item.stat_date ? thousands(value) : ''};
                case 'ctr':
                  return {...row, "CTR": value};
                case 'cpc':
                  return {...row, "CPC": value};
                case 'cpm':
                  return {...row, "CPM": value};
                case 'rpc':
                  return {...row, "RPC": value};
                case 'roi':
                  return {...row, "ROI": value};
                case 'conversion_rate':
                  return {...row, "Conversion Rate": value};
              }

              return {...row, [columnKey]: value};
            }, {});
        })
    );
  };

  const searchByQuery = () => {
    setPage(1);
    setSearch(searchQuery || '');
    fetchData(searchQuery);
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      searchByQuery();
    }
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const handleReloadButtonClick = () => {
    fetchData(searchQuery);
    setPage(1);
  };


  /**
   * Find parent and/or child by "rowKey" value
   * @param nodeId  Node ID value
   * @return Array with [parentIndex, childIndex] value. If found item is a parent then childIndex === -1
   */
  const findParentOrChildIndicesByRowKey = (nodeId: string) => {
    let foundParent = data.findIndex((item: any) => item[rowKey] === nodeId);
    let foundChild = -1;

    if (foundParent === -1) {
      const parentsWithChildren = data.filter((parentItem: any) => parentItem.children && parentItem.children.length);

      for (const parentItem of parentsWithChildren) {
        foundChild = parentItem.children.findIndex((item: any) => {
          return item[rowKey] === nodeId;
        });

        if (foundChild > -1) {
          foundParent = data.findIndex((item: any) => item[rowKey] === parentItem[rowKey]);

          break;
        }
      }
    }

    return [foundParent, foundChild];
  };

  const findCurrentItem = (nodeId: string) => {
    const currentIndices = findParentOrChildIndicesByRowKey(nodeId);

    if (currentIndices[0] === -1) {
      return;
    }

    const parentItem = data[currentIndices[0]];

    return currentIndices[1] === -1 ? parentItem : parentItem.children[currentIndices[1]];
  };

  const changeIsCampaignUpdating = React.useCallback((nodeId: string, newValue: boolean, status: number = -1) => {
    const currentItem = findCurrentItem(nodeId);

    currentItem.isCampaignUpdating = newValue;

    if (status > -1)
      currentItem.campaign_active = status;

    setData([...data]);

    return currentItem;
  }, [data]);

  const changeAdGroupUpdatingStatusByAdGroupId = React.useCallback((adGroupId: string, newValue: boolean, status: number = -1) => {
    const changeAdGroup = (item: Record<string, any>) => {
      item.isAdGroupUpdating = newValue;

      if (status > -1) {
        item.adgroup_operation_status = status ? AD_GROUP_STATUS_ENABLE : AD_GROUP_STATUS_DISABLE;
      }
    };

    data.forEach((item: Record<string, any>) => {
      if (item.adgroup_id === adGroupId) {
        changeAdGroup(item);
      }

      if (item.children.length) {
        item.children.forEach((item: Record<string, any>) => {
          if (item.adgroup_id === adGroupId) {
            changeAdGroup(item);
          }
        });
      }
    });

    setData([...data]);
  }, [data]);

  const changeCampaignStatus = React.useCallback((nodeId: string, checked: boolean) => {
    const activeValue = checked ? 1 : 0;

    // Clone data with changed 'active' field values
    const currentItem = changeIsCampaignUpdating(nodeId, true);

    setData([...data]);

    const campaignId = currentItem.campaign_id;

    if (campaignId) {
      changeCampaignStatusRequest(
        campaignId,
        activeValue,
        currentItem,
        (activeValue: number) => changeIsCampaignUpdating(nodeId, false, activeValue));
    }
  }, [changeIsCampaignUpdating, data]);

  const changeAdGroupStatus = React.useCallback((nodeId: string, checked: boolean) => {
    const activeValue = checked ? 1 : 0;
    const currentItem = findCurrentItem(nodeId);
    const adGroupId = currentItem.adgroup_id;

    changeAdGroupUpdatingStatusByAdGroupId(adGroupId, true);

    if (adGroupId) {
      changeAdGroupStatusRequest(
        adGroupId,
        activeValue,
        currentItem,
        (activeValue: number) => changeAdGroupUpdatingStatusByAdGroupId(adGroupId, false, activeValue));
    }
  }, [changeAdGroupUpdatingStatusByAdGroupId, data]);

  const changeCampaignStatusRequest = (id: number, newValue: number, item: object, success: any) => {
    HttpClient
      .put<any>(`campaign_status/${id}`, { ...item, active: newValue })
      .then((_res) => {
        success(!isNaN(parseInt(_res.data.active)) ? parseInt(_res.data.active, 10) : -1);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const searchChange = (value: any, search:any) => {
    setAccounts(duplicateAccounts);
    setSearchAccount(value);
  };

  const handleChange = (v:any) => {
    if (v == null) {
      setAdvertiserIds([])
      setAccounts(duplicateAccounts);
    } else {
      setAdvertiserIds(v)
    }
  };

  const renderMenu = (menu: any) => {
    if (accounts.length === 0) {
      return (
          <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
            <SpinnerIcon spin /> Loading...
          </p>
      );
    }

    return menu;
  };

  const renderManagerSelector = (menu: any) => {
    if (managers.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  React.useEffect(() => {
    HttpClient
        .get<any>('advertiser')
        .then(res => {
          // @ts-ignore
          setAccounts(_.sortBy(res.data,'advertiser_name'));
          // @ts-ignore
          setDuplicateAccounts(_.sortBy(res.data,'advertiser_name'));
        })
        .catch((_error) => {
          // TODO Add something
          // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          //   {error.response.data?.error || error.toString()}
          // </Notification>);
        });

    HttpClient
      .get<any>('managed_user_n_group')
      .then(res => {
        setManagers(res.data.data);
      })
      .catch((_error) => {
        // TODO Add something
        // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
        //   {error.response.data?.error || error.toString()}
        // </Notification>);
      });
  }, []);

  const changeAdGroupStatusRequest = (id: string, newValue: number, item: object, success: any) => {
    HttpClient
      .put<any>(`ad_group_status/${id}`, { ...item, active: newValue })
      .then((_res) => {
        success(!isNaN(parseInt(_res.data.active)) ? parseInt(_res.data.active, 10) : -1);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  // Check to highlight or not filter elements
  const initialSearchFilter = React.useRef(searchQuery !== '' ? searchQuery : {})
  const initialStartDateRef = React.useRef(dateRange.start);
  const initialEndDateRef = React.useRef(dateRange.end);

  const hasSearchEqualToInit = searchQuery === initialSearchFilter.current;
  const hasDateRangeEqualToInit = (dateRange.start.getTime() === initialStartDateRef.current.getTime()) && (dateRange.end.getTime() === initialEndDateRef.current.getTime());

  const initialClassName = 'init-by-stored-values';

  const handleManagerChange = (managerUid: any) => {
    console.log('handleManagerChange: ', managerUid);
    const [type, id] = managerUid != null ? managerUid.split('::') : ['', ''];

    setManagerType(type);
    setManagerId(id);
  };


  return (
    <>
      <Title title="TikTok Domain Direct Summary Report"/>
      <Grid fluid>
        <Row className="show-grid">
          <Col md={4}>
            <IconButton
              size="lg"
              appearance="subtle"
              style={{marginLeft: 15}}
              icon={<FileDownloadIcon/>}
              placement="right"
              onClick={() =>
                exportCsv(
                  `campaign_tiktok.csv`,
                  getExportData(treeData)
                )
              }
            >
              Download CSV
            </IconButton>
          </Col>
          <Col md={4}>
            <SelectPicker
              className="manager-filter"
              block
              name="manager"
              placeholder="Select Manager"
              placement="autoVerticalStart"
              data={managers}
              valueKey="uid"
              labelKey="label"
              groupBy="type"
              // value={advertiserIds}
              // onSelect={handleSelect}
              // onSearch={searchChange}
              onChange={handleManagerChange}
              renderMenu={renderManagerSelector}
            />
          </Col>
          <Col md={5}>
            <TagPicker
                className="account-filter"
                block
                name="traffic_source_account"
                placeholder="Select Account"
                placement="autoVerticalStart"
                data={accounts}
                valueKey="advertiser_id"
                labelKey="advertiser_name"
                groupBy="traffic_source_type"
                value={advertiserIds}
                onSelect = {handleSelect}
                onSearch={searchChange}
                onChange={(v:any) =>handleChange(v)}
                renderMenu={renderMenu}
            />
          </Col>
          <Col md={5}>
            <DateRangePicker
              className={["date-filter", ...[hasDateRangeEqualToInit ? initialClassName : null]].join(' ')}
              size="md"
              placement="autoVerticalEnd"
              renderValue={formatDates}
              value={[dateRange.start, dateRange.end]}
              defaultValue={[dateRange.start, dateRange.end]}
              onChange={setDates}
              cleanable={false}
              style={{width: "100%"}}
              // TODO Uncomment it and ***FIX*** a typescript warning
              // disabledDate={allowedMaxDays(60)}
              ranges={[
                {
                  label: "Yesterday",
                  value: [subDays(new Date(), 1), subDays(new Date(), 1)],
                },
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "Last 7 days",
                  value: [subDays(new Date(), 7), subDays(new Date(), 0)],
                },
                {
                  label: "This Month",
                  value: [startOfMonth(new Date()), new Date()],
                },
                {
                  label: "Last Month",
                  value: [startOfMonth(last_month), lastDayOfMonth(last_month)],
                },
              ]}
            />
          </Col>
          <Col md={4}>
            <InputGroup style={{width: "100%",}}>
              <InputGroup.Button onClick={searchByQuery}>
                <SearchIcon/>
              </InputGroup.Button>
              <Input
                className={["search-filter", ...[hasSearchEqualToInit ? initialClassName : null]].join(' ')}
                placeholder="Search"
                value={searchQuery}
                onChange={(v) => handleSearchChange(v)}
                onKeyUp={onKeyUpEnter}
              />
              <InputGroup.Button onClick={searchRemoveContent}>
                <CloseIcon/>
              </InputGroup.Button>
            </InputGroup>
          </Col>
          <Col md={2}>
            <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Run Report</Tooltip>}
            >
              <IconButton
                color="blue"
                appearance="primary"
                size="md"
                style={{ width: 100 }}
                icon={<RunningRound />}
                onClick={handleReloadButtonClick}
              >
                Go
              </IconButton>
            </Whisper>
          </Col>
        </Row>
      </Grid>

      <DomainDirectSummaryList
        data={treeData}
        dataFetchParams={fetchParams}
        page={page}
        setPage={setPage}
        loading={loading}
        onChangeGridConfiguration={handleGridConfigurationChange}
        onChangeCampaignStatus={changeCampaignStatus}
        onChangeAdGroupStatus={changeAdGroupStatus}
        isGropedByDate={isGropedByDate}
        setIsGropedByDate={setIsGropedByDate}
      />
    </>
  );
};

export default DomainDirectSummaryGrid;