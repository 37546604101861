import React from "react";
import { Nav, Tooltip, Whisper } from "rsuite";
import { NavLink } from "react-router-dom";
import { IconProps } from "@rsuite/icons/lib/Icon";
import useCheckAccess from "../../../@Utils/useCheckAccess";

interface IconNavLinkProps {
  link: string;
  title?: string;
  isExpanded?: boolean;
  currentRoute?: string;
  icon: React.ReactElement<IconProps>;
  isPublic?: boolean;
}

const IconNavLink: React.FC<IconNavLinkProps> = ({
  link,
  title = '',
  isExpanded = true,
  currentRoute = '',
  icon,
  isPublic = false,
  ...props
}) => {
  const checkAccess = useCheckAccess();
  const hasAccess = isPublic || checkAccess(link, 'showPage');

  if (!hasAccess) {
    return null;
  }

  const isActive = currentRoute && currentRoute === link;
  const navIcon = isExpanded
    ? icon
    : <Whisper trigger="hover" placement="right" enterable={isExpanded}
      speaker={<Tooltip visible={isExpanded}>{title}</Tooltip>}>{icon}</Whisper>;

  return <NavLink exact to={link}>
    {/*<IconButton icon={<StarIcon />} circle size="lg" />*/}
    <Nav.Item eventKey="1" as="span" icon={navIcon}>
      {isExpanded ? <span className={'link-text ' + (isActive ? 'active' : '')}>{title}</span> : ''}
    </Nav.Item>
  </NavLink>
};

export default IconNavLink;