import { Button, Checkbox, } from "rsuite";
import React from "react";

export const WeekHourTable = ({
  data = {},
  setData,
  isError,
}: any) => {
  const allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const hours = Array.from(Array(24).keys());

  const changeForDays = (data: { [p: string]: number[] }, days: string | string[]) => {
    if (!Array.isArray(days)) {
      days = [days];
    }

    const hasAllForDays = Object.keys(data).filter(day => days.includes(day)).length === days.length
      && days
        .map((day: string) => (data[day] && data[day].length === hours.length) || false)
        .filter((checked: boolean) => checked)
        .length === days.length;

    const newData: { [key: string]: number[] } = {};
    allDays.forEach((day: string) => {
      newData[day] = days.includes(day) ? (hasAllForDays ? [] : hours) : data[day];
    });

    return newData;
  };

  const changeForDaysAndHours = (data: { [key: string]: number[] }, days: string[], hours: number[]) => {
    const isHourCheckedForEachDay = days
      .map((day: string) => (data[day] && hours.every((h: number) => data[day].includes(h))) || false)
      .filter((checked: boolean) => checked)
      .length === days.length;

    days.forEach((day: string) => {
      hours.forEach((hour: number) => {
        if (!data[day] || !Array.isArray(data[day])) {
          data[day] = [];
        }

        if (isHourCheckedForEachDay) {
          data[day] = data[day].filter((h: number) => h !== hour);
        } else if (!data[day].includes(hour)) {
          data[day].push(hour);
        }
      });
    });

    return data;
  };

  const changeHandler = (day: string, hour: number, checked: boolean) => {
    if (!data[day]) {
      data[day] = [];
    }

    if (!checked && data[day].includes(hour)) {
      data[day] = data[day].filter((h: number) => h !== hour);
    } else if (checked && !data[day].includes(hour)) {
      data[day].push(hour);
    }

    setData(data);
  };

  const clickDayHandler = (day: string) => {
    setData(changeForDays(data, day));
  };

  const clickAllHandler = () => {
    setData(changeForDays(data, allDays));
  };

  const clickWeekDaysHandler = () => {
    setData(changeForDays(data, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']));
  };

  const clickWeekendsHandler = () => {
    setData(changeForDays(data, ['Sat', 'Sun']));
  };

  const clickHourHandler = (hour: number) => {
    setData(changeForDaysAndHours(data, allDays, [hour]));
  };

  const clickWorkingHoursHandler = () => {
    setData(changeForDaysAndHours(data, ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'], [9, 10, 11, 12, 13, 14, 15, 16, 17]));
  };

  const classNames = ["adm-rule-condition-hours-table"];

  if (isError) {
    classNames.push("adm-rule-condition-hours-table-error");
  }

  return (
    <>
      <Button appearance="link" onClick={clickAllHandler}>Select All / Clear</Button>
      <Button appearance="link" onClick={clickWeekDaysHandler}>Week Days</Button>
      <Button appearance="link" onClick={clickWeekendsHandler}>Weekends</Button>
      <Button appearance="link" onClick={clickWorkingHoursHandler}>Working Hours</Button>
      <table className={classNames.join(' ')}>
        <tbody>
        {allDays.map(day => {
          return (
            <tr key={day}>
              <th className="adm-rule-condition-hours-table-edge-item-left" key={`th-${day}`}>

                <div onClick={() => clickDayHandler(day)} style={{cursor: "pointer"}}>{day}</div>
              </th>
              {hours.map(hour => {
                const value = data[day] ? data[day].includes(hour) : false;

                return (
                  <td key={`td-${day}-${hour}`}>
                    <Checkbox
                      key={`checkbox-${day}-${hour}`}
                      checked={!!value}
                      onChange={(value, checked) => changeHandler(day, hour, checked)}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })
        }
        <tr key="bottom-hours">
          <th style={{paddingTop: 1}}>
            <div onClick={() => clickAllHandler()} style={{cursor: "pointer"}}>X</div>
          </th>
          {hours.map(hour => {
            const outputHour = "0" + hour;
            const output = outputHour.substring(outputHour.length - 2);

            return (
              <th className="adm-rule-condition-hours-table-edge-item-bottom" key={`bottom-th-${hour}`}>
                <div onClick={() => clickHourHandler(hour)} style={{cursor: "pointer"}}>{output}</div>
              </th>
            );
          })
          }
        </tr>
        </tbody>
      </table>
    </>
  );
};

