import React, { ReactNode } from "react";
import {
    Button,
    Drawer,
    toaster,
    Notification
} from "rsuite";
import { FormInstance } from "rsuite/Form";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import TrafficSourceForm from "../TrafficSources/TrafficSourceForm";
import HttpClient from "../../@Utils/HttpClient";

type TrafficSourceProps = {
    formData: any;
    openWithHeader: any;
    setOpenWithHeader: any;
    trafficSourceId: number;
    setTrafficSourceId: any;
    onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const TrafficSource: React.FC<TrafficSourceProps> = ({
    formData,
    openWithHeader,
    setOpenWithHeader,
    trafficSourceId = 0,
    setTrafficSourceId,
    onAfterCreateOrUpdateGridRow
}) => {
    const [formValue, setFormValue] = React.useState(formData);
    const mainFormRef = React.createRef<FormInstance>();
    const [isSaveButtonLoading, setIsSaveButtonLoading] = React.useState(false);

    const handleCallback = (formValue: any) => {
        setFormValue(formValue);
    };
    // TODO Replace it
    React.useEffect(() => {
        setFormValue(formData);
    }, [formData]);

    const handleSave = () => {
        const node = mainFormRef && mainFormRef.current;

        if (node?.check && !node.check()) {
            return;
        }

        const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });

        setIsSaveButtonLoading(true);

        if (trafficSourceId === 0) {
            // Create a new one
            HttpClient.post<object, any>('traffic_source', formValue)
                .then(res => {
                    const trafficId = parseInt(res.data.traffic_source_id);

                    // Update form data
                    const updatedFormValue = {
                        ...formValue,
                        traffic_source_id: trafficId,
                    };

                    setIsSaveButtonLoading(false);
                    setTrafficSourceId(trafficId);
                    setFormValue({
                        ...formValue,
                        traffic_source_id: trafficId,
                    });

                    const type = res.status === 201 ? "success" : "error";
                    const text = res.status === 201 ? "Traffic Source has been created" : res.statusText;

                    pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);
                    setOpenWithHeader(false)
                    // Add a new row into a grid data
                    onAfterCreateOrUpdateGridRow(trafficId, updatedFormValue);
                })
                .catch(error => {
                    setIsSaveButtonLoading(false);

                    pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                });
        } else {
            // Update an existing one
            HttpClient.put<any>(`traffic_source/${formValue.traffic_source_id}`, formValue)
                .then(res => {
                    setIsSaveButtonLoading(false);

                    const type = res.status === 200 ? "success" : "error";
                    const text = res.status === 200 ? "Traffic Source has been updated" : res.statusText;

                    pushMessage(<Notification closable type={type} header="Success">{text}</Notification>);

                    // Update form data
                    const updatedFormValue = {
                        ...formValue,
                        custom_tracking_enabled: parseInt(formValue.custom_tracking_enabled),
                    };
                    setOpenWithHeader(false)
                    // Update a grid data
                    onAfterCreateOrUpdateGridRow(parseInt(formValue.traffic_source_id), updatedFormValue);
                })
                .catch(error => {
                    setIsSaveButtonLoading(false);

                    pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                });
        }
    }

    return (
        <>
            <Drawer open={openWithHeader} backdrop={false} onClose={() => setOpenWithHeader(false)} size="lg">
                <Drawer.Header>
                    <Drawer.Title>
                        {trafficSourceId === 0 ? 'Add Traffic Source' : 'Edit Traffic Source'}
                    </Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={handleSave} appearance="primary" loading={isSaveButtonLoading}>
                            Save
                        </Button>
                        <Button onClick={() => {
                            setOpenWithHeader(false)
                            //setFormError({});
                        }}>Cancel</Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <TrafficSourceForm
                        formRef={mainFormRef}
                        formValue={formValue}
                        parentCallback={handleCallback}
                    />
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default TrafficSource;
