import React from "react";
import {Form, Schema} from "rsuite";
import Textarea from "../Textarea";

const {StringType} = Schema.Types;

const model = Schema.Model({
  image_urls: StringType()
    .isRequired("Image URLs is required")
    .addRule((value, data) => {
      const urls = value.split(/\r?\n/).filter((url: string) => url.trim() !== '');

      for (let i = 0; i < urls.length; i++) {
        if (!/^(http|https):\/\/[^ "]+$/.test(urls[i])) {
          return false;
        }
      }

      return true;
    }, 'Some url is not valid')
});

type ImageUrlsFormType = {
  formValue?: any;
  formRef?: React.MutableRefObject<any>;
  setFormValue?: any;
};

const ImageUrlsForm: React.FC<ImageUrlsFormType> = ({
  formValue = "",
  setFormValue = () => {},
  formRef = null,
}) => {
  formRef = formRef || React.createRef();

  const [formError, setFormError] = React.useState({});

  const handleChange = (data: any) => {
    if (setFormValue instanceof Function) {
      setFormValue(data);
    }
  };

  return (
    <Form
      ref={formRef}
      onChange={handleChange}
      onCheck={setFormError}
      model={model}
      layout="vertical"
      formValue={formValue}
      formError={formError}
    >
      <Form.ControlLabel>URLs</Form.ControlLabel>
      <Form.Control
        name="image_urls"
        placeholder="Enter Image URLs"
        rows={8}
        style={{width: '100%'}}
        accepter={Textarea}
      />
    </Form>
  );
};

export default ImageUrlsForm;
