import React from "react";
import {CheckboxGroup, Form} from "rsuite";

const Field: React.FC<any> = ({
  name,
  label,
  accepter,
  error,
  message = null,
  outerPostfix = null,
  outerPostfixStyles = {},
  formGroupStyle = {},
  ...props
}) => {
  outerPostfixStyles = {
    display: "inline-block",
    paddingLeft: 5,
    marginTop: 10,
    ...outerPostfixStyles,
  };

  // Set default values
  props.style = {
    width: 300,
    ...props.style,
  };

  // Fixed next warning:
  // "index.js:1 Warning: Failed prop type: Invalid prop `children` of type `object` supplied to `defaultProps(CheckboxGroup)`, expected `array`."
  if (accepter === CheckboxGroup && !Array.isArray(props.children)) {
    props.children = [props.children];
  }

  return (
    <Form.Group className={error ? "has-error" : ""} controlId={name} style={formGroupStyle}>
      <Form.ControlLabel style={{display: "block"}}>{label}</Form.ControlLabel>
      <Form.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...props}
      />
      {/*TODO Change the postfix span tag to InputGroup+InputGroup.Addon */}
      {outerPostfix && (
        <span className={"field-postfix"} style={outerPostfixStyles}>
          {outerPostfix}
        </span>
      )}
      {message && <Form.HelpText tooltip>{message}</Form.HelpText>}
    </Form.Group>
  );
};

export default Field;
