import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Notification,
  Row,
  Schema,
  SelectPicker,
} from "rsuite";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import CampaignList from "./CampaignList";
import MoreIcon from '@rsuite/icons/More';
import HttpClient from "../../@Utils/HttpClient";
import Campaign from "../Drawers/Campaign";
import CampaignRuleListDrawer from "../Drawers/CampaignRuleListDrawer";
import {format,  set,startOfWeek, startOfMonth, subDays} from "date-fns";
import PlusRound from "@rsuite/icons/PlusRound";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import CampaignContentListDrawer from "../Drawers/CampaignContentListDrawer";
import Card from "../Campaigns/Card";
import { Filter, filterData } from "../../@Utils/DataProcessing";
import CampaignWidgetsRemotely, { Dimensions } from "../Drawers/CampaignWidgetsRemotely";
import { initialCampaignData } from "./CampaignForm";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import SearchInput from "../SearchInput";
import TikTokAdGroupListDrawer from "../Drawers/TikTokAdGroupListDrawer";

const API_NAME_TABOOLA = "taboola";

export type FiltersType = {
  traffic_source_type: string;
  traffic_source_account: string | null;
  dates: {
    start: Date;
    end: Date;
  };
};

const CampaignGrid: React.FC<any> = () => {
  const [search, setSearch] = useStoredValue<string>(LOCAL_STORAGE_KEYS.CAMPAIGNS_PAGE_SEARCH_QUERY, '');
  const [campaignId, setCampaignId] = React.useState(0);
  const [loadCampaign, setLoadCampaign] = React.useState(false);
  const [campaignName, setCampaignName] = React.useState<string>('');
  const [apiCampaignId, setApiCampaignId] = React.useState<string>('');
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [formData, setFormData] = React.useState(initialCampaignData);
  const [campaignData, setCampaignData] = React.useState<any>({});
  const [widgetData, setWidgetData] = React.useState<any[]>([]);
  const [isCampaignDrawerOpen, setIsCampaignDrawerOpen] = React.useState(false);
  const [isCampaignContentListDrawerOpen, setIsCampaignContentListDrawerOpen] = React.useState(false);
  const [isTikTokAdGroupListDrawerOpen, setIsTikTokAdGroupListDrawerOpen] = React.useState(false);
  const [campaignRuleListDrawerOpen, setCampaignRuleListDrawerOpen] = React.useState(false);
  const [isCampaignWidgetsRemotelyDrawerOpen, setIsCampaignWidgetsRemotelyDrawerOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [idList, setIdList] = React.useState<any[]>([]);
  const [accounts, setAccounts] = React.useState([]);
  const currentDayWithoutTime = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const startDate = set(new Date(new Date().setDate(new Date().getDate()-1)),{hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const [filters, setFilters] = useStoredValue<FiltersType>(LOCAL_STORAGE_KEYS.CAMPAIGNS_PAGE_FILTERS, {
    traffic_source_type: '',
    traffic_source_account: '',
    dates: {
      start: startDate,
      end: currentDayWithoutTime,
    },
  });
  const [start, setStart] = React.useState(filters.dates.start);
  const [end, setEnd] = React.useState(filters.dates.end);
  const [formError, setFormError] = React.useState<any>({});
  const [formValue, setFormValue] = React.useState({
    start: start,
    end: end
  });
  const [widgetsLoading, setWidgetsLoading] = React.useState(false);
  const [widgetsStart, setWidgetsStart] = React.useState(startDate);
  const [widgetsEnd, setWidgetsEnd] = React.useState(currentDayWithoutTime);
  const [reportDimension, setReportDimension] = React.useState(Dimensions.Default);

  const [contentDrawerStart, setContentDrawerStart] = React.useState(startDate);
  const [contentDrawerEnd, setContentDrawerEnd] = React.useState(currentDayWithoutTime);

  const [gridFilters, setGridFilters] = React.useState<Filter[]>([]);

  const [dateRange, setDateRange] = React.useState({
    start: start,
    end: end,
  });

  const predefinedRanges = [
    {
      label: 'This Month',
      value: startOfMonth(new Date()),
      placement: 'left'
    },
    {
      label: 'This Week',
      value: startOfWeek(new Date()),
      placement: 'left'
    },
    {
      label: 'Last three days',
      value: subDays(new Date(), 3),
      placement: 'left'
    },
    {
      label: 'Last two days',
      value: subDays(new Date(), 2),
      placement: 'left'
    },
    {
      label: 'yesterday',
      value: subDays(new Date(), 1),
      placement: 'left'
    },
    {
      label: 'today',
      value: new Date(),
      placement: 'left'
    },
  ];

  const changeFormValue = (data: any) => {
    setDateRange({
      start: data.start,
      end: data.end
    });
    setFormValue({
      start: data.start,
      end: data.end
    });
    setWidgetsStart(data.start);
    setWidgetsEnd(data.end);
    setContentDrawerStart(data.start);
    setContentDrawerEnd(data.end);
  };

  React.useEffect(() => {
    setAccounts([]);
    HttpClient
      .get<any>('traffic_source_account', {
        traffic_source_type: filters.traffic_source_type
      })
      .then(res => {
        setAccounts(res.data.data.map((acc: any) => {
          let account_name = acc.account_name ?? '';

          if (acc.account_id) {
            account_name += ' (' + acc.account_id + ')';
          }

          acc.traffic_source_type = acc.traffic_source_type.charAt(0).toUpperCase() + acc.traffic_source_type.slice(1);

          return {...acc, 'account_name': account_name};
        }));
      })
      .catch((_error) => {
        // TODO Add something
        // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
        //   {error.response.data?.error || error.toString()}
        // </Notification>);
      });
  }, [filters.traffic_source_type]);

  // Load campaign data
  React.useEffect(() => {
    if (campaignId) {
      HttpClient
        .get<any>(`campaign/${campaignId}`)
        .then(res => {
          const campaign = res.data;

          setFormData({
            ...formData,
            ...campaign,
            campaign_id: !isNaN(parseInt(campaign.campaign_id)) ? parseInt(campaign.campaign_id, 10) : 0,
            budget_amount: !isNaN(parseFloat(campaign.budget_amount)) ? parseFloat(campaign.budget_amount) : null,
            bid_amount: !isNaN(parseFloat(campaign.bid_amount)) ? parseFloat(campaign.bid_amount) : null,
            spending_limit: !isNaN(parseFloat(campaign.spending_limit)) ? parseFloat(campaign.spending_limit) : null,
            daily_cap: !isNaN(parseFloat(campaign.daily_cap)) ? parseFloat(campaign.daily_cap) : null,
            rules: campaign.rules
              .map((rule_id: any) => !isNaN(parseInt(rule_id)) ? parseInt(rule_id) : 0)
              .filter((rule_id: number) => rule_id > 0),
            node_count: campaign.node_count > 0
          });
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        });
    }
  }, [campaignId, loadCampaign]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set Loading icon or built menu into the Account selector
  const renderMenu = (menu: any) => {
    if (accounts.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  const handleTrafficSourceTypeChange = (v: string) => {
    setFilters({
      ...filters,
      traffic_source_type: v,
      traffic_source_account: null
    });
  };

  const handleTrafficSourceAccountChange = (v: string) => {
    setFilters({
      ...filters,
      traffic_source_account: v
    });
  };

  const fetchData = () => {
    // Clear and mask a table
    setData([]);
    setLoading(true);

    const {dates, ...filtersExcludeDates} = filters;

    // Get data
    HttpClient
      .get<any>('campaign', {
        query: search,
        ids: idList.join(),
        ...filtersExcludeDates,
        start_date: format(dates.start, "yyyy-MM-dd 00:00:00.000"),
        end_date: format(dates.end, "yyyy-MM-dd 23:59:59.997"),
      })
      .then(res => {
        setLoading(false);
        setData(formatData(res.data.data));
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
        setLoading(false);
      });
  };

  React.useEffect(fetchData, [search, filters, idList]);

  // TODO Remove after adding these data into the DB table and SP output
  const addCalculatedData = (list: any) => {
    return list.map((item: any) => {
      const cost = item.spent ? parseFloat(item.spent) : 0;
      const revenue = item.estimated_revenue ? parseFloat(item.estimated_revenue) : 0;
      const total_net = revenue - cost;
      const total_roi = cost !== 0 && total_net !== 0 ? (total_net / cost) * 100 : 0;

      return {
        ...item,
        arb_clicks: item.arb_clicks == null ? 0 : item.arb_clicks,
        arb_cost: item.arb_cost == null ? 0 : parseFloat(item.arb_cost),
        budget_amount: item.budget_amount ? parseFloat(item.budget_amount) : 0,
        budget_optimize_on: item.budget_optimize_on ? parseInt(item.budget_optimize_on) : 0,
        set_campaign_budget: item.budget_optimize_on !== 1 && item.budget_amount > 0 ? 1 : 0,
        bid_amount: item.bid_amount ? parseFloat(item.bid_amount) : 0,
        daily_cap: item.daily_cap ? parseFloat(item.daily_cap) : 0,
        spending_limit: item.spending_limit ? parseFloat(item.spending_limit) : 0,
        cpa: item.cpa ? parseFloat(item.cpa) : 0,
        cpc: item.cpc ? parseFloat(item.cpc) : 0,
        average_cpc: item.average_cpc ? parseFloat(item.average_cpc) : 0,
        clicks: item.clicks ? parseInt(item.clicks) : 0,
        tonic_clicks: item.tonic_clicks ? parseInt(item.tonic_clicks) : 0,
        impressions: item.impressions ? parseInt(item.impressions) : 0,
        visible_impressions: item.visible_impressions ? parseInt(item.visible_impressions) : 0,
        ctr: item.ctr ? parseFloat(item.ctr) : 0,
        vctr: item.vctr ? parseFloat(item.vctr) : 0,
        cpm: item.cpm ? parseFloat(item.cpm) : 0,
        vcpm: item.vcpm ? parseFloat(item.vcpm) : 0,
        campaigns_num: item.campaigns_num ? parseFloat(item.campaigns_num) : 0,
        cpa_actions_num: item.cpa_actions_num ? parseInt(item.cpa_actions_num) : 0,
        cpa_conversation_rate: item.cpa_conversation_rate ? parseFloat(item.cpa_conversation_rate) : 0,
        total_cost: cost,
        total_revenue: revenue,
        total_net,
        total_roi,
      };
    });
  };

  const [searchValue, setSearchValue] = React.useState('');

  const handleSearchInputChange = (value:any) => {
    setSearchValue(value);
  };

  const handleSubmitDate = () => {
    setFilters({
      ...filters,
      dates: dateRange
    });
    searchByQuery(searchValue)
  };

  const searchByQuery = (searchQuery: string) => {
    setPage(1);
    setSearch(searchQuery || '');
  };

  const [checkedKeys, setCheckedKeys] = React.useState<any[]>([]);

  const changeIsUpdating = React.useCallback((campaign: any, newValue: boolean) => {
    const current = data.findIndex((item: any) => item.campaign_id === campaign.campaign_id);

    campaign.isUpdating = newValue;
    data[current] = campaign;

    setData([...data]);
  }, [data]);

  const changeStatusBulk = React.useCallback((campaignIds: number[], checked: boolean) => {
    const activeValue = checked ? 1 : 0;

    // Clone data with changed 'active' field values
    const changedData = data.map((item: any) => {
      return campaignIds.includes(item.campaign_id)
        ? {...item, active: activeValue, isUpdating: true} : item;
    });

    setData(changedData);

    // Change status for each selected row
    campaignIds.forEach((campaignId) => {
      const campaign = changedData.find((item: any) => item.campaign_id === campaignId);

      changeStatus(campaignId, activeValue, campaign, () => changeIsUpdating(campaign, false));
    });
  }, [changeIsUpdating, data]);

  const changeStatus = (id: number, newValue: number, item: object, success: any) => {
    HttpClient
      .put<any>(`campaign/${id}`, { ...item, active: newValue })
      .then((_res) => {
        success();
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const handleActivateMenuItemClick = () => {
    changeStatusBulk(checkedKeys, true);
  };

  const handleDeactivateMenuItemClick = () => {
    changeStatusBulk(checkedKeys, false);
  };

  const handleViewReportMenuItemClick = () => {
    setIdList(checkedKeys);
  };

  const handleShowAllMenuItemClick = () => {
    setIdList([]);
  };

  const handleAfterCreateOrUpdateGridRow = React.useCallback((id: number, item: object) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.campaign_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      // Push a new row at the top
      setData([item, ...data]);
    }
  }, [data]);

  const formatData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        ...item,
        campaign_id: !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0,
        traffic_source_id: !isNaN(parseInt(item.traffic_source_id)) ? parseInt(item.traffic_source_id) : 0,
        unique_window: !isNaN(parseInt(item.unique_window)) ? parseInt(item.unique_window) : 0,
        active: !isNaN(parseInt(item.active)) ? parseInt(item.active) : 0,
        budget_amount: !isNaN(parseFloat(item.budget_amount)) ? parseFloat(item.budget_amount) : null,
        bid_amount: !isNaN(parseFloat(item.bid_amount)) ? parseFloat(item.bid_amount) : null,
        spending_limit: !isNaN(parseFloat(item.spending_limit)) ? parseFloat(item.spending_limit) : null,
        daily_cap: !isNaN(parseFloat(item.daily_cap)) ? parseFloat(item.daily_cap) : null,
      }
    });
  };

  function getSummary(data: any) {
    const summary: { [p: string]: number } = {};
    const summarizer = (accumulator: number, currentValue: number) =>
      accumulator + currentValue;

    const summaryFields = ["total_cost", "total_revenue", "total_net", "total_roi"];
    summaryFields.forEach((field) => {
      summary[field] = 0;
    });

    summaryFields.forEach((field: string) => {
      let values = data.map((item: { [index: string]: string }): number => {
        return parseFloat(item[field]);
      });
      summary[field] = values.reduce(summarizer, 0) || 0;
    });

    summary.total_roi = summary.total_roi ? (summary.total_net / summary.total_cost) * 100 : 0
    return summary;
  }

  const calculatedData = React.useMemo(() => addCalculatedData(data), [data]);
  const summary = React.useMemo(() => getSummary(Array.isArray(calculatedData) ? filterData(calculatedData, gridFilters) : []), [calculatedData, gridFilters]);

  const average = {
    total_cost: data.length && summary.total_cost && summary.total_cost / data.length,
    cpa: data.length && summary.total_revenue && summary.total_revenue / data.length,
    epc: data.length && summary.total_net && summary.total_net / data.length,
    // roi: widgetData.length && summary.roi && summary.roi / widgetData.length
  };

  const changeWidgetStatus = (campaignWidget: any, newValue: boolean) => {
    changeWidgetIsUpdating(campaignWidget, true);

    HttpClient
      .put<any>(`publisher/${campaignWidget.entry_id}`, {...campaignWidget, block: newValue})
      .then(res => {
        changeWidgetIsUpdating(campaignWidget, false);
        changeBlockedPublisherId(campaignWidget, res.data.data.blocked);
      })
      .catch(error => {
        changeWidgetIsUpdating(campaignWidget, true);
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const changeWidgetIsUpdating = (campaignWidget: any, newValue: boolean) => {
    const current = widgetData.findIndex((item: any) => item.entry_id === campaignWidget.entry_id);

    campaignWidget.isUpdating = newValue;
    widgetData[current] = campaignWidget;

    setWidgetData([...widgetData]);
  };

  const changeWidgetBidModifier = (campaignWidget: any, newValue: number|null) => {
    const current = widgetData.findIndex((item: any) => item.entry_id === campaignWidget.entry_id);

    campaignWidget.bid_modifier = newValue;
    widgetData[current] = campaignWidget;

    setWidgetData([...widgetData]);
  };

  const changeBlockedPublisherId = (campaignWidget: any, id: number = 0) => {
    const current = widgetData.findIndex((item: any) => item.entry_id === campaignWidget.entry_id);

    campaignWidget.blocked_publisher_id = id;
    widgetData[current] = campaignWidget;

    setWidgetData([...widgetData]);
  };

  const handleSubmitWidgetsFilter = (campaignId: number, start: Date, end: Date, dimension: Dimensions) => {
    showWidgetsRemotelyPopup(campaignId, start, end, dimension);
  };

  const addCalculatedValues = React.useCallback((row: any, index: number) => {
    const spent = !isNaN(parseFloat(row.spent)) ? parseFloat(row.spent) : 0;
    const arb_revenue = !isNaN(parseFloat(row.arb_revenue)) ? parseFloat(row.arb_revenue) : 0;
    const net_value = arb_revenue - spent;
    const roi = spent ? (net_value / spent) * 100 : 0;

    return {
      ...row,
      entry_id: row.entry_id || index,
      campaign_id: !isNaN(parseInt(row.campaign_id)) ? parseInt(row.campaign_id) : 0,
      bid_amount: !isNaN(parseFloat(row.bid_amount)) ? parseFloat(row.bid_amount) : 0,
      bid_modifier: !isNaN(parseFloat(row.bid_modifier)) ? parseFloat(row.bid_modifier) : null,
      blocked_publisher_id: !isNaN(parseInt(row.blocked_publisher_id)) ? parseInt(row.blocked_publisher_id) : 0,
      api_campaign_id: !isNaN(parseInt(row.api_campaign_id)) ? parseInt(row.api_campaign_id) : 0,
      clicks: !isNaN(parseInt(row.clicks)) ? parseInt(row.clicks) : 0,
      impressions: !isNaN(parseInt(row.impressions)) ? parseInt(row.impressions) : 0,
      visible_impressions: !isNaN(parseInt(row.visible_impressions)) ? parseInt(row.visible_impressions) : 0,
      spent,
      arb_revenue,
      conversions_value: !isNaN(parseFloat(row.conversions_value)) ? parseFloat(row.conversions_value).toFixed(2) : 0,
      net_value,
      roi: roi !== 0 ? roi.toFixed(2) : 0,
    };
  }, []);

  const addCalculatedValuesRecursively = React.useCallback((list: any) => {
    return list.map((row: any, index: number) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = addCalculatedValuesRecursively(row.children);
      }

      return addCalculatedValues(row, index);
    });
  }, [addCalculatedValues]);

  const showWidgetsRemotelyPopup = React.useCallback((campaignId: number, start: Date, end: Date, dimension: Dimensions) => {
    setWidgetsLoading(true);
    setWidgetData([]);
    setIsCampaignWidgetsRemotelyDrawerOpen(true);

    const path = dimension === Dimensions.Publisher ? 'campaign_widgets' : 'campaign_statistical_report';

    HttpClient
      .get<any>(`${path}/${campaignId}`, {
        start_date: format(start, "yyyy-MM-dd 00:00:00.000"),
        end_date: format(end, "yyyy-MM-dd 23:59:59.997"),
        dimension
      })
      .then(res => {
        setWidgetsLoading(false);
        setWidgetData(addCalculatedValuesRecursively(res.data.data));
      })
      .catch(error => {
        setWidgetsLoading(false);
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString() || "There are no widgets associated to that campaign name"}
        </Notification>);
      });
  }, [addCalculatedValuesRecursively]);

  const handleWidgetsRemotelyActionClick = React.useCallback((rowData: any) => {
    let msg;
    if (!rowData.api_campaign_id) {
      msg = "This campaign is not synchronized with a third-party provider.";
    }

    if (rowData.traffic_source_type !== API_NAME_TABOOLA) {
      msg = 'You can see widgets for Taboola provider only.'
    }

    const campaignId = rowData.campaign_id ? parseInt(rowData.campaign_id, 10) : 0;
    if (!campaignId) {
      msg = 'Campaign ID is empty';
    }

    if (msg) {
      pushInforming(<Notification closable type="warning" header="Warning" duration={30000}>{msg}</Notification>);
      return;
    }

    setCampaignData(rowData);
    setWidgetsStart(start);
    setWidgetsEnd(end);
    setReportDimension(Dimensions.Publisher)

    showWidgetsRemotelyPopup(campaignId, start, end, Dimensions.Publisher);
  }, [end, start, showWidgetsRemotelyPopup]);

  const changeContentDateRange = React.useCallback(() => {
    setContentDrawerStart(start);
    setContentDrawerEnd(end);
  }, [start, end]);

  //Filter highlight code
const initialStartDateRef = React.useRef(start);  
const initialEndDateRef = React.useRef(end); 
// const initialCategoryFilter = React.useRef(category !== null ? category: {})
const initialSearchFilter = React.useRef(search !== '' ? search: {})
const initialAdvertiserFilter = React.useRef(filters.traffic_source_account !== '' && filters.traffic_source_account !== null ? filters.traffic_source_account : {});
const initialTrafficSoucreFilter = React.useRef(filters.traffic_source_type !== '' && filters.traffic_source_type !== null ? filters.traffic_source_type : {});

 React.useEffect(() => {

     if (filters.traffic_source_account === initialAdvertiserFilter.current) {
      const innerDiv = document.querySelector('.account-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.account-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

     if (filters.traffic_source_type === initialTrafficSoucreFilter.current) {
      const innerDiv = document.querySelector('.traffic-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.traffic-filter > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if (search === initialSearchFilter.current) {
      setSearchValue(search)
      const innerDiv = document.querySelector('.search-filter > .rs-input-group > .rs-input');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.search-filter > .rs-input-group > .rs-input');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if (start.getTime() === initialStartDateRef.current.getTime()) {
      const innerDiv = document.querySelector('.date-start-filter > .rs-picker-toggle-wrapper > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.date-start-filter > .rs-picker-toggle-wrapper > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }

    if (end.getTime() === initialEndDateRef.current.getTime()) {
      const innerDiv = document.querySelector('.date-end-filter >  .rs-picker-toggle-wrapper > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', '#f6ffff', 'important');
      }
    }else{
      const innerDiv = document.querySelector('.date-end-filter > .rs-picker-toggle-wrapper > .rs-picker-toggle');
      if (innerDiv) {
        //@ts-ignore
        innerDiv.style.setProperty('background-color', 'inherit', 'important');
      }
    }
  }, [start, end, filters.traffic_source_account, filters.traffic_source_type, search]);
 
  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
            <Card label="Cost" type="currency" value={summary.total_cost} color={"#e26868"} average={average.total_cost}
                  averageTitle="Avg CPC"/>
          </Col>
          <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
            <Card label="Revenue" type="currency" value={summary.total_revenue} color={"#38c3b2"} average={average.cpa}
                  averageTitle="Avg CPA"/>
          </Col>
          <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
            <Card label="Net" type="currency" value={summary.total_net} color={"#e3ab1a"} average={average.epc}
                  averageTitle="Avg EPC"/>
          </Col>
          <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
            <Card label="ROI" type="percent" value={summary.total_roi} color={"#20aeda"}
                  averageTitle="Return On Investment"
                  hasAverage={false}
            />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row className="show-grid">
          <Col md={2}>
            <IconButton
              size="lg"
              color="blue"
              appearance="subtle"
              icon={<PlusRound />}
              onClick={() => {
                setCampaignId(0);
                setFormData(initialCampaignData);
                setIsCampaignDrawerOpen(true);
              }}
            >
              New
            </IconButton>
          </Col>
          <Col md={2}>
            <ActionsMenu
              isActive={checkedKeys.length > 0}
              selectedIds={idList}
              activateHandler={handleActivateMenuItemClick}
              deactivateHandler={handleDeactivateMenuItemClick}
              viewReportHandler={handleViewReportMenuItemClick}
              showAllHandler={handleShowAllMenuItemClick}
            />
          </Col>
          <Col md={4}>
            <SelectPicker
              className="traffic-filter"
              block
              name="traffic_source_type"
              placeholder="Select Traffic Source Type"
              placement="autoVerticalStart"
              defaultValue={filters.traffic_source_type}
              data={[
                {
                  label: "Taboola",
                  value: "taboola",
                },
                {
                  label: "Outbrain",
                  value: "outbrain",
                },
                {
                  label: "TikTok",
                  value: "tiktok",
                }
              ]}
              onChange={handleTrafficSourceTypeChange}
            />
          </Col>
          <Col md={4}>
            <SelectPicker
              className="account-filter"
              block
              name="traffic_source_account"
              placeholder="Select Account"
              placement="autoVerticalStart"
              defaultValue={filters.traffic_source_account !== null ? filters.traffic_source_account : ''}
              data={accounts}
              valueKey="account_id"
              labelKey="account_name"
              groupBy="traffic_source_type"
              onChange={handleTrafficSourceAccountChange}
              renderMenu={renderMenu}
            />
          </Col>
          <Col md={4} className="search-filter">
            {/* <SearchInput className="search-filter" initValue={search} setSearch={setSearch} searchByQuery={searchByQuery} /> */}
              {/* <SearchInput className="search-filter" initValue={search} setSearch={setSearch} searchByQuery={searchByQuery} />  */}

            <InputGroup
            style={{ width: 270, display: "inline-block" }}
          >
            <Input
              className="search-filter"
              placeholder="Search"
              value={searchValue}
              onChange={(v:any) => {
                handleSearchInputChange(v);
              }}
              size="md"
            />
          </InputGroup>
          </Col>
          <Col md={8}>

            <Form layout="inline"
                  model={model}
                  onChange={changeFormValue}
                  formValue={formValue}
                  formError={formError}
                  onCheck={setFormError}
            >     
              <Form.Group controlId="start">
                <Form.ControlLabel></Form.ControlLabel>
                <Form.Control
                  className="date-start-filter"
                  name="start"
                  accepter={DatePicker}
                  cleanable = {false}
                  value ={start}
                  ranges = {predefinedRanges}
                  onChange={(v:any) => {
                    setStart(v)
                    setEnd(new Date())
                    setDateRange({
                      start: v,
                      end: new Date()
                    });
                   }}
                  style={{width: 150}}
                  placement="autoVerticalStart"
                />
              </Form.Group>
              <Form.Group controlId="end">
                <Form.ControlLabel></Form.ControlLabel>
                <Form.Control
                  className="date-end-filter"
                  name="end"
                  accepter={DatePicker}
                  cleanable = {false}
                  value ={end}
                  ranges = {predefinedRanges}
                  onChange={(v:any) => {
                    setEnd(v);
                  }}
                  style={{width: 150}}
                  placement="autoVerticalEnd"
                />
              </Form.Group>

              <Button
                appearance="primary"
                color="green"
                onClick={handleSubmitDate}
              >Apply</Button>
            </Form>
          </Col>

        </Row>
      </Grid>

      <Campaign
        formData={formData}
        setFormData={setFormData}
        isOpen={isCampaignDrawerOpen}
        setIsOpen={setIsCampaignDrawerOpen}
        campaignId={campaignId}
        setCampaignName={setCampaignName}
        setCampaignId={setCampaignId}
        setApiCampaignId={setApiCampaignId}
        callbackCampaignContentDrawer={setIsCampaignContentListDrawerOpen}
        onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
      />

      <CampaignRuleListDrawer
        formData={formData}
        campaignData = {campaignData}
        setCampaignData = {setCampaignData}
        setFormData={setFormData}
        isOpen={campaignRuleListDrawerOpen}
        setIsOpen={setCampaignRuleListDrawerOpen}
        campaignId={campaignId}
        setCampaignName={setCampaignName}
        setCampaignId={setCampaignId}
        setApiCampaignId={setApiCampaignId}
        callbackCampaignContentDrawer={setIsCampaignContentListDrawerOpen}
        loadCampaign = {loadCampaign}
        setLoadCampaign = {setLoadCampaign}
      />

      <CampaignContentListDrawer
        itemData={formData}
        isOpen={isCampaignContentListDrawerOpen}
        setIsOpen={setIsCampaignContentListDrawerOpen}
        campaignId={campaignId}
        apiCampaignId={apiCampaignId}
        campaignName={campaignName}
        setCampaignId={setCampaignId}
        start={contentDrawerStart}
        end={contentDrawerEnd}
        setContentDrawerStart={setContentDrawerStart}
        setContentDrawerEnd={setContentDrawerEnd}
      />

      <TikTokAdGroupListDrawer
        itemData={formData}
        isOpen={isTikTokAdGroupListDrawerOpen}
        setIsOpen={setIsTikTokAdGroupListDrawerOpen}
        campaignId={campaignId}
        apiCampaignId={apiCampaignId}
        campaignName={campaignName}
        setCampaignId={setCampaignId}
      />

      <CampaignList
        data={calculatedData}
        filters={gridFilters}
        setFilters={setGridFilters}
        page={page}
        setPage={setPage}
        loading={loading}
        loadCampaign = {loadCampaign}
        setLoadCampaign = {setLoadCampaign}
        setIsCampaignDrawerOpen={setIsCampaignDrawerOpen}
        setIsCampaignContentListDrawerOpen={setIsCampaignContentListDrawerOpen}
        setIsTikTokAdGroupListDrawerOpen={setIsTikTokAdGroupListDrawerOpen}
        setCampaignRuleListDrawerOpen={setCampaignRuleListDrawerOpen}
        handleWidgetsRemotelyActionClick={handleWidgetsRemotelyActionClick}
        setFormData={setFormData}
        setCampaignId={setCampaignId}
        setApiCampaignId={setApiCampaignId}
        setCampaignData = {setCampaignData}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        changeStatus={changeStatusBulk}
        onAfterCreateOrUpdateGridRow={handleAfterCreateOrUpdateGridRow}
        changeContentDateRange={changeContentDateRange}
      />

      <CampaignWidgetsRemotely
        campaignData={campaignData}
        widgetData={widgetData}
        loading={widgetsLoading}
        start={widgetsStart}
        end={widgetsEnd}
        open={isCampaignWidgetsRemotelyDrawerOpen}
        setOpen={setIsCampaignWidgetsRemotelyDrawerOpen}
        setWidgetsStart={setWidgetsStart}
        setWidgetsEnd={setWidgetsEnd}
        changeWidgetStatus={changeWidgetStatus}
        changeWidgetBidModifier={changeWidgetBidModifier}
        handleSubmitWidgetsFilter={handleSubmitWidgetsFilter}
        reportDimension={reportDimension}
        setReportDimension={setReportDimension}
      />
    </>
  );
};

const ActionsMenu = ({
  isActive,
  selectedIds,
  activateHandler,
  deactivateHandler,
  viewReportHandler,
  showAllHandler,
  ...props
}: any) => {
  const activateKey = "activate";
  const deactivateKey = "deactivate";
  const viewReportKey = "view_report";
  const showAllKey = "show_all";

  const handleSelect = (eventKey: any) => {
    switch (eventKey) {
      case activateKey:
        activateHandler();
        break;

      case deactivateKey:
        deactivateHandler();
        break;

      case viewReportKey:
        viewReportHandler();
        break;

      case showAllKey:
        showAllHandler();
        break;
    }
  };


  return (
    <Dropdown
      title="Actions"
      trigger="hover"
      icon={<MoreIcon style={{fontSize: "1.8em"}}/>}
      onSelect={handleSelect}
      {...props}
    >
      <Dropdown.Item disabled={!isActive} eventKey={activateKey}>Activate</Dropdown.Item>
      <Dropdown.Item disabled={!isActive} eventKey={deactivateKey}>Deactivate</Dropdown.Item>
      <Dropdown.Item disabled={!isActive} eventKey={viewReportKey}>View Report</Dropdown.Item>
      {Array.isArray(selectedIds) && selectedIds.length > 0 &&
        <Dropdown.Item disabled={!isActive} eventKey={showAllKey}>Show All</Dropdown.Item>
      }
    </Dropdown>
  );
};

const { DateType } = Schema.Types;

const model = Schema.Model({
  start: DateType(),
  end: DateType(),
});

export default CampaignGrid;