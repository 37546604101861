import React, {ReactNode} from "react";
import {
  IconButton, Modal,
  Notification, toaster
} from "rsuite";
import PlusRound from "@rsuite/icons/PlusRound";
import CheckIcon from '@rsuite/icons/Check';
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import axios from "axios";
import GroupList from "./GroupList";
import AdmediaryConfig from "../../AdmediaryConfig";
import HttpClient from "../../@Utils/HttpClient";
import {pushInforming} from "../../@Utils/Messager";

type ManageGroupsProps = {
  open: boolean;
  groups: any;
  rules: any;
  handleClose: any;
  handleOpen: any;
};

const ManageGroups: React.FC<ManageGroupsProps> = ({
  open,
  rules,
  handleClose,
}) => {
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [scrollX, setScrollX] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);
  const [newData, setNewData] = React.useState<any[]>([]);
  const [removeIds, setRemoveIds] = React.useState<any[]>([]);
  const handleSetY = () => {
      setScrollY(5008);
 }


  const handleSave = () => {
    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, { placement });
    setLoading(true);
    const edited = data.filter((item: any) => item.status === 'edited');
    const added  =  data.filter((item: any) => item.status === 'added').map((item:any)=>({...item, group_id:0}));
    const removed  =  removeIds.filter((r) =>!newData.includes(r));
    let transformed = [...edited, ...added, ...removed];
    axios.all(transformed.map((element) => element.group_id === 0 ?
      axios.post<object, any>(AdmediaryConfig.API_BASE + '/rule_group', element) :
      element.status === 'edited'? axios.put<any>(AdmediaryConfig.API_BASE +`/rule_group/${element.group_id}`, element) :
        axios.delete<any>(AdmediaryConfig.API_BASE +`/rule_group/${element}`)
    )).then(
      (data) => {
        data.forEach((item:any) => {
          setLoading(!loading);
          const text = item.data.text;
          pushMessage(<Notification closable type={"success"} header="Success">{text}</Notification>);
        });
        handleClose();
      }
    );
    setRemoveIds([]);
    setNewData([]);
  };

  const handleCloseModal = () => {
    setRemoveIds([]);
    setScrollY(0);
    handleClose();
  };

  const handleAddClick = () => {
    // TO do Set max scroll of the table
    setScrollY(10000);
    let newGroup =  {
      group_name: 'New Group',
      status: 'added',
      group_id: data.length +1,
      rules: []
    };
    data.push(newGroup);
    newData.push(newGroup.group_id);
    setData([...data]);
    setNewData([...newData]);
  };

  return (
    <>
      <Modal
        size={"lg"}
        overflow
        open={open}
        onClose={handleCloseModal}
        dialogClassName={'modal-group'}
      >
        <Modal.Header>
          <Modal.Title as={'h4'} classPrefix={'modal-title'}>Manage Groups</Modal.Title>
        </Modal.Header>
        <hr/>
        <Modal.Body className="show-container"  style={{height: 800}}>
                <GroupList
                    data={data}
                    rules = {rules}
                    loading={loading}
                    setData={setData}
                    removeIds = {removeIds}
                    setRemoveIds ={setRemoveIds}
                    setLoading={setLoading}
                    setScrollY = {setScrollY}
                    scrollX = {scrollX}
                    scrollY = {scrollY}
                    parentCallback = {handleSetY}
                />
        </Modal.Body>
        <Modal.Footer className="show-container">
          <IconButton
            size="lg"
            color="blue"
            appearance="subtle"
            icon={<PlusRound/>}
            onClick={handleAddClick}
          >
            Add Group
          </IconButton>
          <IconButton
            size="lg"
            color="blue"
            appearance="primary"
            icon={<CheckIcon/>}
            onClick={handleSave}
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};


export default ManageGroups;
