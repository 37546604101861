const locationArray = [
    {
        "id": 2004,
        "name": "Afghanistan"
    },
    {
        "id": 2008,
        "name": "Albania"
    },
    {
        "id": 2010,
        "name": "Antarctica"
    },
    {
        "id": 2012,
        "name": "Algeria"
    },
    {
        "id": 2016,
        "name": "American Samoa"
    },
    {
        "id": 2020,
        "name": "Andorra"
    },
    {
        "id": 2024,
        "name": "Angola"
    },
    {
        "id": 2028,
        "name": "Antigua and Barbuda"
    },
    {
        "id": 2031,
        "name": "Azerbaijan"
    },
    {
        "id": 2032,
        "name": "Argentina"
    },
    {
        "id": 2036,
        "name": "Australia"
    },
    {
        "id": 2040,
        "name": "Austria"
    },
    {
        "id": 2044,
        "name": "The Bahamas"
    },
    {
        "id": 2048,
        "name": "Bahrain"
    },
    {
        "id": 2050,
        "name": "Bangladesh"
    },
    {
        "id": 2051,
        "name": "Armenia"
    },
    {
        "id": 2052,
        "name": "Barbados"
    },
    {
        "id": 2056,
        "name": "Belgium"
    },
    {
        "id": 2064,
        "name": "Bhutan"
    },
    {
        "id": 2068,
        "name": "Bolivia"
    },
    {
        "id": 2070,
        "name": "Bosnia and Herzegovina"
    },
    {
        "id": 2072,
        "name": "Botswana"
    },
    {
        "id": 2076,
        "name": "Brazil"
    },
    {
        "id": 2084,
        "name": "Belize"
    },
    {
        "id": 2090,
        "name": "Solomon Islands"
    },
    {
        "id": 2096,
        "name": "Brunei"
    },
    {
        "id": 2100,
        "name": "Bulgaria"
    },
    {
        "id": 2104,
        "name": "Myanmar (Burma)"
    },
    {
        "id": 2108,
        "name": "Burundi"
    },
    {
        "id": 2112,
        "name": "Belarus"
    },
    {
        "id": 2116,
        "name": "Cambodia"
    },
    {
        "id": 2120,
        "name": "Cameroon"
    },
    {
        "id": 2124,
        "name": "Canada"
    },
    {
        "id": 2132,
        "name": "Cabo Verde"
    },
    {
        "id": 2140,
        "name": "Central African Republic"
    },
    {
        "id": 2144,
        "name": "Sri Lanka"
    },
    {
        "id": 2148,
        "name": "Chad"
    },
    {
        "id": 2152,
        "name": "Chile"
    },
    {
        "id": 2156,
        "name": "China"
    },
    {
        "id": 2162,
        "name": "Christmas Island"
    },
    {
        "id": 2166,
        "name": "Cocos (Keeling) Islands"
    },
    {
        "id": 2170,
        "name": "Colombia"
    },
    {
        "id": 2174,
        "name": "Comoros"
    },
    {
        "id": 2178,
        "name": "Republic of the Congo"
    },
    {
        "id": 2180,
        "name": "Democratic Republic of the Congo"
    },
    {
        "id": 2184,
        "name": "Cook Islands"
    },
    {
        "id": 2188,
        "name": "Costa Rica"
    },
    {
        "id": 2191,
        "name": "Croatia"
    },
    {
        "id": 2196,
        "name": "Cyprus"
    },
    {
        "id": 2203,
        "name": "Czechia"
    },
    {
        "id": 2204,
        "name": "Benin"
    },
    {
        "id": 2208,
        "name": "Denmark"
    },
    {
        "id": 2212,
        "name": "Dominica"
    },
    {
        "id": 2214,
        "name": "Dominican Republic"
    },
    {
        "id": 2218,
        "name": "Ecuador"
    },
    {
        "id": 2222,
        "name": "El Salvador"
    },
    {
        "id": 2226,
        "name": "Equatorial Guinea"
    },
    {
        "id": 2231,
        "name": "Ethiopia"
    },
    {
        "id": 2232,
        "name": "Eritrea"
    },
    {
        "id": 2233,
        "name": "Estonia"
    },
    {
        "id": 2239,
        "name": "South Georgia and the South Sandwich Islands"
    },
    {
        "id": 2242,
        "name": "Fiji"
    },
    {
        "id": 2246,
        "name": "Finland"
    },
    {
        "id": 2250,
        "name": "France"
    },
    {
        "id": 2258,
        "name": "French Polynesia"
    },
    {
        "id": 2260,
        "name": "French Southern and Antarctic Lands"
    },
    {
        "id": 2262,
        "name": "Djibouti"
    },
    {
        "id": 2266,
        "name": "Gabon"
    },
    {
        "id": 2268,
        "name": "Georgia"
    },
    {
        "id": 2270,
        "name": "The Gambia"
    },
    {
        "id": 2276,
        "name": "Germany"
    },
    {
        "id": 2288,
        "name": "Ghana"
    },
    {
        "id": 2296,
        "name": "Kiribati"
    },
    {
        "id": 2300,
        "name": "Greece"
    },
    {
        "id": 2308,
        "name": "Grenada"
    },
    {
        "id": 2316,
        "name": "Guam"
    },
    {
        "id": 2320,
        "name": "Guatemala"
    },
    {
        "id": 2324,
        "name": "Guinea"
    },
    {
        "id": 2328,
        "name": "Guyana"
    },
    {
        "id": 2332,
        "name": "Haiti"
    },
    {
        "id": 2334,
        "name": "Heard Island and McDonald Islands"
    },
    {
        "id": 2336,
        "name": "Vatican City"
    },
    {
        "id": 2340,
        "name": "Honduras"
    },
    {
        "id": 2348,
        "name": "Hungary"
    },
    {
        "id": 2352,
        "name": "Iceland"
    },
    {
        "id": 2356,
        "name": "India"
    },
    {
        "id": 2360,
        "name": "Indonesia"
    },
    {
        "id": 2368,
        "name": "Iraq"
    },
    {
        "id": 2372,
        "name": "Ireland"
    },
    {
        "id": 2376,
        "name": "Israel"
    },
    {
        "id": 2380,
        "name": "Italy"
    },
    {
        "id": 2384,
        "name": "Cote d'Ivoire"
    },
    {
        "id": 2388,
        "name": "Jamaica"
    },
    {
        "id": 2392,
        "name": "Japan"
    },
    {
        "id": 2398,
        "name": "Kazakhstan"
    },
    {
        "id": 2400,
        "name": "Jordan"
    },
    {
        "id": 2404,
        "name": "Kenya"
    },
    {
        "id": 2410,
        "name": "South Korea"
    },
    {
        "id": 2414,
        "name": "Kuwait"
    },
    {
        "id": 2417,
        "name": "Kyrgyzstan"
    },
    {
        "id": 2418,
        "name": "Laos"
    },
    {
        "id": 2422,
        "name": "Lebanon"
    },
    {
        "id": 2426,
        "name": "Lesotho"
    },
    {
        "id": 2428,
        "name": "Latvia"
    },
    {
        "id": 2430,
        "name": "Liberia"
    },
    {
        "id": 2434,
        "name": "Libya"
    },
    {
        "id": 2438,
        "name": "Liechtenstein"
    },
    {
        "id": 2440,
        "name": "Lithuania"
    },
    {
        "id": 2442,
        "name": "Luxembourg"
    },
    {
        "id": 2450,
        "name": "Madagascar"
    },
    {
        "id": 2454,
        "name": "Malawi"
    },
    {
        "id": 2458,
        "name": "Malaysia"
    },
    {
        "id": 2462,
        "name": "Maldives"
    },
    {
        "id": 2466,
        "name": "Mali"
    },
    {
        "id": 2470,
        "name": "Malta"
    },
    {
        "id": 2478,
        "name": "Mauritania"
    },
    {
        "id": 2480,
        "name": "Mauritius"
    },
    {
        "id": 2484,
        "name": "Mexico"
    },
    {
        "id": 2492,
        "name": "Monaco"
    },
    {
        "id": 2496,
        "name": "Mongolia"
    },
    {
        "id": 2498,
        "name": "Moldova"
    },
    {
        "id": 2499,
        "name": "Montenegro"
    },
    {
        "id": 2504,
        "name": "Morocco"
    },
    {
        "id": 2508,
        "name": "Mozambique"
    },
    {
        "id": 2512,
        "name": "Oman"
    },
    {
        "id": 2516,
        "name": "Namibia"
    },
    {
        "id": 2520,
        "name": "Nauru"
    },
    {
        "id": 2524,
        "name": "Nepal"
    },
    {
        "id": 2528,
        "name": "Netherlands"
    },
    {
        "id": 2531,
        "name": "Curacao"
    },
    {
        "id": 2534,
        "name": "Sint Maarten"
    },
    {
        "id": 2535,
        "name": "Caribbean Netherlands"
    },
    {
        "id": 2540,
        "name": "New Caledonia"
    },
    {
        "id": 2548,
        "name": "Vanuatu"
    },
    {
        "id": 2554,
        "name": "New Zealand"
    },
    {
        "id": 2558,
        "name": "Nicaragua"
    },
    {
        "id": 2562,
        "name": "Niger"
    },
    {
        "id": 2566,
        "name": "Nigeria"
    },
    {
        "id": 2570,
        "name": "Niue"
    },
    {
        "id": 2574,
        "name": "Norfolk Island"
    },
    {
        "id": 2578,
        "name": "Norway"
    },
    {
        "id": 2580,
        "name": "Northern Mariana Islands"
    },
    {
        "id": 2581,
        "name": "United States Minor Outlying Islands"
    },
    {
        "id": 2583,
        "name": "Micronesia"
    },
    {
        "id": 2584,
        "name": "Marshall Islands"
    },
    {
        "id": 2585,
        "name": "Palau"
    },
    {
        "id": 2586,
        "name": "Pakistan"
    },
    {
        "id": 2591,
        "name": "Panama"
    },
    {
        "id": 2598,
        "name": "Papua New Guinea"
    },
    {
        "id": 2600,
        "name": "Paraguay"
    },
    {
        "id": 2604,
        "name": "Peru"
    },
    {
        "id": 2608,
        "name": "Philippines"
    },
    {
        "id": 2612,
        "name": "Pitcairn Islands"
    },
    {
        "id": 2616,
        "name": "Poland"
    },
    {
        "id": 2620,
        "name": "Portugal"
    },
    {
        "id": 2624,
        "name": "Guinea-Bissau"
    },
    {
        "id": 2626,
        "name": "Timor-Leste"
    },
    {
        "id": 2634,
        "name": "Qatar"
    },
    {
        "id": 2642,
        "name": "Romania"
    },
    {
        "id": 2643,
        "name": "Russia"
    },
    {
        "id": 2646,
        "name": "Rwanda"
    },
    {
        "id": 2652,
        "name": "Saint Barthelemy"
    },
    {
        "id": 2654,
        "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
        "id": 2659,
        "name": "Saint Kitts and Nevis"
    },
    {
        "id": 2662,
        "name": "Saint Lucia"
    },
    {
        "id": 2663,
        "name": "Saint Martin"
    },
    {
        "id": 2666,
        "name": "Saint Pierre and Miquelon"
    },
    {
        "id": 2670,
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "id": 2674,
        "name": "San Marino"
    },
    {
        "id": 2678,
        "name": "Sao Tome and Principe"
    },
    {
        "id": 2682,
        "name": "Saudi Arabia"
    },
    {
        "id": 2686,
        "name": "Senegal"
    },
    {
        "id": 2688,
        "name": "Serbia"
    },
    {
        "id": 2690,
        "name": "Seychelles"
    },
    {
        "id": 2694,
        "name": "Sierra Leone"
    },
    {
        "id": 2702,
        "name": "Singapore"
    },
    {
        "id": 2703,
        "name": "Slovakia"
    },
    {
        "id": 2704,
        "name": "Vietnam"
    },
    {
        "id": 2705,
        "name": "Slovenia"
    },
    {
        "id": 2706,
        "name": "Somalia"
    },
    {
        "id": 2710,
        "name": "South Africa"
    },
    {
        "id": 2716,
        "name": "Zimbabwe"
    },
    {
        "id": 2724,
        "name": "Spain"
    },
    {
        "id": 2728,
        "name": "South Sudan"
    },
    {
        "id": 2736,
        "name": "Sudan"
    },
    {
        "id": 2740,
        "name": "Suriname"
    },
    {
        "id": 2748,
        "name": "Eswatini"
    },
    {
        "id": 2752,
        "name": "Sweden"
    },
    {
        "id": 2756,
        "name": "Switzerland"
    },
    {
        "id": 2158,
        "name": "Taiwan"
    },
    {
        "id": 2762,
        "name": "Tajikistan"
    },
    {
        "id": 2764,
        "name": "Thailand"
    },
    {
        "id": 2768,
        "name": "Togo"
    },
    {
        "id": 2772,
        "name": "Tokelau"
    },
    {
        "id": 2776,
        "name": "Tonga"
    },
    {
        "id": 2780,
        "name": "Trinidad and Tobago"
    },
    {
        "id": 2784,
        "name": "United Arab Emirates"
    },
    {
        "id": 2788,
        "name": "Tunisia"
    },
    {
        "id": 2792,
        "name": "Turkiye"
    },
    {
        "id": 2795,
        "name": "Turkmenistan"
    },
    {
        "id": 2798,
        "name": "Tuvalu"
    },
    {
        "id": 2800,
        "name": "Uganda"
    },
    {
        "id": 2804,
        "name": "Ukraine"
    },
    {
        "id": 2807,
        "name": "North Macedonia"
    },
    {
        "id": 2818,
        "name": "Egypt"
    },
    {
        "id": 2826,
        "name": "United Kingdom"
    },
    {
        "id": 2831,
        "name": "Guernsey"
    },
    {
        "id": 2832,
        "name": "Jersey"
    },
    {
        "id": 2833,
        "name": "Isle of Man"
    },
    {
        "id": 2834,
        "name": "Tanzania"
    },
    {
        "id": 2840,
        "name": "United States"
    },
    {
        "id": 2854,
        "name": "Burkina Faso"
    },
    {
        "id": 2858,
        "name": "Uruguay"
    },
    {
        "id": 2860,
        "name": "Uzbekistan"
    },
    {
        "id": 2862,
        "name": "Venezuela"
    },
    {
        "id": 2876,
        "name": "Wallis and Futuna"
    },
    {
        "id": 2882,
        "name": "Samoa"
    },
    {
        "id": 2887,
        "name": "Yemen"
    },
    {
        "id": 2894,
        "name": "Zambia"
    }
];

export default locationArray;