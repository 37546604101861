import React from "react";
import { Container, Content, Loader } from "rsuite";
import SideBar from "./Dashboard/Sidebar/SideBar";
import 'rsuite/dist/rsuite.min.css';
import { useHistory } from "react-router";
import { FirebaseAuthContext, LOCAL_STORAGE_AUTH_KEYS } from "../@Context/FirebaseAuthContext";
import HttpClient from "../@Utils/HttpClient";
import { Link } from "react-router-dom";
import { pushErrorNotification } from "../@Utils/Messager";
import { getAuth } from "firebase/auth";
import { useUserPermissions } from "../@Context/UserPermissionsContext";

const sidebar_styles: any = {
  background: "#021b3b",
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  position: 'fixed',
  width: 243,
  zIndex: 1
};

interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const authContext = React.useContext(FirebaseAuthContext);
  const auth = getAuth();

  const getIdToken = (isRefreshOnBackend: boolean = false) =>
    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);

        if (isRefreshOnBackend) {
          setTimeout(() => {
            HttpClient.put<any>('refresh_token');
          }, 10000);
        }
      })
      .catch((error) => console.log(error));

  // refresh token on page route change
  const history = useHistory();
  React.useEffect(() => {
    return history.listen(() => {
      reload();
      getIdToken();
    });
  }, [history]);

  // Refresh an access token every 15 minutes
  React.useEffect(() => {
    const interval = setInterval(() => getIdToken(true), 900000);

    return () => clearInterval(interval);
  }, []);

  const isUserInitialized = authContext.isUserInitialized;
  const {permissions, isLoading, error, reload} = useUserPermissions();

  const outputLoader = (title: string, desc: string) =>
    <Loader
      center
      content={
        <div>
          {desc}
          <br/>
          <Link
            to="/signin"
            onClick={(e) => {
              e.preventDefault();
              authContext.logout();
            }}
            style={{fontSize: 20}}
          >
            {title}
          </Link>
        </div>
      }
    />
  ;

  // Wait for auth user initialization
  if (!isUserInitialized) {
    return outputLoader('Abort', 'User Initialization...');
  }

  // TODO Remove after testing
  // Wait for permissions loading at first time
  // if (isLoading && error === null) {
  //   return outputLoader('Abort', 'Fetching user permissions...');
  // }

  // Check if there was an error during permissions loading
  if (!isLoading && error !== null) {
    pushErrorNotification(error);

    return outputLoader('Sign in', 'There is an error with fetching user permissions. Try to sign in again!');
  }

  /***
   * Helper function for setting active class if the current location
   * has a base that matches the string provided.
   */
  return (
    <Container>
      <SideBar styles={sidebar_styles}/>
      <Container>
        <Content style={{position: 'relative', maxWidth: 'calc(100vw - 275px)', left: 260, height: '100vh'}}>
          {(isLoading && error === null)
            ? <Loader center size="lg" content="Fetching user permissions..." />
            : props.children
          }
        </Content>
      </Container>
    </Container>
  );
};

export default Dashboard;
