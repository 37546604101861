import React, {useState} from "react";
import { Button, Drawer, Loader, Notification } from "rsuite";
import { FormInstance } from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import _ from "lodash";
import RuleForm from "../Rules/RuleForm";

type RuleProps = {
  formData: any;
  isOpen: any;
  closeDrawer: () => void;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
  taboolaProviders: any[];
  isFormLoading?: boolean;
};

const Rule: React.FC<RuleProps> = ({
  formData,
  isOpen,
  closeDrawer,
  onAfterCreateOrUpdateGridRow,
  taboolaProviders,
  isFormLoading = false,
}) => {
  const [formValue, setFormValue] = React.useState(formData);
  const formRef = React.createRef<FormInstance>();
  const [formError, setFormError] = useState<any>({});
  const isNew = !(formValue.rule_id > 0);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [ruleGroups, setRuleGroups]: any = React.useState([]);

  // TODO Replace it
  React.useEffect(() => {
    setFormValue(formData);
  }, [formData]);

  // Load group list
  React.useEffect(() => {
    HttpClient
      .get<any>('rule_group')
      .then(res => {
        setRuleGroups(res.data.data.map((item: any) => ({
          ...item,
          group_id: !isNaN(parseInt(item.group_id)) ? parseInt(item.group_id) : 0
        })));
      });
  }, []);

  const handleSave = () => {
    const node = formRef && formRef.current;

    if (!!node?.check && !node.check()) {
      return;
    } else if (Object.keys(formError).length > 0) {

        return;
    }

    saveItem(formValue, formValue.rule_id);
  };

  const saveItem = (data: any, itemId: number = 0) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    data.conditions = data.conditions.map((condition: any) => {
      if (condition.compare_criteria === '' || !['percent_of_campaign', 'percent_of_adgroup'].includes(condition.compare_criteria)) {
        condition = {
          ...condition,
          field_criteria: '',
        };
      }

      if (condition.if_criteria === 'hour_of_day') {
        return {
          ...condition,
          are_criteria: '',
          than_criteria: JSON.stringify(condition.hours_than_criteria),
        }
      }

      return condition;
    });

    data.active = !isNaN(parseInt(data.active)) ? parseInt(data.active, 10) : 0;
    data.test = !isNaN(parseInt(data.test)) ? parseInt(data.test) : 0;
    setSaveButtonLoading(true);

    // if (campaignId > 0) {
    //   data = {
    //     ...data,
    //     campaign_id: campaignId
    //   };
    // }

    if (itemId === 0) {
      // Create a new one
      HttpClient.post<object, any>('rule', data)
        .then(res => {
          const ruleId = parseInt(res.data.rule_id, 10);
          const type = res.status === 201 ? "success" : "error";
          const text = res.status === 201 ? "Rule has been created" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          // Update form data
          const updatedFormValue = {
            ...data,
            ...res.data,
     //       campaign_id: campaignId,
            rule_id: ruleId,
            groups: res.data.groups
              .map((group_id: any) => !isNaN(parseInt(group_id)) ? parseInt(group_id) : 0)
              .filter((group_id: number) => group_id > 0)
              .map((group_id: number) => ({
                group_id,
                group_name: ruleGroups.filter((item: any) => item.group_id === group_id).pop()?.group_name || '',
              })),
          };

          closeDrawer();

          // Add a new row into a grid data
          onAfterCreateOrUpdateGridRow(ruleId, updatedFormValue);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    } else {

      // Update an existing one
      HttpClient.put<any>(`rule/${itemId}`, data)
        .then(res => {
          const type = res.status === 200 ? "success" : "error";
          const text = res.status === 200 ? "Rule has been updated" : res.statusText;

          pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

          closeDrawer();

          // Update form data
          const updatedFormValue = {
            ...data,
            ...res.data,
            groups: res.data.groups
              .map((group_id: any) => !isNaN(parseInt(group_id)) ? parseInt(group_id) : 0)
              .filter((group_id: number) => group_id > 0)
              .map((group_id: number) => ({
                group_id,
                group_name: ruleGroups.filter((item: any) => item.group_id === group_id).pop()?.group_name || '',
              })),
          };

          // Update a grid data
          onAfterCreateOrUpdateGridRow(itemId, updatedFormValue);
        })
        .catch(error => {
          pushErrorNotification(error.response.data?.error || error.toString());
        })
        .finally(() => {
          setSaveButtonLoading(false);
        });
    }
  };
  const handleCloseButtonClick = () => {
    closeDrawer();
  };

  const handleRuleCallback = (formValue: any) => {
    setFormValue(formValue);
  };

  return (
    <>
      <Drawer open={isOpen} backdrop={false} onClose={handleCloseButtonClick} size='full'>
        <Drawer.Header>
          <Drawer.Title>
            {isNew ? 'Add Rule' : 'Edit Rule'}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
              Save
            </Button>
            <Button onClick={handleCloseButtonClick}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {isFormLoading
            ? <Loader size="lg" content="Loading..." center />
            : <RuleForm
              formRef={formRef}
              formValue={formValue}
              parentCallback={handleRuleCallback}
              setFormError={setFormError}
              formError={formError}
              taboolaProviders={taboolaProviders}
            />
          }
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default Rule;