import React from "react";
import { Button, Drawer, FlexboxGrid, IconButton, Notification } from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification, pushInforming } from "../../@Utils/Messager";
import PlusRound from "@rsuite/icons/PlusRound";
import TikTokAdGroupList from "../Campaigns/TikTokAdGroupList";
import { AD_STATUS_DISABLE, AD_STATUS_ENABLE } from "../Campaigns/TikTokAdModel";
import TikTokAdGroupDrawer from "./TikTokAdGroupDrawer";
import { initialData as initialAdGroupData } from "../Campaigns/AdGroupModel";

type TikTokAdGroupListDrawerProps = {
  itemData: any;
  isOpen: any;
  setIsOpen: any;
  campaignId: number;
  campaignName: any;
  setCampaignId: any;
  apiCampaignId: any;
};

const TikTokAdGroupListDrawer: React.FC<TikTokAdGroupListDrawerProps> = ({
  itemData,
  isOpen,
  setIsOpen,
  campaignId = 0,
  campaignName = '',
  apiCampaignId = 0,
}) => {
  itemData.campaign_id = campaignId;
  itemData.campaign_name = itemData.campaign_name ? itemData.campaign_name :campaignName;

  const [formData, setFormData] = React.useState(initialAdGroupData);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [sortType, setSortType] = React.useState();
  const [sortColumn, setSortColumn] = React.useState();
  const [adGroupId, setAdGroupId] = React.useState(0);
  const [isAdGroupDrawerOpen, setIsAdGroupDrawerOpen] = React.useState(false);

  const formatRow = React.useCallback((item: any) => {
    const formatAsInt = (value: any, defVal: number | null = 0) => !isNaN(parseInt(value)) ? parseInt(value) : defVal;
    const formatAsFloat = (value: any, defVal: number = 0) => !isNaN(parseFloat(value)) ? parseFloat(value) : defVal;

    return {
      ...item,
      adgroup_id: formatAsInt(item.adgroup_id),
      ad_id: formatAsInt(item.ad_id),
      campaign_id: formatAsInt(item.campaign_id),
    };
  }, []);

  const formatDataRecursively = React.useCallback((list: any[]) => {
    return list.map((row: any) => {
      if (row.children && Array.isArray(row.children)) {
        row.children = formatDataRecursively(row.children);
      }

      return formatRow(row);
    });
  }, [formatRow]);

  const fetchData = () => {
    // Clear and mask a table
    setData([]);
    setLoading(true);

    if (itemData.api_campaign_id && isOpen) {
      // Get data
      HttpClient
        .get<any>('tiktok_ad_tree', {
          apiCampaignId: itemData.api_campaign_id,
        })
        .then(res => {
          setLoading(false);
          setData(formatDataRecursively(res.data.data));
        })
        .catch(error => {
          pushInforming(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
          setLoading(false);
        });
    }
  };

  React.useEffect(fetchData, [isOpen, itemData.api_campaign_id, formatDataRecursively]);

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleNewButtonClick = (rowData: any) => {
    setFormData({
      ...initialAdGroupData,
      advertiser_id: itemData.advertiser_id,
      campaign_id: itemData.api_campaign_id,
    });
    setIsAdGroupDrawerOpen(true);
  };

  const changeAdStatus = (id: number, newValue: string, item: object, success: any) => {
    HttpClient
      .put<any>(`tiktok_ad_status/${id}`, {
        ...item,
        operation_status: newValue,
      })
      .then((_res) => {
        success();
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
      });
  };

  const changeIsUpdating = (adItem: any, newValue: boolean) => {
    const currentGroup = data.findIndex((item: any) =>
      item.children.some((childItem: any) => childItem.ad_id === adItem.ad_id));

    const currentAd = data[currentGroup].children.findIndex((item: any) => item.ad_id === adItem.ad_id);

    adItem.isUpdating = newValue;
    data[currentGroup].children[currentAd] = adItem;

    setData([...data]);
  };

  const handleChangeAdStatus = (rowData: any, checked: boolean) => {
    const adId = rowData.ad_id;
    const statusValue = checked ? AD_STATUS_ENABLE : AD_STATUS_DISABLE;

    // Clone data with changed 'active' field values
    const changeItem = (item: any, adId: number) => item.ad_id === adId
        ? {...item, operation_status: statusValue, isUpdating: true} : item;

    const changedData = data.map((item: any) => {
      if (item.children && item.children.length) {
        item.children = item.children.map((item: any) => changeItem(item, adId));
      }

      return changeItem(item, adId);
    });

    setData(changedData);

    // Change status for each selected row
    const changedRowData = changeItem(rowData, adId);

    changeAdStatus(adId, statusValue, rowData, () => changeIsUpdating(changedRowData, false));
  };

  const [isAdGroupDataLoading, setIsAdGroupDataLoading] = React.useState(false);

  return (
    <>
      <Drawer open={isOpen} onClose={() => setIsOpen(false)} size="full">
        <Drawer.Header>
          <Drawer.Title>TikTok Ad Groups</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <div>
            <span>Campaign ID: {itemData.campaign_id}</span><br/>
            <span>Campaign Name: {itemData.campaign_name}</span>
          </div>

          <FlexboxGrid justify="space-between" style={{marginTop: 15, marginBottom: 15, padding: "0 15px"}}>
            <FlexboxGrid.Item colspan={24}>
              <IconButton
                size="lg"
                color="blue"
                appearance="subtle"
                icon={<PlusRound/>}
                onClick={handleNewButtonClick}
              >
                New Ad Group
              </IconButton>
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <TikTokAdGroupList
            data={data.map((item: object) => ({
              ...item,
              campaign_name: itemData.campaign_name,
            }))}
            loading={loading}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            setAdGroupFormData={setFormData}
            setAdGroupId={setAdGroupId}
            setIsAdGroupDrawerOpen={setIsAdGroupDrawerOpen}
            changeAdStatus={handleChangeAdStatus}
            refreshCallback={() => fetchData()}
            setIsAdGroupDataLoading={setIsAdGroupDataLoading}
          />

          <TikTokAdGroupDrawer
            formValue={formData}
            setFormValue={setFormData}
            isOpen={isAdGroupDrawerOpen}
            setIsOpen={setIsAdGroupDrawerOpen}
            refreshCallback={() => fetchData()}
            isDataLoading={isAdGroupDataLoading}
          />
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default TikTokAdGroupListDrawer;
