import React from "react";
import Dashboard from "../@Components/Dashboard";
import TonicKeywordGrid from "../@Components/TonicKeywords/TonicKeywordGrid";

const TonicKeywordReport: React.FC = () => {
  return (
    <Dashboard>
      <TonicKeywordGrid/>
    </Dashboard>
  );
};

export default TonicKeywordReport;
