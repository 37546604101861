import React from "react";
import {Col, Grid, Row, SelectPicker, Loader, Button} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import _ from "lodash";
import {pushErrorNotification} from "../../@Utils/Messager";

type UrlAdvertiserForm = {
    formValue: any,
    parentCallback?: any;
}
const UrlAdvertiserForm: React.FC<UrlAdvertiserForm> = ({
formValue,
parentCallback = () => {},
}) => {
    const defaultAdvertiser = formValue.advertiser_id ? formValue.advertiser_id : '';
    const [accounts, setAccounts] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState(defaultAdvertiser);
    const [pixels, setPixels] = React.useState([]);
    const [selectedPixel, setSelectedPixel] = React.useState();
    const [pixelLoading, setPixelLoading] = React.useState('none');

    React.useEffect(() => {
        HttpClient
            .get<any>('advertiser')
            .then(res => {
                // @ts-ignore
                setAccounts(_.sortBy(res.data,'advertiser_name'));
            })
            .catch((_error) => {
                // TODO Add something
                // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                //   {error.response.data?.error || error.toString()}
                // </Notification>);
            });
    }, []);

    React.useEffect(() => {
        setPixels([]);
        if (selectedAccount) {
            setPixelLoading('block')
            HttpClient
                .get<any>('tiktok_pixels', {
                    advertiser_id: selectedAccount
                })
                .then(res => {
                    setPixels(
                        res.data.map((pixel: any) => ({
                            ...pixel,
                            pixel_output: `${pixel.pixel_name} (ID=${pixel.pixel_code})`
                        }))
                    )
                    setPixelLoading('none')
                })
                .catch(error => {
                    pushErrorNotification(error.response.data?.error || error.toString());
                });
        }

    }, [selectedAccount])

    const handlePixelChange = (data: any) => {
        setSelectedPixel(data);

        if (typeof parentCallback === "function") {
            parentCallback({
                ...formValue,
            ...{
                    ttid: data
            }
            });
        }
    }

    const handleAdvertiserAccountChange = (data: any) => {
        setSelectedAccount(data);

        if (typeof parentCallback === "function") {
            parentCallback({
                ...formValue,
                ...{
                    advertiser_id: data
                }
            });
        }
    }


    return (
        <>
            <Grid fluid>
                <Row>
                    <Col xs={12} sm={12} md={24} lg={24} style={{ textAlign: 'center'}}>
                        <SelectPicker
                            searchable={true}
                            cleanable={true}
                            placeholder="Ad Account"
                            value={selectedAccount}
                            data={accounts}
                            valueKey="advertiser_id"
                            labelKey="advertiser_name"
                            groupBy="traffic_source_type"
                            // defaultValue={formValue.advertiser_id}
                            onChange={(v) => {
                                handleAdvertiserAccountChange(v);
                            }}
                            style={{  marginBottom: 15, width: '50%' }}
                        />
                        <Loader size="md" style={{display: pixelLoading}}/>
                    </Col>

                    { selectedAccount && pixels.length && formValue.advertiser_id ? (<Col xs={12} sm={12} md={24} lg={24} style={{ textAlign: 'center'}}>
                            <SelectPicker
                                searchable={true}
                                cleanable={true}
                                placeholder="Pixel"
                                value={selectedPixel}
                                data={pixels}
                                defaultValue={formValue.ttid}
                                valueKey="pixel_code"
                                labelKey="pixel_output"
                                onChange={(v) => {
                                    handlePixelChange(v);
                                }}
                                style={{ marginBottom: 15,marginRight: 15}}
                            />
                        </Col>
                    ) : ''}
                </Row>
            </Grid>
        </>
    )
}

// const convertToInt = (value: string | number) => typeof value === 'number' ? value : parseInt(value, 10);

export default UrlAdvertiserForm;