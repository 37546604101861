import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useFirebaseAuth } from "../@Context/FirebaseAuthContext";
import { PropsWithChildren } from "react";
import { pushErrorNotification } from "../@Utils/Messager";
import useCheckAccess from "../@Utils/useCheckAccess";
import { SEARCH_ARB_HOME_URL } from "../App";
import { useUserPermissions } from "../@Context/UserPermissionsContext";

// @ts-ignore
interface ProtectedRouteWithPermissionsProps extends RouteProps {
    component: React.ComponentType<any>;
}

const ProtectedRouteWithPermissions: React.FC<ProtectedRouteWithPermissionsProps> = ({
    component: Component,
    ...rest
}: PropsWithChildren<any>) => {
    // Check for permissions
    const {isAuth} = useFirebaseAuth();
    const {isLoading} = useUserPermissions();
    const checkAccess = useCheckAccess();
    const hasAccess = checkAccess(rest.path, 'showPage');

    if (!isLoading && !hasAccess) {
        pushErrorNotification("You don't have permissions for current functionality. Contact the Administrator.");

        return <Redirect push to={SEARCH_ARB_HOME_URL} />;
    }

    return (
      <Route
        render={(props) =>
          isAuth
            ? <Component {...props} />
            : <Redirect to={{pathname: "/signin", state: {from: props.location}}}/>
        }
        {...rest}
      />
    );
};

export default ProtectedRouteWithPermissions;
