import React from "react";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  FlexboxGrid,
  Form,
  Grid,
  Input,
  InputGroup,
  Row,
  Schema,
  SelectPicker,
} from "rsuite";
import Card from "../Campaigns/Card";
import SearchIcon from "@rsuite/icons/Search";
import CloseIcon from "@rsuite/icons/Close";
import { DEFAULT_PAGE, filterByProperties, getSummaryByProperties } from "../../@Utils/DataProcessing";
import CampaignWidgetList from "../Campaigns/CampaignWidgetList";
import CampaignStatisticsReportList from "../Campaigns/CampaignStatisticsReportList";

export enum Dimensions {
  Day = 'day',
  DayOfWeek = 'day_of_week',
  HourOfDay = 'by_hour_of_day',
  Publisher = 'site_breakdown',
  Country = 'country_breakdown',
  Region = 'region_breakdown',
  Platform = 'platform_breakdown',
  OsFamily = 'os_family_breakdown',
  OsVersion = 'os_version_breakdown',
  Browser = 'browser_breakdown',
  Unknown = 'unknown',
  Default = 'site_breakdown',
}

/**
 * Filter data by query
 */
const filterByQuery = (data: any[], query: string = '') =>
    query
      ? filterByProperties(data, query, ['site', 'site_id', 'site_name', 'date', 'hour_of_day', 'site', 'country_name', 'country', 'country_code', 'region', 'region_code', 'platform', 'platform_name', 'os_family', 'os_version', 'browser'])
      : data;

type CampaignWidgetsRemotelyProps = {
  campaignData: any;
  widgetData: any;
  loading: boolean;
  start: Date;
  end: Date;
  open: any;
  setOpen: any;
  setWidgetsStart: any;
  setWidgetsEnd: any;
  changeWidgetStatus: (rowData: any, checked: boolean) => void;
  changeWidgetBidModifier: (rowData: any, bidModifier: number|null) => void;
  handleSubmitWidgetsFilter: (campaignId: number, start: Date, end: Date, dimension: Dimensions) => void;
  reportDimension: Dimensions;
  setReportDimension: any;
};

const CampaignWidgetsRemotely: React.FC<CampaignWidgetsRemotelyProps> = ({
  campaignData,
  widgetData,
  loading = false,
  start,
  end,
  open,
  setOpen,
  setWidgetsStart,
  setWidgetsEnd,
  changeWidgetStatus,
  changeWidgetBidModifier,
  handleSubmitWidgetsFilter,
  reportDimension = Dimensions.Default,
  setReportDimension,
}) => {
  const [page, setPage] = React.useState(DEFAULT_PAGE);
  const [searchQuery, setSearchQuery] = React.useState('');
  const filteredData = filterByQuery(widgetData, searchQuery);
  const summary = getSummaryByProperties(filteredData, ["clicks", "cpa_actions_num", "spent", "arb_revenue", "net_value"]);

  const average = {
    cpc: filteredData.length && summary.clicks && (summary.spent / summary.clicks),
    cpa: filteredData.length && summary.cpa_actions_num && (summary.spent / summary.cpa_actions_num),
    epc: filteredData.length && summary.clicks && (summary.arb_revenue - summary.spent / summary.clicks),
  };

  const [formError, setFormError] = React.useState<any>({});
  const [formValue, setFormValue] = React.useState({
    start: start,
    end: end,
  });

  React.useEffect(() => {
    setFormValue({
      start: start,
      end: end,
    });
  }, [start, end]);

  // Clean up the search input if the grid data have been changed
  React.useEffect(() => {
    setSearchQuery('');
  }, [widgetData]);

  const handleFormChange = (formValue: any) => {
    setFormValue(formValue);
    setWidgetsStart(formValue.start);
    setWidgetsEnd(formValue.end);
  };

  const searchByQuery = () => {
    setPage(1);
    setSearchQuery(searchQuery || '');
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  const renderReportByDimension = (dimension: Dimensions) => {
    switch(dimension) {
      case Dimensions.Publisher:
        return <CampaignWidgetList
          data={filteredData}
          loading={loading}
          page={page}
          setPage={setPage}
          campaignData={campaignData}
          changeWidgetStatus={changeWidgetStatus}
          changeWidgetBidModifier={changeWidgetBidModifier}
        />;

      case Dimensions.Day:
      case Dimensions.DayOfWeek:
      case Dimensions.HourOfDay:
      case Dimensions.Country:
      case Dimensions.Region:
      case Dimensions.Platform:
      case Dimensions.OsFamily:
      case Dimensions.OsVersion:
      case Dimensions.Browser:
        return <CampaignStatisticsReportList
          dimension={dimension}
          data={filteredData}
          loading={loading}
          page={page}
          setPage={setPage}
        />;

      case Dimensions.Unknown:
      default:
        return <div>Report for dimension={dimension} is under construction</div>;
    }
  }

  return (
    <>
      <Drawer open={open} backdrop={false} onClose={() => setOpen(false)} size="full">
        <Drawer.Header>
          <Drawer.Title>Campaign {reportDimension === Dimensions.Publisher ? "Widgets" : "Statistics"}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpen(false)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <FlexboxGrid justify="space-between" style={{ marginBottom: 25 }}>
            <FlexboxGrid.Item colspan={10}>
              <Grid fluid>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <b>Campaign ID</b>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    {campaignData.campaign_id}
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <b>Campaign Name</b>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    {campaignData.campaign_name}
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <b>API Campaign ID</b>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    {campaignData.api_campaign_id}
                  </Col>
                </Row>
              </Grid>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={3} style={{ textAlign: "right", paddingRight: 20 }}>
              <SelectPicker
                size="md"
                placeholder="Report Dimension"
                searchable={false}
                cleanable={false}
                defaultValue={Dimensions.Default}
                value={reportDimension}
                data={[
                  {value: Dimensions.Day, label: "Day"},
                  {value: Dimensions.DayOfWeek, label: "Day of Week"},
                  {value: Dimensions.HourOfDay, label: "Hour of Day"},
                  {value: Dimensions.Publisher, label: "Publisher"},
                  {value: Dimensions.Country, label: "Country"},
                  {value: Dimensions.Region, label: "Region"},
                  {value: Dimensions.Platform, label: "Platform"},
                  {value: Dimensions.OsFamily, label: "OS Family"},
                  {value: Dimensions.OsVersion, label: "OS Version"},
                  {value: Dimensions.Browser, label: "Browser"},
                ]}
                onChange={(value: Dimensions) => {
                  setReportDimension(value);
                  handleSubmitWidgetsFilter(campaignData.campaign_id, start, end, value)
                }}
                style={{marginRight: 15, width: 200}}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4} style={{ textAlign: "right", paddingRight: 20 }}>
              <InputGroup style={{ width: '100%' }}>
                <InputGroup.Button onClick={searchByQuery}>
                  <SearchIcon />
                </InputGroup.Button>
                <Input
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(v) => handleSearchChange(v)}
                />
                <InputGroup.Button onClick={clearSearchQuery}>
                  <CloseIcon />
                </InputGroup.Button>
              </InputGroup>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={7} style={{ textAlign: "right", paddingRight: 20 }}>
              <Form
                layout="inline"
                model={model}
                formValue={formValue}
                formError={formError}
                onChange={handleFormChange}
                onCheck={setFormError}
              >
                <Form.Group controlId="start">
                  <Form.ControlLabel></Form.ControlLabel>
                  <Form.Control
                    name="start"
                    accepter={DatePicker}
                    cleanable={false}
                    // value={start}
                    // onChange={(v:any) => {setStart(v)}}
                    style={{width: 150}}
                    placement="autoVerticalStart"
                  />
                </Form.Group>
                <Form.Group controlId="end">
                  <Form.ControlLabel></Form.ControlLabel>
                  <Form.Control
                    name="end"
                    accepter={DatePicker}
                    cleanable={false}
                    // value={end}
                    // onChange={(v:any) => {setEnd(v)}}
                    style={{width: 150}}
                    placement="autoVerticalStart"
                  />
                </Form.Group>

                <Button
                  appearance="primary"
                  color="green"
                  onClick={() => handleSubmitWidgetsFilter(campaignData.campaign_id, start, end, reportDimension)}
                >
                  Apply
                </Button>
              </Form>
            </FlexboxGrid.Item>
          </FlexboxGrid>

          <Grid fluid>
            <Row className="show-grid">
              <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                <Card
                  label="Cost"
                  type="currency"
                  value={summary.spent}
                  color={"#e26868"}
                  average={average.cpc}
                  averageTitle="Avg CPC"
                />
              </Col>
              <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                <Card
                  label="Revenue"
                  type="currency"
                  value={summary.arb_revenue}
                  color={"#38c3b2"}
                  average={average.cpa}
                  averageTitle="Avg CPA"
                />
              </Col>
              <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                <Card
                  label="Net"
                  type="currency"
                  value={summary.net_value}
                  color={"#e3ab1a"}
                  average={average.epc}
                  averageTitle="Avg EPC"
                />
              </Col>
              <Col className="content-card stat-card" xs={12} sm={12} md={6} lg={6}>
                <Card
                  label="ROI"
                  type="percent"
                  value={(summary.net_value / summary.spent) * 100}
                  color={"#20aeda"}
                  averageTitle="Return On Investment"
                  hasAverage={false}
                />
              </Col>
            </Row>
          </Grid>

          {renderReportByDimension(reportDimension)}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

const {DateType} = Schema.Types;

const model = Schema.Model({
  start: DateType(),
  end: DateType(),
});

export default CampaignWidgetsRemotely;
