import { useCallback } from "react";
import { useUserPermissions } from "../@Context/UserPermissionsContext";

const useCheckAccess = () => {
  const {isLoading, permissions} = useUserPermissions();

  return useCallback((resource: string, action: string) => {
    if (permissions.length === 0) return true;

    if (isLoading) return false;

    // remove a leading slash if it was sent as path
    resource = (resource.charAt(0) === '/') ? resource.substring(1) : resource;

    // At first checked denied resources
    const deniedResource = permissions.find((item: any) => {
      return item.type !== undefined && item.type === 'deny'
        && (item.resource === resource || item.resource === '*')
        && (item.action === undefined
          || (Array.isArray(item.action) && item.action.includes(action))
          || item.action === action || item.action === '*'
          );
    });

    if (deniedResource !== undefined) {
      return false;
    }

    const allowedResource = permissions.find((item: any) => {
      return (item.type === undefined || item.type === 'allow')
        && (item.resource === resource || item.resource === '*')
        && (item.action === undefined
          || (Array.isArray(item.action) && item.action.includes(action))
          || item.action === action || item.action === '*'
        );
    });

    return allowedResource !== undefined;
  }, [isLoading, permissions]);
};

export default useCheckAccess;
