import React from "react";
import {
  Checkbox,
  Col,
  Grid,
  IconButton,
  Notification,
  Pagination,
  Row,
  Table,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import CopyIcon from "@rsuite/icons/Copy";
import ListIcon from "@rsuite/icons/List";
import ReloadIcon from "@rsuite/icons/Reload";
import CheckIcon from "@rsuite/icons/Check";
import ShareOutlineIcon from "@rsuite/icons/ShareOutline";
import RuleIcon from '@rsuite/icons/DocPass';
import { Link } from "react-router-dom";
import ButtonToolbar from "rsuite/ButtonToolbar";
import _ from "lodash";
import { addDays, format, parse, set } from "date-fns";
import HttpClient from "../../@Utils/HttpClient";
import { pushInforming } from "../../@Utils/Messager";
import FunnelIcon from "@rsuite/icons/Funnel";
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import { sort } from "../../@Utils/Sorting";
import { Filter, filterData, paginateData } from "../../@Utils/DataProcessing";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { exportCsv } from "../../@Utils/Export";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import TableColumnsMenu, { buildColumns, formatExportData, renderColumns } from "../TableColumnsMenu";

const {Column, HeaderCell, Cell} = Table;

const API_NAME_TIKTOK = "tiktok";

type CampaignListProps = {
  data?: any;
  setCampaignData: any;
  filters: Filter[];
  setFilters: any;
  page: number;
  setPage: any;
  loading?: any;
  setIsCampaignDrawerOpen: any;
  setIsCampaignContentListDrawerOpen: any;
  setIsTikTokAdGroupListDrawerOpen: any;
  setCampaignRuleListDrawerOpen: any;
  handleWidgetsRemotelyActionClick: (rowData: any) => void;
  setFormData: any;
  setCampaignId: any;
  loadCampaign : any;
  setLoadCampaign : any;
  setApiCampaignId: any;
  checkedKeys: any[];
  setCheckedKeys: any;
  changeStatus: (campaignIds: number[], checked: boolean) => void;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
  changeContentDateRange: () => void;
};

const CampaignList: React.FC<CampaignListProps> = ({
  data = [],
  setCampaignData,
  filters,
  setFilters,
  page = 1,
  setPage,
  loading = false,
  setIsCampaignDrawerOpen,
  setCampaignRuleListDrawerOpen,
  setIsCampaignContentListDrawerOpen,
  setIsTikTokAdGroupListDrawerOpen,
  handleWidgetsRemotelyActionClick,
  setFormData,
  setCampaignId,
  loadCampaign,
  setLoadCampaign,
  setApiCampaignId,
  checkedKeys,
  setCheckedKeys,
  changeStatus,
  onAfterCreateOrUpdateGridRow,
  changeContentDateRange,
}) => {
  // @ts-ignore
  const [sortType, setSortType] =  useStoredValue(LOCAL_STORAGE_KEYS.CAMPAIGN_PAGE_SORT_TYPE,"asc");
  const [sortColumn, setSortColumn] = useStoredValue(LOCAL_STORAGE_KEYS.CAMPAIGN_PAGE_SORT_COLUMN,'campaign_id');

  const [campaigns, setCampaigns] = React.useState<any[]>([]);
  const checked = checkedKeys.length > 0 && checkedKeys.length === campaigns.length;
  const indeterminate = checkedKeys.length > 0 && checkedKeys.length < campaigns.length;
  const [limit, setLimit] = React.useState(100);

  const handleCheckAll = (value: any, checked: boolean) => {
    const keys = checked ? campaigns.map((item: any) => item.campaign_id) : [];
    setCheckedKeys(keys);
  };

  const handleCheck = (value: any, checked: boolean) => {
    const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);

    setCheckedKeys(keys);
  };

  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  React.useEffect(() => {
    setCampaigns(data);
  }, [data]);

  const handleEditActionClick = (rowData: any) => {
    setCampaignId(rowData.campaign_id);
    setFormData({...rowData, rules_groups: []});
    setIsCampaignDrawerOpen(true);
    setLoadCampaign(!loadCampaign);
  };

  const handleRuleListAction = (rowData: any) => {
    setCampaignId(rowData.campaign_id);
    setCampaignData(rowData);
    setCampaignRuleListDrawerOpen(true);
    setLoadCampaign(!loadCampaign);
  };

  const handleCloneActionClick = (rowData: any) => {
    const formData = {
      ...rowData,
      campaign_id: 0,
    };

    fetchCampaignContentsAndSaveCampaign(rowData.campaign_id, formData);
  };

  const updateLocalCampaign = (campaignId: string | number, values: any) => {
    campaignId = typeof campaignId === "string" ? parseInt(campaignId, 10) : campaignId;
    values = typeof values === 'object' && values !== null ? values : {};

    const campaignIndex = campaigns.findIndex((item: any) => item.campaign_id === campaignId);

    if (campaignIndex === -1) {
      return;
    }

    campaigns[campaignIndex] = {
      ...campaigns[campaignIndex],
      ...values,
    };

    setCampaigns([...campaigns]);
  };

  const handleSynchroStatus = (rowData: any) => {
    const campaignId = rowData.campaign_id ? rowData.campaign_id : 0;

    if (!campaignId) return;

    updateLocalCampaign(campaignId, {isSynchronizing: true});

    HttpClient
      .post<object, any>(`api_campaign/${campaignId}`, rowData)
      .then(res => {
        const apiData = isJson(res.data.api_data) ? JSON.parse(res.data.api_data) : {};
        const isError = res.status === 201 && res.data.api_synchro_status !== "done";
        const type = !isError ? "success" : "error";
        const header = !isError ? "Success" : "Error";
        const duration = !isError ? 10000 : 60000;

        let fullMessage = apiData.last_message;
        if (res.data.api_synchro_status === 'failed' && apiData.last_message) {
          const messageObject = isJson(apiData.last_message) ? JSON.parse(apiData.last_message) : {};
          const message = messageObject.message || apiData.last_message;
          fullMessage =
            <span>
              Error: {message}<br/>
              {messageObject.offending_field ? 'Field: ' + messageObject.offending_field : ''}
            </span>;
        }

        const text = !isError ? "Campaign is synchronized via API" :
          fullMessage ? fullMessage : res.statusText;

        // Stop synchronizing and update row data
        updateLocalCampaign(res.data.campaign_id, {
          api_campaign_id: res.data.api_campaign_id,
          api_data: res.data.api_data,
          api_synchro_status: res.data.api_synchro_status,
          isSynchronizing: false,
        });

        pushInforming(<Notification closable type={type} header={header} duration={duration}>{text}</Notification>);
      })
      .catch(error => {
        // Stop synchronizing and update row data
        updateLocalCampaign(campaignId, {isSynchronizing: false});

        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const saveItem = (data: any, contentData: any) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    const formatDateToStringOrNull = (date: Date | string | null): string | null =>
      (date === null ?
          null :
          (date instanceof Date ? format(date, "yyyy-MM-dd 00:00:00") : date)
      );

    const stringifyArray = (data: any): string =>
      Array.isArray(data) ? JSON.stringify(data) : data;

    // Format date to string
    data.end_date = formatDateToStringOrNull(data.end_date);
    data.creative_traffic_allocation_start = formatDateToStringOrNull(data.creative_traffic_allocation_start);
    data.creative_traffic_allocation_end = formatDateToStringOrNull(data.creative_traffic_allocation_end);

    if (data.creative_traffic_allocation_end) {
      const today = set(new Date(), {minutes: 0, seconds: 0, milliseconds: 0});

      if (addDays(today, 3) > parse(data.creative_traffic_allocation_end, "yyyy-MM-dd HH:mm:ss", new Date())) {
        data.creative_traffic_allocation_end = formatDateToStringOrNull(addDays(today, 3));
      }
      // 00:00:00
      // const date = parse(dateStr, "yyyy-MM-dd HH:mm:ss", new Date());
      // const nextDate = set(addHours(date, hours), {minutes: 0, seconds: 0, milliseconds: 0});
      //
      // return format(nextDate, "yyyy-MM-dd HH:mm:ss");
      // subDays(new Date(), 1)
      // set(subDays(new Date(), 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    }

    // Format arrays as JSON
    data.locations = stringifyArray(data.locations);
    data.locations_sub = stringifyArray(data.locations_sub);
    data.device_type = stringifyArray(data.device_type);
    data.os = stringifyArray(data.os);
    data.browser = stringifyArray(data.browser);

    // Change campaign name to make it unique
    const matches = data.campaign_name.match(/^(.*)copy\(([0-9]*)\)$/);
    const index = matches && matches.length ? parseInt(matches[2]) + 1 : 1;
    const newName = (matches && matches.length) ? matches[1] : data.campaign_name;

    data.campaign_name = `${newName} copy(${index})`;

    // Clean up sync data
    data.api_campaign_id = '';
    data.api_data = '';
    data.api_synchro_status = 'none';

    // Create a new one
    HttpClient.post<object, any>("campaign", data)
      .then(res => {
        const campaignId = parseInt(res.data.campaign_id, 10);
        const type = res.status === 201 ? "success" : "error";
        const text = res.status === 201 ? "Campaign has been created" : res.statusText;

        pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

        contentData.forEach((element: any) => {
          fetchCampaignContentMediaListAndSaveCampaignContent(element, campaignId);
        });

        // Add a new row into a grid data
        onAfterCreateOrUpdateGridRow(campaignId, {
          ...data,
          ...res.data,
          campaign_id: campaignId,
          api_campaign_id: res.data.api_campaign_id || '',
          api_synchro_status: res.data.api_synchro_status || 'none',
          api_data: res.data.api_data,
        });
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const fetchCampaignContentsAndSaveCampaign = (campaignId: number = 0, formData: any) => {
    // Get campaign contents data
    HttpClient
      .get<any>("campaign_content", {
        campaignId
      })
      .then(res => {
        const contentData = formatData(res.data.data);

        saveItem(formData, contentData);
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const saveCampaignContent = (data: any, campaignId: number) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    // Remove empty headline without image value
    data.media_list = data.media_list.filter((media: any) => media.headline !== "" && media.image !== null);
    data.campaign_id = campaignId;

    // Create a new one
    HttpClient.post<object, any>("campaign_content", data)
      .then(_res => {
      })
      .catch(error => {
        pushInforming(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  const fetchCampaignContentMediaListAndSaveCampaignContent = (data: any, campaignId: number) => {
    HttpClient
      .get<any>('campaign_content_media', {
        page: 1,
        limit: 5000,
        contentId: data.content_id,
        sortDir: 'a'
      })
      .then(res => {
        data.content_id = 0;
        data.media_list = res.data.data.map((item: any) => ({
          ...item,
          api_item_id: null,
          api_data: '{}',
          content_id: 0,
          media_id: 0,
        }));

        saveCampaignContent(data, campaignId);
      });
  };

  const formatData = (data: any[]) => {
    return data.map((item: any) => {
      return {
        ...item,
        campaign_id: !isNaN(parseInt(item.campaign_id)) ? parseInt(item.campaign_id) : 0,
        content_id: !isNaN(parseInt(item.content_id)) ? parseInt(item.content_id) : 0,
        media_list: [],
      };
    });
  };

  const handleViewCampaignContents = (rowData: any) => {
    setApiCampaignId(rowData.api_campaign_id);
    setCampaignId(rowData.campaign_id);
    setFormData(rowData);
    changeContentDateRange();

    if (API_NAME_TIKTOK === rowData.traffic_source_type) {
      setIsTikTokAdGroupListDrawerOpen(true);
    } else {
      setIsCampaignContentListDrawerOpen(true);
    }
  };

  const handleChangeStatus = (rowData: any, checked: boolean) => {
    const campaignIds = checkedKeys.length > 0 && (rowData === undefined || checkedKeys.includes(rowData.campaign_id))
      ? checkedKeys : [rowData.campaign_id];

    changeStatus(campaignIds, checked);
  };

  const handleChangeLimit = (limit: number) => {
    setPage(1);
    setLimit(limit);
  };

  const changeHeaderFiltersHandler = (filters: Filter[]) => {
    setPage(1);
    setFilters(filters);
  };

  const handleExportCsvButtonClick = () => {
    exportCsv('campaigns', formatExportData(Array.isArray(filteredRows) ? filteredRows : [], builtColumns));
  };

  const filteredRows = React.useMemo(() => filterData(campaigns, filters), [campaigns, filters]);

  const columns = React.useMemo(() => [
    {
      dataKey: 'campaign_id',
      label: 'Camp ID',
      fixed: true,
      width: 90,
      align: 'right',
      enableHiding: false,
    },
    {
      dataKey: 'campaign_name',
      label: 'Name',
      fixed: true,
      width: 200
    },
    {
      dataKey: 'active',
      label: 'Status',
      width: 110,
      align: 'center',
      cellCmp: CircleCell,
      exportFormatFn: (value: any) => (!!value ? 'Active' : 'Inactive'),
    },
    {
      dataKey: 'description',
      label: 'Branding Text',
      width: 200
    }, {
      dataKey: 'api_campaign_id',
      label: 'API Campaign ID',
      width: 200
    },
    {
      dataKey: 'total_cost',
      label: 'Total Cost',
      type: 'currency',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'total_revenue',
      label: 'Total Revenue',
      type: 'currency',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'total_net',
      label: 'Total Net',
      type: 'currency',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'total_roi',
      label: 'Total ROI',
      type: 'percent',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'clicks',
      label: 'Taboola Clicks',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'tonic_clicks',
      label: 'S1/Tonic Clicks',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'impressions',
      label: 'Impressions',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'visible_impressions',
      label: 'Visible Impressions',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'cpc',
      label: 'CPC',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'average_cpc',
      label: 'Avg CPC',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'ctr',
      label: 'Avg CTR',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'vctr',
      label: 'Avg VCTR',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'cpm',
      label: 'Avg CPM',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'vcpm',
      label: 'Avg VCPM',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'cpa',
      label: 'Avg CPA',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'bid_amount',
      label: 'Bid Amount',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'daily_cap',
      label: 'Daily Cap',
      type: 'currency',
      width: 100,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'spending_limit',
      label: 'Spending Limit',
      type: 'currency',
      width: 120,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'cpa_actions_num',
      label: 'CPA Actions Num',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'cpa_conversation_rate',
      label: 'CPA Conversations Rate',
      type: 'currency',
      width: 200,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'campaigns_num',
      label: 'Campaigns Num',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'arb_clicks',
      label: 'ARB Clicks',
      type: 'number',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
    {
      dataKey: 'arb_cost',
      label: 'ARB Costs',
      type: 'currency',
      width: 150,
      align: 'right',
      isFiltered: true,
    },
  ], []);

  const [visibleColumnKeys, setVisibleColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.CAMPAIGNS_PAGE_GRID_SHOW_COLUMNS,
    columns.map(column => column.dataKey)
  );

  const [orderedColumnKeys, setOrderedColumnKeys] = useStoredValue(
    LOCAL_STORAGE_KEYS.CAMPAIGNS_PAGE_GRID_COLUMNS_ORDER,
    columns.map(column => column.dataKey)
  );

  const builtColumns = React.useMemo(() => {
    return buildColumns(columns, visibleColumnKeys, orderedColumnKeys);
  }, [columns, visibleColumnKeys, orderedColumnKeys]);

  const handleDragColumn = (sourceId: string, targetId: string) => {
    setOrderedColumnKeys(sortColumns(orderedColumnKeys, sourceId, targetId));
  };

  const sortColumns = (source: string[], sourceId: string, targetId: string) => {
    // Push source element in the source array if the source doesn't contain it
    if (source.indexOf(sourceId) === -1) {
      source = [...source, sourceId];
    }

    const nextData = source.filter((key: string) => key !== sourceId);
    const dragItem = source.find((key: string) => key === sourceId);
    const index = nextData.findIndex((key: string) => key === targetId);

    if (dragItem)
      nextData.splice(index, 0, dragItem);

    return nextData;
  };

  const preparedData = React.useMemo(() => {
    return paginateData(sort(filteredRows, sortColumn, sortType), page, limit);
  }, [filteredRows, sortColumn, sortType, page, limit]);

  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col md={23}>
            <IconButton
              size="md"
              appearance="subtle"
              style={{marginLeft: 15, backgroundColor: '#f2f2f5', float: 'right'}}
              icon={<FileDownloadIcon/>}
              placement="right"
              onClick={handleExportCsvButtonClick}
            >
              Download CSV
            </IconButton>
          </Col>
          <Col md={1}>
            <TableColumnsMenu
              columns={columns}
              visibleColumnKeys={visibleColumnKeys}
              setVisibleColumnKeys={setVisibleColumnKeys}
            />
          </Col>
        </Row>
      </Grid>

      <DndProvider backend={HTML5Backend}>
        <Table
          autoHeight
          data={preparedData}
          loading={loading}
          virtualized
          rowClassName="striped-rows"
          affixHeader
          affixHorizontalScrollbar
          //@ts-ignore
          sortType={sortType}
          sortColumn={sortColumn}
          onSortColumn={handleSortColumn}
          rowHeight={43}
          headerHeight={61}
        >
          <Column width={70} align="center" fixed>
            <HeaderCell style={{padding: 0}}>
              <div style={{lineHeight: "38px"}}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell dataKey="campaign_id" checkedKeys={checkedKeys} onChange={handleCheck}/>
          </Column>
          <Column width={300} align="center" fixed verticalAlign="top">
            <HeaderCell>Actions</HeaderCell>
            <ActionCell
              editHandler={handleEditActionClick}
              changeStatusHandler={handleChangeStatus}
              cloneHandler={handleCloneActionClick}
              viewCampaignContentsHandler={handleViewCampaignContents}
              viewWidgetsRemotelyHandler={handleWidgetsRemotelyActionClick}
              synchroStatusHandler={handleSynchroStatus}
              ruleListHandler={handleRuleListAction}
            />
          </Column>
          {renderColumns(builtColumns, handleDragColumn, filters, changeHeaderFiltersHandler)}
        </Table>
      </DndProvider>

      <Pagination
        prev
        next
        first
        last
        ellipsis
        boundaryLinks
        maxButtons={5}
        size="lg"
        layout={["total", "-", "limit", "|", "pager", "skip"]}
        total={filteredRows.length}
        limitOptions={[10, 20, 50, 100]}
        limit={limit}
        activePage={page}
        onChangePage={setPage}
        onChangeLimit={handleChangeLimit}
      />
    </>
  );
};

const CircleCell = ({
  rowData,
  ...props
}: any) => (
  <Cell {...props} className="link-group">
    <Circle isActive={rowData.active}/>
  </Cell>
);

const Circle = ({isActive = false}) => {
  const style = {
    height: 20,
    width: 20,
    marginTop: 8,
    backgroundColor: isActive ? "#00FF00" : "#FFA500",
    borderRadius: "50%",
    display: "inline-block",
  };

  return <span style={style}/>;
};

export const ActionCell = ({
  rowData,
  editHandler,
  changeStatusHandler,
  cloneHandler,
  viewCampaignContentsHandler,
  viewWidgetsRemotelyHandler,
  synchroStatusHandler,
  ruleListHandler,
  ...props
}: any) => {
  const status = !!rowData.active;
  const apiData = isJson(rowData.api_data) ? JSON.parse(rowData.api_data) : {};
  const isNotSynchronized = rowData.api_synchro_status === 'none';
  const isSynchronizedFailed = rowData.api_synchro_status === 'failed';
  const error = apiData?.last_message ? ' Error: ' + apiData.last_message : '';
  const synchroTooltipText = isNotSynchronized ? 'Start synchronization' : isSynchronizedFailed
    ? 'Synchronization is failed. Press to repeat.' + error : 'Synchronization is successful';
  const SynchroIcon = isNotSynchronized ? <ShareOutlineIcon/> : isSynchronizedFailed
    ? <ReloadIcon/> : <CheckIcon/>;
  const buttonStyle = {marginLeft: '6px'};
  return (
    <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
      <div>
        <ButtonToolbar>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Enable/Disable Toggle</Tooltip>}
          >
            <Toggle
              loading={rowData.isUpdating}
              checked={status}
              onChange={() => changeStatusHandler(rowData, !status)}
              size="md"
              style={{marginBottom: 'auto', marginTop: 'auto'}}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Clone</Tooltip>}
          >
            <IconButton
              icon={<CopyIcon />}
              onClick={(e) => cloneHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Edit</Tooltip>}
          >
            <IconButton
              icon={<EditIcon/>}
              onClick={() => editHandler(rowData)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>{API_NAME_TIKTOK === rowData.traffic_source_type ? 'Ad Groups' : 'Campaign Contents'}</Tooltip>}
          >
            <IconButton
              icon={<ListIcon/>}
              onClick={() => viewCampaignContentsHandler(rowData)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Widgets and Statistics</Tooltip>}
          >
            <IconButton
              icon={<CloudReflashIcon/>}
              onClick={() => viewWidgetsRemotelyHandler(rowData)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>{synchroTooltipText}</Tooltip>}
          >
            <IconButton
              icon={SynchroIcon}
              loading={rowData.isSynchronizing}
              onClick={() => (isNotSynchronized || isSynchronizedFailed) && synchroStatusHandler(rowData)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>View Related Funnels</Tooltip>}
          >
            <Link to={`/funnels/campaign/${rowData.campaign_id}`}
                  style={buttonStyle}>
              <IconButton
                icon={<FunnelIcon />}
                size='sm'
              />
            </Link>
          </Whisper>
          <Whisper
            trigger="hover"
            placement="top"
            speaker={<Tooltip>Rules</Tooltip>}
          >
            <IconButton
              icon={<RuleIcon/>}
              onClick={(e) => ruleListHandler(rowData, e)}
              size='sm'
              style={buttonStyle}
            />
          </Whisper>
        </ButtonToolbar>
      </div>
    </Cell>
  );
};

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
  <Cell {...props} style={{padding: 0}}>
    <div style={{lineHeight: "35px"}}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
      />
    </div>
  </Cell>;

const isJson = (str: string) => {
  try { JSON.parse(str);} catch (e) {return false;}
  return true;
};

export default CampaignList;