import React, { useEffect } from "react";
import { Message, toaster } from "rsuite";
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, provider } from "../@Config/Firebase";
import { pushErrorMessage, pushInforming } from "../@Utils/Messager";
import HttpClient from "../@Utils/HttpClient";

const ALERT_DURATION: number = 20000;

/**
 * Constants for local storage keys
 */
const LOCAL_STORAGE_AUTH_KEYS = {
    NAME: "auth.name",
    UID: "auth.uid",
    ACCESS_TOKEN: "auth.access_token",
    ID_TOKEN: "auth.id_token",
    EMAIL: "auth.email",
    URL: "history.url",
};

interface FirebaseAuthProviderProps {
    children?: React.ReactNode;
}

type FirebaseAuthContextType = {
    isAuth: boolean;
    isUserInitialized: boolean;
    config: any;
    logout: any;
    signInPopup: any;
    url: any;
};

// Create React Context to keep track of current auth state
const FirebaseAuthContext = React.createContext<FirebaseAuthContextType>({
    isAuth: false,
    isUserInitialized: false,
    config: null,
    url: null,
    logout: () => { },
    signInPopup: () => { },
});

/**
 * AuthProvider has API check for authentication
 * @param props
 * @constructor
 */
const FirebaseAuthProvider: React.FC<FirebaseAuthProviderProps> = (props) => {
    const [AuthState, setAuthState] = React.useState({
        isAuth: false,
    });
    const [Config, setConfig] = React.useState({});
    const [URL, setURL] = React.useState("");
    const [isUserInitialized, setIsUserInitialized] = React.useState(false);

    /**
     * Successful logins will store credentials in local storage. If values
     * can be found, restore them into the config and set the user as
     * authenticated.
     */
    useEffect(() => signInPopup(), [setAuthState, setConfig]);

    /**
     * Helper function for resetting the session to log the user out
     */
    const logout = () => {
      // Clear all messages and notifications
      toaster.clear();

      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.NAME);
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.UID);
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN);

      setAuthState({ isAuth: false });

      signOut(auth)
        .then(() => {
          console.log('logged out');
          // navigate(SEARCH_ARB_HOME_URL);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Refresh token when the component is rendered
    React.useEffect(() => {
      if (auth.currentUser !== null) {
        setIsUserInitialized(true);
      } else {
        const AuthCheck = onAuthStateChanged(auth, (user) => {
          if (user) {
            user.getIdToken(true)
              .then((token) => {
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);

                // Wait for 1 second before using local storage value in request headers
                setTimeout(() => {
                  setIsUserInitialized(true);
                }, 1000);

                setTimeout(() => {
                  HttpClient.put<any>('refresh_token');
                }, 10000);
              })
              .catch((error) => console.log(error));
          } else {
            // console.log('unauthorized');
            // navigate('/signin');
          }
        });

        return () => AuthCheck();
      }
    }, []);

    const signInPopup = () => {
      let displayName = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.NAME);
      let email = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
      let uid = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.UID);
      let accessToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN);
      let idToken = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN);

      if (window.location.hash === "#redirecting") {
          pushInforming(<Message duration={2500} type="info">We're verifying your session.. sit tight.</Message>);
      }

      if (!displayName || !uid || !accessToken || !idToken) {
        signInWithPopup(auth, provider)
          .then(async (result: any) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const accessToken = credential !== null ? credential.accessToken : null;

            window.location.hash = "";
            /**
             * Confirm we have a redirect from Google Auth with proper credentials
             * and user object. In addition, it has to be confirmed that a user with
             * an @admediaryllc.com was authenticated. As a safety precaution, we'll
             * confirm that their email is flagged as verified.
             *
             * These checks are a precaution. The API will verify the tokens and
             * domain to ensure no sensitive information leaks.
             */
            if (!result.user || !accessToken) {
              pushErrorMessage("Please Sign In to view the dashboard", ALERT_DURATION);

              return false;
            }

            // Destructure result object for fields needed for verifying an employee
            const { email, emailVerified, displayName, isAnonymous, uid } = result.user;

            // Ensure we have necessary fields, user is not anonymous, and verified email
            if (!email || !emailVerified || isAnonymous || !uid || !displayName) {
              pushErrorMessage("Something went wrong. Try refreshing?", ALERT_DURATION);

              return false;
            }

            // Make sure an ADM employee is interacting with the dashboard
            /*const domain = email.includes("@") ? email.split("@")[1] : "";
            if (domain !== "admediaryllc.com") {
              pushErrorMessage("Dashboard is limited to Admediary, LLC employees", ALERT_DURATION);

              return false;
            }*/

            try {
              const token: any = await auth.currentUser?.getIdToken(true).then((token: any) => token);

              if (token.length) {
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.NAME, displayName);
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL, email);
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.UID, uid);
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ACCESS_TOKEN, accessToken);
                localStorage.setItem(LOCAL_STORAGE_AUTH_KEYS.ID_TOKEN, token);

                // Clear all messages and notifications
                toaster.clear();

                // Wait for 1 second before redirection to prevent issue
                setTimeout(() => {
                  setConfig({
                    displayName,
                    email,
                    uid,
                    accessToken,
                  });
                  setAuthState({ isAuth: true });
                  setURL(window.location.pathname);
                }, 1000);
              }
            } catch (error) {
              pushErrorMessage("Could not create an access token. Try again?", ALERT_DURATION);

              return false;
            }
          })
          .catch((error) => {
            const errorMessage = error.message;

            if (
              !["auth/cancelled-popup-request", "auth/popup-blocked", "auth/popup-closed-by-user"].some((v) =>
                errorMessage.includes(v)
              )
            ) {
              pushErrorMessage("Something went wrong. Try again? Error: " + errorMessage, ALERT_DURATION);
            }
          });
      } else {
        setConfig({
          displayName,
          email,
          uid,
          accessToken,
        });
        setAuthState({ isAuth: true });
      }
    };

    // Return Higher Order Component of FirebaseAuthContext.Provider
    return (
        <FirebaseAuthContext.Provider
            value={{
                isAuth: AuthState.isAuth,
                isUserInitialized,
                url: URL,
                logout: logout,
                signInPopup: signInPopup,
                config: Config,
            }}
        >
            {props.children}
        </FirebaseAuthContext.Provider>
    );
};

function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error('useFirebaseAuth must be used within a FirebaseAuthProvider');
  }
  return context;
}

const FirebaseAuthConsumer = FirebaseAuthContext.Consumer;

export {
    FirebaseAuthProvider,
    FirebaseAuthConsumer,
    FirebaseAuthContext,
    LOCAL_STORAGE_AUTH_KEYS,
    useFirebaseAuth,
};
