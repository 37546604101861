import React, {ReactNode} from "react";
import {
    Col, Dropdown,
    FlexboxGrid,
    IconButton,
    Input,
    InputGroup,
    Notification, SelectPicker,
    toaster,
} from "rsuite";
import Title from "../../@Components/Title";
import HttpClient from "../../@Utils/HttpClient";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import UrlManagementList from "./UrlManagementList";
import PlusRound from "@rsuite/icons/PlusRound";
import EditModal from "../OfferSources/EditModal";
import UrlFormsContainer from "./UrlFormsContainer";
import _ from "lodash";
import SpinnerIcon from "@rsuite/icons/Reload";
import {LOCAL_STORAGE_AUTH_KEYS} from "../../@Context/FirebaseAuthContext";
import MoreIcon from "@rsuite/icons/More";
import ManageAccessDomains from "./ManageAccessDomains";

const UrlManagementTree: React.FC = () => {
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState<any[]>([]);
    const intervalRef: any = React.useRef(null);
    const [searchQuery, setSearchQuery] = React.useState(null);
    const [isModalLoading, setIsModalLoading] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [status, setStatus] = React.useState<string>('');
    const [managers, setManagers] = React.useState([]);
    const [managerType, setManagerType] = React.useState<string>('');
    const [managerId, setManagerId] = React.useState<string>('');
    const [defaultManager, setDefaultManager] = React.useState();
    const [checkedKeys, setCheckedKeys] = React.useState<any[]>([]);

    const initFormValue = {
        forcekeyA: '',
        forcekeyB: '',
        forcekeyC: '',
        forcekeyD: '',
        forcekeyE: '',
        ttid: '',
        url: '',
        domain_id: 0,
        eu_domain: [],
        facebook_domain_verification_code: '',
        domain: '',
        created: '',
        id: '',
        last_updated: '',
        status: '',
        rskey: ''
    };
    const [rowData, setRowData] = React.useState(initFormValue);
    const [formValue, setFormValue] = React.useState(initFormValue);

    const statusFilters = [
        {label: 'All', value: ''},
        {label: 'Success', value: 'success'},
        {label: 'Pending', value: 'pending'},
        {label: 'Failed', value: 'failed'},
        {label: 'Disabled', value: 'disabled'}
    ];
    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    const refresh = () => fetchData();

    const fetchData = () => {
        if (defaultManager || defaultManager === null) {
            setData([]);
            setLoading(true);
            setCheckedKeys([]);

            HttpClient.get<any>('system1_urls', {
                status: status,
                managerType: managerType,
                managerId: managerId
            })
                .then(res => {
                    setLoading(false);
                    setData(res.data.data);
                }).catch(error => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setLoading(false);
            });
        }
    }

    React.useEffect(fetchData, [status, managerType, managerId, defaultManager]);

    React.useEffect(() => {
        HttpClient
            .get<any>('managed_user_n_group')
            .then(res => {
                setManagers(res.data.data);

                const loggedInuser = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
                const managersList = res.data.data;
                const userData = managersList.filter((item: any) => {
                    return item.label === loggedInuser
                })

                if (userData) {
                    handleManagerChange(userData[0].uid)
                }
            })
            .catch((_error) => {
                // TODO Add something
            });
    }, []);


    const handleManagerChange = (managerUid: any) => {
        const [type, id] = managerUid != null ? managerUid.split('::') : ['', ''];

        setManagerType(type);
        setManagerId(id);
        setDefaultManager(managerUid)
    };

    const renderManagerSelector = (menu: any) => {
        if (managers.length === 0) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <SpinnerIcon spin /> Loading...
                </p>
            );
        }

        return menu;
    };

    /**
     * Method is used to debounce server request while user types their search keyword
     * @param value
     * @param type
     */
    const debounceSearchTerm = (value: any, type: number = 0) => {
        if (intervalRef.current) {
            clearTimeout(intervalRef.current);
        }
        intervalRef.current = setTimeout(() => {
            setSearchQuery(value);
        }, 250);
    };

    /**
     * Filter data by search string as live search
     *
     * @param data
     * @param search
     */
    const filter = (data: any, search: string | null) => {
        if (!search) {
            return data;
        }

        search = search.toLowerCase();

        const include = (v: any) => v.toLowerCase().includes(search);
        const fields = [
            'domain',
            'status'
        ];

        return data.filter((item: any) =>
            fields.some((field: string) => {
                return include(item[field]);
            })
        );
    };

    const handleNewClick = () => {
        setFormValue(initFormValue);
        setRowData(initFormValue);
        setIsOpen(true)
    }

    const handleEditModalClose = () => {
        setIsOpen(false);
    };

    const handleFormValueCallback = (data: any) => {
        setFormValue(prepareFormData(data))
    }

    const handleSave = () => {
        let valid = false;

        valid = formValue.forcekeyA !== '' &&
            formValue.forcekeyB !== '' &&
            formValue.forcekeyC !== '' &&
            formValue.ttid !== '' &&
            formValue.url !== ''

        if (valid) {

            const data: any = _.cloneDeep(formValue);

            if (Array.isArray(data.eu_domain)) {
                data.eu_domain = data.eu_domain.length
                    ? data.eu_domain[0]
                    : 0;
            }

            HttpClient.post<object, any>('system1_urls', data)
                .then(res => {
                    refresh()
                    setIsOpen(false);
                    if (data.domain_id > 0) {
                        pushMessage(<Notification closable type="success" header="Success" duration={60000}>
                            Domain Updated
                        </Notification>);
                    } else {
                        pushMessage(<Notification closable type="success" header="Success" duration={60000}>
                            Domain sent for activation
                        </Notification>);
                    }

                }).catch(error => {
                pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                    {error.response.data?.error || error.toString()}
                </Notification>);
                setLoading(false);
            });
        } else {
            pushMessage(<Notification closable type="error" header="Error" duration={60000}>
                Please complete all steps before saving
            </Notification>);
        }


    }

    const prepareFormData = (record: any) => {
        if (record === null) {
            return;
        }

        if (!Array.isArray(record.eu_domain)) {
            record.eu_domain = record.eu_domain === 1 ? [1] : [];
        }

        return record;
    }

    const formatData = (data: any[]) => {
        return data.map((item: any) => {
            const keyword1 = item.forcekeyA !== null ? item.forcekeyA.replace(/ /g, '+') : '';
            const keyword2 = item.forcekeyB !== null ? item.forcekeyB.replace(/ /g, '+') : '';
            const keyword3 = item.forcekeyC !== null ? item.forcekeyC.replace(/ /g, '+') : '';
            const keyword4 = item.forcekeyD !== null ? item.forcekeyD.replace(/ /g, '+') : '';
            const keyword5 = item.forcekeyE !== null ? item.forcekeyE.replace(/ /g, '+') : '';
            const rskey = item.rskey !== null ? item.rskey.replace(/ /g, '+') : '';

            const managers = item?.managers && isJson(item.managers)
                ? JSON.parse(item.managers)
                : [];
            return {
                ...item,
                complete_url: item.status === 'success' ? 'https://' + item.domain
                + '/?'
                + 'forcekeyA=' + keyword1
                + '&forcekeyB=' + keyword2
                + '&forcekeyC=' + keyword3
                + '&forcekeyD=' + keyword4
                + '&forcekeyE=' + keyword5
                + '&ttid=' + item.ttid
                + '&rskey=' + rskey
                    + '&ref=tiktok-__placement__&ttland=PageView&ttserp=ClickButton&ttclick=CompletePayment&ttclid=_CLICKID_&subid=__CID__'
                    : 'Domain Pending',
                managers: managers[0]
            }
        })
    }

    const isJson = (str: string) => {
        try { JSON.parse(str);} catch (e) {return false;}
        return true;
    };

    const handleManageAccessItemClick = () => {
        // Show popup
        openManageAccessModal();
    };

    const [isManageAccessModalOpen, setIsManageAccessModalOpen] = React.useState(false);
    const openManageAccessModal = () => setIsManageAccessModalOpen(true);
    const closeManageAccessModal = () => setIsManageAccessModalOpen(false);

    return (
        <>
            <Title title="Landing Page Domains" />
            <FlexboxGrid justify="space-between" style={{ marginBottom: 25, marginLeft: 25 }}>
                <FlexboxGrid.Item colspan={24} style={{ marginBottom: 25, marginLeft: 25 }}>
                    <Col md={2}>
                        <ActionsMenu
                            isActive={checkedKeys.length > 0}
                            manageAccessHandler={handleManageAccessItemClick}
                        />
                    </Col>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={4}>
                    <IconButton
                        size="lg"
                        color="blue"
                        appearance="subtle"
                        icon={<PlusRound />}
                        onClick={() => {
                            handleNewClick();
                        }}
                    >
                        New
                    </IconButton>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                    <label>Status: </label>
                    <SelectPicker
                        size="lg"
                        searchable={false}
                        cleanable={true}
                        placeholder="Status"
                        value={status}
                        data={statusFilters}
                        valueKey="value"
                        labelKey="label"
                        onChange={setStatus}
                        style={{ marginRight: 15,  width: '25%'}}
                    />
                    <label>Manager: </label>
                    <SelectPicker
                        size="lg"
                        className="manager-filter"
                        name="manager"
                        placeholder="Select Manager"
                        placement="autoVerticalStart"
                        data={managers}
                        value={defaultManager}
                        valueKey="uid"
                        labelKey="label"
                        groupBy="type"
                        onChange={(v:any) =>handleManagerChange(v)}
                        renderMenu={renderManagerSelector}
                        style={{ marginRight: 15,  width: '25%'}}
                    />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={8}>

                    <InputGroup
                        style={{ width: 250, marginRight: 15, display: "inline-block" }}
                    >
                        <Input
                            placeholder="Search"
                            onChange={(v) => {
                                debounceSearchTerm(v);
                            }}
                            size="lg"
                        />
                    </InputGroup>
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <UrlManagementList
                data={filter(Array.isArray(data) ? formatData(data) : [], searchQuery)}
                loading={loading}
                setIsOpen={setIsOpen}
                setRowData={setRowData}
                checkedKeys={checkedKeys}
                setCheckedKeys={setCheckedKeys}
                />
            <EditModal
                size={'lg'}
                title={rowData.domain}
                loading={isModalLoading}
                open={isOpen}
                onClose={handleEditModalClose}
                onCancel={handleEditModalClose}
                onSubmit={handleSave}
            >
                <UrlFormsContainer
                    domain={formValue.domain}
                    postValue={rowData}
                    parentCallback={handleFormValueCallback}
                />
            </EditModal>

            <ManageAccessDomains
                open={isManageAccessModalOpen}
                handleOpen={openManageAccessModal}
                handleClose={closeManageAccessModal}
                trafficSourceKeys={checkedKeys}
                refreshCallback={refresh}
            />
        </>
    )
}

const ActionsMenu = ({
                         isActive,
                         manageAccessHandler,
                         ...props
                     }: any) => {
    const manageAccessKey = "manageAccess";

    const handleSelect = (eventKey: any) => {
        switch (eventKey) {
            case manageAccessKey:
                manageAccessHandler();
                break;
        }
    };

    return (
        <Dropdown
            title="Actions"
            trigger="hover"
            icon={<MoreIcon style={{fontSize: "1.8em"}}/>}
            onSelect={handleSelect}
            {...props}
        >
            <Dropdown.Item disabled={!isActive} eventKey={manageAccessKey}>Assign Manager</Dropdown.Item>
        </Dropdown>
    );
};

export default UrlManagementTree;