import React from "react";
import { IconButton, Table, Toggle, Tooltip, Whisper } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import ButtonToolbar from 'rsuite/ButtonToolbar';
import { Filter, filterData } from "../../@Utils/DataProcessing";
import { sortTree } from "../../@Utils/Sorting";
import PlusRound from "@rsuite/icons/PlusRound";
import TikTokAdDrawer from "../Drawers/TikTokAdDrawer";
import { AD_STATUS_ENABLE, initialData as initialAdData } from "./TikTokAdModel";
import { initialData as initialAdGroupData } from "./AdGroupModel";
import HttpClient from "../../@Utils/HttpClient";
import { pushErrorNotification } from "../../@Utils/Messager";

const { Column, HeaderCell, Cell } = Table;

type TikTokAdGroupListProps = {
  data?: any;
  loading?: any;
  sortType?: 'desc' | 'asc';
  sortColumn?: string;
  onSortColumn: (column: any, type: any) => void;
  setAdGroupFormData: any;
  setAdGroupId: any;
  setIsAdGroupDrawerOpen: any;
  changeAdStatus: (rowData: any, checked: boolean) => void;
  refreshCallback?: () => void;
  setIsAdGroupDataLoading?: (state: boolean) => void;
};

const rowKey: string = "node_id";
const rootKey: string = "adgroup_id";

const TikTokAdGroupList: React.FC<TikTokAdGroupListProps> = ({
  data = [],
  loading = false,
  sortType,
  sortColumn = "group_id",
  onSortColumn,
  setAdGroupFormData,
  setIsAdGroupDrawerOpen,
  changeAdStatus,
  refreshCallback,
  setIsAdGroupDataLoading,
}) => {
  const [campaigns, setCampaigns] = React.useState<any[]>([]);
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const [isAdDrawerOpen, setIsAdDrawerOpen] = React.useState(false);
  const [adFormData, setAdFormData] = React.useState(initialAdData);

  React.useEffect(() => {
    setCampaigns(data);
  }, [data]);

  const handleEditActionClick = (rowData: any) => {
    setAdGroupFormData({...initialAdGroupData});

    loadAdGroupData(rowData.adgroup_id);

    setIsAdGroupDrawerOpen(true);
  };

  // Load ad group
  const loadAdGroupData = (adGroupId: number) => {
    if (setIsAdGroupDataLoading) {
      setIsAdGroupDataLoading(true);
    }

    HttpClient
      .get<any>(`ad_group/${adGroupId}`)
      .then(res => {
        const data = res.data;

        setAdGroupFormData({
          ...data,
          adgroup_id: !isNaN(parseInt(data.adgroup_id)) ? parseInt(data.adgroup_id) : 0,
          bid_amount: data.optimization_goal === 'CONVERT'
            ? (!isNaN(parseFloat(data.conversion_bid_price)) ? parseFloat(data.conversion_bid_price) : 0)
            : (!isNaN(parseFloat(data.bid_price)) ? parseFloat(data.bid_price) : 0),
          search_result_enabled: !!data.search_result_enabled,
          auto_targeting_enabled: !!data.auto_targeting_enabled,
          budget: !isNaN(parseFloat(data.budget)) ? parseFloat(data.budget) : 0,
        });

        if (setIsAdGroupDataLoading) {
          setIsAdGroupDataLoading(false);
        }
      })
      .catch(error => {
        pushErrorNotification(error.response.data?.error || error.toString());
        if (setIsAdGroupDataLoading) {
          setIsAdGroupDataLoading(false);
        }
      });
  };

  const handleAddActionClick = (rowData: any) => {
    setAdFormData({
      ...initialAdData,
      advertiser_id: rowData.advertiser_id,
      adgroup_id: rowData.adgroup_id,
    });
    setIsAdDrawerOpen(true);
  };

  /**
   * Expand rows
   */
  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys: string[] = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleChangeAdStatus = (rowData: any, checked: boolean) => {
    changeAdStatus(rowData, checked);
  };

  const filteredRows = React.useMemo(() => filterData(campaigns, filters), [campaigns, filters]);

  const preparedData = React.useMemo(() => {
    return sortTree(filteredRows, sortColumn, sortType);
  }, [filteredRows, sortColumn, sortType]);

  return (
    <>
      <Table
        isTree
        defaultExpandAllRows={true}
        shouldUpdateScroll={false}
        height={600}
        data={preparedData}
        loading={loading}
        rowKey={rowKey}
        expandedRowKeys={expandedRowKeys}
        virtualized
        rowClassName="striped-rows"
        affixHeader
        affixHorizontalScrollbar
        sortType={sortType}
        sortColumn={sortColumn}
        onSortColumn={onSortColumn}
        onRowClick={handleExpanded}
        headerHeight={61}
      >
        <Column width={100} align="right" fixed sortable treeCol={true}>
          <HeaderCell>Group ID</HeaderCell>
          <Cell dataKey="adgroup_id">
            {(rowData) => rowData[rootKey] ? rowData.adgroup_id : null}
          </Cell>
        </Column>
        <Column width={150} align="right" fixed sortable>
          <HeaderCell>API Group ID</HeaderCell>
          <Cell dataKey="api_adgroup_id"/>
        </Column>
        <Column width={100} align="center" fixed>
          <HeaderCell>Actions</HeaderCell>
          <ActionCell
            editHandler={handleEditActionClick}
            addHandler={handleAddActionClick}
            changeAdStatusHandler={handleChangeAdStatus}
          />
        </Column>
        <Column width={90} align="left" sortable>
          <HeaderCell>Ad ID</HeaderCell>
          <Cell dataKey="ad_id">
            {(rowData) => rowData[rootKey] ? null : rowData.ad_id}
          </Cell>
        </Column>
        <Column width={150} align="left" sortable>
          <HeaderCell>API Ad ID</HeaderCell>
          <Cell dataKey="api_ad_id">
            {(rowData) => rowData[rootKey] ? null : rowData.api_ad_id}
          </Cell>
        </Column>
        <Column width={300} align="left" sortable resizable>
          <HeaderCell>Ad Name</HeaderCell>
          <Cell dataKey="ad_name">
            {(rowData) => rowData[rootKey] ? null : rowData.ad_name}
          </Cell>
        </Column>
      </Table>

      <TikTokAdDrawer
        formValue={adFormData}
        setFormValue={setAdFormData}
        isOpen={isAdDrawerOpen}
        setIsOpen={setIsAdDrawerOpen}
        refreshCallback={() => refreshCallback instanceof Function ? refreshCallback() : null}
      />
    </>
  );
};

export const ActionCell = ({
  rowData,
  editHandler,
  addHandler,
  changeAdStatusHandler,
  ...props
}: any) => {
  const status = rowData.operation_status === AD_STATUS_ENABLE;
  const isDynamic = rowData.creative_material_mode === 'DYNAMIC';

  return (
    <Cell {...props} className="link-group">
      <div>
        <ButtonToolbar>
          {
            rowData[rootKey]
              ?
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>Edit this ad group</Tooltip>}
              >
                <IconButton
                  icon={<EditIcon/>}
                  onClick={(event) => {
                    // Prevent event bubbling for filtering
                    event.stopPropagation();

                    editHandler(rowData);
                  }}
                />
              </Whisper>
              : null
          }
          {
            rowData[rootKey]
              ?
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>{isDynamic ? 'You can\'t create ads for a "Dynamic" ad group' : 'Create a new ad'}</Tooltip>}
              >
                <IconButton
                  icon={<PlusRound/>}
                  onClick={(event) => {
                    // Prevent event bubbling for filtering
                    event.stopPropagation();

                    addHandler(rowData);
                  }}
                  disabled={isDynamic}
                />
              </Whisper>
              : null
          }
          {
            !rowData[rootKey]
              ?
              <Whisper
                trigger="hover"
                placement="top"
                speaker={<Tooltip>{status ? 'Deactivate' : 'Activate'}</Tooltip>}
              >
                <span> {/*Wrap with span to proper work of tooltip*/}
                  <Toggle
                    loading={rowData.isUpdating}
                    checked={status}
                    onChange={() => changeAdStatusHandler(rowData, !status)}
                    size="md"
                    style={{marginBottom: 'auto', marginTop: 'auto'}}
                  />
                </span>
              </Whisper>
              : null
          }
        </ButtonToolbar>
      </div>
    </Cell>
  );
}

export default TikTokAdGroupList;