import React, { ReactNode } from "react";
import { Form, IconButton, Notification, Radio, RadioGroup, SelectPicker, toaster, Toggle, Uploader, } from "rsuite";
import Field from "../Field";
import HttpClient, { getAccessHeader, getBaseApiUrl } from "../../@Utils/HttpClient";
import TikTokAdModel, {
  AD_STATUS_ENABLE,
  AD_STATUS_DISABLE,
  AD_IDENTITY_TYPE_CUSTOM,
  AD_IDENTITY_TYPE_ACCOUNT
} from "./TikTokAdModel";
import { FileType } from "rsuite/esm/Uploader/Uploader";
import { PlacementType } from "rsuite/esm/toaster/ToastContainer";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "../OfferSources/EditModal";
import { FormInstance } from "rsuite/Form";
import TikTokIdentityForm from "./TikTokIdentityForm";
import { pushInforming } from "../../@Utils/Messager";

type TikTokAdFormProps = {
  formValue: any,
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
};

const TikTokAdForm: React.FC<TikTokAdFormProps> = ({
  formValue,
  formRef = null,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  formValue = prepareFormData(formValue);
  const [formError, setFormError] = React.useState<any>({});
  const [files, setFiles] = React.useState<any[]>([]);
  const [identityModalOpen, setIdentityModalOpen] = React.useState(false);
  const [identitySaveButtonLoading, setIdentitySaveButtonLoading] = React.useState(false);
  const identityFormRef = React.createRef<FormInstance>();
  const [identityFormValue, setIdentityFormValue] = React.useState({});

  const isNew = !(formValue.ad_id > 0);

  const setFormValue = (data: any) => {
    if (typeof parentCallback === "function") {
      parentCallback({
        ...data,
      });
    }
  };

  const handleFileListUpload = (value: any) => {
    setFormValue({
      ...formValue,
      video_id: null,
    });
  };

  const handleFileListChange = (value: any) => {
    setFiles(value.filter((file: any) => file.status === 'inited'));
    setFormError({
      ...formError,
      video_id: null,
    });
  };

  const handleFileUploadingSuccess = (response: any, file: FileType) => {
    const videoId = response?.data[0]?.video_id || formValue.video_id;

    setFormValue({
      ...formValue,
      video_id: videoId,
      videoInfo: response,
      uploader: [file],
    });
    setFormError({
      ...formError,
      video_id: null,
    });
  };

  const handleFileError = (reason: any, file: FileType) => {
    setFormValue({
      ...formValue,
      video_id: null,
      videoInfo: reason.response,
    });
    setFormError({
      ...formError,
      video_id: reason.response.message,
    })
  };

  const handleFileRemoving = (file: FileType) => {
    setFormValue({
      ...formValue,
      video_id: null,
      videoInfo: null,
    });
    setFormError({
      ...formError,
      video_id: undefined,
    })
  };

  const [ctaOptions , setCtaOptions] = React.useState([
    {"value" : "APPLY_NOW", "label": "Apply now"},
    {"value" : "BOOK_NOW", "label": "Book now"},
    {"value" : "CONTACT_US", "label": "Contact us"},
    {"value" : "DOWNLOAD_NOW", "label": "Download"},
    {"value" : "EXPERIENCE_NOW", "label": "Experience now"},
    {"value" : "GET_QUOTE", "label": "Get quote"},
    {"value" : "GET_SHOWTIMES", "label": "Get showtimes"},
    {"value" : "GET_TICKETS_NOW", "label": "Get tickets now"},
    {"value" : "INSTALL_NOW", "label": "Install now"},
    {"value" : "INTERESTED", "label": "Interested"},
    {"value" : "LEARN_MORE", "label": "Learn more"},
    {"value" : "LISTEN_NOW", "label": "Listen now"},
    {"value" : "ORDER_NOW", "label": "Order now"},
    {"value" : "PLAY_GAME", "label": "Play game"},
    {"value" : "PREORDER_NOW", "label": "Pre-order now"},
    {"value" : "READ_MORE", "label": "Read more"},
    {"value" : "SHOP_NOW", "label": "Shop now"},
    {"value" : "SIGN_UP", "label": "Sign up"},
    {"value" : "SUBSCRIBE", "label": "Subscribe"},
    {"value" : "VIEW_NOW", "label": "View now"},
    {"value" : "VISIT_STORE", "label": "Visit store"},
    {"value" : "WATCH_NOW", "label": "Watch now"},
  ]);

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

  React.useEffect(() => {
    if (formValue.advertiser_id) {
      HttpClient
        .get<any>('tiktok_cta_recommendation', {
          advertiser_id: formValue.advertiser_id,
        })
        .then(res => {
          setCtaOptions(
            res.data
              .map((item: any) => {
                if (item.asset_content === 'play_game_cta') {
                    return {
                      ...item,
                      asset_content: 'play_game',
                    };
                }

                return item;
              })
              .map((item: any) => ({
                value: item.asset_content,
                label: item.asset_content
                  .split('_')
                  .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
                  .join(' ')
              }))
          );
        })
        .catch(error => {
          pushMessage(<Notification closable type="error" header="Error" duration={60000}>
            {error.response.data?.error || error.toString()}
          </Notification>);
        });
    }
  }, [formValue.advertiser_id]);

  const [identityList , setIdentityList] = React.useState<any[]>([]);

  const fetchIdentityList = (advertiserId: string) => {
    HttpClient
      .get<any>('tiktok_identity', {
        advertiser_id: advertiserId,
      })
      .then(res => {
        setIdentityList(res.data);
      })
      .catch(error => {
        pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      });
  };

  React.useEffect(() => {
    if (formValue.advertiser_id) {
      fetchIdentityList(formValue.advertiser_id);
    }
  }, [formValue.advertiser_id]);

  const addNewItemInIdentityList = (identityId: string, name: string, type: string) => {
    setIdentityList([
      ...identityList,
      {
        identity_type: type,
        identity_id: identityId,
        display_name: name,
      }
    ]);
  };

  const selectJustCreatedIdentityList = (identityId: string) => {
    setFormValue({
      ...formValue,
      identity_id: identityId,
    });
  };

  const saveIdentity = (data: any) => {
    setIdentitySaveButtonLoading(true);

    HttpClient.post<object, any>('tiktok_identity', {
        ...data,
        advertiser_id: formValue.advertiser_id,
      })
      .then((res) => {
        const isStatusSuccess = res.status === 200 || res.status === 201;

        if (isStatusSuccess) {
          const identityId = res.data.identity_id;

          addNewItemInIdentityList(identityId, data.display_name, formValue.identity_type);
          selectJustCreatedIdentityList(identityId);

          setIdentityModalOpen(false);
        } else {
          const text = res.data.message ? res.data.message : 'Something was wrong during the identity saving';

          pushInforming(<Notification closable type="error" header="Error">{text}</Notification>);
        }
      })
      .catch(error => {
        pushMessage(<Notification closable type="error" header="Error" duration={60000}>
          {error.response.data?.error || error.toString()}
        </Notification>);
      })
      .finally(() => {
        setIdentitySaveButtonLoading(false);
      });
  };

  const handleIdentityModalSave = () => {
    const node = identityFormRef && identityFormRef.current;

    if (node?.check && node.check()) {
      saveIdentity(identityFormValue);
    }
  };

  const handleIdentityFormCallback = (formValue: any) => {
    setIdentityFormValue(formValue);
  };

  const handleIdentityModalClose = () => {
    setIdentityModalOpen(false);
  };

  return (
    <Form
      ref={formRef}
      model={TikTokAdModel}
      formValue={formValue}
      formError={formError}
      onChange={setFormValue}
      onCheck={setFormError}
      layout="horizontal"
    >
      <Form.Group>
        <Form.ControlLabel style={{ display: "block" }}>Operation Status</Form.ControlLabel>
        <Toggle
          size="lg"
          style={{ display: "inline-block", paddingTop: 5 }}
          checked={formValue.operation_status === AD_STATUS_ENABLE}
          checkedChildren="Enable"
          unCheckedChildren="Disable"
          onChange={(checked) =>
            setFormValue({
              ...formValue,
              operation_status: checked ? AD_STATUS_ENABLE : AD_STATUS_DISABLE,
            })
          }
        />
      </Form.Group>
      <Field
        label="Ad Name"
        name="ad_name"
        placeholder="Enter Ad Name"
      />
      <Form.Group style={{marginBottom: 10}}>
        <Form.ControlLabel style={{display: "block"}}>Identity</Form.ControlLabel>
        <Toggle
          checkedChildren={<CheckIcon/>}
          unCheckedChildren={<CloseIcon/>}
          readOnly={!isNew && formValue.budget_amount > 0}
          style={{display: "inline-block", paddingTop: 5}}
          checked={formValue.use_tiktok_account}
          onChange={(checked) =>
            setFormValue({
              ...formValue,
              use_tiktok_account: checked,
              identity_type: checked ? AD_IDENTITY_TYPE_ACCOUNT : AD_IDENTITY_TYPE_CUSTOM,
              identity_id: "",
            })
          }
        /> Use TikTok account to deliver Spark Ads
      </Form.Group>
      {formValue.use_tiktok_account ?
        <Field
          label=""
          name="identity_id"
          placeholder="Select Identity"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={identityList.filter((identity: any) => identity.identity_type === AD_IDENTITY_TYPE_ACCOUNT)}
          valueKey="identity_id"
          labelKey="display_name"
          cleanable={false}
        />
        : null
      }
      {!formValue.use_tiktok_account ?
        <>
          <Field
            label=""
            name="identity_id"
            placeholder="Select Identity"
            placement="autoVerticalStart"
            accepter={SelectPicker}
            data={identityList.filter((identity: any) => identity.identity_type === AD_IDENTITY_TYPE_CUSTOM)}
            valueKey="identity_id"
            labelKey="display_name"
            cleanable={false}
            formGroupStyle={{marginBottom: 5}}
          />
          <Form.Group>
            <Form.ControlLabel style={{display: "block"}}></Form.ControlLabel>
            <IconButton
              size="md"
              icon={<PlusIcon/>}
              style={{marginBottom: 5}}
              onClick={() => {
                setIdentityModalOpen(true);
              }}
            >
              create new custom identity
            </IconButton>
          </Form.Group>
        </>
        : null
      }
      <Form.Group controlId="uploader" className={formError.video_id ? "has-error" : ""}>
        <Form.ControlLabel>Upload new Creation Video ONLY</Form.ControlLabel>
        <div className="rs-form-control rs-form-control-wrapper">
          <Uploader
            name="uploader"
            fileList={files}
            action={`${getBaseApiUrl()}/tiktok_video`}
            headers={getAccessHeader()}
            data={{adgroup_id: formValue.adgroup_id}}
            listType="picture-text"
            multiple={false}
            draggable
            onUpload={handleFileListUpload}
            onChange={handleFileListChange}
            onSuccess={handleFileUploadingSuccess}
            onError={handleFileError}
            onPreview={(file: any, event: any) => console.log('onPreview: ', file, event)}
            onRemove={handleFileRemoving}
          />
          {formError.video_id ?
            <div id="ad_name-error-message" role="alert" aria-relevant="all" className="rs-form-control-message-wrapper rs-form-error-message-wrapper rs-form-error-message-placement-bottom-start"><span className="rs-form-error-message rs-form-error-message-show"><span className="rs-form-error-message-arrow"></span><span className="rs-form-error-message-inner">
              {formError.video_id}
            </span></span></div> : null
          }
        </div>
      </Form.Group>
      <Field
        label="Ad Text"
        name="ad_text"
        placeholder="Enter Ad Text"
      />
      <Field
        label="Call to Action"
        name="cta_type"
        accepter={RadioGroup}
        formGroupStyle={{marginBottom: 0}}
      >
        <Radio value="DYNAMIC">Dynamic</Radio>
        <Radio value="STANDARD">Standard</Radio>
      </Field>
      {formValue.cta_type === 'STANDARD' ?
        <Field
          label=""
          name="call_to_action"
          placeholder="Select CTA"
          placement="autoVerticalStart"
          accepter={SelectPicker}
          data={ctaOptions}
          cleanable={false}
        />
        : null
      }
      <Field
        label="Destination URL"
        name="landing_page_url"
        placeholder="Enter Destination URL"
      />

      <EditModal
        title="Create new custom identity"
        loading={identitySaveButtonLoading}
        open={identityModalOpen}
        onClose={handleIdentityModalClose}
        onCancel={handleIdentityModalClose}
        onSubmit={handleIdentityModalSave}
        size="xs"
      >
        <TikTokIdentityForm
          formRef={identityFormRef}
          parentCallback={handleIdentityFormCallback}
        />
      </EditModal>
    </Form>
  )
};

const prepareFormData = (record: any) => {
  if (record === null) {
    return;
  }

  record.ad_id = !isNaN(parseInt(record.ad_id)) ? parseInt(record.ad_id) : 0;
  record.adgroup_id = !isNaN(parseInt(record.adgroup_id)) ? parseInt(record.adgroup_id) : 0;

  return record;
};

export default TikTokAdForm;