import React, { ReactNode } from "react";
import {Table, Pagination, Whisper, Tooltip, IconButton, Loader, Notification, toaster, Checkbox} from "rsuite";
import { sort } from "../../@Utils/Sorting";
import ButtonToolbar from "rsuite/ButtonToolbar";
import EditIcon from "@rsuite/icons/Edit";
import CopyIcon from '@rsuite/icons/Copy';
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";

const {Column, HeaderCell, Cell} = Table;

type UrlManagementListProps = {
    data?: any;
    loading?: any;
    setIsOpen: any;
    setRowData: any;
    checkedKeys: any[];
    setCheckedKeys: any;
}

const UrlManagementList: React.FC<UrlManagementListProps> = ({
    data = [],
    loading = false,
    setIsOpen,
    setRowData,
                                                                 checkedKeys,
                                                                 setCheckedKeys,
}) => {
    const [sortType, setSortType] = React.useState<"desc" | "asc">("desc");
    const [sortColumn, setSortColumn] = React.useState("");
    const [limit, setLimit] = React.useState(25);
    const [page, setPage] = React.useState(1);


    const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});

    /**
     * Sort handler for Rsuite tables
     * @param column
     * @param type
     */
    const handleSortColumn = (column: any, type: any) => {
        setSortColumn(column);
        setSortType(type);
    };

    const handleChangeLimit = (dataKey: any) => {
        setPage(1);
        setLimit(dataKey);
    };

    const paginatedData = data.filter((v: any, i: any) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    })

    const handleEditActionClick = (rowData: any) => {
        setIsOpen(true)
        setRowData(rowData)
    }

    const handleCopyButtonClick = (rowData: any) => {
        navigator.clipboard.writeText(rowData.complete_url)

        pushMessage(<Notification closable type="success" header="URL Copied" duration={60000}>
            Copied Url
        </Notification>);
    }

    const handleCheckAll = (value: any, checked: boolean) => {
        const keys = checked ? paginatedData.map((item: any) => item.domain) : [];
        setCheckedKeys(keys);
    };

    const handleCheck = (value: any, checked: boolean) => {
        const keys: any = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);

        setCheckedKeys(keys);
    };

    const checked = checkedKeys.length > 0 && checkedKeys.length === paginatedData.length;
    const indeterminate = checkedKeys.length > 0 && checkedKeys.length < paginatedData.length;

    return (
        <>
            <Table
                height={600}
                data={Array.isArray(data) ? sort(paginatedData, sortColumn, sortType) : []}
                loading={loading}
                virtualized
                rowClassName="striped-rows"
                affixHeader
                affixHorizontalScrollbar
                sortType={sortType}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
            >
                <Column width={70} align="center" fixed>
                    <HeaderCell style={{padding: 0}}>
                        <div style={{lineHeight: "38px"}}>
                            <Checkbox
                                inline
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={handleCheckAll}
                            />
                        </div>
                    </HeaderCell>
                    <CheckCell dataKey="domain" checkedKeys={checkedKeys} onChange={handleCheck}/>
                </Column>

                <Column width={100} align="center" fixed>
                    <HeaderCell>Edit/Copy URL</HeaderCell>
                    <ActionCell
                        editHandler={handleEditActionClick}
                        copyHandler={handleCopyButtonClick}
                    />
                </Column>

                <Column width={250} align="left" sortable resizable>
                    <HeaderCell>Domain</HeaderCell>
                    <Cell dataKey="domain" />
                </Column>

                <Column width={100} align="left" sortable resizable>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="status" />
                </Column>

                <Column width={75} align="left" sortable resizable>
                    <HeaderCell>EU Domain</HeaderCell>
                    <Cell dataKey="eu_domain" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Created Date</HeaderCell>
                    <Cell dataKey="created" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Last Updated</HeaderCell>
                    <Cell dataKey="last_updated" />
                </Column>

                <Column width={125} align="left" sortable resizable>
                    <HeaderCell>FB Verification Code</HeaderCell>
                    <Cell dataKey="facebook_domain_verification_code" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Keyword 1</HeaderCell>
                    <Cell dataKey="forcekeyA" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Keyword 2</HeaderCell>
                    <Cell dataKey="forcekeyB" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Keyword 3</HeaderCell>
                    <Cell dataKey="forcekeyC" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Keyword 4</HeaderCell>
                    <Cell dataKey="forcekeyD" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Keyword 5</HeaderCell>
                    <Cell dataKey="forcekeyE" />
                </Column>

                <Column width={175} align="left" sortable resizable>
                    <HeaderCell>Pixel ID</HeaderCell>
                    <Cell dataKey="ttid" />
                </Column>

                <Column width={100} align="left" sortable resizable>
                    <HeaderCell>RSKEY</HeaderCell>
                    <Cell dataKey="rskey" />
                </Column>

                <Column width={350} align="left" sortable resizable>
                    <HeaderCell>Complete URL</HeaderCell>
                    <Cell dataKey="complete_url" />
                </Column>
                <Column width={350} align="left" sortable resizable>
                    <HeaderCell>Manager</HeaderCell>
                    <Cell dataKey="managers" />
                </Column>
            </Table>
            <div style={{ padding: 20 }}>
                <Pagination
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="xs"
                    layout={['total', '-', 'limit', '|', 'pager']}
                    total={data.length}
                    limitOptions={[10, 30, 50]}
                    limit={limit}
                    activePage={page}
                    onChangePage={setPage}
                    onChangeLimit={handleChangeLimit}
                />
            </div>
        </>
    )
}

const CheckCell = ({rowData, onChange, checkedKeys, dataKey, ...props}: any) =>
    <Cell {...props} style={{padding: 0}}>
        <div style={{lineHeight: "35px"}}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some((item: any) => item === rowData[dataKey])}
            />
        </div>
    </Cell>;

export const ActionCell = ({
rowData,
editHandler,
removeHandler,
copyHandler,
...props
}: any) => {
    const buttonStyle = {marginLeft: '6px'};
    return (
        <Cell {...props} className="link-group" style={{lineHeight: '10px !important'}}>
            <div>
                <ButtonToolbar>
                    <Whisper
                        trigger="hover"
                        placement="top"
                        speaker={<Tooltip>Edit</Tooltip>}
                    >
                        <IconButton
                            icon={<EditIcon/>}
                            onClick={(event) => {
                                // Prevent event bubbling for filtering
                                event.stopPropagation();

                                editHandler(rowData);
                            }}
                        />
                    </Whisper>

                    {
                        rowData.status === 'success' ? (<Whisper
                            trigger="hover"
                            placement="top"
                            speaker={<Tooltip>Copy Full URL</Tooltip>}
                        >
                            <IconButton
                                icon={<CopyIcon/>}
                                onClick={(event) => {
                                    // Prevent event bubbling for filtering
                                    event.stopPropagation();

                                    copyHandler(rowData);
                                }}
                            />
                        </Whisper>) : null
                    }

                </ButtonToolbar>
            </div>
        </Cell>
    );
};
export default UrlManagementList;