import React from "react";

import {
    Form
} from "rsuite";


type ConfigureDataProps = {
    domains: any;
    fieldError?: any;
    data: any;
    action: any;
};

const TikTokDomainTable: React.FC<ConfigureDataProps> = ({
    domains,
    fieldError,
    data,
    action


}) => {
    // @ts-ignore
  const value = addIds(data ? data : []);

    return (
        <>
        <Form.Group>
            <Form.Control
                name="data"
                accepter={domains}
                fieldError={fieldError}
                value = {value}
                action = {action}
            />
        </Form.Group>
    </>)

}
const defaultIdKey = 'id';
const addIds = (data: any, idKey: string = defaultIdKey) =>
    data.map((item: any, index: number) => ({
        ...item,
        [idKey]: index + 1
    }));
export default TikTokDomainTable;