import React from "react";
import {Col, Form, Grid, IconButton, Row, SelectPicker, Tooltip, Whisper} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditModal from "./EditModal";
import OfferSourceCategory from "./OfferSourceCategory";
import {FormInstance} from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";

type GeneralSettingsProps = {
  onChangeCategory?: any;
};

const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  onChangeCategory
}) => {
  const [categories, setCategories]: any = React.useState([]);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [categoryFormValue, setCategoryFormValue] = React.useState({});
  const mainFormRef = React.createRef<FormInstance>();
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  const handleCallback = (formValue: any) => {
    setCategoryFormValue(formValue);
  };

  // Load category list
  React.useEffect(() => {
    HttpClient
      .get<any>('offer_source_category')
      .then(res => {
        setCategories(res.data.map((item: any) => ({
          ...item,
          offer_source_category_id: isNaN(parseInt(item.offer_source_category_id))
            ? 0 : parseInt(item.offer_source_category_id),
        })));
      });
  }, []);

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const changeCategory = (categoryId: number, categoryName: string = '') => {
    if (typeof onChangeCategory === "function") {
      onChangeCategory(categoryId, categoryName);
    }
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && node.check()) {
      saveCategory(categoryFormValue);
    }
  };

  const saveCategory = (data: any) => {
    setSaveButtonLoading(true);

    HttpClient.post<object, any>('offer_source_category', data)
      .then((res) => {
        const categoryId = parseInt(res.data.offer_source_category_id);
        // Add created category in the selector list
        setCategories([...categories, {
          ...data,
          offer_source_category_id: categoryId
        }]);
        changeCategory(categoryId, data.offer_source_category_name);
        handleEditModalClose();
      })
      .finally(() => {
        setSaveButtonLoading(false);
      });
  };

  const setOfferSourceCategory = (categoryId: number) => {
    const category = categories.find((item: any) => item.offer_source_category_id === categoryId);

    changeCategory(categoryId, category.offer_source_category_name);
  };

  return (
    <>
      <Grid fluid>
        <Row>
          <Col md={16}>
            <Form.Group controlId="offer_source_name">
              <Form.ControlLabel style={{textAlign: "left"}}>Offer Source Name</Form.ControlLabel>
              <Form.Control name="offer_source_name" style={{width: 500}}/>
            </Form.Group>
          </Col>
          <Col xs={6} sm={12} md={6}>
            <Form.Group controlId="offer_source_category_id">
              <Form.ControlLabel style={{textAlign: "left"}}>Category</Form.ControlLabel>
              <Form.Control
                name="offer_source_category_id"
                accepter={SelectPicker}
                data={categories}
                valueKey="offer_source_category_id"
                labelKey="offer_source_category_name"
                size="md"
                style={{width: 224}}
                onChange={(categoryId: any) => setOfferSourceCategory(categoryId)}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={12} md={2}>
          <Whisper
              trigger="hover"
              placement="top"
              speaker={<Tooltip>Add New Category</Tooltip>}
            >
            <IconButton
              circle
              size="md"
              style={{marginTop: 40, marginLeft: 30}}
              icon={<PlusIcon/>}
              onClick={() => {
                setEditModalOpen(true);
              }}
            />
            </Whisper>
          </Col>
        </Row>
      </Grid>
      <EditModal
        title={"Add Category"}
        loading={saveButtonLoading}
        open={editModalOpen}
        onClose={handleEditModalClose}
        onCancel={handleEditModalClose}
        onSubmit={handleSave}
      >
        <OfferSourceCategory
          formRef={mainFormRef}
          parentCallback={handleCallback}
        />
      </EditModal>
    </>
  );
};

export default GeneralSettings;