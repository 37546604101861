import React from "react";
import Dashboard from "../@Components/Dashboard";
import CampaignGrid from "../@Components/Campaigns/CampaignGrid";

// const {allowedMaxDays} = DateRangePicker;

const CampaignsReport: React.FC = () => {
  return (
    <Dashboard>
      {/* <SummaryCards summary={summary} filters={filters}/> */}
      <CampaignGrid />
    </Dashboard>
  );
};

export default CampaignsReport;
