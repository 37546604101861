import React from 'react';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import { FirebaseAuthProvider } from "./@Context/FirebaseAuthContext";

import Signin from "./@Pages/Signin";
import OfferSourcesTree from "./@Pages/OfferSourcesTree";
import CampaignsReport from "./@Pages/CampaignsReport";
import TrafficSourcesTree from "./@Pages/TrafficSourcesTree";
import OffersTree from "./@Pages/OffersTree";
import FunnelsReport from "./@Pages/FunnelsReport";
import TonicKeywordReport from "./@Pages/TonicKeywordReport";
import RulesReport from "./@Pages/RulesReport";
import TiktokCampaignReport from "./@Pages/TikTokCampaignReport";
import DomainMappingTool from "./@Pages/DomainMappingTool";
import TikTokDomainDirectSummaryReport from './@Pages/TikTokDomainDirectSummaryReport';
import System1DomainPage from "./@Pages/System1Domain";
import GoogleKeywordsPage from "./@Pages/GoogleKeywords";
import System1UrlManagement from "./@Pages/System1UrlManagement";
import MediaBuyingPerformanceReport from "./@Pages/MediaBuyerPerformance";
import System1KeywordStatsPage from "./@Pages/System1KeywordStats";


import ProtectedRoute from "./@Components/ProtectedRoute";
import ProtectedRouteWithPermissions from "./@Components/ProtectedRouteByPermissions";
import { UserPermissionsProvider } from "./@Context/UserPermissionsContext";

export const SEARCH_ARB_HOME_URL = "/tiktok-domain-direct-summary";

function App() {
  return (
    <Router>
      <FirebaseAuthProvider>
        <UserPermissionsProvider>
          <Switch>
            <Route path="/signin" component={Signin} />
            <Route>
              <>
                <Switch>
                  <ProtectedRouteWithPermissions exact path="/offer-sources" component={OfferSourcesTree} />
                  <ProtectedRouteWithPermissions exact path="/funnels/campaign/:campaignId" component={FunnelsReport} />
                  <ProtectedRouteWithPermissions exact path="/funnels" component={FunnelsReport} />
                  <ProtectedRouteWithPermissions exact path="/offers" component={OffersTree} />
                  <ProtectedRouteWithPermissions exact path="/traffic-sources" component={TrafficSourcesTree} />
                  <ProtectedRouteWithPermissions exact path="/campaigns" component={CampaignsReport} />
                  <ProtectedRouteWithPermissions exact path="/tonic-keyword-report" component={TonicKeywordReport} />
                  <ProtectedRouteWithPermissions exact path="/rules" component={RulesReport} />
                  <ProtectedRouteWithPermissions exact path="/tiktok-campaign-report" component={TiktokCampaignReport} />
                  <ProtectedRouteWithPermissions exact path="/tiktok-subid-mapping"
                                  component={DomainMappingTool}
                                  render = {(routeProps )=> (<DomainMappingTool />)} />
                  <ProtectedRouteWithPermissions exact path="/domains" component={System1DomainPage} />
                  <ProtectedRouteWithPermissions exact path="/url-management" component={System1UrlManagement} />
                  <ProtectedRoute exact path="/tiktok-domain-direct-summary" component={TikTokDomainDirectSummaryReport} />
                  <ProtectedRoute exact path="/keywords" component={GoogleKeywordsPage} />
                  <ProtectedRoute exact path="/keyword-stats" component={System1KeywordStatsPage} />
                  <ProtectedRoute exact path="/media-buyer-report" component={MediaBuyingPerformanceReport} />
                  <Redirect exact from="/" to={SEARCH_ARB_HOME_URL} />
                </Switch>
              </>
            </Route>
          </Switch>
        </UserPermissionsProvider>
      </FirebaseAuthProvider>
    </Router>
  );
}

export default App;
