import React, {ReactNode} from "react";
import {
  Col,
  Dropdown,
  FlexboxGrid,
  IconButton,
  Input,
  InputGroup,
  Notification,
  Pagination,
  SelectPicker,
  toaster,
} from "rsuite";
import SearchIcon from '@rsuite/icons/Search';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import TrafficSourcesList from "./TrafficSourcesList";
import Title from "../../@Components/Title";
import TrafficSource from "../Drawers/TrafficSource";
import {exportCsvRemotely} from "../../@Utils/Export";
import {PlacementType} from "rsuite/esm/toaster/ToastContainer";
import HttpClient from "../../@Utils/HttpClient";
import { LOCAL_STORAGE_KEYS, useStoredValue } from "../../@Utils/useStoredValue";
import SpinnerIcon from "@rsuite/icons/Reload";
import MoreIcon from "@rsuite/icons/More";
import ManageAccess from "./ManageAccess";

const TrafficSourcesTree: React.FC = () => {
  const [trafficSourceId, setTrafficSourceId] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any[]>([]);
  const [formData, setFormData] = React.useState({});
  const [openWithHeader, setOpenWithHeader] = React.useState(false);
  const [sortType, setSortType] =  useStoredValue(LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_SORT_TYPE,"asc");
  const [sortColumn, setSortColumn] = useStoredValue(LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_SORT_COLUMN,'traffic_source_id');
  const minLimit = 15;
  const limitOptions = [minLimit, 30, 50, 100];
  const [activePage, setActivePage] = React.useState(1);
  const [limit, setLimit] = React.useState(50);
  const [total, setTotal] = React.useState(0);
  const [searchQuery, setSearchQuery] = useStoredValue<string>(LOCAL_STORAGE_KEYS.TRAFFIC_SOURCES_PAGE_SEARCH_QUERY, '');
  const [search, setSearch] = React.useState<string>(searchQuery);
  const [managers, setManagers] = React.useState([]);
  const [checkedKeys, setCheckedKeys] = React.useState<any[]>([]);
  const [managerType, setManagerType] = React.useState<string>('');
  const [managerId, setManagerId] = React.useState<string>('');

  const pushMessage = (message: ReactNode, placement: PlacementType = "topEnd") => toaster.push(message, {placement});
  /**
   * Sort handler for Rsuite tables
   * @param column
   * @param type
   */
  const handleSortColumn = (column: any, type: any) => {
    setSortColumn(column);
    setSortType(type);
  };

  const handleSetLimit = (limit: any) => {
    setActivePage(1);
    setLimit(limit);
  };

  const refresh = () => fetchData();

  const fetchData = () => {
    // Clear and mask a table
    setData([]);
    setLoading(true);
    setTotal(0);
    setCheckedKeys([]);

    // Get data
    HttpClient
      .get<any>('traffic_source', {
        page: activePage,
        limit,
        sortBy: sortColumn,
        sortDir: sortType,
        query: search,
        managerType,
        managerId,
      })
      .then(res => {
        setLoading(false);
        setData(res.data.data);
        setTotal(parseInt(res.data.total) || 0);
      }).catch(error => {
      pushMessage(<Notification closable type="error" header="Error" duration={60000}>
        {error.response.data?.error || error.toString()}
      </Notification>);
      setLoading(false);
    });
  };

  React.useEffect(fetchData, [activePage, limit, sortColumn, sortType, search, managerType, managerId]);
  const searchByQuery = () => {
    setSearch(searchQuery);
  };

  const onKeyUpEnter = (event: any) => {
    if (event.key === "Enter") {
      setSearch(searchQuery);
    }
  };

  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const handleRowCreateOrUpdateAfterGrid = (id: number, item: object) => {
    const updatedItemIndex = data.findIndex((item: any) => parseInt(item.traffic_source_id) === id);

    if (updatedItemIndex > -1) {
      // Update data
      data[updatedItemIndex] = item;
      setData([...data]);
    } else {
      // Push a new row at the top
      setTotal(total + 1)
      setData([item, ...data]);
    }
  };

  const handleExportCsvButtonClick = () => {
    exportCsvRemotely('traffic_sources', {
      entity: 'traffic_source',
      sortBy: sortColumn,
      sortDir: sortType,
      query: search,
    });
  };

  //Filter highlight code
  const initialSearchFilter = React.useRef(searchQuery !== '' ? searchQuery: {});
  React.useEffect(() => {
    const bgColor = (searchQuery === initialSearchFilter.current) ? '#f6ffff' : 'inherit';

    const innerDiv = document.querySelector('.search-filter') as HTMLElement;
    if (innerDiv) {
      innerDiv.style.setProperty('background-color', bgColor, 'important');
    }
  }, [searchQuery]);

  const renderManagerSelector = (menu: any) => {
    if (managers.length === 0) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <SpinnerIcon spin /> Loading...
        </p>
      );
    }

    return menu;
  };

  React.useEffect(() => {
    HttpClient
      .get<any>('managed_user_n_group')
      .then(res => {
        setManagers(res.data.data);
      })
      .catch((_error) => {
        // TODO Add something
        // pushMessage(<Notification closable type="error" header="Error" duration={60000}>
        //   {error.response.data?.error || error.toString()}
        // </Notification>);
      });
  }, []);

  const handleManageAccessItemClick = () => {
    // Show popup
    openManageAccessModal();
  };

  const [isManageAccessModalOpen, setIsManageAccessModalOpen] = React.useState(false);
  const openManageAccessModal = () => setIsManageAccessModalOpen(true);
  const closeManageAccessModal = () => setIsManageAccessModalOpen(false);

  const handleManagerChange = (managerUid: any) => {
    const [type, id] = managerUid != null ? managerUid.split('::') : ['', ''];

    setManagerType(type);
    setManagerId(id);
  };

  return (
    <>
      <Title title="Traffic Sources"/>
      <FlexboxGrid justify="space-between" style={{marginBottom: 25}}>
        <FlexboxGrid.Item colspan={2}>
          <Col md={2}>
            <ActionsMenu
              isActive={checkedKeys.length > 0}
              manageAccessHandler={handleManageAccessItemClick}
            />
          </Col>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={10}>
          <IconButton
            size="lg"
            appearance="subtle"
            style={{marginLeft: 15}}
            icon={<FileDownloadIcon/>}
            placement="right"
            onClick={handleExportCsvButtonClick}
          >
            Download CSV
          </IconButton>
          {/*
          <IconButton
            size="lg"
            appearance="subtle"
            style={{marginLeft: 15}}
            icon={<FileDownloadIcon/>}
            placement="right"
            onClick={handleExportXlsxButtonClick}
          >
            Download Excel
          </IconButton> */}
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={4} style={{paddingRight: 10}}>
          <SelectPicker
            className="manager-filter"
            block
            name="manager"
            placeholder="Select Manager"
            placement="autoVerticalStart"
            data={managers}
            valueKey="uid"
            labelKey="label"
            groupBy="type"
            onChange={(v:any) =>handleManagerChange(v)}
            renderMenu={renderManagerSelector}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={6} style={{textAlign: "right", paddingRight: 10}}>
          <InputGroup style={{width: "100%"}}>
            <InputGroup.Button onClick={searchByQuery}>
              <SearchIcon/>
            </InputGroup.Button>
            <Input
              className="search-filter"
              placeholder="Search"
              value={searchQuery}
              onChange={(v) => handleSearchChange(v)}
              onKeyUp={onKeyUpEnter}
            />
          </InputGroup>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <TrafficSource
        formData={formData}
        openWithHeader={openWithHeader}
        setOpenWithHeader={setOpenWithHeader}
        trafficSourceId={trafficSourceId}
        setTrafficSourceId={setTrafficSourceId}
        onAfterCreateOrUpdateGridRow={handleRowCreateOrUpdateAfterGrid}
      />
      <TrafficSourcesList
        openWithHeader={openWithHeader}
        setModalOpen={setOpenWithHeader}
        setFormatData={setFormData}
        setTrafficSourceId={setTrafficSourceId}
        data={data}
        loading={loading}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        refreshCallback={refresh}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
      />
      <Pagination
        style={{margin: "10px 20px"}}
        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
        size="lg"
        prev={true}
        next={true}
        first={true}
        last={true}
        ellipsis={true}
        boundaryLinks={true}
        disabled={total < minLimit}
        total={total}
        limit={limit}
        limitOptions={limitOptions}
        maxButtons={7}
        activePage={activePage}
        onChangePage={setActivePage}
        onChangeLimit={handleSetLimit}
      />

      <ManageAccess
        open={isManageAccessModalOpen}
        handleOpen={openManageAccessModal}
        handleClose={closeManageAccessModal}
        trafficSourceKeys={checkedKeys}
        refreshCallback={refresh}
      />
    </>
  );
};

const ActionsMenu = ({
  isActive,
  manageAccessHandler,
  ...props
}: any) => {
  const manageAccessKey = "manageAccess";

  const handleSelect = (eventKey: any) => {
    switch (eventKey) {
      case manageAccessKey:
        manageAccessHandler();
        break;
    }
  };

  return (
    <Dropdown
      title="Actions"
      trigger="hover"
      icon={<MoreIcon style={{fontSize: "1.8em"}}/>}
      onSelect={handleSelect}
      {...props}
    >
      <Dropdown.Item disabled={!isActive} eventKey={manageAccessKey}>Manage Access</Dropdown.Item>
    </Dropdown>
  );
};

export default TrafficSourcesTree;