import React from "react";
import Dashboard from "../@Components/Dashboard";
import TrafficSources from "../@Components/TrafficSources/TrafficSourcesTree";

const TrafficSourcesTree: React.FC = () => {
  return (
    <Dashboard>
      <TrafficSources/>
    </Dashboard>
  );
};

export default TrafficSourcesTree;
