import React from "react";
import {Button, Drawer, FlexboxGrid, Notification} from "rsuite";
import {FormInstance} from "rsuite/Form";
import CampaignContentForm, {CampaignContentModel} from "../Campaigns/CampaignContentForm";
import HttpClient from "../../@Utils/HttpClient";
import {pushInforming} from "../../@Utils/Messager";
import _ from "lodash";
import EditModal from "../OfferSources/EditModal";
import MediaForm from "../Campaigns/MediaForm";

type CampaignContentProps = {
  formValue: any;
  setFormValue: any;
  isOpen: any;
  setIsOpen: any;
  campaignContentId: number;
  campaignId: number;
  setCampaignContentId: any;
  trafficSourceType?: string;
  updateDataListOnClose: any;
  onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const CampaignContent: React.FC<CampaignContentProps> = ({
  formValue,
  setFormValue,
  isOpen,
  setIsOpen,
  campaignContentId = 0,
  campaignId = 0,
  setCampaignContentId,
  trafficSourceType,
  updateDataListOnClose,
  onAfterCreateOrUpdateGridRow
}) => {
  const mainFormRef = React.createRef<FormInstance>();
  const isNew = campaignContentId === 0;
  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [fileList, setFileList] = React.useState<any>([]);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);

  const handleCallback = (formValue: any) => {
    let list = [...formValue.media_list];

    const lastMedia = list[list.length - 1];

    if (list.length === 0 || lastMedia.headline !== '' || lastMedia.image !== null) {
      list = addEmptyMedia(list, campaignContentId);
    }

    setFormValue({
      ...formValue,
      media_list: list,
    });
  };

  React.useEffect(() => {
    setFileList(formValue.media_list
      .filter((media: any) => media.image !== null)
      .filter((media: any) => media.remove !== 1)
      .map((item: any, index: number) => ({
        fileKey: item.fileKey || item.media_id || 'index_' + index,
        name: item.image,
        url: item.image,
        blobFile: item.blobFile,
        status: item.status,
      })));
  }, [formValue.media_list]);

  const setMediaList = (list: any) => {
    setFormValue({
      ...formValue,
      media_list: list,
    });
  };

  // Load campaign content media list
  //Was React.useEffect, but didn't always trigger if already mounted
  const campaignContentDrawerOpened = () => {
    setFileList([]);
    setMediaList(addEmptyMedia([], campaignContentId));

    if (campaignContentId === 0) return;

    setIsFormLoading(true);

    HttpClient
        .get<any>('campaign_content_media', {
          page: 1,
          limit: 5000,
          contentId: campaignContentId,
          sortDir: 'a'
        })
        .then(res => {
          const mediaList = res.data.data.map((item: any, index: number) => ({
            ...item,
            content_id: !isNaN(parseInt(item.content_id)) ? parseInt(item.content_id) : 0,
            media_id: !isNaN(parseInt(item.media_id)) ? parseInt(item.media_id) : 0,
            order_id: index,
          }));

          const nextContentMedia = addEmptyMedia(mediaList, campaignContentId);

          setIsFormLoading(false);
          setMediaList(nextContentMedia);
          setFileList(mediaList.map((item: any, index: number) => ({
            fileKey: item.fileKey || item.media_id || 'index_' + index,
            name: item.image,
            url: item.image,
          })));
        });
  };

  const handleAfterMediaFileUploading = (file: any, url: string) => {
    setFormValue((formValue: any) => {
      let mediaList = [...formValue.media_list];

      const mediaIndex = mediaList.findIndex((media: any) => media.image === null && media.fileKey === null);
      let media = mediaIndex > -1 ? mediaList[mediaIndex] : null;

      if (!media) {
        mediaList = addEmptyMedia(mediaList, campaignContentId);
        media = mediaList[mediaList.length - 1];
      }

      if (!media) {
        return formValue;
      }

      media.fileKey = file.fileKey;
      media.image = url;
      media.url = url;

      return {
        ...formValue,
        media_list: mediaList,
      };
    });
  };

  const handleAfterUrlsPasting = (urls: string[]) => {
    if (!urls.length) {
      return;
    }

    let mediaList = [...formValue.media_list];

    // Update or create a media for each url
    urls.forEach((url: string) => {
      const mediaIndex = mediaList.findIndex((media: any) => media.image === null && media.fileKey === null);
      let media = mediaIndex > -1 ? mediaList[mediaIndex] : null;

      if (!media || mediaIndex === mediaList.length - 1) {
        mediaList = addEmptyMedia(mediaList, campaignContentId);
      }

      if (!media) {
        media = mediaList[mediaList.length - 1];
      }

      if (media) {
        media.image = url;
        media.url = url;
      }
    });

    setFormValue({
      ...formValue,
      media_list: [...mediaList],
    });

    setFileList(mediaList
        .filter((media: any) => media.image !== null)
        .map((item: any, index: number) => ({
          fileKey: item.fileKey || item.media_id || 'index_' + index,
          name: item.image,
          url: item.image,
        })));
  };

  const handleAfterHeadlinesPasting = (headlines: string[]) => {
    if (!headlines.length) {
      return;
    }

    let mediaList = [...formValue.media_list];

    // Update or create a media for each url
    headlines.forEach((headline: string) => {
      const mediaIndex = mediaList.findIndex((media: any) => media.headline === '');
      let media = mediaIndex > -1 ? mediaList[mediaIndex] : null;

      if (!media || mediaIndex === mediaList.length - 1) {
        mediaList = addEmptyMedia(mediaList, campaignContentId);
      }

      if (!media) {
        media = mediaList[mediaList.length - 1];
      }

      if (media) {
        media.headline = headline;
      }
    });

    setFormValue({
      ...formValue,
      media_list: [...mediaList],
    });
  };

  const handleAfterMediaListSelection = (list: any[]) => {
    if (!list.length) {
      return;
    }

    let mediaList = formValue.media_list.filter((media: any) => media.headline !== '' || media.image !== null);

    // Create a new media for each selected media
    list.forEach((newMedia: any) => {
      mediaList = addEmptyMedia(mediaList, campaignContentId);

      const media = mediaList[mediaList.length - 1];

      media.headline = newMedia.headline;
      media.image = newMedia.image;
      media.url = newMedia.image;
    });

    // Add last empty one
    mediaList = addEmptyMedia(mediaList, campaignContentId);

    setFormValue({
      ...formValue,
      media_list: [...mediaList],
    });

    setFileList(mediaList
        .filter((media: any) => media.image !== null)
        .map((item: any, index: number) => ({
          fileKey: item.fileKey || index,
          name: item.image,
          url: item.image,
        })));
  };

  const handleSave = () => {
    const node = mainFormRef && mainFormRef.current;

    if (node?.check && !node.check()) {
      if (CampaignContentModel.check(formValue)?.media_list.hasError) {
        pushInforming(<Notification closable type="error" header="Error">
          {CampaignContentModel.check(formValue)?.media_list.errorMessage}
        </Notification>);
      }

      return;
    }

    // Create new pairs only for not-saved images or headlines with all unique saved headlines and images
    // For example, for each new image should be X new pairs with X unique headlines
    // For example, for each new headlines should be Y new pairs with Y unique images
    const removedMedias = formValue.media_list.filter((media: any) => media.media_id !== 0 && media.remove === 1);
    const savedMedias = formValue.media_list.filter((media: any) => media.media_id !== 0 && media.remove !== 1);

    const newMedias = formValue.media_list.filter((media: any) => media.media_id === 0);

    const uniqueHeadlines = formValue.media_list
      .filter((media: any) => media.remove !== 1)
      .filter((media: any) => media.headline !== '' && media.headline !== null)
      .map((media: any) => media.headline)
      .filter((headline: string, index: number, list: any[]) => list.indexOf(headline) === index);

    const uniqueSavedImages = savedMedias
      .filter((media: any) => media.image !== '' && media.image !== null)
      .map((media: any) => media.image)
      .filter((image: string, index: number, list: any[]) => list.indexOf(image) === index);

    const newHeadlines = newMedias
      .filter((media: any) => media.headline !== '' && media.headline !== null)
      .map((media: any) => media.headline)
      .filter((headline: string, index: number, list: any[]) => list.indexOf(headline) === index);

    const newImages = newMedias
      .filter((media: any) => media.image !== '' && media.image !== null)
      .map((media: any) => media.image)
      .filter((image: string, index: number, list: any[]) => list.indexOf(image) === index);

    const newMediaList = [
      ...removedMedias,
      ...savedMedias,
      // Pairs of unique headlines + new images
      ...uniqueHeadlines.flatMap((headline: string) =>
        newImages.map((image: string) => ({headline, image}))
      ),
      // Pairs of unique saved images + new headlines, because we already have combinations with new images
      ...uniqueSavedImages.flatMap((image: string) =>
        newHeadlines.map((headline: string) => ({headline, image}))
      ),
    ];

    saveItem({...formValue, media_list: newMediaList}, parseInt(formValue.content_id, 10));
  };

  const saveItem = (data: any, itemId: number = 0) => {
    // We should clone data to change it
    data = _.cloneDeep(data);

    // Build data for saving
    // Remove empty headline without image value
    data.media_list = data.media_list.filter((media: any) => media.headline !== '' && media.image !== null);

    setSaveButtonLoading(true);

    if (itemId === 0) {
      // Create a new one
      HttpClient.post<object, any>('campaign_content', data, {timeout: 60000})
          .then(res => {
            const contentId = parseInt(res.data.content_id, 10);
            const campaignId = data.campaign_id;
            const type = res.status === 201 ? "success" : "error";
            const text = res.status === 201 ? "Content has been created" : res.statusText;

            pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

            // Update form data
            const updatedFormValue = {
              ...data,
              campaign_id: campaignId,
            };
            setCampaignContentId(contentId);
            setFormValue(updatedFormValue);
            setIsOpen(false);

            // Add a new row into a grid data
            onAfterCreateOrUpdateGridRow(contentId, updatedFormValue);
          })
          .catch(error => {
            pushInforming(<Notification closable type="error" header="Error" duration={60000}>
              {error.response.data?.error || error.toString()}
            </Notification>);
          })
          .finally(() => {
            setSaveButtonLoading(false);
          });
    } else {
      // Update an existing one
      HttpClient.put<any>(`campaign_content/${itemId}`, data, {timeout: 60000})
          .then(res => {
            const type = res.status === 200 ? "success" : "error";
            const text = res.status === 200 ? "Content has been updated" : res.statusText;

            pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

            // Update form data
            const updatedFormValue = {
              ...data
            };
            setFormValue(updatedFormValue);
            updateDataListOnClose(data);
            setIsOpen(false);

            // Update a grid data
            onAfterCreateOrUpdateGridRow(itemId, data);
          })
          .catch(error => {
            pushInforming(<Notification closable type="error" header="Error" duration={60000}>
              {error.response.data?.error || error.toString()}
            </Notification>);
          })
          .finally(() => {
            setSaveButtonLoading(false);
          });
    }
  };

  const handleMediaEditClick = (media: any) => {
    setEditedMedia(media);
    setMediaEditModalOpen(true);
  };

  const [editedMedia, setEditedMedia] = React.useState<any>();
  const [mediaEditModalOpen, setMediaEditModalOpen] = React.useState(false);

  const handleMediaEditModalClose = () => {
    setMediaEditModalOpen(false);
  };

  const handleMediaSave = () => {

    const node = mediaFormRef && mediaFormRef.current;
    if (node?.check && !node.check()) {
      return;
    }

    let mediaList = [...formValue.media_list];
    const media = mediaList.find((media: any) => media.media_id === editedMedia.media_id);

    if (media) {
      media.headline = editedMedia.headline;
      media.image = editedMedia.image;
    }

    setFormValue({
      ...formValue,
      media_list: [...mediaList],
    });

    setFileList(mediaList
        .filter((media: any) => media.image !== null)
        .map((item: any, index: number) => ({
          fileKey: item.fileKey || index,
          name: item.image,
          url: item.image,
        })));

    setMediaEditModalOpen(false);
  };

  // Create new pairs only for not-saved images or headlines with all unique saved headlines and images
  // For example, for each new image should be X new pairs with X unique headlines
  // For example, for each new headlines should be Y new pairs with Y unique images
  const createAllCombinationsWithNewHeadlinesAndImagesToMediaList = ((mediaList: any[]) => {
    const savedMedias = mediaList.filter((media: any) => media.media_id !== 0 && media.remove !== 1);

    const newMedias = mediaList.filter((media: any) => media.media_id === 0);

    const uniqueHeadlines = mediaList
      .filter((media: any) => media.headline !== '' && media.headline !== null)
      .filter((media: any) => media.remove !== 1)
      .map((media: any) => media.headline)
      .filter((headline: string, index: number, list: any[]) => list.indexOf(headline) === index);

    const uniqueSavedImages = savedMedias
      .filter((media: any) => media.image !== '' && media.image !== null)
      .map((media: any) => media.image)
      .filter((image: string, index: number, list: any[]) => list.indexOf(image) === index);

    const newHeadlines = newMedias
      .filter((media: any) => media.headline !== '' && media.headline !== null)
      .map((media: any) => media.headline)
      .filter((headline: string, index: number, list: any[]) => list.indexOf(headline) === index);

    const newImages = newMedias
      .filter((media: any) => media.image !== '' && media.image !== null)
      .map((media: any) => media.image)
      .filter((image: string, index: number, list: any[]) => list.indexOf(image) === index);

    const newMediaList = [
      ...savedMedias,
      // Pairs of unique headlines + new images
      ...uniqueHeadlines.flatMap((headline: string) =>
        newImages.map((image: string) => ({headline, image}))
      ),
      // Pairs of unique saved images + new headlines, because we already have combinations with new images
      ...uniqueSavedImages.flatMap((image: string) =>
        newHeadlines.map((headline: string) => ({headline, image}))
      ),
    ];

    return newMediaList;
  });

  const mediaFormRef = React.createRef<FormInstance>();

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        size="full"
        backdrop="static"
        keyboard={false}
        onOpen={campaignContentDrawerOpened}
      >
        <Drawer.Header>
          <Drawer.Title>
            {isNew ? 'Upload New Contents' : 'Edit Contents or Upload New Ones'}
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
              Save
            </Button>
            <Button onClick={() => setIsOpen(false)}>Discard</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={12} style={{paddingRight: 10}}>
              <CampaignContentForm
                  formRef={mainFormRef}
                  formValue={formValue}
                  parentCallback={handleCallback}
                  onAfterMediaFileUploading={handleAfterMediaFileUploading}
                  onAfterUrlsPasting={handleAfterUrlsPasting}
                  onAfterHeadlinesPasting={handleAfterHeadlinesPasting}
                  onAfterMediaListSelection={handleAfterMediaListSelection}
                  fileList={fileList}
                  setFileList={setFileList}
                  isFormLoading={isFormLoading}
                  trafficSourceType={trafficSourceType}
              />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} style={{padding: 15, backgroundColor: "#EEE"}}>
              <h4>Preview</h4>
              {createAllCombinationsWithNewHeadlinesAndImagesToMediaList(formValue.media_list)
                .map((media: any) =>
                  <div key={`${media.image}-${media.headline}`} style={{backgroundColor: "#FFF", borderRadius: 12}}>
                    <p style={{float: "left"}}>
                      <img src={media.image} alt={media.image} width={200} height={100}
                           style={{float: "left", borderRadius: "12px 0 0 12px"}}/>
                    </p>
                    <p style={{paddingLeft: 210}}>
                      <strong>{media.headline}</strong><br/>
                      <span style={{color: "#6263f6"}}>{formValue.target_url}</span><br/>
                      <Button
                          appearance="default"
                          onClick={() => handleMediaEditClick(media)}
                      >
                        Edit
                      </Button>
                    </p>
                    <br style={{clear: "left"}}/>
                  </div>)
              }
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Drawer.Body>
      </Drawer>

      <EditModal
          title="Edit content media"
          open={mediaEditModalOpen}
          size="xs"
          onClose={handleMediaEditModalClose}
          onCancel={handleMediaEditModalClose}
          onSubmit={handleMediaSave}
      >
        <MediaForm
            formValue={editedMedia}
            formRef={mediaFormRef}
            setFormValue={setEditedMedia}
        />
      </EditModal>
    </>
  );
};

export const addEmptyMedia = (mediaList: any[] = [], campaignContentId: number = 0) => {
  const maxOrderId = mediaList.reduce((prev: number, nextMedia: any) => {
    return Math.max(prev, nextMedia.order_id);
  }, -1);

  const emptyMedia = {
    content_id: campaignContentId,
    media_id: 0,
    order_id: maxOrderId + 1,
    headline: '',
    image: null,
    fileKey: null,
  };

  return [
    ...mediaList,
    emptyMedia,
  ];
};

export default CampaignContent;
