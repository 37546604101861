import React from "react";
import {Schema, Form, TagPicker, FlexboxGrid, IconButton, Loader} from "rsuite";

import HttpClient from "../../@Utils/HttpClient";
import {pushErrorNotification} from "../../@Utils/Messager";
const { ArrayType} = Schema.Types;

const model = Schema.Model({
  groups: ArrayType(),
  rules: ArrayType(),
});

type RuleGroupFormType = {
  formRef?: React.MutableRefObject<any>;
  parentCallback?: any;
  setLoading?: any;
  selectedRules: any;
  selectedGroups: any;
  setDisabledButton?: any;
  loading?: boolean;
};

const RuleGroupForm: React.FC<RuleGroupFormType> = ({
  formRef = null,
  loading,
  setLoading,
  selectedRules,
  selectedGroups,
  setDisabledButton,
  parentCallback = () => {
  },
}) => {
  formRef = formRef || React.createRef();
  interface formData {
    rules:string [];
    groups:string [];
  }

  const initData: formData = {
    rules: [],
    groups: []
  }

  const [formValue, setFormValue] = React.useState(initData);
  const [formError, setFormError] = React.useState({});
  formRef = formRef || React.createRef();
  // Load group list
  const [groupList, setGroupList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('rule_group',{
        group_id: 0,
        includes_rule: true
      })
      .then(res => {

        const filteredGroup = res.data.data.map((group:any) => ({
          value: !isNaN(parseInt(group.group_id)) ? parseInt(group.group_id) : 0,
          label: group.group_name,
          rules: group.rules.filter((rule:any)=>!selectedRules.includes(rule))
        }));

        setGroupList(filteredGroup);
      });

  }, [selectedGroups]);

  // Load rule list
  const [ruleList, setRuleList]:any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('rule',{
        group_id: 0
      })
      .then(res => {
        setRuleList(res.data.data.filter((rule:any) => !selectedRules.includes(parseInt(rule.rule_id))).map((item:any) => ({
          value: !isNaN(parseInt(item.rule_id)) ? parseInt(item.rule_id) : 0,
          label: item.rule_name,
          groups: item.groups
        })));
        setLoading(false);
      }).catch(error => {
      setLoading(false);
      pushErrorNotification(error.response.data?.error || error.toString());
    });

  }, [selectedRules]);

  const handleChange = (data:any) => {

    let filteredGroup:any[] ;
    let removeRulesIds:any[] ;
    let allRules:any[] = ruleList;

    if(data.groups === null ) {
      data.groups = [];
    }
    if(data.rules === null ) {
      data.rules = [];
    }

    if(data.groups.length > formValue.groups.length && data.groups.length >0 ) {

      filteredGroup = data.groups.filter((item:string) => !formValue.groups.includes(item))[0];
      allRules = allRules.filter((rule:any) => rule.groups.some((group:any) => group.group_id === filteredGroup)).filter((rule: any) => !formValue.rules.includes(rule.value)).map((rule:any) => rule.value);
      data.rules = [...formValue.rules, ...allRules];

    }

    if(data.groups.length < formValue.groups.length) {
      //@ts-ignore
      filteredGroup = formValue.groups.filter((item:any) => !data.groups.includes(item))[0];
      removeRulesIds = allRules.filter((rule:any) => rule.groups.some((group: any) => group.group_id === filteredGroup)).map((rule:any) => rule.value);
      data.rules = formValue.rules.filter((rule:any) => !removeRulesIds.includes(rule));

    }

    setFormValue(data);
    setLoading(true);

    if (parentCallback instanceof Function) {

      data === initData ? setDisabledButton(true) : setDisabledButton(false);
      parentCallback(data);
      setLoading(false);

    }

  };

  const handleFormClear = () => {
    setDisabledButton(true);
    setFormValue({
      rules: [],
      groups: []
    });

    if (parentCallback instanceof Function) {

      parentCallback({
        rules: [],
        groups: []
      });

    }

  };

  return (
    <FlexboxGrid style={{height: 200}}>
      { loading || groupList.length === 0 ?
        <Loader size="lg" center content="Loading" />  :
        <Form
          ref={formRef}
          onChange={handleChange}
          onCheck={setFormError}
          model={model}
          layout="horizontal"
          formValue={formValue}
          formError={formError}
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={20}>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={4}>
              <IconButton
                onClick={handleFormClear}
                // icon={<ImageIcon/>}
                placement="right"
                size="sm"
                appearance="ghost"
                style={{marginTop: 15, marginBottom: 15, width: "100%", textAlign: 'center'}}
              >
                Clear
              </IconButton>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <Form.Group>
            <Form.Control
              name="rules"
              placeholder = {"Select Individual Rules"}
              style={{ width: 750 }}
              accepter={TagPicker}
              placement="autoVerticalEnd"
              searchable={true}
              defaultValue={formValue.rules}
              data={ruleList}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              name="groups"
              placeholder = {"Select Rule Groups"}
              style={{ width: 750 }}
              accepter={TagPicker}
              placement="autoVerticalEnd"
              searchable={true}
              // onChange={handleChange}
              defaultValue={formValue.groups}
              data={groupList}
            />
          </Form.Group>
        </Form>
      }

      </FlexboxGrid>
  );
};

export default RuleGroupForm;
