import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Form,
  Grid,
  InputNumber,
  Panel,
  PanelGroup,
  Row,
  Schema,
  SelectPicker,
  TagPicker,
  Toggle,
  IconButton
} from "rsuite";
import Field from "../Field";
import HttpClient from "../../@Utils/HttpClient";
import Textarea from "../Textarea";
import moment from "moment";
import OfferSliderTree from "../Funnels/OfferSliderTree";
import { pushErrorNotification } from "../../@Utils/Messager";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";


import CreateOfferModal from "../Offers/CreateOfferModal";
import PlusRound from "@rsuite/icons/PlusRound";
import EditModal from "../OfferSources/EditModal";


type Offer = {
  funnel_node_id: number;
  offer_id: number;
  funnel_id: number;
  name: string;
  locked: boolean;
  offer_split: number;
};

const API_NAME_TABOOLA = "taboola";
const API_NAME_OUTBRAIN = "outbrain";
const API_NAME_TIKTOK = "tiktok";

const getDefaultObjectiveByType = (type: string) => {
  switch (type) {
    case API_NAME_OUTBRAIN:
      return "Awareness";

    case API_NAME_TIKTOK:
      return "WEB_CONVERSIONS";

    case API_NAME_TABOOLA:
    default:
      return "LEADS_GENERATION";
  }
};

const getObjectiveListByType = (type: string) => {
  switch (type) {
    case API_NAME_OUTBRAIN:
      return [
        { value: "Awareness", label: "Awareness" },
        { value: "Traffic", label: "Traffic" },
        { value: "Conversions", label: "Conversions" },
        { value: "AppInstalls", label: "AppInstalls" },
      ];

    case API_NAME_TIKTOK:
      return [
        // { value: "TRAFFIC", label: "Traffic" },
        // { value: "APP_INSTALL", label: "App Installs" },
        // { value: "CONVERSIONS", label: "Conversions" },
        // { value: "REACH", label: "Reach" },
        // { value: "VIDEO_VIEWS", label: "Video Views" },
        // { value: "LEAD_GENERATION", label: "Lead Generation" },
        // { value: "ENGAGEMENT", label: "Community interaction" },
        // { value: "CATALOG_SALES", label: "Catalog Sales" },
        { value: "WEB_CONVERSIONS", label: "Website conversions" },
      ];

    case API_NAME_TABOOLA:
    default:
      return [
        { value: "LEADS_GENERATION", label: "LEADS_GENERATION" },
        { value: "ONLINE_PURCHASES", label: "ONLINE_PURCHASES" },
        { value: "DRIVE_WEBSITE_TRAFFIC", label: "DRIVE_WEBSITE_TRAFFIC" },
        { value: "BRAND_AWARENESS", label: "BRAND_AWARENESS" },
        { value: "MOBILE_APP_INSTALL", label: "MOBILE_APP_INSTALL" },
      ];
  }
};

const getBudgetTypeList = (formData: any) => {
  if (formData.objective === "RF_REACH")
    return [{value: "BUDGET_MODE_INFINITE", label: "Infinite"}];

  if (formData.budget_optimize_on === 1)
    return [{value: "BUDGET_MODE_DAY", label: "Daily"}];

  if (formData.set_campaign_budget === 1)
    return [
      {value: "BUDGET_MODE_TOTAL", label: "Lifetime"},
      {value: "BUDGET_MODE_DAY", label: "Daily"},
    ];

  return [
    {value: "BUDGET_MODE_INFINITE", label: "Infinite"},
    {value: "BUDGET_MODE_TOTAL", label: "Lifetime"},
    {value: "BUDGET_MODE_DAY", label: "Daily"},
  ];
};

const prepareFormData = (record: any) => {
  if (record === null) {
    return;
  }

  const parseFloatWithoutStrippingZeroes = (value: string | number, defaultValue = null) => {
    if (typeof value === 'number') {
      return value;
    }

    if (isNaN(parseFloat(value))) {
      return defaultValue;
    }

    const separator = value.split(',').length > 1 ? ',' : '.';
    const parts = value.split(separator);
    const toFixedLength = parts.length === 2 ? parts[1].length : 0;

    return parseFloat(value).toFixed(toFixedLength);
  };

  record.groups = record.groups === null ? [] : record.groups;
  record.campaign_id = !isNaN(parseInt(record.campaign_id)) ? parseInt(record.campaign_id) : 0;
  record.traffic_source_id = !isNaN(parseInt(record.traffic_source_id)) ? parseInt(record.traffic_source_id) : 0;
  record.unique_window = !isNaN(parseInt(record.unique_window)) ? parseInt(record.unique_window) : 0;
  record.active = !isNaN(parseInt(record.active)) ? parseInt(record.active) : 0;
  record.budget_amount = parseFloatWithoutStrippingZeroes(record.budget_amount);
  record.budget_optimize_on = !isNaN(parseInt(record.budget_optimize_on))
    ? parseInt(record.budget_optimize_on) : 0;
  record.bid_amount = parseFloatWithoutStrippingZeroes(record.bid_amount);
  record.spending_limit = parseFloatWithoutStrippingZeroes(record.spending_limit);
  record.daily_cap = parseFloatWithoutStrippingZeroes(record.daily_cap);
  record.end_date = record?.hasOwnProperty("end_date") && record?.end_date !== null ? new Date(record.end_date) : null;
  record.creative_traffic_allocation_start =
    record?.hasOwnProperty("creative_traffic_allocation_start") && record?.creative_traffic_allocation_start !== null
      ? new Date(record.creative_traffic_allocation_start)
      : null;
  record.creative_traffic_allocation_end =
    record?.hasOwnProperty("creative_traffic_allocation_end") && record?.creative_traffic_allocation_end !== null
      ? new Date(record.creative_traffic_allocation_end)
      : null;

  return record;
};

type CampaignProps = {
  formValue: any;
  formRef?: React.MutableRefObject<any>;
  onChangeFunnelNodes?: any;
  parentCallback?: any;
};

const CampaignForm: React.FC<CampaignProps> = ({
  formValue,
  formRef = null,
  onChangeFunnelNodes,
  parentCallback = () => {},
}) => {
  formRef = formRef || React.createRef();
  formValue = prepareFormData(formValue);

  const [formError, setFormError] = React.useState<any>({});
  const [trafficSources, setTrafficSources]: any = React.useState([]);
  const [offerList, setOfferList]: any = React.useState([]);
  const [offerSearchValue, setOfferSearchValue] = React.useState('');
  const [offerSliderError, setOfferSliderError] = React.useState('');
  const [isOffers, setIsOffers] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const isNew = !(formValue.campaign_id > 0);
  const hasNodeCount = formValue.node_count === false;
  // let isOffers = false;

  const locationTypeList = [
    { value: "all", label: "All Locations" },
    { value: "target", label: "Target Locations" },
    { value: "block", label: "Block Locations" },
  ];

  // Load country list
  const [locationList, setLocationList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('country')
      .then(res => {
        setLocationList(res.data.data);
      });
  }, []);

  const locationSubtypeList = [
    { value: "all", label: "Entire Country" },
    { value: "region", label: "Region/State" },
  ];

  // Load region list
  const [locationSubList, setLocationSubList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('region')
      .then(res => {
        setLocationSubList(res.data.data);
      });

    HttpClient
      .get<any>('offer', {
        status: 1,
        limit: 1000,
      })
      .then(res => {
        setOfferList(res.data.data.map((item: any) => ({
          value: item.offer_id,
          label: item.offer_name,
          category: item.offer_category_name,
          traffic_source_type: item.traffic_source_type,
        })));
      });
  }, []);
  // Load group list
  const [groupList, setGroupList]: any = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('rule_group',{
        group_id: 0,
        includes_rule: true
    })
      .then(res => {
        setGroupList(res.data.data.map((item: any) => ({
          value: !isNaN(parseInt(item.group_id)) ? parseInt(item.group_id) : 0,
          label: item.group_name,
          rules: item.rules
        })));


      });

  }, []);

  // Load rule list
  const [ruleList, setRuleList] = React.useState([]);

  React.useEffect(() => {
    HttpClient
      .get<any>('rule',{
        group_id: 0
      })
      .then(res => {
        setRuleList(res.data.data.map((item: any) => ({
          value: !isNaN(parseInt(item.rule_id)) ? parseInt(item.rule_id) : 0,
          label: item.rule_name,
          groups: item.groups
        })));
      });

  }, []);

  const osTargetList = [
    { value: "all", label: "Target All" },
    { value: "include", label: "Include" },
    { value: "exclude", label: "Exclude" },
  ];
  const browserTargetList = [
    { value: "all", label: "All Browsers" },
    { value: "include", label: "Include" },
    { value: "exclude", label: "Exclude" },
  ];

  const osList = [
    { value: "win", label: "Windows" },
    { value: "mac", label: "Mac" },
    { value: "linux", label: "Linux" },
    { value: "ios", label: "iOS" },
    { value: "android", label: "Android" },
    ...formValue.traffic_source_type !== API_NAME_TABOOLA ? [{ value: "chrome", label: "ChromeOS" }] : [],
  ];

  const browserList = [
    { value: "chrome", label: "Chrome" },
    { value: "firefox", label: "FireFox" },
    { value: "edge", label: "Edge" },
    { value: "safari", label: "Safari" },
  ];

  const bidStrategyList = [
    { value: "FIXED", label: "Fixed" },
    { value: "OPTIMIZED_CONVERSIONS", label: "Smart Bid" },
  ];

  const spendingLimitTypeList = [
    { value: "ENTIRE", label: "Entire Campaign" },
    { value: "MONTHLY", label: "Monthly Flight" },
  ];

  const creativeTrafficAllocationList = [
    { value: "OPTIMIZED", label: "Optimized" },
    { value: "EVEN", label: "A/B Testing" },
  ];

  const dailyAddDeliveryTypeList = [
    { value: "BALANCED", label: "Balanced" },
    { value: "STRICT", label: "Strict" },
  ];

  // Load traffic source list
  React.useEffect(() => {
    HttpClient
      .get<any>('traffic_source', {
        page: 1,
        limit: 5000,
      })
      .then(res => {
        setTrafficSources(res.data.data.map((item: any) => ({
          ...item,
          traffic_source_id: !isNaN(parseInt(item.traffic_source_id)) ? parseInt(item.traffic_source_id) : 0,
          traffic_source_type_output: item.traffic_source_type.charAt(0).toUpperCase() + item.traffic_source_type.slice(1),
        })));
      });
  }, []);

  const setFormValue = (data: any) => {
    let filteredGroup: any[];
    let allRulesUnique: any[];
    let removeRulesIds: any[];
    let removeRulesIdsUnique: any[];
    let allRules: any[] = ruleList;

    if(data.groups === null ) {
      data.groups = [];
      formValue.groups = [];
      data.rules = [];
      formValue.rules = [];
    }

    if(data.rules === null ) {
      data.rules = [];
      data.separatedRules = [];
      data.groups = [];
      formValue.groups = [];
      formValue.rules = [];
    }

    if (data.groups.length > formValue.groups.length && data.groups.length > 0) {

      filteredGroup = data.groups.filter((item: string) => !formValue.groups.includes(item))[0];
      allRules = allRules.filter((rule: any) => rule.groups.some((group: any) => group.group_id === filteredGroup)).filter((rule: any) => !formValue.rules.includes(rule.value)).map((rule: any) => rule.value);
      allRulesUnique = allRules.filter((x, i, a) => a.indexOf(x) == i);
      data.rules = [...formValue.rules, ...allRulesUnique];

    }

    if (data.groups.length < formValue.groups.length ) {
      //@ts-ignore
      filteredGroup = formValue.groups.filter((item: any) => !data.groups.includes(item))[0];
      allRulesUnique = allRules.filter((x, i, a) => a.indexOf(x) == i);
      removeRulesIds = allRulesUnique.filter((rule: any) => rule.groups.some((group: any) => group.group_id === filteredGroup)).map((rule: any) => rule.value);
      removeRulesIdsUnique = removeRulesIds.filter((x, i, a) => a.indexOf(x) == i)
      data.rules = formValue.rules.filter((rule: any) => !removeRulesIdsUnique.includes(rule));
    }

    if(data.groups.length == formValue.groups.length ) {

      allRules = allRules.filter((rule: any) => rule.groups.some((group: any) => formValue.groups.includes(group.group_id))).filter((rule: any) => data.rules.includes(rule.value));
      if(allRules.length == 0) data.groups = [];

    }

    if (typeof parentCallback === "function") {
      const traffic = trafficSources.find((item: any) => item.traffic_source_id === data.traffic_source_id);
      const objectives = getObjectiveListByType(traffic.traffic_source_type).map((item) => item.value);

      if (!objectives.includes(data.objective)) {
        data.objective = getDefaultObjectiveByType(traffic.traffic_source_type);
      }

      parentCallback({
        ...data,
        traffic_source_id: traffic.traffic_source_id,
        traffic_source_name: traffic.traffic_source_name,
        traffic_source_type: traffic.traffic_source_type,
        account: traffic.account,
      });
    }
  };

  const addFunnelNode = (val: string, item: any) => {

    if (item) {

      if (formValue.offers.find((offer: Offer) => offer.offer_id === item.value)) {
        pushErrorNotification("Offer Already Exists in List");
        return;
      }

      let newOffers: Offer[] = [
        ...formValue.offers,
        {
          funnel_node_id: 0,
          offer_id: item.value,
          offer_name: item.label,
          offer_split: 0,
          locked: false
        }
      ];

      let newFormValue = { ...formValue, 'offers': equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true)) };
      setFormValue(newFormValue);
    }
    setOfferSearchValue('');
  };

  const getOfferSliderSum = (newOffers: Offer[], ignoreUnlockedSliders = false) => {
    let sum = 0;
    newOffers.forEach((offer: Offer) => {
      if (offer.locked || !ignoreUnlockedSliders) {
        sum += offer.offer_split;
      }
    });
    return sum;
  };

  const getOfferSliderCount = (newOffers: Offer[], ignoreLockedSliders = false) => {
    let count = 0;
    newOffers.forEach((offer: Offer) => {
      count += (!offer.locked || !ignoreLockedSliders) ? 1 : 0
    });
    return count;
  };

  const equalizeOfferSliders = (newOffers: Offer[], sum = 0) => {
    sum = 100 - sum;
    const count = getOfferSliderCount(newOffers, true);

    return newOffers.map((offer: Offer) => {
      //If unedited, then adjust for new value
      if (!offer.locked) {
        offer.offer_split = Number((sum / count).toFixed(1));

        //set constraints between 0 and sum
        if (offer.offer_split < 0) {
          offer.offer_split = 0;
        }
      }
      return offer;
    });
  };

  const updateOfferSliders = (sliderOffer: any, value: number) => {
    if (!sliderOffer.locked) {
      let sum = 100 - getOfferSliderSum(formValue.offers, true);

      sum = Math.round(sum * 10) / 10;  //Set precision to 3 sigfigs
      value = value > 0 ? value : 0;  //No negative values

      let newOffers = formValue.offers.map((offer: Offer) => {
        if (offer.offer_id === sliderOffer.offer_id) {
          offer.offer_split = value;

          if (offer.offer_split > sum) { //Top out the slider at max sum
            offer.offer_split = sum;
          }
          offer.locked = true;  //Lock updated slider so equalize doesn't change it.
        }
        return offer;
      });

      //Moves all the unlocked sliders to accommodate the change.
      newOffers = equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true));

      //If they do not sum to 100, update current slider to make up difference
      if (getOfferSliderCount(newOffers, true) !== 0) {
        let newSum = getOfferSliderSum(newOffers);
        sliderOffer.offer_split = (100 - newSum) + sliderOffer.offer_split;
        sliderOffer.offer_split = Math.round(sliderOffer.offer_split * 10) / 10;
        sliderOffer.offer_split = sliderOffer.offer_split > 0 ? sliderOffer.offer_split : 0;
      }

      //Unlock the updated slider
      let newFormValue = {
        ...formValue, 'offers': newOffers.map((offer: Offer) => {
          if (offer.offer_id === sliderOffer.offer_id) {
            offer.locked = false;
            offer.offer_split = sliderOffer.offer_split;
          }
          return offer;
        })
      };

      setFormValue(newFormValue);
      checkFunnelNodeSum(newFormValue.offers);
    }
  };

  const checkFunnelNodeSum = (offers: Offer[]) => {
    let newSum = getOfferSliderSum(offers);
    onChangeFunnelNodes(newSum);
    if (newSum < 99.8) {
      setOfferSliderError('*Offer Sliders Should Sum to 100%');
    } else {
      setOfferSliderError('');
    }
  };

  const toggleSliderLock = (offer: Offer) => {
    let newFormValue = {
      ...formValue, 'offers': formValue.offers.map((j: Offer) => {
        if (j.offer_id === offer.offer_id) {
          j.locked = !j.locked;
        }
        return j;
      })
    };
    setFormValue(newFormValue);
  };

  const removeOfferSlider = (offer: Offer) => {
    let newOffers = formValue.offers.filter((i: Offer) => {
      return i.offer_id !== offer.offer_id;
    });

    let newFormValue = { ...formValue, 'offers': equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true)) };
    setFormValue(newFormValue);
  };

  const openOfferModal = () => {
    setOpenModal(true);
  }

  const closeOfferModal = () => {
    setOpenModal(false);
  }

  const getCreatedOffer = (offerID: any) => {
    closeOfferModal();
    setOfferSearchValue(offerID)

    HttpClient
        .get<any>(`offer/${offerID}`)
        .then(res => {
          let newOffers: Offer[] = [
            ...formValue.offers,
            {
              funnel_node_id: 0,
              offer_id: res.data.offer_id,
              offer_name: res.data.offer_name,
              offer_split: 100,
              locked: false
            }
          ];

          let newFormValue = { ...formValue, 'offers': equalizeOfferSliders(newOffers, getOfferSliderSum(newOffers, true)) };
          setFormValue(newFormValue);
        });
  }

  const initFormData = {
    offer_id: 0,
    offer_name: '',
    offer_source_id: 0,
    offer_category_id: '',
    base_offer_url: '',
    offer_payout: '',
    notes: '',
    data_passing: '',
    status: 1,
    traffic_source_type: null,
  };



  return (
      <>
    <Form
      layout="horizontal"
      ref={formRef}
      onChange={setFormValue}
      onCheck={setFormError}
      model={model}
      formValue={formValue}
      formError={formError}
    >
      <Form.Group>
        <Form.ControlLabel style={{ display: "block" }}>Active</Form.ControlLabel>
        <Toggle
          style={{ display: "inline-block", paddingTop: 5 }}
          checked={formValue.active === 1}
          onChange={(checked) =>
            setFormValue({
              ...formValue,
              active: checked ? 1 : 0,
            })
          }
        />
      </Form.Group>
      <Field
        label="Traffic Source"
        name="traffic_source_id"
        placeholder="Select Traffic Source"
        placement="autoVerticalStart"
        accepter={SelectPicker}
        data={trafficSources}
        valueKey="traffic_source_id"
        labelKey="traffic_source_name"
        groupBy="traffic_source_type_output"
        cleanable={false}
        readOnly={!isNew}
      />
      <Field
        label={formValue.traffic_source_type === API_NAME_TABOOLA ? "Marketing Objective" : "Objective"}
        name="objective"
        placement="autoVerticalStart"
        accepter={SelectPicker}
        data={getObjectiveListByType(formValue.traffic_source_type)}
        defaultValue={formValue.objective}
        cleanable={false}
        disabled={!formValue.traffic_source_type}
        readOnly={formValue.traffic_source_type === API_NAME_TIKTOK && !isNew}
      />
      { formValue.traffic_source_type != null ?
        <>
          <Field
            label="Campaign Name"
            name="campaign_name"
            placeholder="Enter Campaign Name"
          />
          {!isNew ?
            <Field
              label="API Campaign ID"
              name="api_campaign_id"
              placeholder="Enter API Campaign ID"
              readOnly={true}
            /> : null
          }
          { formValue.traffic_source_type !== API_NAME_TIKTOK &&
            <>
              <Form.Group>
                <Form.ControlLabel style={{ display: "block" }}>Unique Window</Form.ControlLabel>
                <Toggle
                  style={{ display: "inline-block", paddingTop: 5 }}
                  checked={formValue.unique_window === 1}
                  onChange={(checked) =>
                    setFormValue({
                      ...formValue,
                      unique_window: checked ? 1 : 0,
                    })
                  }
                />
              </Form.Group>
              <Field
                label="Branding Text"
                name="description"
                placeholder="Enter Branding Text"
                rows={5}
                accepter={Textarea}
              />
              <Field
                label="Bid Amount"
                name="bid_amount"
                accepter={InputNumber}
                prefix="USD"
                postfix="per click"
                min={0}
                step={0.01}
              />
              <Field
                label="Bid Strategy"
                name="bid_strategy"
                searchable={false}
                cleanable={false}
                placeholder="Select Bid Strategy"
                placement="bottomStart"
                accepter={SelectPicker}
                data={bidStrategyList}
              />
              <Field
                label="Spending Limit"
                name="spending_limit"
                accepter={InputNumber}
                prefix="USD"
              />
              <Field
                name="spending_limit_type"
                searchable={false}
                cleanable={false}
                placeholder="Select Spending Limit Type"
                placement="autoVerticalStart"
                accepter={SelectPicker}
                data={spendingLimitTypeList}
              />
              <Field
                label="Creative Traffic Allocation"
                name="creative_traffic_allocation"
                searchable={false}
                cleanable={false}
                placeholder="Select Creative Traffic Allocation"
                placement="bottomStart"
                accepter={SelectPicker}
                data={creativeTrafficAllocationList}
              />
              {formValue.creative_traffic_allocation === "EVEN" &&
                <Grid fluid style={{ marginBottom: 30 }}>
                  <Row className="show-grid">
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Form.Group>
                        <Form.ControlLabel style={{ textAlign: "left", width: 300 }}>
                          Creative Traffic Allocation Start
                        </Form.ControlLabel>
                        <Form.Control
                          name="creative_traffic_allocation_start"
                          accepter={DatePicker}
                          disabledDate={(date: any) => moment(date).isBefore(moment().subtract(1, "days"))}
                          style={{ width: 300 }}
                          placement="autoVerticalStart"
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Form.Group>
                        <Form.ControlLabel style={{ textAlign: "left", width: 300 }}>
                          Creative Traffic Allocation End
                        </Form.ControlLabel>
                        <Form.Control
                          name="creative_traffic_allocation_end"
                          accepter={DatePicker}
                          disabledDate={(date: any) => moment(date).isBefore(formValue.creative_traffic_allocation_start)}
                          style={{ width: 300 }}
                          placement="autoVerticalEnd"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Grid>
              }
              <Field
                label="Daily Ad Delivery"
                name="daily_ad_delivery_type"
                searchable={false}
                cleanable={false}
                placeholder="Select Daily Delivery"
                placement="autoVerticalStart"
                accepter={SelectPicker}
                data={dailyAddDeliveryTypeList}
              />
              {formValue.daily_ad_delivery_type === "STRICT" &&
                <Field
                  label="Daily Cap"
                  name="daily_cap"
                  accepter={InputNumber}
                  prefix="USD"
                  outerPostfix={<div><em>Actual daily spend may be up to 25% higher</em></div>}
                />
              }

              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Form.Group controlId="locations_type">
                      <Form.ControlLabel style={{ textAlign: "left" }}>Location</Form.ControlLabel>
                      <Form.Control
                        name="locations_type"
                        searchable={false}
                        cleanable={false}
                        style={{ width: 300 }}
                        accepter={SelectPicker}
                        placement="autoVerticalStart"
                        data={locationTypeList}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {formValue.locations_type !== "all" &&
                      <Form.Group>
                        <Form.ControlLabel style={{ textAlign: "left" }}>Countries</Form.ControlLabel>
                        <Form.Control
                          name="locations"
                          style={{ width: 350 }}
                          accepter={TagPicker}
                          placement="autoVerticalEnd"
                          searchable={true}
                          data={locationList}
                          labelKey="country_name"
                          valueKey="country_iso_code"
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
                <Row className="show-grid" style={{ marginTop: 15 }}>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Form.Group controlId="locations_subtype">
                      <Form.ControlLabel style={{ textAlign: "left" }}>Locations to be included</Form.ControlLabel>
                      <Form.Control
                        name="locations_subtype"
                        searchable={false}
                        cleanable={false}
                        style={{ width: 300 }}
                        accepter={SelectPicker}
                        placement="autoVerticalStart"
                        data={locationSubtypeList}
                        disabled={formValue.locations_type === "all" || formValue.locations.length === 0}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {formValue.locations.length > 0 && formValue.locations_subtype !== "all" &&
                      <Form.Group>
                        <Form.ControlLabel style={{ textAlign: "left" }}>Regions</Form.ControlLabel>
                        <Form.Control
                          name="locations_sub"
                          style={{ width: 350 }}
                          accepter={TagPicker}
                          placement="autoVerticalEnd"
                          searchable={true}
                          data={locationSubList.filter((item: any) => formValue.locations.includes(item.country_iso_code))}
                          labelKey="subdivision_1_name"
                          valueKey="subdivision_1_iso_code"
                          groupBy="country_name"
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Grid>

              <Grid fluid>
                <Row className="show-grid">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Form.Group controlId="device_type">
                      <Form.ControlLabel style={{ textAlign: "left" }}>Platform</Form.ControlLabel>
                      <Form.Control name="device_type" accepter={CheckboxGroup} inline>
                        <Checkbox value="desktop">Desktop</Checkbox>
                        <Checkbox value="tablet">Tablet</Checkbox>
                        <Checkbox value="mobile">Mobile</Checkbox>
                      </Form.Control>

                    </Form.Group>
                  </Col>
                </Row>
                <Row className="show-grid" style={{ marginTop: 15 }}>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <Form.Group>
                      <Form.ControlLabel style={{ textAlign: "left" }}>Operating System</Form.ControlLabel>
                      <Form.Control
                        name="os_target"
                        searchable={false}
                        cleanable={false}
                        accepter={SelectPicker}
                        placement="autoVerticalStart"
                        data={osTargetList}
                        style={{ marginRight: 15, width: 200 }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    {formValue.os_target !== "all" &&
                      <Form.Group>
                        <Form.Control
                          name="os"
                          accepter={TagPicker}
                          placement="autoVerticalEnd"
                          data={osList}
                          style={{ marginRight: 15, marginTop: 40, width: 400 }}
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
                <Row className="show-grid" style={{ marginTop: 15 }}>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <Form.Group>
                      <Form.ControlLabel style={{ textAlign: "left" }}>Browser</Form.ControlLabel>
                      <Form.Control
                        name="browser_target"
                        placement="autoVerticalStart"
                        accepter={SelectPicker}
                        searchable={false}
                        cleanable={false}
                        data={browserTargetList}
                        style={{ marginRight: 15, width: 200 }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    {formValue.browser_target !== "all" &&
                      <Form.Group>
                        <Form.Control
                          name="browser"
                          placement="autoVerticalEnd"
                          accepter={TagPicker}
                          data={browserList}
                          style={{ marginRight: 15, marginTop: 40, width: 400 }}
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              </Grid>
            </>
          }

          {formValue.traffic_source_type === API_NAME_TIKTOK &&
            <>
              <Form.Group>
                <Form.ControlLabel style={{ display: "block" }}>Campaign Budget Optimization</Form.ControlLabel>
                <Toggle
                  checkedChildren={<CheckIcon />}
                  unCheckedChildren={<CloseIcon />}
                  readOnly={!isNew}
                  style={{ display: "inline-block", paddingTop: 5 }}
                  checked={formValue.budget_optimize_on === 1}
                  onChange={(checked) =>
                    setFormValue({
                      ...formValue,
                      set_campaign_budget: 0,
                      budget_optimize_on: checked ? 1 : 0,
                      budget_type: checked ? "BUDGET_MODE_DAY" : "BUDGET_MODE_INFINITE",
                    })
                  }
                />
                <div className={"field-postfix"} style={{paddingLeft: 5, marginTop: 10}}>
                  <em>Automatically optimize your budget allocation</em>
                </div>
              </Form.Group>
              {formValue.budget_optimize_on === 0 &&
                <Form.Group>
                  <Form.ControlLabel style={{display: "block"}}>Set campaign budget</Form.ControlLabel>
                  <Toggle
                    checkedChildren={<CheckIcon/>}
                    unCheckedChildren={<CloseIcon/>}
                    readOnly={!isNew && formValue.budget_amount > 0}
                    style={{display: "inline-block", paddingTop: 5}}
                    checked={formValue.set_campaign_budget === 1}
                    onChange={(checked) =>
                      setFormValue({
                        ...formValue,
                        set_campaign_budget: checked ? 1 : 0,
                        budget_optimize_on: 0,
                        budget_type: checked ? (isNew ? "BUDGET_MODE_DAY" : "BUDGET_MODE_TOTAL") : "BUDGET_MODE_INFINITE",
                      })
                    }
                  />
                </Form.Group>
              }
              {(formValue.budget_optimize_on === 1 || formValue.set_campaign_budget === 1) &&
                <>
                  <Field
                    label="Budget Type"
                    name="budget_type"
                    searchable={false}
                    cleanable={false}
                    readOnly={!isNew}
                    placeholder="Select Budget Type"
                    placement="autoVerticalStart"
                    accepter={SelectPicker}
                    data={getBudgetTypeList(formValue)}
                  />
                  <Field
                    label="Budget"
                    name="budget_amount"
                    accepter={InputNumber}
                    prefix="USD"
                    placeholder="At least 50.00"
                    rule={formValue.budget_optimize_on === 1 || formValue.set_campaign_budget === 1
                      ? NumberType().isRequired("Your budget must be a number between 50.00 and 10,000,000.00.")
                      : NumberType()
                    }
                  />
                </>
              }
            </>
          }
          {isNew || hasNodeCount ? (
            <PanelGroup accordion bordered style={{marginTop: 15}}>
              <Panel header="Edit Offers" defaultExpanded>
                <Grid fluid>
                  <Row className="show-grid">
                    <Col xs={6} sm={6} md={6} lg={6} style={{ height: 50 }}>
                      <Form.Group controlId="addFunnelNode">
                        <SelectPicker
                          placeholder="Add Offer..."
                          groupBy="category"
                          style={{ width: '100%' }}
                          data={offerList.filter((item: any) => item.traffic_source_type === formValue.traffic_source_type)}
                          placement="topStart"
                          onSelect={addFunnelNode}
                          onChange={(val) => setOfferSearchValue(val) }
                          onExited={() => setOfferSearchValue('') }
                          value={offerSearchValue} />

                      </Form.Group>

                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} style={{ height: 50 }}>
                      <IconButton
                          size="lg"
                          // color="blue"
                          appearance="subtle"
                          style={{marginLeft: 15}}
                          icon={<PlusRound/>}
                          onClick={() => {
                            openOfferModal();
                          }}
                      />
                      {/*  New*/}
                      {/*</IconButton>*/}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <label style={{ color: 'red' }}>{offerSliderError}</label>
                    </Col>
                  </Row>
                  <OfferSliderTree
                    offers={formValue.offers}
                    onChange={updateOfferSliders}
                    removeOfferSlider={removeOfferSlider}
                    toggleSliderLock={toggleSliderLock}
                  />
                </Grid>
              </Panel>
            </PanelGroup>
          ) : ''
          }
          <PanelGroup accordion bordered style={{marginTop: 15}}>
            <Panel  defaultExpanded>
              <Grid fluid>
                <Row className="show-grid" style={{ marginTop: 15 }}>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    <Form.Group>

                      <Form.ControlLabel style={{ textAlign: "left" }}>Add Rule Groups</Form.ControlLabel>

                      <Form.Control
                        name="groups"
                        placement="autoVerticalStart"
                        accepter={TagPicker}
                        searchable={false}
                        data={groupList}
                        defaultValue={formValue.groups}
                        style={{ marginRight: 15, width: 200 }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={16} sm={16} md={16} lg={16}>
                    <Form.Group>

                    <Form.ControlLabel style={{ textAlign: "left" }}>Add Individual Rules</Form.ControlLabel>

                    <Form.Control
                      name="rules"
                      placement="autoVerticalEnd"
                      accepter={TagPicker}
                      data={ruleList}
                      searchable={true}
                      defaultValue={formValue.rules}
                      style={{ marginRight: 15,  width: 400 }}
                    />
                </Form.Group>
              </Col>
            </Row>
          </Grid>
        </Panel>
      </PanelGroup>
        </> : null }
    </Form>

        <EditModal
            title={"Create Offer"}
            size="lg"
            open={openModal}
            onClose={closeOfferModal}
            onCancel={closeOfferModal}
            hideSubmitButton={true}
            backdrop={false}
        >
          <CreateOfferModal
              formData={initFormData}
              offerId={0}
              parentCallback={getCreatedOffer}
          />
        </EditModal>
      </>
  );
};

const { ArrayType, BooleanType, DateType, NumberType, StringType } = Schema.Types;

const model = Schema.Model({
  campaign_id: NumberType(),
  traffic_source_id: NumberType()
    .isRequiredOrEmpty("Traffic Source is required")
    .addRule((value) => value > 0, "Traffic Source is required"),
  traffic_source_type: StringType(),
  objective: StringType().isRequired("Objective is required"),
  campaign_name: StringType().isRequired("Campaign Name is required"),
  description: StringType(),
  api_campaign_id: StringType(),
  unique_window: NumberType(),
  active: NumberType(),
  set_campaign_budget: NumberType(),
  budget_optimize_on: NumberType(),
  budget_type: StringType(),
  budget_amount: NumberType(),
  groups: ArrayType(),
  rules: ArrayType(),
  bid_amount: NumberType().addRule((value, data) => {
    if (data.traffic_source_type === API_NAME_TIKTOK) {
      return true;
    }

    return (value >= 0.01 && value <= 10.00);
  }, "Your budget must be a number between 0.01 and 10.00."),
  bid_strategy: StringType(),
  spending_limit: NumberType().max(999999.99).addRule((value, data) => {
    if (data.traffic_source_type === API_NAME_TIKTOK) {
      return true;
    }

    if (value > data.bid_amount && value >= 2*data.daily_cap) {
      return true;
    }

    return false;
  }, "Must be higher than cpc and at least twice the daily_cap"),
  spending_limit_type: StringType(),
  creative_traffic_allocation: StringType(),
  creative_traffic_allocation_start: DateType(),
  creative_traffic_allocation_end: DateType(),
  daily_ad_delivery_type: StringType(),
  daily_cap: NumberType().max(999999.99).addRule((value:any, data) => {
    if (data.traffic_source_type === API_NAME_TABOOLA && value > 0) {
      return true;
    }

    if (data.traffic_source_type === API_NAME_OUTBRAIN || data.traffic_source_type === API_NAME_TIKTOK) {
      return true;
    }

    return false;
  }, "Please set Daily Cap"),
  locations_type: StringType(),
  locations: ArrayType(),
  locations_subtype: StringType(),
  locations_sub: ArrayType(),
  device_type: ArrayType().addRule((value, data) => {
    if (data.traffic_source_type === API_NAME_OUTBRAIN && value.length === 0) {
      return false;
    }

    return true;
  }, "Please select at least one platform type"),
  os_target: StringType(),
  os: ArrayType(),
  browser_target: StringType(),
  browser: ArrayType(),
  offers: ArrayType(),
  create_funnel: BooleanType(),
});

export const initialCampaignData = {
  campaign_id: 0,
  campaign_name: '',
  objective: '',
  client_id: '',
  client_secret: '',
  traffic_source_id: 0,
  description: '',
  api_campaign_id: '',
  unique_window: 1,
  active: 1,
  set_campaign_budget: 0,
  budget_optimize_on: 0,
  budget_amount: 0,
  budget_type: '',
  bid_amount: null,
  bid_strategy: "FIXED",
  end_date: null,
  spending_limit: null,
  locations_type: '',
  locations: [],
  locations_subtype: 'all',
  locations_sub: [],
  device_type: [],
  os_target: '',
  os: [],
  browser_target: '',
  browser: [],
  rules: [],
  groups: [],
  rules_groups: [],
  spending_limit_type: "MONTHLY",
  creative_traffic_allocation: "EVEN",
  creative_traffic_allocation_start: null,
  creative_traffic_allocation_end: null,
  daily_ad_delivery_type: "STRICT",
  daily_cap: null,
  node_count: true
};

export default CampaignForm;
