import React from "react";
import { Button, Drawer, Form, Notification, Panel, PanelGroup, Schema } from "rsuite";
import GeneralSettings from "../OfferSources/GeneralSettings";
import TrackingTokens from "../OfferSources/TrackingTokens";
import DataPassing from "../OfferSources/DataPassing";
import { FormInstance } from "rsuite/Form";
import HttpClient from "../../@Utils/HttpClient";
import { pushInforming } from "../../@Utils/Messager";

type OfferSourceProps = {
    formData: any;
    open: any;
    setOpen: any;
    offerSourceId: number;
    setOfferSourceId: any;
    onAfterCreateOrUpdateGridRow: (id: number, data: object) => void;
};

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
    offer_source_id: NumberType(),
    offer_source_name: StringType().isRequired("Offer Source Name is required."),
    offer_source_category_id: NumberType().isRequired("Category is required"),
    offer_payout: NumberType().max(99999.9999),
    transaction_id: StringType(),
    hit_id_field: StringType(),
    data_passing: StringType(),
});

const OfferSource: React.FC<OfferSourceProps> = ({
    formData,
    open,
    setOpen,
    offerSourceId = 0,
    setOfferSourceId,
    onAfterCreateOrUpdateGridRow
}) => {
    const [formError, setFormError] = React.useState<any>({});
    const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
    const [formValue, setFormValue] = React.useState(formData);
    const mainFormRef = React.createRef<FormInstance>();

    // TODO Replace it
    React.useEffect(() => setFormValue(formData), [formData]);

    const handleChange = (data: any) => changeFormValue(data);

    const changeFormValue = (data: any) => {
        setFormValue(data);
    };

    const handleChangeCategory = (categoryId: number, categoryName: string = '') => {
        setFormValue({
            ...formValue,
            offer_source_category_id: categoryId,
            offer_source_category_name: categoryName,
        });
    };

    const handleChangeDataPassing = (values: any) => {
        setFormValue({
            ...formValue,
            data_passing: JSON.stringify(values),
        });
    };

    const handleSave = () => {
        const node = mainFormRef && mainFormRef.current;

        if (node?.check && !node.check()) {
            return;
        }

        setSaveButtonLoading(true);

        if (offerSourceId === 0) {
            // Create a new one
            HttpClient.post<object, any>('offer_source', formValue)
                .then((res) => {
                    const sourceId = parseInt(res.data.offer_source_id);
                    const type = res.status === 201 ? "success" : "error";
                    const text = res.status === 201 ? "Offer Source has been created" : res.statusText;

                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

                    // Update form data
                    const updatedFormValue = {
                        ...formValue,
                        offer_source_id: sourceId,
                    };

                    setOfferSourceId(sourceId);
                    setFormValue(updatedFormValue);
                    setSaveButtonLoading(false);
                    setOpen(false)
                    // Add a new row into a grid data
                    onAfterCreateOrUpdateGridRow(sourceId, updatedFormValue);
                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        } else {
            // Update an existing one
            HttpClient.put<any>(`offer_source/${formValue.offer_source_id}`, formValue)
                .then((res) => {
                    const type = res.status === 200 ? "success" : "error";
                    const text = res.status === 200 ? "Offer Source has been updated" : res.statusText;

                    pushInforming(<Notification closable type={type} header="Success">{text}</Notification>);

                    setSaveButtonLoading(false);
                    setOpen(false);
                    // Update a grid data
                    onAfterCreateOrUpdateGridRow(parseInt(formValue.offer_source_id), formValue);
                })
                .catch(error => {
                    pushInforming(<Notification closable type="error" header="Error" duration={60000}>
                        {error.response.data?.error || error.toString()}
                    </Notification>);
                    setSaveButtonLoading(false);
                });
        }

        setFormError({});
    };

    return (
        <>
            <Drawer open={open} backdrop={false} onClose={() => setOpen(false)} size="lg">
                <Drawer.Header>
                    <Drawer.Title>
                        {offerSourceId === 0 ? 'Add Offer Source' : 'Edit Offer Source'}
                    </Drawer.Title>
                    <Drawer.Actions>
                        <Button onClick={handleSave} appearance="primary" loading={saveButtonLoading}>
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setFormError({});
                            }}
                        >
                            Cancel
                        </Button>
                    </Drawer.Actions>
                </Drawer.Header>
                <Drawer.Body>
                    <Form
                        ref={mainFormRef}
                        onChange={handleChange}
                        onCheck={setFormError}
                        model={model}
                        layout="horizontal"
                        formValue={formValue}
                        formError={formError}
                    >
                        {/*TODO Remove after testing*/}
                        {/*<span>ID: {JSON.stringify(formValue.offer_source_id)}</span><br/>*/}
                        {/*<span>{JSON.stringify(formValue)}</span><br/>*/}
                        {/*<span>{formValue.data_passing}</span>*/}
                        <PanelGroup accordion bordered>
                            <Panel header="General Settings" defaultExpanded>
                                <GeneralSettings onChangeCategory={handleChangeCategory} />
                            </Panel>
                            <Panel header="Configure Tracking Tokens" defaultExpanded>
                                <TrackingTokens data={formValue} />
                            </Panel>
                            <Panel header="Configure Data Passing" defaultExpanded={true}>
                                <DataPassing
                                    fields={formValue.data_passing ? JSON.parse(formValue.data_passing) : []}
                                    onChangeData={handleChangeDataPassing}
                                />
                            </Panel>
                        </PanelGroup>
                    </Form>
                </Drawer.Body>
            </Drawer>
        </>
    );
};

export default OfferSource;
