import React from "react";
import {Col, Grid, Row, SelectPicker, Loader, Button} from "rsuite";
import HttpClient from "../../@Utils/HttpClient";
import {pushErrorNotification} from "../../@Utils/Messager";
import {LOCAL_STORAGE_AUTH_KEYS} from "../../@Context/FirebaseAuthContext";

type UrlDomainManagerSelectProps = {
    formValue: any,
    parentCallback?: any;
}
const UrlDomainManagerSelect: React.FC<UrlDomainManagerSelectProps> = ({
                                                            formValue,
                                                            parentCallback = () => {},
                                                        }) => {

    const [isLoading, setIsLoading] = React.useState('block');
    const [selectedUsers, setSelectedUsers] = React.useState<any>();
    const [users, setUsers] = React.useState<Record<string, any>[]>([]);

    React.useEffect(() => {
        HttpClient
            .get<any>('access_user')
            .then(res => {
                const data = res.data.data;

                const formatUsers =  data.map((item: any) => ({...item, user_id: convertToInt(item.user_id)}))

                const loggedInuser = localStorage.getItem(LOCAL_STORAGE_AUTH_KEYS.EMAIL);
                const managersList = res.data.data;
                const userData = managersList.filter((item: any) => {
                    return item.email === loggedInuser
                })


                if(formValue.user_id !== undefined && formValue.user_id !== '' && !isNaN(formValue.user_id)) {
                    handleManagerChange(formValue.user_id)
                } else {
                    handleManagerChange(userData[0].user_id)
                }

                setUsers(formatUsers)
                setIsLoading('none')

            }).catch(error => {
            pushErrorNotification(error.response.data?.error || error.toString());
        });
    }, []);

    const handleManagerChange = (managerUid: any) => {
        setSelectedUsers(convertToInt(managerUid))

        if (typeof parentCallback === "function") {
            parentCallback({
                ...formValue,
                ...{
                    user_id: managerUid
                }
            });
        }
    };

    return (
        <>
            <Grid fluid>
                <Row>
                        <Col xs={12} sm={12} md={24} lg={24} style={{ textAlign: 'center'}}>
                            <label style={{fontWeight: 'bold'}}>Assign Manager: </label>
                            <br/>
                            <Loader size="md" style={{display: isLoading}}/>
                            {selectedUsers !== undefined ? (
                                <SelectPicker
                                    size="lg"
                                    searchable={true}
                                    cleanable={true}
                                    placeholder="Select User"
                                    value={selectedUsers}
                                    data={users}
                                    valueKey="user_id"
                                    labelKey="email"
                                    onChange={(v) => {
                                        handleManagerChange(v);
                                    }}
                                    style={{  }}
                                />
                            ) : ''}

                        </Col>
                </Row>
            </Grid>
        </>
    )
}

const convertToInt = (value: string | number) => typeof value === 'number' ? value : parseInt(value, 10);

export default UrlDomainManagerSelect;